import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

const BackgroundImageOptionalText = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  return (
    <section className="relative background-section margin">
      <div className="container">
        <LazyLoadImage
          className="cover-img"
          wrapperClassName="stretch"
          src={
            isMobile
              ? get(props, "primary.image.Mobile.url")
              : get(props, "primary.image.url")
          }
          alt={
            isMobile
              ? get(props, "primary.image.Mobile.alt")
              : get(props, "primary.image.alt")
          }
          effect="opacity"
        />
        <div className="copy-wrapper relative top">
          <PrismicRichText render={get(props, "primary.copy")} />
        </div>
      </div>
    </section>
  );
};

export default BackgroundImageOptionalText;
