import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import Slices from "../slices";
import { Redirect } from "react-router-dom";

export default function PrismicPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleLoading = props.loading;

  const isStaging = props.isStaging;

  const whichPage = (path) => {
    if (path === "/") {
      return "home";
    } else if (path === "/404") {
      return "404";
    } else {
      return path.substr(1);
    }
  };

  const pagePath = whichPage(location.pathname);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.page.loading", true)
  );
  const page = useSelector((state) => get(state, "prismic.page.data", false));

  const showGlobalSlices = get(page, "global_slices");

  const globalSlicesLoading = useSelector((state) =>
    get(state, "prismic.global_slices.loading", true)
  );
  const globalSlices = useSelector((state) =>
    get(state, "prismic.global_slices.data.body", false)
  );

  const slices = get(page, "body");

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "page",
      id: pagePath,
    });
    // dispatch({
    //   type: "PRISMIC_LOAD_SINGLE_PAGE",
    //   key: "global_slices",
    // });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      handleLoading(false);
    }
  }, [pageLoading, handleLoading]);

  if (!page && !pageLoading && !isStaging) {
    return <Redirect to={"/404"} />;
  }
  return (
    !pageLoading &&
    page && (
      <div className={`${pagePath}-page`}>
        <Slices slices={slices}></Slices>
        {/* {showGlobalSlices && !globalSlicesLoading && globalSlices && (
          <Slices slices={globalSlices}></Slices>
        )} */}
      </div>
    )
  );
}
