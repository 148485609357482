import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Link from "./../components/Link";

const ImageWithLink = (slice) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const renderImages = (items) => {
    return items.map((el, i) => (
      <div key={i} className="relative img-item mb-40 flex-start-center">
        <Link
          to={"/" + get(el, "link.uid")}
          url={get(el, "link.url")}
          target={get(el, "link.target")}
          className="top black-border mt-40"
        >
          {get(el, "link_text[0].text")}
        </Link>
        <LazyLoadImage
          className="cover-img img-link"
          wrapperClassName="stretch"
          src={isMobile ? get(el, "image.Mobile.url") : get(el, "image.url")}
          alt={isMobile ? get(el, "image.Mobile.alt") : get(el, "image.alt")}
          effect="opacity"
        />
      </div>
    ));
  };

  return (
    <section>
      <div className={` padding ${!isMobile && "grid-3-col"}`}>
        {renderImages(get(slice, "items"))}
      </div>
    </section>
  );
};

export default ImageWithLink;
