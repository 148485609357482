import {createTheme} from "@material-ui/core/styles";

const themeObj = {
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#ad0000",
    },
  },
  typography: {
    fontFamily: "AcuminRegular",
    fontWeight: "normal",
  },
  overrides: {
    MuiButton: {
      root: {
        height: "auto",
        borderRadius: "0px",
        fontSize: "16px",
        borderWidth: "10px",
        fontFamily: "AcuminRegular",
        fontWeight: "normal",
      },
      outlined: {
        padding: "15px 25px",
      },
      contained: {
        padding: "7px 16px",
      },
      sizeSmall: {
        padding: "5px 10px",
        height: "30px",
      },
      containedPrimary: {
        color: "#000000",
      },
      containedSecondary: {
        color: "#ffffff",
        backgroundColor: "#000000",
        padding: "15px 25px",
      },
      outlinedPrimary: {
        border: "1px solid #ffffff",
        transition: "all 200ms ease-in-out",
        "&:hover": {
          border: "1px solid #ffffff",
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
      outlinedSecondary: {
        border: "2px solid #000000",
        transition: "all 200ms ease-in-out",
        color: "#000000",
        textDecoration: "none",
        "&:hover": {
          backgroundColor: "#000000",
          color: "#ffffff",
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: "0",
        borderRadius: "18px",
        width: "53px",
        height: "24px",
      },
      thumb: {
        width: "18px",
        height: "18px",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      },
      colorSecondary: {
        "&&&&$checked + $track": {
          opacity: "1",
          backgroundColor: "gray",
        },
        "&&&&$checked": {
          transform: "translateX(calc(100% + 5px))",
          color: "black",
        },
      },
      switchBase: {
        padding: "3px",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&&&&$checked": {
          transform: "translateX(calc(100% + 5px))",
          color: "white",
        },
        "&&&&$checked + $track": {
          opacity: "1",
          backgroundColor: "black",
        },
        "&&&&$checked + &&&&$disabled + $track": {
          opacity: ".2",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        borderRadius: "0px",
      },
      fontSizeLarge: {
        fontSize: "40px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px",
      },
    },
    MuiMenu: {
      list: {
        backgroundColor: "#ffffff",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "AcuminRegular",
        fontWeight: "normal",
      },
    },
    MuiListItem: {
      button: {
        color: "#000000",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#000000",
        },
        color: "#000000",
      },
    },
    MuiFormControlLabel: {
      root: {
        color: "white",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "#000000",
          borderWidth: "2px",
        },
        "&:hover $notchedOutline": {
          borderColor: "#000000",
          borderWidth: "2px",
        },
        borderRadius: "0",
      },
      notchedOutline: {
        borderColor: "#000000",
        borderWidth: "2px",
      },
    },
    MuiTypography: {
      root: {
        color: "#000000",
      },
    },
  },
};
const theme = createTheme(themeObj);
export {theme, themeObj};
