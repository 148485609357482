import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import {useTheme} from "@material-ui/core/styles";
import PrismicRichText from "../components/PrismicRichText";

const OppositeBackgroundBlocks = (slice) => {
  const theme = useTheme();

  const renderImages = (items) => {
    return items.map((el, i) => (
      <div
        className={`flex-col items-center opp-blocks ${
          get(el, "background_color") === "black" ? "black" : "silver"
        }`}
      >
        <LazyLoadImage
          className="cover-img"
          src={get(el, "image.url")}
          alt={get(el, "image.alt")}
          effect="opacity"
          wrapperClassName="opp-block-img"
        />
        <div className="center-align">
          <PrismicRichText render={get(el, "copy")} />
        </div>
      </div>
    ));
  };

  return (
    <section>
      <div className="padding">
        <div className="grid-cols-bigger">
          {renderImages(get(slice, "items"))}
        </div>
      </div>
    </section>
  );
};

export default OppositeBackgroundBlocks;
