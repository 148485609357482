import * as prismic from "@prismicio/client";

const endpoint = prismic.getRepositoryEndpoint("oneplantproduction");
const accessToken =
  "MC5ZVWpodUJJQUFDTUFtVGVX.Ye-_vVjvv70g77-9JFUvTUvvv702cQpn77-9Sy4eUlV0Wu-_vRoeB--_vVBgYg"; // Set an access token
const routes = [];

const client = prismic.createClient(endpoint, {routes, accessToken});

export default client;
