import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    const elements = document.getElementsByClassName("custom-animation");
    window.onscroll = () => {
      for (let el of elements) {
        if (el.offsetTop - window.outerHeight * 0.75 < window.pageYOffset) {
          el.classList.add("animation-active");
        }
        if (
          el.offsetTop + el.clientHeight - window.outerHeight <
          window.pageYOffset
        ) {
          el.classList.add("scrolled-to-bottom");
        }
      }

      // const videoEl = document.getElementById("home-video");

      // if (videoEl && !videoHasAutoplayed) {
      //   const elTop = videoEl.offsetTop;
      //   const scroll = window.pageYOffset;

      //   if (scroll > elTop - window.outerHeight) {
      //     videoEl.play();
      //   }
      // }
    };
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
