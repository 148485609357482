import React, {useState} from "react";
import Link from "../Link";
import {useSelector} from "react-redux";
import {get} from "lodash";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const [navOpen, setNavOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const activePage = props.activePage;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const componentLoading = useSelector((state) =>
    get(state, "prismic.header.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.header.data", false)
  );

  const logo = get(component, "logo.url", false);
  const navigationLinks = get(component, "navigation", false);

  const renderNavigation = (navigationLinks) => {
    return navigationLinks.map((el, i) => {
      const link = el.link_url.uid === "home" ? "" : el.link_url.uid;

      return (
        <li key={i}>
          <Link
            onClick={() => {
              setNavOpen(false);
            }}
            key={i}
            to={`/${link}`}
            className={
              activePage === `/${el.link_url.uid}` ? "active" : undefined
            }
          >
            {el.link_text[0].text}
          </Link>
        </li>
      );
    });
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  if (componentLoading) {
    return (
      <div style={{height: "80px", width: "100%", background: "#ffffff"}}></div>
    );
  }

  return (
    !componentLoading &&
    component && (
      <>
        <div className="header-spacer"></div>
        <div className="header-wrapper">
          <header>
            <div className="wrapper">
              <Link
                to="/"
                className={`logo-container ${navOpen ? "hide" : null}`}
              >
                <img id="logo" className="logo" src={logo} alt="Brand Logo" />
              </Link>

              {isMobile ? (
                <>
                  <IconButton
                    onClick={toggleNav}
                    className="menu-icon"
                    edge="end"
                    aria-label="menu open"
                  >
                    <MenuIcon fontSize="large" htmlColor="#000000" />
                  </IconButton>

                  <Drawer anchor="left" open={navOpen} className="nav-drawer">
                    <ul className="nav-drawer-content">
                      <IconButton
                        onClick={toggleNav}
                        className="menu-icon-close"
                        edge="end"
                        aria-label="menu close"
                      >
                        <CloseIcon fontSize="large" htmlColor="#000000" />
                      </IconButton>

                      {renderNavigation(navigationLinks)}
                    </ul>
                  </Drawer>
                </>
              ) : (
                <ul className="desktop-navigation">
                  {renderNavigation(navigationLinks)}
                </ul>
              )}
            </div>
          </header>
        </div>
      </>
    )
  );
}
