import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get, sortBy} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Link from "./../components/Link";
import PrismicRichText from "../components/PrismicRichText";

const FourColImgGrid = (slice) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const renderImages = (items) => {
    const sortedItems = sortBy(items, ["location_name[0].text"]);
    return sortedItems.map((el, i) => (
      <Link
        to={"/" + get(el, "link.uid")}
        url={get(el, "link.url")}
        target={get(el, "link.target")}
        className="no-underline-black"
        key={i}
      >
        <LazyLoadImage
          className="cover-img"
          src={isMobile ? get(el, "image.Mobile.url") : get(el, "image.url")}
          alt={isMobile ? get(el, "image.Mobile.alt") : get(el, "image.alt")}
          effect="opacity"
        />
        <div className="center-align four-col-text">
          <PrismicRichText render={get(el, "location_name")} />
        </div>
      </Link>
    ));
  };

  return (
    <section>
      <div className="padding">
        <PrismicRichText render={get(slice, "primary.content")} />
        <div className="four-col">{renderImages(get(slice, "items"))}</div>
      </div>
    </section>
  );
};

export default FourColImgGrid;
