import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import renderButtons from "./../helpers/renderButtons";
import {get} from "lodash";

export default function Text2Columns(slice) {
  const backgroundStyle = {
    backgroundColor: get(slice, "primary.background_color"),
    color: get(slice, "primary.text_color"),
  };

  return (
    <section className="padding" style={backgroundStyle}>
      <div className="wrapper two-col">
        <div>
          <PrismicRichText render={get(slice, "primary.heading_copy")} />
          {slice.items && (
            <div className="buttons mt-mb-30">
              {renderButtons(get(slice, "items"))}
            </div>
          )}
        </div>
        <div className="text-content">
          <PrismicRichText render={get(slice, "primary.content")} />
        </div>
      </div>
    </section>
  );
}
