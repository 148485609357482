import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import PrismicRichText from "../components/PrismicRichText";
import Link from "./../components/Link";

const MobileView = ({slice}) => {
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({getItemById, scrollToItem}) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} =
      React.useContext(VisibilityContext);
    return (
      <button
        className="slider-btn left"
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        ariaLabel="scroll left"
      >
        <span className="triangle triangle-left relative"></span>
      </button>
    );
  }

  function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);
    return (
      <button
        className="slider-btn right"
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
        ariaLabel="scroll right"
      >
        <span className="triangle triangle-right relative"></span>
      </button>
    );
  }

  function Card({children, itemId}) {
    const visibility = React.useContext(VisibilityContext);
    return <div className="img-card">{children}</div>;
  }

  const renderImages = (items) => {
    return items.map((el, i) => (
      <Card
        itemId={i}
        key={i}
        onClick={handleClick(i)}
        selected={isItemSelected(i)}
      >
        <LazyLoadImage
          className="cover-img"
          src={get(el, "image.url")}
          alt={get(el, "image.alt")}
          effect="opacity"
        />
      </Card>
    ));
  };

  return (
    <section className="scroller">
      {get(slice, "primary.copy[0].text") && (
        <div className="slider-copy">
          <PrismicRichText render={get(slice, "primary.copy")} />
        </div>
      )}
      <div>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {renderImages(get(slice, "items"))}
        </ScrollMenu>
      </div>

      {get(slice, "primary.link_for_ig") && (
        <Link
          to={"/" + get(slice, "primary.link_for_ig.uid")}
          url={get(slice, "primary.link_for_ig.url")}
          target={get(slice, "primary.link_for_ig.target")}
        >
          <img
            src={get(slice, "primary.link_for_ig_image.url")}
            alt=""
            presentation="presentation"
            className="ig-img"
          />
        </Link>
      )}
    </section>
  );
};

const DesktopView = ({slice}) => {
  const renderImages = (items) => {
    return items.map((el, i) => (
      <div key={i}>
        <LazyLoadImage
          className="cover-img "
          src={get(el, "image.url")}
          alt={get(el, "image.alt")}
          effect="opacity"
        />
      </div>
    ));
  };

  return (
    <section>
      {get(slice, "primary.copy[0].text") && (
        <div className="slider-copy">
          <PrismicRichText render={get(slice, "primary.copy")} />
        </div>
      )}
      <div className="grid-cols padding">
        {renderImages(get(slice, "items"))}
      </div>
      {get(slice, "primary.link_for_ig") && (
        <Link
          to={"/" + get(slice, "primary.link_for_ig.uid")}
          url={get(slice, "primary.link_for_ig.url")}
          target={get(slice, "primary.link_for_ig.target")}
        >
          <img
            src={get(slice, "primary.link_for_ig_image.url")}
            alt=""
            presentation="presentation"
            className="ig-img"
          />
        </Link>
      )}
    </section>
  );
};

function ImageSlider(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  return isMobile ? (
    <MobileView slice={slice} />
  ) : (
    <DesktopView slice={slice} />
  );
}

export default ImageSlider;
