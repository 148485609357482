import React from "react";
import TextSlice from "./TextSlice";
import FullWidthImageSlice from "./FullWidthImageSlice";
import StandardHero from "./StandardHero";
import ImageCopy from "./ImageCopy";
import ImageWithLink from "./ImageWithLink";
import BackgroundImageOptionalText from "./BackgroundImageOptionalText";
import Text2Columns from "./Text2Columns";
import FourColImgGrid from "./FourColImgGrid";
import OppositeBackgroundBlocks from "./OppositeBackgroundBlocks";
import ImageSlider from "./ImageSlider";
import DoctorLocatorSlice from "./DoctorLocatorSlice";

export default function Slices(props) {
  if (!document) {
    return null;
  }
  return (
    <div className="slice-wrapper">
      {props.slices.map((slice, index) => (
        <div
          key={index}
          className={`slice-single ${
            slice.type === slice.full_width_text_image_slice &&
            slice.primary.half_width
              ? "half-width"
              : "full-width"
          }`}
        >
          {slice.slice_type === "text_slice" && TextSlice(slice)}
          {slice.slice_type === "image_with_link_slice" && ImageWithLink(slice)}
          {slice.slice_type === "image_copy_slice" && ImageCopy(slice)}
          {slice.slice_type === "full_width_image_slice" &&
            FullWidthImageSlice(slice)}
          {slice.slice_type === "standard_hero" && StandardHero(slice)}
          {slice.slice_type === "background_image_optional_text" &&
            BackgroundImageOptionalText(slice)}
          {slice.slice_type === "text_2_columns" && Text2Columns(slice)}
          {slice.slice_type === "4_column_image_grid" && FourColImgGrid(slice)}
          {slice.slice_type === "opposite_background_blocks" &&
            OppositeBackgroundBlocks(slice)}
          {slice.slice_type === "image_slider" && ImageSlider(slice)}
          {slice.slice_type === "doctor_locator_slice" &&
            DoctorLocatorSlice(slice)}
        </div>
      ))}
    </div>
  );
}
