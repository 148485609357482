import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {useSelector} from "react-redux";
import {get} from "lodash";
import PrismicRichText from "../PrismicRichText";
import AccessibilitySwitch from "../AccessibilitySwitch";
import Link from "../Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import CookieSettingsLink from "./CookieSettingsLink";

const Footer = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const componentLoading = useSelector((state) =>
    get(state, "prismic.footer.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.footer.data", false)
  );

  const headerLoading = useSelector((state) =>
    get(state, "prismic.header.loading", true)
  );
  const header = useSelector((state) =>
    get(state, "prismic.header.data", false)
  );

  const navigationLinks = get(header, "navigation", false);

  const backgroundColor = get(component, "background_color");
  const textColor = get(component, "text_color");

  const backgroundStyle = {
    color: textColor,
    background: backgroundColor,
  };

  const renderNavigation = (navigationLinks) => {
    return navigationLinks.map((el, i) => {
      return (
        <li key={i}>
          <Link key={i} to={`/${el.link_url.uid}`}>
            {el.link_text[0].text}
          </Link>
        </li>
      );
    });
  };

  const renderSocialLinks = (socialLinks) => {
    return socialLinks.map((el, i) => (
      <Link
        className="social-link"
        key={i}
        url={get(el, "link.url")}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="social-icon" src={el.icon.url} alt="Social Icon"></img>
      </Link>
    ));
  };

  const renderLegalLinks = (legalLinks) => {
    return legalLinks.map((el, i) => (
      <li key={i}>
        <Link
          to={"/" + get(el, "link.uid")}
          url={get(el, "link.url")}
          target={get(el, "link.target")}
          className=""
        >
          {get(el, "link_text[0].text")}
        </Link>
      </li>
    ));
  };

  return (
    !componentLoading &&
    component && (
      <footer className="section-padding" style={backgroundStyle}>
        <div className={`${!isMobile && "flex-col-center"}`}>
          <LazyLoadImage
            className="cover-img footer-img"
            wrapperClassName="block"
            src={
              isMobile
                ? get(component, "image.Mobile.url")
                : get(component, "image.url")
            }
            alt={
              isMobile
                ? get(component, "image.Mobile.alt")
                : get(component, "image.alt")
            }
            effect="opacity"
          />
          {!headerLoading && (
            <div className="footer-container footer-nav">
              <ul className="flex-center wrapper">
                {renderNavigation(navigationLinks)}
              </ul>
            </div>
          )}

          <div className="social footer-container">
            <div className="sm-legal-container">
              <div className="sm-legal-content">
                <div className="social-icons flex-center wrapper">
                  {renderSocialLinks(component.social_links)}
                </div>
                <div className="accessibility">
                  <AccessibilitySwitch />
                  <CookieSettingsLink />
                </div>
              </div>
            </div>

            <div className="flex-center wrapper">
              <ul className="flex-center wrapper legal-links">
                {renderLegalLinks(get(component, "legal_links"))}
              </ul>
            </div>

            <div className="footer-container copyright center-align">
              <PrismicRichText render={component?.copyright} />
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default Footer;
