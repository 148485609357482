import React from "react";
import {RichText} from "prismic-reactjs";
import renderButtons from "./../helpers/renderButtons";
import {get} from "lodash";

export default function TextSlice(slice) {
  const backgroundStyle = {
    backgroundColor: get(slice, "primary.background_color"),
    color: get(slice, "primary.text_color"),
  };

  const boldText = get(slice, "primary.big_bold_text_style");

  return (
    <section
      className={`padding2 ${boldText === true ? "boldText" : ""}`}
      style={backgroundStyle}
    >
      <div className="wrapper">
        <div className="text-content">
          <RichText render={get(slice, "primary.content")} />
          {slice.items && (
            <div className="buttons">{renderButtons(get(slice, "items"))}</div>
          )}
        </div>
      </div>
    </section>
  );
}
