import React from "react";
import PrismicPage from "./pages/PrismicPage";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";

export default function Routes({loading, isStaging}) {
  const {pathname} = useLocation();

  const prismicPage = () => {
    return <PrismicPage loading={loading} isStaging={isStaging} />;
  };

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route exact path="/contact">
        <Redirect to="/404" />
      </Route>
      <Route path="/:id" render={prismicPage} />
      <Route exact path="/" render={prismicPage} />
    </Switch>
  );
}
