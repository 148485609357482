import Prismic from 'prismic-javascript';
// -- Prismic API endpoint
const apiEndpoint = 'https://oneplantproduction.prismic.io/api/v2';
// -- Access Token if the repository is not public
const accessToken =
  'MC5ZVWpodUJJQUFDTUFtVGVX.Ye-_vVjvv70g77-9JFUvTUvvv702cQpn77-9Sy4eUlV0Wu-_vRoeB--_vVBgYg';
// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, { accessToken });

export default PrismicClient;
