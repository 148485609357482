import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import renderButtons from "../helpers/renderButtons";

const ImageCopy = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  return (
    <section className={`${!isMobile && "padding"}`}>
      <div className={`container ${!isMobile && "grid-2-col"}`}>
        <div
          className={`copy-wrapper padding ${!isMobile && "flex-col-center"}`}
        >
          <PrismicRichText render={get(props, "primary.copy")} />
          <div className="button-container mt-30">
            {renderButtons(get(props, "items"))}
          </div>
        </div>
        <LazyLoadImage
          className="cover-img"
          wrapperClassName={`${!isMobile && "order-1"}`}
          src={
            isMobile
              ? get(props, "primary.image.Mobile.url")
              : get(props, "primary.image.url")
          }
          alt={
            isMobile
              ? get(props, "primary.image.Mobile.alt")
              : get(props, "primary.image.alt")
          }
          effect="opacity"
        />
      </div>
    </section>
  );
};

export default ImageCopy;
