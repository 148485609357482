export default [
  {
    name: "ABDALLAH, NADER",
    address: "8996 Alexandra circle",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 891-9810",
    lat: 26.645663,
    lng: -80.182625,
    id: 1,
  },
  {
    name: "ABDOOL, SHEREZA",
    address: "39344 US HWY 19N",
    city: "TARPON SPRINGS",
    state: "FL",
    zip: 34689,
    phone: "(727) 940-5278",
    lat: 28.148306,
    lng: -82.759877,
    id: 2,
  },
  {
    name: "ABDULFATTAH, QAHTAN",
    address: "11323 CORTEZ BLVD",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 596-8344",
    lat: 28.536624,
    lng: -82.535912,
    id: 3,
  },
  {
    name: "ABELLO, ROBERT",
    address: "5000 UNIVERSITY DRIVE",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33146,
    phone: "(786) 308-3902",
    lat: 25.724742,
    lng: -80.273896,
    id: 4,
  },
  {
    name: "ABIERA, CESAR",
    address: "2729 SOUTH WOODLAND BLVD.",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(386) 943-8883",
    lat: 28.98045,
    lng: -81.297265,
    id: 5,
  },
  {
    name: "ABINADER, AZAREL",
    address: "1330 CORAL WAY , SUITE 401",
    city: "MIAMI",
    state: "FL",
    zip: 33145,
    phone: "(305) 854-0919",
    lat: 25.748303,
    lng: -80.282985,
    id: 6,
  },
  {
    name: "ABRAHAM, ARUN",
    address: "2577 Gunn Highway , Apt 316",
    city: "ODESSA",
    state: "FL",
    zip: 33556,
    phone: "(321) 412-7243",
    lat: 28.19589,
    lng: -82.592749,
    id: 7,
  },
  {
    name: "ABU SHAHIN, FADI",
    address: "8931 COLONIAL CENTER DR , SUITE 400",
    city: "FORT MYERS",
    state: "FL",
    zip: 33905,
    phone: "(216) 444-4884",
    lat: 26.614848,
    lng: -81.808774,
    id: 8,
  },
  {
    name: "ABUGRARA, HAZEM",
    address: "201 300 Pinellas Street, 24 ON Physicians, P.C.",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(813) 557-8675",
    lat: 27.952803,
    lng: -82.803272,
    id: 9,
  },
  {
    name: "ACEVEDO, PABLO",
    address: "15119 US Highway 19",
    city: "HUDSON",
    state: "FL",
    zip: 34667,
    phone: "(727) 919-4295",
    lat: 28.377008,
    lng: -82.681999,
    id: 10,
  },
  {
    name: "ACLOQUE, GERARD",
    address: "11750 SW 40TH ST",
    city: "MIAMI",
    state: "FL",
    zip: 33175,
    phone: "(305) 222-5685",
    lat: 25.730915,
    lng: -80.386247,
    id: 11,
  },
  {
    name: "ADELEYE, MICHAEL",
    address: "1613 N. HARRISON PARKWAY , #200",
    city: "SUNRISE",
    state: "FL",
    zip: 33323,
    phone: "(954) 846-5409",
    lat: 26.143986,
    lng: -80.331946,
    id: 12,
  },
  {
    name: "ADILI, TAGRID",
    address: "463 NW PRIMA VISTA BOULEVARD",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34983,
    phone: "(772) 807-7166",
    lat: 27.318412,
    lng: -80.358988,
    id: 13,
  },
  {
    name: "ADLER, JONATHAN",
    address: "1509 53RD AVENUE WEST",
    city: "BRADENTON",
    state: "FL",
    zip: 34207,
    phone: "(941) 753-0220",
    lat: 27.448137,
    lng: -82.577643,
    id: 14,
  },
  {
    name: "AFZAL, AJAZ",
    address: "1032 MANN STREET",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 518-7277",
    lat: 28.301713,
    lng: -81.414234,
    id: 15,
  },
  {
    name: "AGBI, ANDREW",
    address: "1613 N Harrison Parkway",
    city: "SUNRISE",
    state: "FL",
    zip: 33323,
    phone: "(718) 960-9000",
    lat: 26.143986,
    lng: -80.331946,
    id: 16,
  },
  {
    name: "AGGARWAL, BARBARA",
    address: "7932 W. Sand Lake Road , STE 205",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(855) 420-9170",
    lat: 28.448733,
    lng: -81.49113,
    id: 17,
  },
  {
    name: "AGIN, BRENT",
    address: "2744 summerdale drive",
    city: "CLEARWATER",
    state: "FL",
    zip: 33761,
    phone: "(727) 230-1438",
    lat: 28.021156,
    lng: -82.734184,
    id: 18,
  },
  {
    name: "AGRAN, JASON",
    address: "2935 Kerry Forest Pkwy , Suite A",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32309,
    phone: "(888) 353-2394",
    lat: 30.539889,
    lng: -84.223695,
    id: 19,
  },
  {
    name: "AGRESTI, MARK",
    address: "44 COCOANUT ROW, SUITE M 202",
    city: "PALM BEACH",
    state: "FL",
    zip: 33480,
    phone: "(561) 842-9550",
    lat: 26.715404,
    lng: -80.041542,
    id: 20,
  },
  {
    name: "Aguiar, Vivian",
    address: "3200 3200 s congress ave",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33426,
    phone: "(561) 336-4776",
    lat: 26.49568,
    lng: -80.08939,
    id: 21,
  },
  {
    name: "AHMAD, BURHAAN",
    address: "3909 GALEN COURT , SUITE 102",
    city: "SUN CITY CENTER",
    state: "FL",
    zip: 33573,
    phone: "(813) 634-5502",
    lat: 27.713881,
    lng: -82.368125,
    id: 22,
  },
  {
    name: "AHMADI, RAMESH",
    address: "708 NE 125 St , SUITE 205",
    city: "MIAMI",
    state: "FL",
    zip: 33161,
    phone: "(305) 576-6611",
    lat: 25.890228,
    lng: -80.184305,
    id: 23,
  },
  {
    name: "AHMED, ABDUL QUYYUM",
    address: "2605 WEST LAKE MARY BLVD., #119",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 878-7990",
    lat: 28.755188,
    lng: -81.325308,
    id: 24,
  },
  {
    name: "AHMED, ALI",
    address: "3231 McMullen Booth Road",
    city: "SAFETY HARBOR",
    state: "FL",
    zip: 34695,
    phone: "(419) 356-0566",
    lat: 28.038777,
    lng: -82.708031,
    id: 25,
  },
  {
    name: "AILANI, RAJESH",
    address: "1055 N DIXIE FREEWAY , SUITE 1",
    city: "NEW SMYRNA BEACH",
    state: "FL",
    zip: 32168,
    phone: "(386) 423-0505",
    lat: 29.041231,
    lng: -80.933533,
    id: 26,
  },
  {
    name: "ALDARONDO, SIGFREDO",
    address: "1110 N. KENTUCKY AVE.",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 539-2766",
    lat: 28.591755,
    lng: -81.363464,
    id: 27,
  },
  {
    name: "ALEA, TODD",
    address: "925 N.E. 30TH TERRACE , SUITE 314",
    city: "HOMESTEAD",
    state: "FL",
    zip: 33033,
    phone: "(786) 243-2950",
    lat: 25.47874,
    lng: -80.4338,
    id: 28,
  },
  {
    name: "ALEGRET, RAMON",
    address: "7171 SW 24th STREET, Suite 311",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(305) 221-0200",
    lat: 25.748483,
    lng: -80.310775,
    id: 29,
  },
  {
    name: "Aleksandrova, Olga",
    address: "7491 Conroy Windermere Rd , First Floor, Suite C",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 717-4400",
    lat: 28.494031,
    lng: -81.483072,
    id: 30,
  },
  {
    name: "ALFRED, GREGORY",
    address: "2866 East Oakland Park Blvd",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33306,
    phone: "(305) 812-5206",
    lat: 26.16698,
    lng: -80.106586,
    id: 31,
  },
  {
    name: "Alhemovich, Danielle",
    address: "11140 W Colonial Drive",
    city: "OCOEE",
    state: "FL",
    zip: 34761,
    phone: "(407) 877-6500",
    lat: 28.551158,
    lng: -81.544621,
    id: 32,
  },
  {
    name: "ALI, MOHAMMED",
    address: "16594 N Dale Mabry Hwy.",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(813) 944-1944",
    lat: 28.107815,
    lng: -82.504322,
    id: 33,
  },
  {
    name: "Alif, Razan",
    address: "10115 Forest Hill Blvd , ste 200",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 795-0016",
    lat: 26.654692,
    lng: -80.204267,
    id: 34,
  },
  {
    name: "ALIU, KINGSLEY",
    address: "1028 Dunn Avenue",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32218,
    phone: "(904) 627-7443",
    lat: 30.428294,
    lng: -81.662482,
    id: 35,
  },
  {
    name: "ALLEGO, MELQUIADITO",
    address: "907 NW ZACK DRIVE",
    city: "LAKE CITY",
    state: "FL",
    zip: 32055,
    phone: "(727) 612-7325",
    lat: 30.19396,
    lng: -82.723364,
    id: 36,
  },
  {
    name: "ALLEN, BRYAN",
    address: "200 3RD AVE WEST , SUITE 210",
    city: "BRADENTON",
    state: "FL",
    zip: 34205,
    phone: "(813) 420-7656",
    lat: 27.497402,
    lng: -82.564972,
    id: 37,
  },
  {
    name: "ALLEN, ROBERT",
    address: "9500 Corkscrew Palms Circle , SUITE #1",
    city: "ESTERO",
    state: "FL",
    zip: 33928,
    phone: "(239) 275-6001",
    lat: 26.430989,
    lng: -81.798484,
    id: 38,
  },
  {
    name: "ALMEYDA RUIZ, REBECCA",
    address: "2900 Veteran's Way",
    city: "VIERA",
    state: "FL",
    zip: 32940,
    phone: "(321) 637-3788",
    lat: 28.25525,
    lng: -80.73961,
    id: 39,
  },
  {
    name: "ALONSO, ALEX",
    address: "3470 NW 82nd Avenue , Suite 119",
    city: "DORAL",
    state: "FL",
    zip: 33122,
    phone: "(305) 788-9565",
    lat: 25.807221,
    lng: -80.330068,
    id: 40,
  },
  {
    name: "ALONSO, DELILAH",
    address: "6705 RED ROAD, SUITE 518",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33143,
    phone: "(305) 403-1181",
    lat: 25.706322,
    lng: -80.284671,
    id: 41,
  },
  {
    name: "ALSABBAGH, EYAD",
    address: "12148 CORTEZ BLVD",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 597-7184",
    lat: 28.532871,
    lng: -82.526038,
    id: 42,
  },
  {
    name: "ALSBROOK, EVERETT",
    address: "1060 1060 11th St NORTH",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 777-1275",
    lat: 26.161326,
    lng: -81.795579,
    id: 43,
  },
  {
    name: "ALSHON, JOSEPH",
    address: "7171 NORTH UNIVERSITY DRIVE , SUITE 300",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 720-3188",
    lat: 26.210802,
    lng: -80.253156,
    id: 44,
  },
  {
    name: "ALT, SUZANNE",
    address: "1715 W. Worley St. , SUITE B",
    city: "COLUMBIA",
    state: "MO",
    zip: 65203,
    phone: "(573) 875-4877",
    lat: 38.961141,
    lng: -92.365294,
    id: 45,
  },
  {
    name: "ALTBUCH, ARTHUR",
    address: "383 Tree Side Lane",
    city: "PONTE VEDRA",
    state: "FL",
    zip: 32081,
    phone: "(904) 337-9885",
    lat: 30.075516,
    lng: -81.385921,
    id: 46,
  },
  {
    name: "ALTMAN, ALAN",
    address: "11632 NORTH KENDALL DRIVE , SUITE 103",
    city: "KENDALL",
    state: "FL",
    zip: 33176,
    phone: "(305) 456-6289",
    lat: 25.68788,
    lng: -80.342688,
    id: 47,
  },
  {
    name: "ALVARADO, RONY",
    address: "2300 E COUNTY RD , 540A",
    city: "LAKELAND",
    state: "FL",
    zip: 33813,
    phone: "(863) 393-9447",
    lat: 27.953956,
    lng: -81.920788,
    id: 48,
  },
  {
    name: "Alvarado-Hernandez, Luis",
    address: "13696 US 441 , STE 100",
    city: "THE VILLAGES",
    state: "FL",
    zip: 32159,
    phone: "(352) 259-2159",
    lat: 28.955174,
    lng: -81.962044,
    id: 49,
  },
  {
    name: "ALZAMORA, MARTHA",
    address: "2650 1ST AVENUE SOUTH",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33712,
    phone: "(516) 451-2415",
    lat: 27.769833,
    lng: -82.669585,
    id: 50,
  },
  {
    name: "AMADI, HEPSHARAT",
    address: "10189 W. SAMPLE RD.",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 757-0064",
    lat: 26.27266,
    lng: -80.26197,
    id: 51,
  },
  {
    name: "AMOLS, LANCE",
    address: "2201 45th St",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33407,
    phone: "(561) 842-6141",
    lat: 26.761404,
    lng: -80.087838,
    id: 52,
  },
  {
    name: "AMSHEL, CRAIG",
    address: "1046 CYPRESS VILLAGE BOULEVARD",
    city: "SUN CITY CENTER",
    state: "FL",
    zip: 33573,
    phone: "(813) 633-0081",
    lat: 27.726952,
    lng: -82.381303,
    id: 53,
  },
  {
    name: "AMUNATEGUI, ANDREW",
    address: "21355 EAST DIXIE HIGHWAY, SUITE 109",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 931-9316",
    lat: 25.972875,
    lng: -80.146789,
    id: 54,
  },
  {
    name: "Anderson, Brian",
    address: "10910 San Jose Boulevard",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32223,
    phone: "(904) 407-8500",
    lat: 30.177828,
    lng: -81.62874,
    id: 55,
  },
  {
    name: "ANDERSON, KATHY",
    address: "510 E. DRUID ROAD, , SUITE A",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 462-5242",
    lat: 27.957689,
    lng: -82.799052,
    id: 56,
  },
  {
    name: "ANDERSON, PAUL",
    address: "1901 Ulmerton Rd , Ste 310",
    city: "CLEARWATER",
    state: "FL",
    zip: 33762,
    phone: "(239) 289-4129",
    lat: 27.895092,
    lng: -82.658868,
    id: 57,
  },
  {
    name: "ANDRAWIS, ASHRAF",
    address: "1543 KINGSLEY AVENUE , BLDG. 3",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 264-0770",
    lat: 30.165513,
    lng: -81.728322,
    id: 58,
  },
  {
    name: "ANDRE, PIERRE",
    address: "16244 South Military Trail 320",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 499-9506",
    lat: 26.43584,
    lng: -80.12201,
    id: 59,
  },
  {
    name: "ANDRE, VALENTIN",
    address: "1272 NW 119TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33167,
    phone: "(954) 278-7429",
    lat: 25.883113,
    lng: -80.220095,
    id: 60,
  },
  {
    name: "ANDREADIS, DEMETRI",
    address: "1309 N FLAGLER DR",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33401,
    phone: "(561) 655-5511",
    lat: 26.725171,
    lng: -80.051685,
    id: 61,
  },
  {
    name: "ANDRIOLA, MICHAEL",
    address: "430 MORTON PLANT STREET , #402",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 461-8635",
    lat: 27.953245,
    lng: -82.800986,
    id: 62,
  },
  {
    name: "ANDRONICO, KENNETH",
    address: "28945 STATE ROAD 54 , SUITE 101",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33543,
    phone: "(813) 907-0950",
    lat: 28.240335,
    lng: -82.33727,
    id: 63,
  },
  {
    name: "ANGELASTRO, NICHOLAS",
    address: "1511 S Tamiami Trail , SUITE 201",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(941) 218-6839",
    lat: 27.078302,
    lng: -82.428333,
    id: 64,
  },
  {
    name: "ANGELILLO, MICHAEL",
    address: "6752 NW 62ND TERRACE , SUITE 510",
    city: "PARKLAND",
    state: "FL",
    zip: 33067,
    phone: "(954) 709-4788",
    lat: 26.313419,
    lng: -80.208863,
    id: 65,
  },
  {
    name: "ANSCHUETZ, DANNELL",
    address: "1600 S.FEDERAL HWY , SUITE 550",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33062,
    phone: "(954) 533-8400",
    lat: 26.210209,
    lng: -80.106513,
    id: 66,
  },
  {
    name: "ANTHONY II, CHARLES",
    address: "6002 POINTE WEST BLVD.",
    city: "BRADENTON",
    state: "FL",
    zip: 34209,
    phone: "(941) 792-2020",
    lat: 27.479987,
    lng: -82.621848,
    id: 67,
  },
  {
    name: "ANTIPORDA, GLORIOSA",
    address: "6349 Beach Blvd",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(352) 215-8106",
    lat: 30.291212,
    lng: -81.595903,
    id: 68,
  },
  {
    name: "ANTOINE, MOGIN",
    address: "915 NE 125 Street , Ste 301",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33161,
    phone: "(305) 836-1421",
    lat: 25.890827,
    lng: -80.179987,
    id: 69,
  },
  {
    name: "ANTON, AMERICO",
    address: "6801 Lake Worth Road , Suite 340",
    city: "GREENACRES",
    state: "FL",
    zip: 33467,
    phone: "(561) 386-7455",
    lat: 26.617747,
    lng: -80.152323,
    id: 70,
  },
  {
    name: "ANTONYRAJAH, BERNADETTE",
    address: "5151 Winter Garden Vineland Road , Suite 208",
    city: "WINDERMERE",
    state: "FL",
    zip: 34786,
    phone: "(407) 635-3280",
    lat: 28.492096,
    lng: -81.584139,
    id: 71,
  },
  {
    name: "AQUA, KEITH",
    address: "1395 STATE ROAD 7 , #450",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33414,
    phone: "(561) 798-1233",
    lat: 26.655789,
    lng: -80.204181,
    id: 72,
  },
  {
    name: "ARAIZA, JOSEPH",
    address: "50 NORTH EAST 26 TH AVE, SUITE 404",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33062,
    phone: "(954) 633-7155",
    lat: 26.232673,
    lng: -80.097588,
    id: 73,
  },
  {
    name: "ARENA, JOSEPH",
    address: "1500 E HILLBORO BLVD , #207",
    city: "DEERFIELD BEACH",
    state: "FL",
    zip: 33441,
    phone: "(954) 715-3334",
    lat: 26.311102,
    lng: -80.09787,
    id: 74,
  },
  {
    name: "Arevalo, Catherine",
    address: "115 EAST LANCASTER ROAD, #A",
    city: "ORLANDO",
    state: "FL",
    zip: 32809,
    phone: "(407) 888-8411",
    lat: 28.465845,
    lng: -81.374262,
    id: 75,
  },
  {
    name: "ARMAS, EDDIE",
    address: "7000 SW 97 Avenue , SUITE 210",
    city: "MIAMI",
    state: "FL",
    zip: 33173,
    phone: "(305) 284-8483",
    lat: 25.702834,
    lng: -80.350839,
    id: 76,
  },
  {
    name: "ARNE, ELMER",
    address: "1250 SOUTH TAMIAMI TRAIL , SUITE 401",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 366-2194",
    lat: 27.321769,
    lng: -82.530096,
    id: 77,
  },
  {
    name: "Arnold, David",
    address: "2925 Aventura Blvd , Suite 205",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(786) 505-6548",
    lat: 25.961549,
    lng: -80.142795,
    id: 78,
  },
  {
    name: "ARNOLD, PAUL",
    address: "2885 triggerfish St.",
    city: "MATLACHA",
    state: "FL",
    zip: 33993,
    phone: "(239) 415-1900",
    lat: 26.637701,
    lng: -82.076906,
    id: 79,
  },
  {
    name: "ARNOLD, PIERCE",
    address: "2263 W New Haven Ave , #350",
    city: "MELBOURNE",
    state: "FL",
    zip: 32904,
    phone: "(321) 216-2288",
    lat: 28.077788,
    lng: -80.6595,
    id: 80,
  },
  {
    name: "ARORA, SIDDHARTH",
    address: "1811 S. Orlando Ave , Suite A",
    city: "COCOA BEACH",
    state: "FL",
    zip: 32931,
    phone: "(844) 782-6963",
    lat: 28.287787,
    lng: -80.608863,
    id: 81,
  },

  {
    name: "ARRIETA, AGUSTIN",
    address: "8940 N. KENDALL DRIVE , SUITE # 504E",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 595-6200",
    lat: 25.687634,
    lng: -80.340991,
    id: 83,
  },
  {
    name: "ARUMUGAM, ROGER",
    address: "3323 Medical Hill Road",
    city: "SEBRING",
    state: "FL",
    zip: 33870,
    phone: "(863) 471-9377",
    lat: 27.468409,
    lng: -81.435422,
    id: 84,
  },
  {
    name: "ARVIV, TALI",
    address: "11327 Countryway Boulevard",
    city: "TAMPA",
    state: "FL",
    zip: 33626,
    phone: "(813) 855-0111",
    lat: 28.064318,
    lng: -82.62437,
    id: 85,
  },
  {
    name: "ASARE, KINGSLEY",
    address: "9555 SEMINOLE BLVD , SUITE 100",
    city: "SEMINOLE",
    state: "FL",
    zip: 33772,
    phone: "(727) 729-9000",
    lat: 27.859396,
    lng: -82.786585,
    id: 86,
  },
  {
    name: "ASENCIO, BRENDALIZ",
    address: "1520 VILLAGE OAK",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34746,
    phone: "(407) 520-3588",
    lat: 28.261231,
    lng: -81.436665,
    id: 87,
  },
  {
    name: "ASHBY, BERNARD",
    address: "305B 8485 BIRD RD",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(305) 432-4218",
    lat: 25.736599,
    lng: -80.311216,
    id: 88,
  },
  {
    name: "ASHIE, FRANCIS",
    address: "1007 BEVERLY DRIVE , SUITE C",
    city: "ROCKLEDGE",
    state: "FL",
    zip: 32955,
    phone: "(321) 636-1834",
    lat: 28.335252,
    lng: -80.724801,
    id: 89,
  },
  {
    name: "ATKINSON, ELIZABETH",
    address: "86058 St. Peter Boulevard",
    city: "YULEE",
    state: "FL",
    zip: 32097,
    phone: "(904) 321-1417",
    lat: 30.629535,
    lng: -81.575653,
    id: 90,
  },
  {
    name: "AU, ADAM",
    address: "2040 NE 163RD ST , STE 204",
    city: "MIAMI",
    state: "FL",
    zip: 33162,
    phone: "(305) 720-4004",
    lat: 25.925386,
    lng: -80.158889,
    id: 91,
  },
  {
    name: "AUERBACH, LEWIS",
    address: "116 W. Bloomingdale Avenue",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 450-6072",
    lat: 27.893928,
    lng: -82.286882,
    id: 92,
  },
  {
    name: "AULD, HEATHER",
    address: "1570 Colonial Blvd",
    city: "FORT MYERS",
    state: "FL",
    zip: 33907,
    phone: "(239) 208-6676",
    lat: 26.596465,
    lng: -81.881063,
    id: 93,
  },
  {
    name: "AULL, SUSAN",
    address: "5535 MARQUESAS CIRCLE",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 487-7244",
    lat: 27.270905,
    lng: -82.488657,
    id: 94,
  },
  {
    name: "AUNG-DIN, RONALD",
    address: "3501 CATTLEMEN ROAD , SUITE A",
    city: "SARASOTA",
    state: "FL",
    zip: 34232,
    phone: "(941) 342-9477",
    lat: 27.302473,
    lng: -82.451,
    id: 95,
  },
  {
    name: "AUSTIN, MICHAEL",
    address: "6611 Gunn Highway",
    city: "TAMPA",
    state: "FL",
    zip: 33625,
    phone: "(813) 964-5901",
    lat: 28.065878,
    lng: -82.562409,
    id: 96,
  },
  {
    name: "AVERY, LISA",
    address: "24761 US HWY 19 N , SUITE 680",
    city: "CLEARWATER",
    state: "FL",
    zip: 33758,
    phone: "(727) 669-5000",
    lat: 27.997083,
    lng: -82.725655,
    id: 97,
  },
  {
    name: "Avetisov, Gregory",
    address: "3530 Lake Center Drive , apt 26-205",
    city: "MOUNT DORA",
    state: "FL",
    zip: 32757,
    phone: "(916) 915-7708",
    lat: 28.814151,
    lng: -81.680077,
    id: 98,
  },
  {
    name: "Avila-Calles, Jose",
    address: "859 Park Avenue , Ste 102",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 449-7246",
    lat: 30.179368,
    lng: -81.701144,
    id: 99,
  },
  {
    name: "AWOSIKA, BANJI",
    address: "1210 EAST PLANT STREET , SUITE 120",
    city: "WINTER GARDEN",
    state: "FL",
    zip: 34787,
    phone: "(407) 297-8408",
    lat: 28.569284,
    lng: -81.566259,
    id: 100,
  },
  {
    name: "AYALA, ORVIL",
    address: "110 Southern Oaks Dr",
    city: "PLANT CITY",
    state: "FL",
    zip: 33563,
    phone: "(513) 497-2102",
    lat: 28.019976,
    lng: -82.142822,
    id: 101,
  },
  {
    name: "AYOUB, MOHAMMAD",
    address: "2459 WILLOWBEND DR",
    city: "ST AUGUSTINE",
    state: "FL",
    zip: 32092,
    phone: "(847) 693-0149",
    lat: 30.052676,
    lng: -81.528717,
    id: 102,
  },
  {
    name: "AZEEM, NOMEN",
    address: "9360 Balm Riverview Rd.",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33569,
    phone: "(813) 333-1353",
    lat: 27.863655,
    lng: -82.323224,
    id: 103,
  },
  {
    name: "Babinsky, ELLEN",
    address: "4600 MILITARY TRAIL , SUITE 103",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 249-7400",
    lat: 26.892246,
    lng: -80.103324,
    id: 104,
  },
  {
    name: "BACHMAN, CHRISTIAN",
    address: "7600 Mayo Street",
    city: "CENTURY",
    state: "FL",
    zip: 32535,
    phone: "(850) 366-4145",
    lat: 30.970426,
    lng: -87.257331,
    id: 105,
  },
  {
    name: "BADANOWSKI, RALPH",
    address: "3201 OCEAN DR S",
    city: "JACKSONVILLE BEACH",
    state: "FL",
    zip: 32250,
    phone: "(904) 294-3548",
    lat: 30.264065,
    lng: -81.38381,
    id: 106,
  },
  {
    name: "BADAWY, AMR",
    address: "4351 Hunters Park lane",
    city: "ORLANDO",
    state: "FL",
    zip: 32837,
    phone: "(407) 985-4700",
    lat: 28.367386,
    lng: -81.430702,
    id: 107,
  },
  {
    name: "BAFITIS, HAROLD",
    address: "4601 MILITARY TRAIL , #208",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 795-3787",
    lat: 26.89224,
    lng: -80.1031,
    id: 108,
  },
  {
    name: "BAGO, MARIACLARA",
    address: "1037 STATE ROAD 7, SUITE 211",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 798-3030",
    lat: 26.662755,
    lng: -80.203876,
    id: 109,
  },
  {
    name: "BAHRAMI, MEHRAN",
    address: "2390 NE 186TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33180,
    phone: "(305) 760-8400",
    lat: 25.946993,
    lng: -80.153039,
    id: 110,
  },
  {
    name: "BAIGABATOV, ELDAR",
    address: "213 S Dillard St, Ste 310",
    city: "WINTER GARDEN",
    state: "FL",
    zip: 34787,
    phone: "(407) 347-9886",
    lat: 28.562521,
    lng: -81.583325,
    id: 111,
  },
  {
    name: "BAIJNATH, JOAN",
    address: "8645 N. Military Trail , STE 409",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33410,
    phone: "(561) 513-9313",
    lat: 26.804357,
    lng: -80.107341,
    id: 112,
  },

  {
    name: "BAILEY, TIMOTHY",
    address: "bldg # 1815 Health Care Drive, Ste B",
    city: "TRINITY",
    state: "FL",
    zip: 34655,
    phone: "(727) 312-4445",
    lat: 28.216016,
    lng: -82.67162,
    id: 114,
  },
  {
    name: "BAISDEN, JAMIE",
    address: "9200 W. WISCONSIN AVE",
    city: "MILWAUKEE",
    state: "WI",
    zip: 53226,
    phone: "(414) 805-5400",
    lat: 43.038677,
    lng: -88.026504,
    id: 115,
  },
  {
    name: "BAKER, JEFFREY",
    address: "162 NE 25th Street, Suite 103",
    city: "MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(786) 490-6200",
    lat: 25.800611,
    lng: -80.19167,
    id: 116,
  },
  {
    name: "BAKER, LAURA",
    address: "5105 BOWDEN RD.",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 374-0260",
    lat: 30.265329,
    lng: -81.614658,
    id: 117,
  },
  {
    name: "BAKER, RYAN",
    address: "2299 9th Avenue, Suite 1A",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 897-5100",
    lat: 27.781545,
    lng: -82.664596,
    id: 118,
  },
  {
    name: "BAKUS, BARBARA",
    address: "6946 Westchester Circle",
    city: "LAKEWOOD RANCH",
    state: "FL",
    zip: 34202,
    phone: "(440) 991-6718",
    lat: 27.393656,
    lng: -82.412564,
    id: 119,
  },
  {
    name: "BALL, ADAM",
    address: "579 NW LAKE WHITNEY PLACE, STE 105",
    city: "PORT ST. LUCIE",
    state: "FL",
    zip: 34986,
    phone: "(772) 465-2020",
    lat: 27.316874,
    lng: -80.403099,
    id: 120,
  },
  {
    name: "BALTER, DAVID",
    address: "24551 Production Circle , STE 6",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(239) 221-6367",
    lat: 26.382822,
    lng: -81.806276,
    id: 121,
  },
  {
    name: "BANWATT, RAMNIK",
    address: "4428 COMMERCIAL WAY",
    city: "SPRING HILL",
    state: "FL",
    zip: 34606,
    phone: "(352) 597-1011",
    lat: 28.493114,
    lng: -82.596244,
    id: 122,
  },
  {
    name: "BARBER, LAURA",
    address: "109 Racetrack Rd NE, Suite D",
    city: "FORT WALTON BEACH",
    state: "FL",
    zip: 32547,
    phone: "(850) 608-5030",
    lat: 30.446941,
    lng: -86.610237,
    id: 123,
  },
  {
    name: "BARBOUR, RONALD",
    address: "341 BULLARD PARKWAY, #AB",
    city: "TAMPA",
    state: "FL",
    zip: 33617,
    phone: "(813) 466-5535",
    lat: 28.032302,
    lng: -82.389018,
    id: 124,
  },
  {
    name: "BARILE, MICHAEL",
    address: "15495 N. TAMIAMI TRAIL , SUITE 119",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(239) 221-3901",
    lat: 26.310076,
    lng: -81.804856,
    id: 125,
  },
  {
    name: "BARNIV, CHARLES",
    address: "623 HARBOR BLVD , SUITE 3",
    city: "DESTIN",
    state: "FL",
    zip: 32541,
    phone: "(850) 837-5181",
    lat: 30.39204,
    lng: -86.493264,
    id: 126,
  },
  {
    name: "BARRIO, GEORGE",
    address: "801 E. 6th Street , Suite 602",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32401,
    phone: "(850) 770-3030",
    lat: 30.159191,
    lng: -85.647678,
    id: 127,
  },
  {
    name: "BARRIOS, HUMBERTO",
    address: "11760 SW 40TH ST, SUITE 311",
    city: "MIAMI",
    state: "FL",
    zip: 33175,
    phone: "(305) 262-9150",
    lat: 25.732182,
    lng: -80.386088,
    id: 128,
  },
  {
    name: "BARRY, BRENDA",
    address: "930 LAKE BALDWIN LANE",
    city: "ORLANDO",
    state: "FL",
    zip: 32814,
    phone: "(407) 898-1500",
    lat: 28.557978,
    lng: -81.327721,
    id: 129,
  },
  {
    name: "BARSUKOVA, TATIANA",
    address: "590 Ellis Rd S",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32254,
    phone: "(904) 800-2231",
    lat: 30.316166,
    lng: -81.743882,
    id: 130,
  },
  {
    name: "BARTCZAK, JENNIFER",
    address: "3197 NE 18TH Terrace",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33306,
    phone: "(954) 533-5382",
    lat: 26.167876,
    lng: -80.121753,
    id: 131,
  },
  {
    name: "BARTON, WILLIAM",
    address: "3781 San Jose Place , Suite 27",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32257,
    phone: "(904) 862-6869",
    lat: 30.197853,
    lng: -81.618619,
    id: 132,
  },
  {
    name: "BATTS, JOHN",
    address: "2433 GULF to BAY BLVD",
    city: "CLEARWATER",
    state: "FL",
    zip: 33765,
    phone: "(888) 908-0143",
    lat: 27.960181,
    lng: -82.736588,
    id: 133,
  },
  {
    name: "BAWA, NITIN",
    address: "4476 LEGENDARY DRIVE , SUITE 100",
    city: "DESTIN",
    state: "FL",
    zip: 32541,
    phone: "(850) 424-7320",
    lat: 30.389168,
    lng: -86.414628,
    id: 134,
  },
  {
    name: "BAWANY, MOHAMMED",
    address: "716 S GOLDENROD ROAD",
    city: "ORLANDO",
    state: "FL",
    zip: 32822,
    phone: "(407) 658-1719",
    lat: 28.536033,
    lng: -81.286823,
    id: 135,
  },
  {
    name: "BEADLING, BRENT",
    address: "7880 Gate Parkway , STE. 200A",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 204-5250",
    lat: 30.235428,
    lng: -81.515751,
    id: 136,
  },
  {
    name: "BEAMAN, RODERICK",
    address: "8761 Perimeter Park Blvd. , Suite 101",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 374-6498",
    lat: 30.2553,
    lng: -81.55486,
    id: 137,
  },
  {
    name: "BEAMS, KENT",
    address: "725 E Silver Springs Blvd",
    city: "OCALA",
    state: "FL",
    zip: 34470,
    phone: "(812) 325-3275",
    lat: 29.187427,
    lng: -82.130553,
    id: 138,
  },
  {
    name: "BEAR, DAVID",
    address: "1110 AIRPORT BLVD , SUITE B",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 438-1136",
    lat: 30.480547,
    lng: -87.218618,
    id: 139,
  },
  {
    name: "BEARD, MARGITA",
    address: "5953 17th Avenue West",
    city: "BRADENTON",
    state: "FL",
    zip: 34209,
    phone: "(941) 794-3998",
    lat: 27.484496,
    lng: -82.6215,
    id: 140,
  },
  {
    name: "BEASLEY, MICHELLE",
    address: "810 Scenic Highway, Suite C",
    city: "PENSACOLA",
    state: "FL",
    zip: 32503,
    phone: "(850) 912-4540",
    lat: 30.427423,
    lng: -87.176928,
    id: 141,
  },
  {
    name: "BEELITZ, JOHN",
    address: "3222 Commerce Pl , Suite A",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33407,
    phone: "(772) 267-9276",
    lat: 26.760983,
    lng: -80.10138,
    id: 142,
  },
  {
    name: "BEG, MIRZA",
    address: "725 W GRANADA BLVD , SUITE 22",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 788-2300",
    lat: 29.279169,
    lng: -81.076292,
    id: 143,
  },
  {
    name: "BEG, RANA",
    address: "3663 s miami ave",
    city: "COCONUT GROVE",
    state: "FL",
    zip: 33133,
    phone: "(305) 854-4400",
    lat: 25.741975,
    lng: -80.216648,
    id: 144,
  },
  {
    name: "BEINHAKER, NEIL",
    address: "5500 N Military Trail , Apt 428",
    city: "BOCA RATON",
    state: "FL",
    zip: 33496,
    phone: "(954) 624-2395",
    lat: 26.397913,
    lng: -80.120368,
    id: 145,
  },
  {
    name: "BEKIC, RONALD",
    address: "2220 SW 28th WAY",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33312,
    phone: "(954) 815-5778",
    lat: 26.094812,
    lng: -80.180916,
    id: 146,
  },
  {
    name: "BELL, JOHN",
    address: "1905 CAPITAL CIRCLE NE",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 222-3937",
    lat: 30.473156,
    lng: -84.232724,
    id: 147,
  },
  {
    name: "BELL, MARGARET",
    address: "9190 sw 9th terr",
    city: "OCALA",
    state: "FL",
    zip: 34476,
    phone: "(954) 804-3443",
    lat: 29.089205,
    lng: -82.147523,
    id: 148,
  },
  {
    name: "BELLECCI, PAULINE",
    address: "5335 Gate Parkway",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 251-6439",
    lat: 30.248911,
    lng: -81.526667,
    id: 149,
  },
  {
    name: "BELOSO, ISMAEL",
    address: "6000 Turkey Lake Road , Suite 209",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(407) 648-5252",
    lat: 28.476873,
    lng: -81.475017,
    id: 150,
  },
  {
    name: "BELOTTI, MICHAEL",
    address: "10111 FOREST HILL BLVD, #202",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 798-1995",
    lat: 26.65087,
    lng: -80.20473,
    id: 151,
  },
  {
    name: "Benedict, Gregory",
    address: "1050 SE Monterey Rd , Suite 204",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 288-2400",
    lat: 27.176785,
    lng: -80.238244,
    id: 152,
  },
  {
    name: "benefield, bonnie",
    address: "250 NE 3RD AVENUE , #404",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33444,
    phone: "(214) 455-9071",
    lat: 26.466223,
    lng: -80.070175,
    id: 153,
  },
  {
    name: "BENEZETTE, ALYN",
    address: "725 West Granada Blvd , Suite 22",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 788-2300",
    lat: 29.279169,
    lng: -81.076292,
    id: 154,
  },
  {
    name: "BENIONI, TEARIKIRANGI",
    address: "4200 Sun N Lake Blvd",
    city: "SEBRING",
    state: "FL",
    zip: 33872,
    phone: "(863) 314-4466",
    lat: 27.538974,
    lng: -81.505889,
    id: 155,
  },
  {
    name: "BENNI, ABD ALRAHMAN",
    address: "1749 S KINGS AVENUE",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 333-1819",
    lat: 27.915794,
    lng: -82.291881,
    id: 156,
  },
  {
    name: "BENTLEY, BERNARD",
    address: "715 Bayshore Dr. , apt. 406",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33304,
    phone: "(954) 909-0234",
    lat: 26.133169,
    lng: -80.108618,
    id: 157,
  },
  {
    name: "BENTLEY, JAMES",
    address: "535 North Adelle ave",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(863) 677-5206",
    lat: 29.036864,
    lng: -81.311702,
    id: 158,
  },
  {
    name: "BENZAQUEN PARKES, ELLY",
    address: "86 WEST UNDERWOOD ST , #102",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(321) 841-3581",
    lat: 28.526763,
    lng: -81.379384,
    id: 159,
  },
  {
    name: "BENZING, PEGGY",
    address: "2190 S. Tamiami Tr",
    city: "VENICE",
    state: "FL",
    zip: 34293,
    phone: "(941) 244-4100",
    lat: 27.05367,
    lng: -82.410706,
    id: 160,
  },
  {
    name: "BERAHMAN, TANAZ",
    address: "5000 UNIVERSITY DRIVE",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33146,
    phone: "(786) 308-3902",
    lat: 25.724742,
    lng: -80.273896,
    id: 161,
  },
  {
    name: "BERDICK, KENNETH",
    address: "3714 EVANS AVE",
    city: "FORT MYERS",
    state: "FL",
    zip: 33901,
    phone: "(239) 334-4157",
    lat: 26.607935,
    lng: -81.860328,
    id: 162,
  },
  {
    name: "BERGER, ALEXANDRA",
    address: "1701 1701 SE HILLMOOR DRIVE , SUITE 5",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-0060",
    lat: 27.286822,
    lng: -80.29379,
    id: 163,
  },
  {
    name: "BERGER, DAVID",
    address: "3405 W Fletcher Ave",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(813) 960-3415",
    lat: 28.066481,
    lng: -82.498793,
    id: 164,
  },
  {
    name: "BERGER, LAWRENCE",
    address: "800 S.E. 4th Ave , SUITE 504",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(305) 932-5551",
    lat: 25.976863,
    lng: -80.144324,
    id: 165,
  },
  {
    name: "BERGER, MARC",
    address: "802B 2nd Street N",
    city: "SAFETY HARBOR",
    state: "FL",
    zip: 34695,
    phone: "(727) 600-9205",
    lat: 27.991475,
    lng: -82.695612,
    id: 166,
  },
  {
    name: "BERKE, WILLIAM",
    address: "1400 N NE MIAMI GARDENS DRIVE, STE. 202",
    city: "NORTH MIAMI BEACH",
    state: "FL",
    zip: 33179,
    phone: "(305) 947-7744",
    lat: 25.944329,
    lng: -80.172558,
    id: 167,
  },
  {
    name: "BERKOWITZ, MARIO",
    address: "4850 W OAKLAND PARK BLVD #201 , SUITE 201",
    city: "LAUDERDALE LAKES",
    state: "FL",
    zip: 33313,
    phone: "(954) 735-3535",
    lat: 26.163185,
    lng: -80.216725,
    id: 168,
  },
  {
    name: "BERLIN, DEBBIE",
    address: "1555 KINGSLEY AVENUE, Suite 503",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 278-5088",
    lat: 30.165496,
    lng: -81.728872,
    id: 169,
  },
  {
    name: "BERMAN, JEFFREY",
    address: "17941 FIELDBROOK CIRCLE",
    city: "BOCA RATON",
    state: "FL",
    zip: 33496,
    phone: "(561) 457-4989",
    lat: 26.410483,
    lng: -80.14873,
    id: 170,
  },
  {
    name: "BERMAN, ROBERT",
    address: "7225 N UNIVERSITY DRIVE , SUITE 210",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 484-1710",
    lat: 26.211681,
    lng: -80.257465,
    id: 171,
  },
  {
    name: "BERNARD, GARY",
    address: "1996 KINGSLEY AVENUE",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 276-5700",
    lat: 30.164404,
    lng: -81.738825,
    id: 172,
  },
  {
    name: "BERNARD, JOHNNY",
    address: "721 Ridgewood Avenue , Suite 9",
    city: "HOLLY HILL",
    state: "FL",
    zip: 32117,
    phone: "(866) 737-9333",
    lat: 29.238545,
    lng: -81.037303,
    id: 173,
  },
  {
    name: "BERNARD, LEONARD",
    address: "1261 SOUTH TAMIAMI TRAIL",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 366-2360",
    lat: 27.321989,
    lng: -82.528174,
    id: 174,
  },
  {
    name: "BERRY, ADAM",
    address: "11903 SOUTHERN BLVD , STE 108",
    city: "ROYAL PALM BEACH",
    state: "FL",
    zip: 33411,
    phone: "(561) 793-1475",
    lat: 26.6815,
    lng: -80.23389,
    id: 175,
  },
  {
    name: "BESNOFF, TODD",
    address: "29750 US HIGHWAY 19 NORTH, SUITE 207",
    city: "CLEARWATER",
    state: "FL",
    zip: 33761,
    phone: "(727) 799-7000",
    lat: 28.042923,
    lng: -82.739092,
    id: 176,
  },
  {
    name: "BETANCOURT, FERNANDO",
    address: "3677 CENTRAL AVE , SUITE H",
    city: "FT MYERS",
    state: "FL",
    zip: 33901,
    phone: "(239) 936-4494",
    lat: 26.60894,
    lng: -81.863213,
    id: 177,
  },
  {
    name: "BEYSOLOW, TAWEH",
    address: "1010 kennedy DR, SUITE 401",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(305) 293-5015",
    lat: 24.565922,
    lng: -81.767965,
    id: 178,
  },
  {
    name: "BHALANI, KANTILAL",
    address: "1650 sandlake rd , unit112",
    city: "BELLE ISLE",
    state: "FL",
    zip: 32809,
    phone: "(407) 286-1829",
    lat: 28.463682,
    lng: -81.394771,
    id: 179,
  },
  {
    name: "BHAMIDIPATI, SAVITHRI",
    address: "180 L OLD HICKORY BLVD",
    city: "JACKSON",
    state: "TN",
    zip: 38305,
    phone: "(731) 661-2750",
    lat: 35.652268,
    lng: -88.8349,
    id: 180,
  },
  {
    name: "BHULLAR, INDERMEET",
    address: "77 WEST UNDERWOOD STREET",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 841-5142",
    lat: 28.527235,
    lng: -81.379332,
    id: 181,
  },
  {
    name: "BIALOW, MARTIN",
    address: "4751 66TH STREET,N.",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33709,
    phone: "(727) 347-8839",
    lat: 27.815245,
    lng: -82.728058,
    id: 182,
  },
  {
    name: "BIRBECK, TAMMY",
    address: "2061 Englewood Road , Suite 4",
    city: "ENGLEWOOD",
    state: "FL",
    zip: 34223,
    phone: "(517) 438-6516",
    lat: 27.0059,
    lng: -82.3798,
    id: 183,
  },
  {
    name: "BIRD, EUGENIO",
    address: "895 OUTER ROAD",
    city: "ORLANDO",
    state: "FL",
    zip: 32814,
    phone: "(407) 644-4477",
    lat: 28.558779,
    lng: -81.328707,
    id: 184,
  },
  {
    name: "BISBY, SHERI",
    address: "837 S. MAIN STREET",
    city: "WILDWOOD",
    state: "FL",
    zip: 34785,
    phone: "(352) 750-6387",
    lat: 28.84951,
    lng: -82.045866,
    id: 185,
  },
  {
    name: "BISHOP, JEFFREY",
    address: "South 9801 Belvedere Road",
    city: "ROYAL PALM BEACH",
    state: "FL",
    zip: 33411,
    phone: "(561) 578-8600",
    lat: 26.664443,
    lng: -80.174116,
    id: 186,
  },
  {
    name: "Black, Jonathan",
    address: "12741 Miramar Parkway, suite 302",
    city: "MIRAMAR",
    state: "FL",
    zip: 33025,
    phone: "(315) 885-7265",
    lat: 25.980506,
    lng: -80.317028,
    id: 187,
  },
  {
    name: "BLAKE, GEORGE",
    address: "605 S. FREMONT AVE., SUITE A",
    city: "TAMPA",
    state: "FL",
    zip: 33606,
    phone: "(813) 251-0209",
    lat: 27.938076,
    lng: -82.479175,
    id: 188,
  },
  {
    name: "BLANCHARD, PATRICK",
    address: "2315 W Jackson Street",
    city: "PENSACOLA",
    state: "FL",
    zip: 32505,
    phone: "(850) 436-4630",
    lat: 30.417832,
    lng: -87.244256,
    id: 189,
  },
  {
    name: "Blanco, Cy",
    address: "4675 Linton Blvd , Suite 200",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33445,
    phone: "(561) 331-5050",
    lat: 26.439627,
    lng: -80.107756,
    id: 190,
  },
  {
    name: "BLANCO FRANCO, ALEJANDRO",
    address: "1726 MEDICAL BLVD, SUITE 201",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(239) 596-8806",
    lat: 26.272781,
    lng: -81.784673,
    id: 191,
  },
  {
    name: "BLATT, MARC",
    address: "8773 Perimeter Park Court",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 493-3390",
    lat: 30.254414,
    lng: -81.554469,
    id: 192,
  },
  {
    name: "BLEICHER, JEREMY",
    address: "1400 NE Miami Gardens Dr , SUITE #105",
    city: "MIAMI",
    state: "FL",
    zip: 33179,
    phone: "(305) 974-5933",
    lat: 25.944329,
    lng: -80.172558,
    id: 193,
  },
  {
    name: "BLEMUR, PIERRE",
    address: "18373 27th avenue",
    city: "MIAMI GARDENS",
    state: "FL",
    zip: 33056,
    phone: "(305) 688-5456",
    lat: 25.943091,
    lng: -80.244015,
    id: 194,
  },
  {
    name: "BLEND, TIMOTHY",
    address: "8648 East State Road 70",
    city: "BRADENTON",
    state: "FL",
    zip: 34202,
    phone: "(941) 722-5600",
    lat: 27.437084,
    lng: -82.452116,
    id: 195,
  },
  {
    name: "BLOCK, MICHAEL",
    address: "5162 LINTON BLVD. , SUITE 107",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 499-8686",
    lat: 26.43925,
    lng: -80.124304,
    id: 196,
  },
  {
    name: "BLOOM, LAUREN",
    address: "3450 11th Court, Suite 201",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 794-3364",
    lat: 27.656308,
    lng: -80.394317,
    id: 197,
  },
  {
    name: "BLOUNT, CALVIN",
    address: "4012 Commons Drive W , Suite 120",
    city: "DESTIN",
    state: "FL",
    zip: 32541,
    phone: "(850) 424-5474",
    lat: 30.39088,
    lng: -86.45297,
    id: 198,
  },
  {
    name: "BLUM, MICHAEL",
    address: "579 NW LAKE WHITNEY PLACE , SUITE #101",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34986,
    phone: "(772) 249-0260",
    lat: 27.316874,
    lng: -80.403099,
    id: 199,
  },
  {
    name: "BLUMENTHAL, BARRY",
    address: "4001 North Federal Highway",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33308,
    phone: "(786) 288-6658",
    lat: 26.17757,
    lng: -80.11996,
    id: 200,
  },
  {
    name: "BODEN, JOHN",
    address: "1230 Creighton Rd",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 477-5586",
    lat: 30.496567,
    lng: -87.212704,
    id: 201,
  },
  {
    name: "BOGLE, ROBERT",
    address: "34621 Us Highway 19",
    city: "PALM HARBOR",
    state: "FL",
    zip: 34684,
    phone: "(727) 953-9888",
    lat: 28.086431,
    lng: -82.738857,
    id: 202,
  },
  {
    name: "BOGLE, TANYA",
    address: "15416 North Florida Ave",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(423) 718-2698",
    lat: 28.092291,
    lng: -82.460881,
    id: 203,
  },
  {
    name: "BOLEK, LUKE",
    address: "1350 SOUTH HICKORY STREAT",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 434-7116",
    lat: 28.087723,
    lng: -80.614319,
    id: 204,
  },
  {
    name: "BOLER, DOUGLAS",
    address: "7207 Golden Wings Road , Suite 100",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32244,
    phone: "(904) 389-1010",
    lat: 30.225647,
    lng: -81.701821,
    id: 205,
  },
  {
    name: "BOLGER, WILLIAM",
    address: "Southpoint Parkway , Suite 6817",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 595-7475",
    lat: 30.260518,
    lng: -81.602345,
    id: 206,
  },
  {
    name: "BOLHOFNER, BRETT",
    address: "603 7th Street South , Suite 450",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 369-1290",
    lat: 27.763462,
    lng: -82.643938,
    id: 207,
  },
  {
    name: "BOLTUCH, ROBERT",
    address: "2790 N MILITARY TR , STE 6",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33409,
    phone: "(561) 689-2110",
    lat: 26.718329,
    lng: -80.109058,
    id: 208,
  },
  {
    name: "BOND, WENDY",
    address: "14271 Metropolis Ave , Suite A",
    city: "FT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(239) 939-7777",
    lat: 26.534649,
    lng: -81.85306,
    id: 209,
  },
  {
    name: "BONE, MELANIE",
    address: "2814 South Dixie Highway , Suite B",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33405,
    phone: "(561) 252-2523",
    lat: 26.687752,
    lng: -80.054121,
    id: 210,
  },
  {
    name: "BONES, RAPHAEL",
    address: "8624 Lee Vista Blvd , SUITE 2",
    city: "ORLANDO",
    state: "FL",
    zip: 32829,
    phone: "(407) 720-3209",
    lat: 28.46854,
    lng: -81.266067,
    id: 211,
  },
  {
    name: "BOORAS, CHARLES",
    address: "6426 Bowden Rd., STE #201",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 420-3703",
    lat: 30.263935,
    lng: -81.597313,
    id: 212,
  },
  {
    name: "BORISIAK, TATYANA",
    address: "8251 W Broward Blvd, suite 103",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 255-7310",
    lat: 26.122511,
    lng: -80.260189,
    id: 213,
  },
  {
    name: "BOTERO-VELEZ, MAURICIO",
    address: "118 HILLCREST DR",
    city: "STUART",
    state: "FL",
    zip: 34996,
    phone: "(772) 214-7584",
    lat: 27.197129,
    lng: -80.200017,
    id: 214,
  },
  {
    name: "Boulos, Paul",
    address: "6530 Park Blvd",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(844) 482-6263",
    lat: 27.838738,
    lng: -82.726862,
    id: 215,
  },
  {
    name: "BOWES, LEYDA",
    address: "3659 SOUTH MIAMI AVENUE, SUITE 6008",
    city: "MIAMI",
    state: "FL",
    zip: 33133,
    phone: "(305) 856-6555",
    lat: 25.740314,
    lng: -80.213808,
    id: 216,
  },
  {
    name: "BOWMAN, STEVEN",
    address: "199 Da 199 Dali Blvd S , Unit 1004",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 743-6116",
    lat: 27.775654,
    lng: -82.64092,
    id: 217,
  },
  {
    name: "BOYLE, KATHLEEN",
    address: "5250 17th St. , Unit #106",
    city: "SARASOTA",
    state: "FL",
    zip: 34235,
    phone: "(941) 203-5347",
    lat: 27.351135,
    lng: -82.465451,
    id: 218,
  },
  {
    name: "BRACCIA, GREGORY",
    address: "1450 Airport Pulling Rd , Suite A",
    city: "NAPLES",
    state: "FL",
    zip: 34112,
    phone: "(239) 317-3300",
    lat: 26.139908,
    lng: -81.766718,
    id: 219,
  },
  {
    name: "BRAINARD, KAREN",
    address: "8614 EAST STATE ROAD 70 , SUITE 200",
    city: "BRADENTON",
    state: "FL",
    zip: 34202,
    phone: "(941) 727-1243",
    lat: 27.437306,
    lng: -82.453166,
    id: 220,
  },
  {
    name: "BRANDWEIN, GARY",
    address: "4699 N. FEDERAL HIGHWAY",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33064,
    phone: "(954) 532-3498",
    lat: 26.288985,
    lng: -80.096341,
    id: 221,
  },
  {
    name: "BRAUNSTEIN, RACHEL",
    address: "611 SW 29th RD",
    city: "MIAMI",
    state: "FL",
    zip: 33129,
    phone: "(775) 843-8806",
    lat: 25.756963,
    lng: -80.213218,
    id: 222,
  },
  {
    name: "BRAY, CHRISTOPHER",
    address: "7485 SW 17TH Road",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32607,
    phone: "(352) 333-5700",
    lat: 29.637844,
    lng: -82.421847,
    id: 223,
  },
  {
    name: "BREIT, JEREMY",
    address: "1601 CLINT MOORE RD , SUITE 100",
    city: "BOCA RATON",
    state: "FL",
    zip: 33487,
    phone: "(561) 939-0200",
    lat: 26.40899,
    lng: -80.116413,
    id: 224,
  },
  {
    name: "BREMER, NICHOLAS",
    address: "10475 CENTURION PKWY N. , SUITE 201",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 223-3321",
    lat: 30.248555,
    lng: -81.542518,
    id: 225,
  },
  {
    name: "BRENT, JOHN",
    address: "1032 MAR WALT DR , SUITE 220",
    city: "FT WALTON BEACH",
    state: "FL",
    zip: 32547,
    phone: "(850) 796-3737",
    lat: 30.454218,
    lng: -86.636241,
    id: 226,
  },
  {
    name: "BRESKY, KENNETH",
    address: "10151 ENTERPRISE CENTER BLVD , SUITE 108",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 740-4855",
    lat: 26.525909,
    lng: -80.167654,
    id: 227,
  },
  {
    name: "BRETAL, ANDREA",
    address: "1605 TOWN CENTER BLVD , SUITE D",
    city: "WESTON",
    state: "FL",
    zip: 33326,
    phone: "(954) 389-1800",
    lat: 26.097048,
    lng: -80.38556,
    id: 228,
  },
  {
    name: "BRIDEWELL, BRUCE",
    address: "10201 ARCOS AVENUE , SUITE 201",
    city: "ESTERO",
    state: "FL",
    zip: 33928,
    phone: "(239) 992-7822",
    lat: 26.433377,
    lng: -81.786923,
    id: 229,
  },
  {
    name: "BRIDGES, KENNETH",
    address: "9639 FAIRWOOD COURT",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34986,
    phone: "(772) 461-7500",
    lat: 27.333462,
    lng: -80.424525,
    id: 230,
  },
  {
    name: "Brill, Michael",
    address: "2525 N. Shadeland Ave., Suite 105",
    city: "INDIANAPOLIS",
    state: "IN",
    zip: 46219,
    phone: "(317) 432-5927",
    lat: 39.803747,
    lng: -86.044432,
    id: 231,
  },
  {
    name: "BRILL, ROGER",
    address: "6716 NW 11TH PLACE, SUITE 100",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32605,
    phone: "(352) 331-7987",
    lat: 29.663459,
    lng: -82.414364,
    id: 232,
  },
  {
    name: "BRINSON, ASHTON",
    address: "695 DOUGLAS AVENUE",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32714,
    phone: "(321) 320-9365",
    lat: 28.675287,
    lng: -81.389875,
    id: 233,
  },
  {
    name: "BRISTOW, SANDEE",
    address: "3661 South Miami Ave , # 906",
    city: "MIAMI",
    state: "FL",
    zip: 33133,
    phone: "(305) 974-5533",
    lat: 25.740156,
    lng: -80.21405,
    id: 234,
  },
  {
    name: "BRODERICK, KATHLEEN",
    address: "700 SECOND AVENUE NORTH, SUITE 204",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 263-2200",
    lat: 26.14925,
    lng: -81.79865,
    id: 235,
  },
  {
    name: "BRODY, FRANCEE",
    address: "1802 KUHL AVE., STE 103",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 839-0096",
    lat: 28.520867,
    lng: -81.378825,
    id: 236,
  },
  {
    name: "BROWN, KACIAN",
    address: "821 GOOD HOMES RD",
    city: "ORLANDO",
    state: "FL",
    zip: 32818,
    phone: "(407) 426-4800",
    lat: 28.552935,
    lng: -81.50491,
    id: 237,
  },
  {
    name: "BROWN, NEAL",
    address: "6770 CONGRESS AVE , SUITE #306",
    city: "BOCA RATON",
    state: "FL",
    zip: 33487,
    phone: "(212) 532-2020",
    lat: 26.407643,
    lng: -80.100643,
    id: 238,
  },
  {
    name: "BROWN, RACHEL",
    address: "1645 Nantahala Beach Road",
    city: "GULF BREEZE",
    state: "FL",
    zip: 32563,
    phone: "(850) 684-3445",
    lat: 30.395581,
    lng: -87.015813,
    id: 239,
  },
  {
    name: "Brown, VASU",
    address: "4307 Continental Blvd",
    city: "ORLANDO",
    state: "FL",
    zip: 32808,
    phone: "(508) 361-5053",
    lat: 28.571892,
    lng: -81.437822,
    id: 240,
  },
  {
    name: "BROWNE-KING, ESTHER",
    address: "850 SOUTHWEST BLVD. NORTH",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33703,
    phone: "(727) 914-3337",
    lat: 27.823212,
    lng: -82.646051,
    id: 241,
  },
  {
    name: "BRUECK, ROBERT",
    address: "3700 CENTRAL AVE, #1",
    city: "FORT MYERS",
    state: "FL",
    zip: 33901,
    phone: "(239) 939-5233",
    lat: 26.608699,
    lng: -81.864585,
    id: 242,
  },
  {
    name: "Bruno, Patricio",
    address: "111 North Orange Ave , Suite 800",
    city: "ORLANDO",
    state: "FL",
    zip: 32801,
    phone: "(407) 564-1440",
    lat: 28.543733,
    lng: -81.378854,
    id: 243,
  },
  {
    name: "BRUNSON, ADAM",
    address: "12225 28TH STREET NORTH, SUITE B",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33716,
    phone: "(727) 561-4303",
    lat: 27.883104,
    lng: -82.671024,
    id: 244,
  },
  {
    name: "BRYAN, CYNTHIA",
    address: "400 Veterans Way",
    city: "EGLIN",
    state: "FL",
    zip: 32542,
    phone: "(850) 609-2600",
    lat: 30.45907,
    lng: -86.55026,
    id: 245,
  },
  {
    name: "BUCKMAN, ROBERT",
    address: "470 COLUMBIA DRIVE , SUITE #201-A",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33409,
    phone: "(561) 684-7703",
    lat: 26.71058,
    lng: -80.1005,
    id: 246,
  },
  {
    name: "BUCY, GUY",
    address: "2020 SE 17TH STREET",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(352) 732-0277",
    lat: 29.170998,
    lng: -82.110829,
    id: 247,
  },
  {
    name: "BUGARIN-VIERA, MAGDALENA",
    address: "3404 West Beach Street",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 951-1990",
    lat: 27.962899,
    lng: -82.499763,
    id: 248,
  },
  {
    name: "BUGGIA, MARY",
    address: "5183 D 5183 SE Graham Dr, 5183 DE Graham Dr",
    city: "STUART",
    state: "FL",
    zip: 34997,
    phone: "(561) 951-1597",
    lat: 27.108081,
    lng: -80.230818,
    id: 249,
  },
  {
    name: "BUNCH, JENNIFER",
    address: "11200 Seminole Blvd, Suite 204",
    city: "LARGO",
    state: "FL",
    zip: 33778,
    phone: "(727) 495-6085",
    lat: 27.874768,
    lng: -82.787769,
    id: 250,
  },
  {
    name: "BURNS, PATRICK",
    address: "2700 RIVERSIDE AVENUE , SUITE 2",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32205,
    phone: "(904) 900-4777",
    lat: 30.307752,
    lng: -81.692254,
    id: 251,
  },
  {
    name: "BURROWS, STEPHANIE",
    address: "103 US Hwy 2",
    city: "WAKEFIELD",
    state: "MI",
    zip: 49968,
    phone: "(906) 229-6120",
    lat: 46.459101,
    lng: -89.88107,
    id: 252,
  },
  {
    name: "BUSCEMA, CHARLES",
    address: "7400 LAURELS PLACE",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34986,
    phone: "(772) 618-0505",
    lat: 27.33216,
    lng: -80.418934,
    id: 253,
  },
  {
    name: "BUSER, DAVID",
    address: "1561 W Fairbanks Ave",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 478-4920",
    lat: 28.593702,
    lng: -81.370485,
    id: 254,
  },
  {
    name: "BUSH, DARIN",
    address: "3101 N FEDERAL HIGHWAY , SUITE 201",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33306,
    phone: "(954) 414-7080",
    lat: 26.167507,
    lng: -80.118506,
    id: 255,
  },
  {
    name: "BUSHNELL, JEANNE",
    address: "5855 SE RIVRBOAT DRIVE",
    city: "STUART",
    state: "FL",
    zip: 34997,
    phone: "(772) 233-9311",
    lat: 27.145622,
    lng: -80.175864,
    id: 256,
  },
  {
    name: "BUTLER, DAVID",
    address: "18316 Murdock Circle , Suite 108",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33948,
    phone: "(941) 629-3500",
    lat: 27.00721,
    lng: -82.14475,
    id: 257,
  },
  {
    name: "BYRD, TIMOTHY",
    address: "2 SW 12TH ST",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(352) 629-4350",
    lat: 29.176646,
    lng: -82.136227,
    id: 258,
  },
  {
    name: "Byrne, Patrick",
    address: "2729 Merrilee Dr",
    city: "FAIRFAX",
    state: "VA",
    zip: 22031,
    phone: "(813) 919-9374",
    lat: 38.878826,
    lng: -77.230398,
    id: 259,
  },
  {
    name: "CABAN, FRANCIS",
    address: "109 Margaret St",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 654-2544",
    lat: 27.936671,
    lng: -82.281943,
    id: 260,
  },
  {
    name: "CABANELLAS, JENNINE",
    address: "13421 SOUTH SHORE BLVD , SUITE 203",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 333-3440",
    lat: 26.640865,
    lng: -80.264289,
    id: 261,
  },
  {
    name: "CABELLO, DANIEL",
    address: "1417 S. Belcher Road , Unit C",
    city: "CLEARWATER",
    state: "FL",
    zip: 33764,
    phone: "(727) 443-3295",
    lat: 27.945546,
    lng: -82.745404,
    id: 262,
  },
  {
    name: "CABRERO-MUNIZ, NICOLAS",
    address: "8784 US HIGHWAY 27 WEST",
    city: "MAYO",
    state: "FL",
    zip: 32066,
    phone: "(850) 878-8000",
    lat: 30.132891,
    lng: -83.304625,
    id: 263,
  },
  {
    name: "CACERES, AILEEN",
    address: "1136 Cypress Glen Circle",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 392-2777",
    lat: 28.345747,
    lng: -81.414805,
    id: 264,
  },
  {
    name: "CALAS, HENRY",
    address: "827 SOUTHEAST 5TH STREET",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 223-5525",
    lat: 27.196808,
    lng: -80.239397,
    id: 265,
  },
  {
    name: "CALKIN, PETER",
    address: "10503 Chamberlain Court",
    city: "TAMPA",
    state: "FL",
    zip: 33626,
    phone: "(508) 471-0414",
    lat: 28.053393,
    lng: -82.622953,
    id: 266,
  },
  {
    name: "CAMPANILE, RICHARD",
    address: "10337 LONGWOOD DRIVE",
    city: "LARGO",
    state: "FL",
    zip: 33777,
    phone: "(727) 592-2212",
    lat: 27.866247,
    lng: -82.761257,
    id: 267,
  },
  {
    name: "CAMPBELL, DOYLE",
    address: "2440 N. E. 22ND TERRACE",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33305,
    phone: "(954) 257-4394",
    lat: 26.156781,
    lng: -80.115109,
    id: 268,
  },
  {
    name: "CAMPBELL, JAMES",
    address: "222 HARBORVIEW LANE",
    city: "LARGO",
    state: "FL",
    zip: 33770,
    phone: "(727) 560-2931",
    lat: 27.916998,
    lng: -82.802668,
    id: 269,
  },
  {
    name: "CAMPBELL, JOHN",
    address: "3200 BAILEY LANE , STE 200",
    city: "NAPLES",
    state: "FL",
    zip: 34105,
    phone: "(239) 262-8971",
    lat: 26.190432,
    lng: -81.768712,
    id: 270,
  },
  {
    name: "CANASI, JAVIER",
    address: "900 Village Square Crossing , Suite 130",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 775-0335",
    lat: 26.835675,
    lng: -80.073886,
    id: 271,
  },
  {
    name: "CANAVES-NUNEZ, SONIA",
    address: "601 UNIVERSITY BLVD, SUITE 205",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 624-0702",
    lat: 26.89136,
    lng: -80.10496,
    id: 272,
  },
  {
    name: "CANAVOSIO, FEDERICO",
    address: "1000 36 TH STREET",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 567-4311",
    lat: 27.658601,
    lng: -80.393883,
    id: 273,
  },
  {
    name: "CANDELORA, PETER",
    address: "5145 DEER PARK DR",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34653,
    phone: "(727) 848-1417",
    lat: 28.234139,
    lng: -82.668449,
    id: 274,
  },
  {
    name: "CANTERBURY, JOHN",
    address: "680 2nd Ave N , Suite 203",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 330-1382",
    lat: 26.149217,
    lng: -81.798936,
    id: 275,
  },
  {
    name: "CANTON, LARRY",
    address: "2888 ROOSEVELT BLVD",
    city: "CLEARWATER",
    state: "FL",
    zip: 33760,
    phone: "(727) 524-7988",
    lat: 27.916693,
    lng: -82.718755,
    id: 276,
  },
  {
    name: "CANTWELL, G",
    address: "1611 NW 12 AVE, HOLTZ CHILDREN S RM 6006",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(305) 585-6051",
    lat: 25.791124,
    lng: -80.212074,
    id: 277,
  },
  {
    name: "Capaldi, David",
    address: "401 Maplewood Dr. , Suite 6",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(609) 412-9949",
    lat: 26.93195,
    lng: -80.1249,
    id: 278,
  },
  {
    name: "CAPONE, EDWARD",
    address: "11528 U.S. HIGHWAY 19",
    city: "PORT RICHEY",
    state: "FL",
    zip: 34668,
    phone: "(727) 868-2151",
    lat: 28.325183,
    lng: -82.698413,
    id: 279,
  },
  {
    name: "CAPOZZI, ANTHONY",
    address: "suite 1730 DUNLAWTON AVE , SUITE 3",
    city: "PORT ORANGE",
    state: "FL",
    zip: 32127,
    phone: "(386) 957-3905",
    lat: 29.138317,
    lng: -80.995611,
    id: 280,
  },
  {
    name: "CAPPIELLO, GERARD",
    address: "1965 L 1965 Lynnwood Ct",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(727) 415-0598",
    lat: 28.010895,
    lng: -82.773995,
    id: 281,
  },
  {
    name: "CAPRARO, FRANCES",
    address: "1231 SOUTH PATRICK DRIVE",
    city: "SATELLITE BEACH",
    state: "FL",
    zip: 32937,
    phone: "(321) 622-5432",
    lat: 28.178208,
    lng: -80.605496,
    id: 282,
  },
  {
    name: "Cara, Jeffrey",
    address: "240 W Indiantown Road , Suite 107",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 377-0070",
    lat: 26.93399,
    lng: -80.09665,
    id: 283,
  },
  {
    name: "CARABALLO, DALIAN",
    address: "11240 N Kendall DR",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 530-7006",
    lat: 25.686207,
    lng: -80.377473,
    id: 284,
  },
  {
    name: "CARABIN, IOANA",
    address: "1438 KENNEDY DRIVE",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(305) 292-2259",
    lat: 24.562301,
    lng: -81.766726,
    id: 285,
  },
  {
    name: "CARADONNA, RICHARD",
    address: "428 PALMETTO CRESCENT",
    city: "NOKOMIS",
    state: "FL",
    zip: 34275,
    phone: "(352) 238-0569",
    lat: 27.121054,
    lng: -82.461249,
    id: 286,
  },
  {
    name: "CARDONA, JOSE",
    address: "7951 SW 40 ST , SUITE 212",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(786) 518-2454",
    lat: 25.733612,
    lng: -80.32491,
    id: 287,
  },
  {
    name: "CAREY, GERARD",
    address: "4818 kipper drive",
    city: "MOSELEY",
    state: "VA",
    zip: 23120,
    phone: "(804) 292-1567",
    lat: 37.43599,
    lng: -77.718398,
    id: 288,
  },
  {
    name: "CARPENTER, THOMAS",
    address: "8250 BRYAN DAIRY ROAD , SUITE 300",
    city: "LARGO",
    state: "FL",
    zip: 33777,
    phone: "(727) 544-2500",
    lat: 27.871698,
    lng: -82.753019,
    id: 289,
  },
  {
    name: "CARRASCO, ANGEL",
    address: "1555 1555 N Krome Ave",
    city: "HOMESTEAD",
    state: "FL",
    zip: 33030,
    phone: "(305) 266-0222",
    lat: 25.485154,
    lng: -80.47718,
    id: 290,
  },
  {
    name: "CARTWRIGHT, JOSEPH",
    address: "105 Whitehall Dr. , Suite 115",
    city: "St. Augustine",
    state: "FL",
    zip: 32086,
    phone: "(904) 235-7342",
    lat: 29.856502,
    lng: -81.329136,
    id: 291,
  },
  {
    name: "Castaneda, Thaddaeus",
    address: "143 S. JOHN SIMS PARKWAY",
    city: "VALPARAISO",
    state: "FL",
    zip: 32580,
    phone: "(850) 729-3300",
    lat: 30.51634,
    lng: -86.4977,
    id: 292,
  },
  {
    name: "CASTELLANOS, JAIME",
    address: "1320 PALM BEACH RD",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 286-5407",
    lat: 27.186838,
    lng: -80.236874,
    id: 293,
  },
  {
    name: "CASTELLANOS, RAMON",
    address: "PHS 7000 SW 62 AVE , SUITE PHS",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 859-4443",
    lat: 25.707602,
    lng: -80.293385,
    id: 294,
  },
  {
    name: "CASTIEL, ALBERTO",
    address: "1677 WELLS ROAD",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 215-8400",
    lat: 30.187676,
    lng: -81.724371,
    id: 295,
  },
  {
    name: "CASTILLO, EMERITA",
    address: "8900 North Kendall Dr",
    city: "KENDALL",
    state: "FL",
    zip: 33176,
    phone: "(786) 594-9251",
    lat: 25.68445,
    lng: -80.338947,
    id: 296,
  },
  {
    name: "CASTILLO-NIEVES, EDGARDO",
    address: "1530 LEE BLVD , SUITE 1400",
    city: "LEHIGH ACRES",
    state: "FL",
    zip: 33936,
    phone: "(239) 368-7310",
    lat: 26.615113,
    lng: -81.660166,
    id: 297,
  },
  {
    name: "CASTRO, IVAN",
    address: "300 N Pennsylvania Ave",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 628-1081",
    lat: 28.599776,
    lng: -81.356626,
    id: 298,
  },
  {
    name: "CATANA, ROBERT",
    address: "3428 N ROOSEVELT BLVD",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(305) 295-9797",
    lat: 24.571567,
    lng: -81.760664,
    id: 299,
  },
  {
    name: "CAUDILL, JEFFREY",
    address: "5851 TIMUQUANA RD. , SUITE 401",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32210,
    phone: "(904) 317-5069",
    lat: 30.24767,
    lng: -81.73501,
    id: 300,
  },
  {
    name: "CAVA, ROBERT",
    address: "1 Andalusia Ave , SUITE 101",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 669-0690",
    lat: 25.749055,
    lng: -80.255703,
    id: 301,
  },
  {
    name: "CEBALLOS, CESAR",
    address: "7800 SW 87TH AVENUE, A110",
    city: "MIAMI",
    state: "FL",
    zip: 33173,
    phone: "(305) 596-2828",
    lat: 25.696577,
    lng: -80.334934,
    id: 302,
  },
  {
    name: "Cedeno, Jose",
    address: "909 State Road 436",
    city: "CASSELBERRY",
    state: "FL",
    zip: 32707,
    phone: "(407) 402-3745",
    lat: 28.649297,
    lng: -81.325782,
    id: 303,
  },
  {
    name: "CELIGOJ, FRANK",
    address: "210 JUPITER LAKES BLVD , STE 4106A",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 295-4446",
    lat: 26.9211,
    lng: -80.097,
    id: 304,
  },
  {
    name: "CELLINI, ALDINO",
    address: "2011 S 25 ST, STE 206",
    city: "FORT PIERCE",
    state: "FL",
    zip: 34947,
    phone: "(772) 237-3600",
    lat: 27.427694,
    lng: -80.350697,
    id: 305,
  },
  {
    name: "CESPEDES, EDGARD",
    address: "11160 SW 88 STREET , Suite 111",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 596-2325",
    lat: 25.685616,
    lng: -80.375907,
    id: 306,
  },
  {
    name: "CHALHOUB, FADI",
    address: "2436 UNIVERSITY BLVD W",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32217,
    phone: "(904) 304-7404",
    lat: 30.260628,
    lng: -81.63392,
    id: 307,
  },
  {
    name: "CHANDLER, ELIZABETH",
    address: "2 Pine Lakes Pkwy N , Suite 3",
    city: "PALM COAST",
    state: "FL",
    zip: 32137,
    phone: "(386) 264-6036",
    lat: 29.553706,
    lng: -81.255863,
    id: 308,
  },
  {
    name: "CHANDRA, SUMEET",
    address: "2290 W. EAU GALLIE BLVD , SUITE 202",
    city: "MELBOURNE",
    state: "FL",
    zip: 32935,
    phone: "(321) 254-4776",
    lat: 28.129184,
    lng: -80.657901,
    id: 309,
  },
  {
    name: "CHANLATTE-ITHIER, MARINO",
    address: "917 RINEHART ROAD, SUITE 2001",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(800) 652-0592",
    lat: 28.783867,
    lng: -81.346404,
    id: 310,
  },
  {
    name: "CHANMUGAM, JAY",
    address: "1133 SAXON BLVD, #100",
    city: "ORANGE CITY",
    state: "FL",
    zip: 32763,
    phone: "(386) 878-4137",
    lat: 28.91178,
    lng: -81.281064,
    id: 311,
  },
  {
    name: "CHAPIN, ETHAN",
    address: "7700 WEST SUNRISE BLVD , STE 200",
    city: "PLANTATION",
    state: "FL",
    zip: 33322,
    phone: "(800) 437-2672",
    lat: 26.147083,
    lng: -80.254615,
    id: 312,
  },
  {
    name: "CHAPLIK, IGOR",
    address: "4331 N FEDERAL HIGHWAY , #400",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33308,
    phone: "(954) 772-0416",
    lat: 26.181692,
    lng: -80.120004,
    id: 313,
  },
  {
    name: "CHAPMAN, CHRISTINE",
    address: "911 SOUTH MAIN STREET",
    city: "TRENTON",
    state: "FL",
    zip: 32693,
    phone: "(352) 463-2374",
    lat: 29.607516,
    lng: -82.818822,
    id: 314,
  },
  {
    name: "CHAPMAN, GLENN",
    address: "4600 N OCEAN BLVD , SUITE 101",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33435,
    phone: "(561) 330-4300",
    lat: 26.507102,
    lng: -80.053965,
    id: 315,
  },
  {
    name: "CHARLES, KEITH",
    address: "17560 US HIGHWAY 441",
    city: "MOUNT DORA",
    state: "FL",
    zip: 32757,
    phone: "(352) 735-2020",
    lat: 28.821027,
    lng: -81.665361,
    id: 316,
  },
  {
    name: "CHARLES, WILLIAM",
    address: "38 NE 20 th Ave",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33060,
    phone: "(954) 545-1778",
    lat: 26.232967,
    lng: -80.103599,
    id: 317,
  },
  {
    name: "CHEESMAN, DAVID",
    address: "2121 FIFTH AVENUE NORTH",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 239-7246",
    lat: 27.777687,
    lng: -82.661524,
    id: 318,
  },
  {
    name: "CHEN, AVIS",
    address: "3781 San Jose PL, #27",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32257,
    phone: "(904) 862-6869",
    lat: 30.197853,
    lng: -81.618619,
    id: 319,
  },
  {
    name: "cheriyath, pramil",
    address: "205 S front street",
    city: "HARRISBURG",
    state: "PA",
    zip: 17101,
    phone: "(717) 460-1971",
    lat: 40.25705,
    lng: -76.879211,
    id: 320,
  },
  {
    name: "CHERN, RICHARD",
    address: "12889 EMERALD COAST PARKWAY WEST , SUITE 107B",
    city: "MIRAMAR BEACH",
    state: "FL",
    zip: 32550,
    phone: "(850) 837-1271",
    lat: 30.384099,
    lng: -86.387578,
    id: 321,
  },
  {
    name: "CHERUKUPALLY, PALLAVI",
    address: "10920 Moss Park Rd , SUITE 218",
    city: "ORLANDO",
    state: "FL",
    zip: 32832,
    phone: "(407) 730-5600",
    lat: 28.413783,
    lng: -81.22572,
    id: 322,
  },
  {
    name: "CHESLOCK, TIMOTHY",
    address: "1000 Waterman Way",
    city: "TAVARES",
    state: "FL",
    zip: 32778,
    phone: "(352) 253-3336",
    lat: 28.82354,
    lng: -81.710784,
    id: 323,
  },
  {
    name: "CHEWNING, JOHN",
    address: "1425 HAND AVENUE, SUITE L",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 256-3977",
    lat: 29.262088,
    lng: -81.084275,
    id: 324,
  },
  {
    name: "CHILDRESS, JOHN",
    address: "210 s federal highway , ste 303",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33020,
    phone: "(954) 322-1110",
    lat: 26.0099,
    lng: -80.143494,
    id: 325,
  },
  {
    name: "CHILITO, ALICIA",
    address: "13501 SW 128th St , SUITE 207",
    city: "MIAMI",
    state: "FL",
    zip: 33186,
    phone: "(305) 269-1988",
    lat: 25.64842,
    lng: -80.412112,
    id: 326,
  },
  {
    name: "CHING, PEGGY",
    address: "8461 Lake Worth Road",
    city: "LAKE WORTH",
    state: "FL",
    zip: 33467,
    phone: "(786) 779-0784",
    lat: 26.618716,
    lng: -80.178231,
    id: 327,
  },
  {
    name: "CHISHOLM, WILLIAM",
    address: "333 N. Byron Butler Pkwy",
    city: "PERRY",
    state: "FL",
    zip: 32347,
    phone: "(850) 584-0800",
    lat: 30.120099,
    lng: -83.593267,
    id: 328,
  },
  {
    name: "CHOKKAVELU, VISWANATHAN",
    address: "8646 Gladiolus drive",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 307-0841",
    lat: 26.521679,
    lng: -81.897135,
    id: 329,
  },
  {
    name: "CHOUDHARY, SUDHIR",
    address: "401 NW 42nd Avenue",
    city: "PLANTATION",
    state: "FL",
    zip: 33317,
    phone: "(954) 587-5010",
    lat: 26.12647,
    lng: -80.205196,
    id: 330,
  },
  {
    name: "CHOUDHRY-AKHTER, MYRA",
    address: "13067 Telecom N Parkway",
    city: "TAMPA",
    state: "FL",
    zip: 33637,
    phone: "(813) 779-6303",
    lat: 28.064606,
    lng: -82.370625,
    id: 331,
  },
  {
    name: "CHOWDHARI, SHAUKAT",
    address: "11707 CLUB DRIVE",
    city: "TAMPA",
    state: "FL",
    zip: 33612,
    phone: "(813) 977-2222",
    lat: 28.055941,
    lng: -82.430004,
    id: 332,
  },
  {
    name: "Choxi, Ankeet",
    address: "1000 West Avenue , Apt 1027",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33139,
    phone: "(770) 313-6741",
    lat: 25.781046,
    lng: -80.142716,
    id: 333,
  },
  {
    name: "CHRZAN, DOMINIK",
    address: "9970 Central Park Blvd N. , Suite 102",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 430-3599",
    lat: 26.355815,
    lng: -80.202215,
    id: 334,
  },
  {
    name: "CILBRITH, DAVID",
    address: "5425 S SEMORAN BLVD , SUITE 11",
    city: "ORLANDO",
    state: "FL",
    zip: 32822,
    phone: "(407) 658-4616",
    lat: 28.483871,
    lng: -81.309545,
    id: 335,
  },
  {
    name: "CILIBERTI, ERIC",
    address: "6233 N. University Drive",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 721-0000",
    lat: 26.200587,
    lng: -80.2523,
    id: 336,
  },
  {
    name: "CIPRIANI, JOSEPH",
    address: "3965 DEER CROSSING COURT , NUMBER 105",
    city: "NAPLES",
    state: "FL",
    zip: 34114,
    phone: "(516) 581-3606",
    lat: 26.03154,
    lng: -81.68783,
    id: 337,
  },
  {
    name: "CIRILLO, RONALD",
    address: "8183 ABINGDON COURT , UNIVERSITY PARK",
    city: "BRADENTON",
    state: "FL",
    zip: 34201,
    phone: "(941) 706-4191",
    lat: 27.394004,
    lng: -82.480236,
    id: 338,
  },
  {
    name: "CLARK, JAMES",
    address: "2600 2nd str",
    city: "COUDERSPORT",
    state: "PA",
    zip: 38375,
    phone: "(330) 604-8759",
    lat: 35.17008,
    lng: -88.59227,
    id: 339,
  },
  {
    name: "CLARK, LISA",
    address: "12815B US HIGHWAY 98 WEST , STE 116",
    city: "MIRAMAR BEACH",
    state: "FL",
    zip: 32550,
    phone: "(850) 269-0111",
    lat: 30.384117,
    lng: -86.385025,
    id: 340,
  },
  {
    name: "CLAVELO, ROMULO",
    address: "1325 SW 1 ST",
    city: "MIAMI",
    state: "FL",
    zip: 33135,
    phone: "(305) 631-0470",
    lat: 25.772733,
    lng: -80.216947,
    id: 341,
  },
  {
    name: "COBIELLA, JOSE",
    address: "950 N Krome Ave, Suite 203",
    city: "HOMESTEAD",
    state: "FL",
    zip: 33030,
    phone: "(305) 242-0911",
    lat: 25.479675,
    lng: -80.477832,
    id: 342,
  },
  {
    name: "CODY, JOSEPH",
    address: "14547 BRUCE B. DOWNS BLVD , SUITE A",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(813) 280-7100",
    lat: 28.079559,
    lng: -82.417587,
    id: 343,
  },
  {
    name: "COHEN, GEORGE",
    address: "1632 RIGGINS ROAD",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 205-6232",
    lat: 30.459132,
    lng: -84.238642,
    id: 344,
  },
  {
    name: "COHEN, STEVEN",
    address: "2788 70th Ave S",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33712,
    phone: "(727) 403-9463",
    lat: 27.704478,
    lng: -82.670431,
    id: 345,
  },
  {
    name: "COHEN-NEAMIE, DANIEL",
    address: "315 N. LAKEMONT AVENUE",
    city: "WINTER PARK",
    state: "FL",
    zip: 32792,
    phone: "(407) 622-2030",
    lat: 28.599382,
    lng: -81.328228,
    id: 346,
  },
  {
    name: "COLE, PERRY",
    address: "4131 University Blvd. South , SUITE 12",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 274-8813",
    lat: 30.281743,
    lng: -81.6027,
    id: 347,
  },
  {
    name: "COLE, WILLIAM",
    address: "4071 BEE RIDGE RD , SUITE 101",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 371-7171",
    lat: 27.299027,
    lng: -82.486272,
    id: 348,
  },
  {
    name: "COLEMAN, STEVEN",
    address: "9200 BONITA BEACH RD SE , Suite 113",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(305) 791-5353",
    lat: 26.33083,
    lng: -81.79837,
    id: 349,
  },
  {
    name: "COLEN, CHAIM",
    address: "1803 S. Australian Ave.",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33409,
    phone: "(561) 377-8772",
    lat: 26.697633,
    lng: -80.073571,
    id: 350,
  },
  {
    name: "COLLIN, BERJAN",
    address: "1801 SE HILLMOOR DRIVE , STE C207",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-4234",
    lat: 27.285382,
    lng: -80.293981,
    id: 351,
  },
  {
    name: "COLLINS, ROBERT",
    address: "1630 SOUTH CONGRESS AVENUE , SUITE 100",
    city: "PALM SPRINGS",
    state: "FL",
    zip: 33461,
    phone: "(561) 472-2888",
    lat: 26.636228,
    lng: -80.087324,
    id: 352,
  },
  {
    name: "COLLMAN, KRISTAMARIE",
    address: "4530 S Orange Blossom Trail , #735",
    city: "ORLANDO",
    state: "FL",
    zip: 32839,
    phone: "(609) 610-9922",
    lat: 28.497388,
    lng: -81.398713,
    id: 353,
  },
  {
    name: "COLOMBO, CARLOS",
    address: "4170 TOWN CENTER BLVD. , SUITE 100",
    city: "ORLANDO",
    state: "FL",
    zip: 32837,
    phone: "(407) 857-6166",
    lat: 28.361943,
    lng: -81.428573,
    id: 354,
  },
  {
    name: "COLON, EDWIN",
    address: "36739 SR 52 , SUITE 102",
    city: "DADE CITY",
    state: "FL",
    zip: 33525,
    phone: "(352) 567-4117",
    lat: 28.351302,
    lng: -82.208871,
    id: 355,
  },
  {
    name: "COMMEDORE, EMILE",
    address: "14502 N. DALE MABRY HWY , SUITE 326",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(813) 968-3344",
    lat: 28.079606,
    lng: -82.506938,
    id: 356,
  },
  {
    name: "CONCILIO, IAN",
    address: "701 W. Hallandale Beach Blvd , Suite 104",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(786) 545-5666",
    lat: 25.98503,
    lng: -80.156541,
    id: 357,
  },
  {
    name: "CONDON, JAMES",
    address: "7645 WIMPOLE DRIVE",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34655,
    phone: "(727) 243-6277",
    lat: 28.196714,
    lng: -82.6882,
    id: 358,
  },
  {
    name: "CONIDI, FRANCIS",
    address: "10377 S. US HIGHWAY 1 , SUITE 104",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 337-7272",
    lat: 27.275408,
    lng: -80.291663,
    id: 359,
  },
  {
    name: "CONNOR, SETH",
    address: "9065 DADELAND BLVD",
    city: "MIAMI",
    state: "FL",
    zip: 33156,
    phone: "(305) 271-9100",
    lat: 25.686266,
    lng: -80.313204,
    id: 360,
  },
  {
    name: "CONTRERAS, RUBEN",
    address: "132 Moorings Park Drive",
    city: "NAPLES",
    state: "FL",
    zip: 34105,
    phone: "(239) 624-1120",
    lat: 26.20056,
    lng: -81.7914,
    id: 361,
  },
  {
    name: "COOK, LEWIS",
    address: "1203 NW SPRUCE RIDGE DR",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 419-8365",
    lat: 27.219913,
    lng: -80.269543,
    id: 362,
  },
  {
    name: "COOPER, HARRY",
    address: "1250 EAST HALLANDALE BEACH BLVD , SUITE 700",
    city: "HALLANDALE",
    state: "FL",
    zip: 33009,
    phone: "(954) 456-3757",
    lat: 25.984664,
    lng: -80.135648,
    id: 363,
  },
  {
    name: "Cooperstein, Gary",
    address: "9641 Brook Isles Avenue",
    city: "WEST DELRAY BEACH",
    state: "FL",
    zip: 33446,
    phone: "(484) 678-5363",
    lat: 26.449238,
    lng: -80.198247,
    id: 364,
  },
  {
    name: "COPPOLA, NICHOLAS",
    address: "820 37 Place",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 567-4336",
    lat: 27.638005,
    lng: -80.40294,
    id: 365,
  },
  {
    name: "CORBETT, SHAUN",
    address: "5200 NE 2ND AVE",
    city: "MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(305) 532-7246",
    lat: 25.823107,
    lng: -80.192185,
    id: 366,
  },
  {
    name: "CORDOVES, LAZARO",
    address: "5951 NW 173rd Drive, Suite 7",
    city: "HIALEAH",
    state: "FL",
    zip: 33015,
    phone: "(305) 606-9042",
    lat: 25.931188,
    lng: -80.299444,
    id: 367,
  },
  {
    name: "CORNACCHIA, LOUIS",
    address: "110 Ocean Blvd",
    city: "POINT LOOKOUT",
    state: "NY",
    zip: 11569,
    phone: "(516) 695-8961",
    lat: 40.586621,
    lng: -73.580122,
    id: 368,
  },
  {
    name: "CORNEAL, SCOTT",
    address: "1301 PLANTATION ISLAND DR. S , SUITE 402 A",
    city: "SAINT AUGUSTINE",
    state: "FL",
    zip: 32080,
    phone: "(904) 471-4744",
    lat: 29.863667,
    lng: -81.298223,
    id: 369,
  },
  {
    name: "CORREA LUNA, LUIS",
    address: "86 W UNDERWOOD ST",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(321) 841-4085",
    lat: 28.526763,
    lng: -81.379384,
    id: 370,
  },
  {
    name: "CORZO, HECTOR",
    address: "7955 66TH STREET NORTH , STE D",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(727) 541-3362",
    lat: 27.844135,
    lng: -82.728227,
    id: 371,
  },
  {
    name: "COSSU, JOHN",
    address: "6120 WINKLER RD , SUITE E",
    city: "FORT MYERS",
    state: "FL",
    zip: 33919,
    phone: "(239) 481-2400",
    lat: 26.554515,
    lng: -81.899263,
    id: 372,
  },
  {
    name: "COSTELL, BRIAN",
    address: "9970 CENTRAL PARK BLVD N, SUITE 207",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 482-1027",
    lat: 26.355815,
    lng: -80.202215,
    id: 373,
  },
  {
    name: "COTTAM, JOHN",
    address: "14310 N DALE MABRY , SUITE 180",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(813) 962-4210",
    lat: 28.075786,
    lng: -82.508382,
    id: 374,
  },
  {
    name: "COUPET, SIDNEY",
    address: "100 E. Sample Road , Suite 300",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33064,
    phone: "(786) 207-4788",
    lat: 26.274702,
    lng: -80.121413,
    id: 375,
  },
  {
    name: "COURTNEY, MICHAEL",
    address: "5467 COMMERCIAL WAY",
    city: "SPRING HILL",
    state: "FL",
    zip: 34606,
    phone: "(352) 597-7111",
    lat: 28.508988,
    lng: -82.583066,
    id: 376,
  },
  {
    name: "COUTURIER, GEORG",
    address: "8575 NE 138TH LANE , SUITE 203",
    city: "LADY LAKE",
    state: "FL",
    zip: 32159,
    phone: "(352) 674-2080",
    lat: 28.959044,
    lng: -81.960137,
    id: 377,
  },
  {
    name: "COX, JEFFREY",
    address: "4709 Scenic Hwy.",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 912-8825",
    lat: 30.46985,
    lng: -87.161487,
    id: 378,
  },
  {
    name: "COYKENDALL, DAVID",
    address: "3370 B 3370 Burns Road , Suite 100",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 513-9806",
    lat: 26.848356,
    lng: -80.087612,
    id: 379,
  },
  {
    name: "Crane, James",
    address: "664 Kingsley Ave. , #104",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 999-4673",
    lat: 30.165278,
    lng: -81.708347,
    id: 380,
  },
  {
    name: "CREIGHTON, CLARA",
    address: "9724 N ARMENIA AVE, STE 100",
    city: "TAMPA",
    state: "FL",
    zip: 33612,
    phone: "(813) 915-8666",
    lat: 28.039375,
    lng: -82.481958,
    id: 381,
  },
  {
    name: "CREW, ERICA",
    address: "333 W Cocoa Beach Causeway , Suite D",
    city: "COCOA BEACH",
    state: "FL",
    zip: 32931,
    phone: "(321) 613-5001",
    lat: 28.358593,
    lng: -80.611604,
    id: 382,
  },
  {
    name: "CROUCH, EUGENE",
    address: "155 S HALIFAX AVE",
    city: "DAYTONA BEACH",
    state: "FL",
    zip: 32118,
    phone: "(386) 252-7119",
    lat: 29.221367,
    lng: -81.011595,
    id: 383,
  },
  {
    name: "CROWLEY, EMILY",
    address: "801 West Bay Drive , Suite 434",
    city: "LARGO",
    state: "FL",
    zip: 33770,
    phone: "(727) 386-8583",
    lat: 27.915162,
    lng: -82.796994,
    id: 384,
  },
  {
    name: "CRUMP, JOHN",
    address: "3781 San Jose Place, Ste 27",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32257,
    phone: "(904) 862-6869",
    lat: 30.197853,
    lng: -81.618619,
    id: 385,
  },
  {
    name: "CRUZ, JOSE",
    address: "4600 N. Habana Ave., SUITE # 12",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(813) 876-7700",
    lat: 27.984209,
    lng: -82.492876,
    id: 386,
  },
  {
    name: "CRUZ, VICTOR",
    address: "109 N 12th , STE 1105",
    city: "TAMPA",
    state: "FL",
    zip: 33602,
    phone: "(813) 808-3142",
    lat: 27.948476,
    lng: -82.446482,
    id: 387,
  },
  {
    name: "CUASCUT REYES, AIDA",
    address: "1936 lee road, suite 137",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 637-8300",
    lat: 28.605569,
    lng: -81.374971,
    id: 388,
  },
  {
    name: "CUERVO-RAMOS, LUCINDA",
    address: "13831 SW 59th Street, SUITE 104",
    city: "MIAMI",
    state: "FL",
    zip: 33183,
    phone: "(305) 270-3087",
    lat: 25.69194,
    lng: -80.41811,
    id: 389,
  },
  {
    name: "CUMBER, ZEHRA",
    address: "224 Southpark Circle East",
    city: "SAINT AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(904) 342-4941",
    lat: 29.863159,
    lng: -81.324849,
    id: 390,
  },
  {
    name: "CURCIONE, PETER",
    address: "3400 Lee Blvd., #105",
    city: "LEHIGH ACRES",
    state: "FL",
    zip: 33971,
    phone: "(239) 368-8277",
    lat: 26.617724,
    lng: -81.687333,
    id: 391,
  },
  {
    name: "DAITCH, JONATHAN",
    address: "8255 COLLEGE PARKWAY , SUITE 200",
    city: "FT MYERS",
    state: "FL",
    zip: 33919,
    phone: "(239) 437-8000",
    lat: 26.55641,
    lng: -81.892003,
    id: 392,
  },
  {
    name: "Damas, Herve",
    address: "8325 NE 2nd AVe , Suite 112",
    city: "MIAMI",
    state: "FL",
    zip: 33138,
    phone: "(833) 362-3262",
    lat: 25.851816,
    lng: -80.192606,
    id: 393,
  },
  {
    name: "DAMERON, DANA",
    address: "475 NW Prima Vista Blvd",
    city: "PORT ST. LUCIE",
    state: "FL",
    zip: 34983,
    phone: "(772) 800-3037",
    lat: 27.318405,
    lng: -80.359526,
    id: 394,
  },
  {
    name: "DANTINI, DANIEL",
    address: "29 N OLD KINGS RD , 6A AND 12B",
    city: "PALM COAST",
    state: "FL",
    zip: 32137,
    phone: "(386) 446-2202",
    lat: 29.560034,
    lng: -81.221641,
    id: 395,
  },
  {
    name: "DAO, HIEN",
    address: "102 4566 E Hwy 20 , Suite 100",
    city: "NICEVILLE",
    state: "FL",
    zip: 32578,
    phone: "(850) 279-3247",
    lat: 30.483727,
    lng: -86.415613,
    id: 396,
  },
  {
    name: "DAPHTARY, UDAY",
    address: "8030 TABBY LANE",
    city: "MAITLAND",
    state: "FL",
    zip: 32751,
    phone: "(407) 924-9732",
    lat: 28.630202,
    lng: -81.404033,
    id: 397,
  },
  {
    name: "DARIA, DANIEL",
    address: "10625 N MILITARY TRL, SUITE 102",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 249-7626",
    lat: 26.832861,
    lng: -80.105671,
    id: 398,
  },
  {
    name: "DASTAIN, JEAN YVES",
    address: "1250 Tamiami N TRL , Suite 104",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 234-4482",
    lat: 26.133968,
    lng: -81.795309,
    id: 399,
  },
  {
    name: "DAVIDSON, MYRON",
    address: "3197 NE 18TH TERR",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33306,
    phone: "(305) 533-5382",
    lat: 26.167876,
    lng: -80.121753,
    id: 400,
  },
  {
    name: "DAVINA, ELEANOR",
    address: "3619 LAKE CENTER DRIVE",
    city: "MOUNT DORA",
    state: "FL",
    zip: 32757,
    phone: "(352) 383-8222",
    lat: 28.814595,
    lng: -81.681325,
    id: 401,
  },
  {
    name: "DAVIS, BARRY",
    address: "951 NW 13TH ST , 2A",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 391-1666",
    lat: 26.361395,
    lng: -80.10402,
    id: 402,
  },
  {
    name: "DAVIS, JUSTIN",
    address: "322 SW 4th Ave",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32601,
    phone: "(352) 641-0123",
    lat: 29.648506,
    lng: -82.328742,
    id: 403,
  },
  {
    name: "DAVIS, MITCHELL",
    address: "5341 W. Atlantic Ave , Suite 306",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 270-4040",
    lat: 26.457665,
    lng: -80.127088,
    id: 404,
  },
  {
    name: "DAVIS, PHILLIP",
    address: "7560 WINKLER ROAD",
    city: "FT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 454-6868",
    lat: 26.51527,
    lng: -81.899232,
    id: 405,
  },
  {
    name: "DAVIS II, CEDRIC",
    address: "499 E. Palmetto Park Rd , suite 222",
    city: "BOCA RATON",
    state: "FL",
    zip: 33432,
    phone: "(678) 799-1669",
    lat: 26.350796,
    lng: -80.077912,
    id: 406,
  },
  {
    name: "DAVISON, ROBERT",
    address: "3425 lake eastbrooke blvd",
    city: "GRAND RAPIDS",
    state: "MI",
    zip: 49546,
    phone: "(616) 916-2620",
    lat: 42.919409,
    lng: -85.582776,
    id: 407,
  },
  {
    name: "DE FERIA, ARMANDO",
    address: "600 N. HIATUS ROAD, SUITE 203",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33026,
    phone: "(954) 392-7157",
    lat: 26.01284,
    lng: -80.2965,
    id: 408,
  },
  {
    name: "DE LA GARZA, MIGUEL",
    address: "5413 GEORGE STREET",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34652,
    phone: "(727) 846-7618",
    lat: 28.237866,
    lng: -82.719893,
    id: 409,
  },
  {
    name: "DE LA HOZ, CARLOS",
    address: "2510 NW 97 AVENUE , SUITE 110",
    city: "DORAL",
    state: "FL",
    zip: 33172,
    phone: "(786) 264-2999",
    lat: 25.797296,
    lng: -80.353152,
    id: 410,
  },
  {
    name: "DE LA TORRE, ENRIQUE",
    address: "777 NW 72nd Avenue",
    city: "MIAMI",
    state: "FL",
    zip: 33126,
    phone: "(786) 753-9092",
    lat: 25.77775,
    lng: -80.31231,
    id: 411,
  },
  {
    name: "DE LA TORRE, ROBERT",
    address: "3635 S CLYDE MORRIS BLVD , #500",
    city: "PORT ORANGE",
    state: "FL",
    zip: 32129,
    phone: "(386) 760-8151",
    lat: 29.145996,
    lng: -81.035032,
    id: 412,
  },
  {
    name: "DE LEON, HECTOR",
    address: "1799 SALK AVENUE",
    city: "TAVARES",
    state: "FL",
    zip: 32778,
    phone: "(352) 742-8300",
    lat: 28.818719,
    lng: -81.7151,
    id: 413,
  },
  {
    name: "DE PADUA, ROBERT",
    address: "1205 MONUMENT ROAD, #203",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32225,
    phone: "(904) 727-5120",
    lat: 30.34192,
    lng: -81.53917,
    id: 414,
  },
  {
    name: "DE PINTO, MARIO",
    address: "1700 EAST VENICE AVENUE",
    city: "VENICE",
    state: "FL",
    zip: 34292,
    phone: "(941) 483-9760",
    lat: 27.100263,
    lng: -82.403849,
    id: 415,
  },
  {
    name: "DEAKTER, DANIEL",
    address: "7666 CYPRESS CRESCENT",
    city: "BOCA RATON",
    state: "FL",
    zip: 33433,
    phone: "(561) 245-1363",
    lat: 26.34904,
    lng: -80.164209,
    id: 416,
  },
  {
    name: "DEAN, BYRON",
    address: "1500 LAKELAND HILLS BLVD #4",
    city: "LAKELAND",
    state: "FL",
    zip: 33805,
    phone: "(863) 937-7157",
    lat: 28.064814,
    lng: -81.953558,
    id: 417,
  },
  {
    name: "DEAN, MELISSA",
    address: "1345 36TH ST., SUITE B",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 567-1500",
    lat: 27.656892,
    lng: -80.398785,
    id: 418,
  },
  {
    name: "DEAN, RAYMOND",
    address: "KH PLA 37TH STREET",
    city: "SEBASTIAN",
    state: "FL",
    zip: 32958,
    phone: "(772) 913-5480",
    lat: 27.799123,
    lng: -80.483098,
    id: 419,
  },
  {
    name: "deandrea, richard",
    address: "A-112 225 Country Club Drive",
    city: "LARGO",
    state: "FL",
    zip: 33771,
    phone: "(722) 480-7349",
    lat: 27.908479,
    lng: -82.756823,
    id: 420,
  },
  {
    name: "DEBEN, SOPHIA",
    address: "2260 NE 123RD ST",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33181,
    phone: "(786) 923-3000",
    lat: 25.889398,
    lng: -80.152928,
    id: 421,
  },
  {
    name: "DECERCE, JOHN",
    address: "107 EDWARDS RD , SUITE F",
    city: "STARKE",
    state: "FL",
    zip: 32091,
    phone: "(904) 368-8111",
    lat: 29.938628,
    lng: -82.113111,
    id: 422,
  },
  {
    name: "DECOSMO, JOHN",
    address: "4800 4 4800 4 ST N",
    city: "ST PETERSBURG",
    state: "FL",
    zip: 33703,
    phone: "(727) 498-6488",
    lat: 27.815974,
    lng: -82.638963,
    id: 423,
  },
  {
    name: "Deeb, Khaled",
    address: "7305 N Military trail",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33410,
    phone: "(954) 483-3381",
    lat: 26.786075,
    lng: -80.111766,
    id: 424,
  },
  {
    name: "DEFREESE, CRAIG",
    address: "661 E Altamonte Dr, Suite 224",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(407) 830-9000",
    lat: 28.66392,
    lng: -81.36797,
    id: 425,
  },
  {
    name: "DEISLER, PATRICIA",
    address: "841 CENTURY MEDICAL DRIVE , SUITE B",
    city: "TITUSVILLE",
    state: "FL",
    zip: 32796,
    phone: "(321) 355-0650",
    lat: 28.625693,
    lng: -80.820912,
    id: 426,
  },
  {
    name: "DEL RIO, JUAN",
    address: "1749 SE 28TH LOOP",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(407) 218-2256",
    lat: 29.16216,
    lng: -82.114186,
    id: 427,
  },
  {
    name: "DEL SOL, MARIA",
    address: "240 Cr Crandon Boulevard, Suite 230",
    city: "KEY BISCAYNE",
    state: "FL",
    zip: 33149,
    phone: "(305) 439-0085",
    lat: 25.693713,
    lng: -80.162825,
    id: 428,
  },
  {
    name: "DELEO, CHRISTOPHER",
    address: "750 S. Federal Hwy",
    city: "DEERFIELD BEACH",
    state: "FL",
    zip: 33441,
    phone: "(954) 421-8181",
    lat: 26.307509,
    lng: -80.091944,
    id: 429,
  },
  {
    name: "DELGADO, MARIA",
    address: "43 NE 15TH ST",
    city: "HOMESTEAD",
    state: "FL",
    zip: 33030,
    phone: "(786) 243-1909",
    lat: 25.484767,
    lng: -80.476554,
    id: 430,
  },
  {
    name: "DELL'AQUILA, PAUL",
    address: "339 PASSAIC AVE.",
    city: "NUTLEY",
    state: "NJ",
    zip: 7110,
    phone: "(973) 542-2880",
    lat: 40.818165,
    lng: -74.154108,
    id: 431,
  },
  {
    name: "DELUCA, JOHN",
    address: "11951 11951 US Highway 1 , Suite 105",
    city: "NORTH PALM BEACH",
    state: "FL",
    zip: 33408,
    phone: "(561) 537-2603",
    lat: 26.851292,
    lng: -80.059711,
    id: 432,
  },
  {
    name: "DELUCIA, EUGENE",
    address: "111 2nd Ave NE. , suite 304B",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 873-0500",
    lat: 27.774324,
    lng: -82.633516,
    id: 433,
  },
  {
    name: "DEMARCHI, WILLIAM",
    address: "9878 CLINT MOORE RD , SUITE 202",
    city: "BOCA RATON",
    state: "FL",
    zip: 33496,
    phone: "(305) 585-5400",
    lat: 26.408324,
    lng: -80.201894,
    id: 434,
  },
  {
    name: "DEMBOWSKE, KERRI",
    address: "2520 SE FEDERAL HWY",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 288-4911",
    lat: 27.173773,
    lng: -80.234059,
    id: 435,
  },
  {
    name: "DENO, YOHANNA",
    address: "1700 SE HILLMOOR DR , SUITE 500",
    city: "PORT ST LUCIES",
    state: "FL",
    zip: 34952,
    phone: "(772) 873-1770",
    lat: 27.286313,
    lng: -80.295969,
    id: 436,
  },
  {
    name: "DENSON, AARON",
    address: "15211 CORTEZ BLVD",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 345-4565",
    lat: 28.5349,
    lng: -82.476453,
    id: 437,
  },
  {
    name: "DEPENA, FLORENCIO",
    address: "11760 SW 40TH STREET , SUITE 411",
    city: "MIAMI",
    state: "FL",
    zip: 33175,
    phone: "(305) 227-9339",
    lat: 25.732182,
    lng: -80.386088,
    id: 438,
  },
  {
    name: "DERBES, THOMAS",
    address: "2211 2211 Highway 77 , STE 105",
    city: "LYNN HAVEN",
    state: "FL",
    zip: 32444,
    phone: "(850) 872-1300",
    lat: 30.223391,
    lng: -85.647837,
    id: 439,
  },
  {
    name: "DESAI, POORVI",
    address: "17 Davis Boulevard , Suite 308",
    city: "TAMPA",
    state: "FL",
    zip: 33606,
    phone: "(813) 259-0670",
    lat: 27.936141,
    lng: -82.459378,
    id: 440,
  },
  {
    name: "DESROSIERS, RONEL",
    address: "1599 SW 187th Avenue",
    city: "MIAMI",
    state: "FL",
    zip: 33186,
    phone: "(305) 480-4242",
    lat: 25.757441,
    lng: -80.498158,
    id: 441,
  },
  {
    name: "DESSER, DANA",
    address: "1049 South State Road 7",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 403-1616",
    lat: 26.662129,
    lng: -80.205235,
    id: 442,
  },
  {
    name: "DEUTSCHER, EDWARD",
    address: "324 Main Street",
    city: "SAFETY HARBOR",
    state: "FL",
    zip: 34695,
    phone: "(727) 238-3455",
    lat: 27.990584,
    lng: -82.690617,
    id: 443,
  },
  {
    name: "DEVRIES, BENJAMIN",
    address: "1573 S. Ft Harrison Ave.",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 584-8777",
    lat: 27.940155,
    lng: -82.798062,
    id: 444,
  },
  {
    name: "DI GIAMBERARDINO, ADRIANO",
    address: "800 East Hallandale beach Blvd.",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(954) 294-3879",
    lat: 25.9851,
    lng: -80.139969,
    id: 445,
  },
  {
    name: "DI IULLO, NINO",
    address: "2075 TOWN CENTER BLVD",
    city: "ORLANDO",
    state: "FL",
    zip: 32837,
    phone: "(407) 240-0129",
    lat: 28.367886,
    lng: -81.406616,
    id: 446,
  },
  {
    name: "DIAMOND, PAUL",
    address: "3848 FAU BLVD , SUITE 201",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 394-3088",
    lat: 26.383926,
    lng: -80.097111,
    id: 447,
  },
  {
    name: "DIAS, TAHA",
    address: "200 AVENUE F, NE",
    city: "WINTER HAVEN",
    state: "FL",
    zip: 33881,
    phone: "(863) 584-8354",
    lat: 28.027696,
    lng: -81.725525,
    id: 448,
  },
  {
    name: "DIAZ, GERARDO",
    address: "5975 Sunset Dr, suite 402",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 669-2833",
    lat: 25.7048,
    lng: -80.29067,
    id: 449,
  },
  {
    name: "DIAZ, JORGE",
    address: "7100 WEST 20 AVENUE , SUITE 401",
    city: "HIALEAH",
    state: "FL",
    zip: 33016,
    phone: "(305) 821-6112",
    lat: 25.88591,
    lng: -80.325188,
    id: 450,
  },
  {
    name: "Diaz Fernandez, Virmarie",
    address: "6800 NW 9th Blvd, Suite 3",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32605,
    phone: "(352) 332-0902",
    lat: 29.662228,
    lng: -82.415192,
    id: 451,
  },
  {
    name: "DIBLASIO, GARY",
    address: "701 NORTHLAKE BLVD, SUITE 208",
    city: "NORTH PALM BCH",
    state: "FL",
    zip: 33408,
    phone: "(561) 863-2828",
    lat: 26.80797,
    lng: -80.06895,
    id: 452,
  },
  {
    name: "DIDONNA, MICHAEL",
    address: "39326 US HIGHWAY 19 NORTH , SUITE A & B",
    city: "TARPON SPRINGS",
    state: "FL",
    zip: 34689,
    phone: "(727) 935-1933",
    lat: 28.129476,
    lng: -82.740981,
    id: 453,
  },
  {
    name: "DIPASQUALE, PAUL",
    address: "27516 CASHFORD CIRCLE, SUITE 101",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33544,
    phone: "(813) 406-4400",
    lat: 28.189034,
    lng: -82.360807,
    id: 454,
  },
  {
    name: "DITARANTO, MICHAEL",
    address: "1537 Oak Street",
    city: "SARASOTA",
    state: "FL",
    zip: 34236,
    phone: "(941) 376-4546",
    lat: 27.331256,
    lng: -82.539249,
    id: 455,
  },
  {
    name: "DIXON, CAROLYN",
    address: "5397 EVERWOOD RUN",
    city: "SARASOTA",
    state: "FL",
    zip: 34235,
    phone: "(941) 356-6639",
    lat: 27.367204,
    lng: -82.460208,
    id: 456,
  },
  {
    name: "DOCTOR, TRUDELL",
    address: "7730 Boynton Beach Blvd , SUITE 3",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 877-1800",
    lat: 26.52798,
    lng: -80.1673,
    id: 457,
  },
  {
    name: "DODSON, DAVID",
    address: "1411 N FLAGLER DR, SUITE 7900",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33401,
    phone: "(561) 655-8448",
    lat: 26.72599,
    lng: -80.05149,
    id: 458,
  },
  {
    name: "DOMINGUEZ, DAVID",
    address: "141 Coconut Dr",
    city: "INDIALANTIC",
    state: "FL",
    zip: 32903,
    phone: "(321) 610-8939",
    lat: 28.110537,
    lng: -80.573448,
    id: 459,
  },
  {
    name: "DOMINICK, RAYMOND",
    address: "8404 US HWY 441",
    city: "LEESBURG",
    state: "FL",
    zip: 34788,
    phone: "(352) 250-5545",
    lat: 28.831954,
    lng: -81.817526,
    id: 460,
  },
  {
    name: "DONOWAY, ROBERT",
    address: "4000 HOLLYWOOD BLVD, suite 160N/600",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 986-6366",
    lat: 26.010797,
    lng: -80.183614,
    id: 461,
  },
  {
    name: "DONSHIK, JON",
    address: "301 NW 84TH AVENUE , SUITE 303",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 888-1000",
    lat: 26.124807,
    lng: -80.260963,
    id: 462,
  },
  {
    name: "DORN, JOSEPH",
    address: "2777 Miccosukee Rd",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 906-5000",
    lat: 30.467714,
    lng: -84.233172,
    id: 463,
  },
  {
    name: "DORSEY, SANDRA",
    address: "11449 DUTCH IRIS DRIVE",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33578,
    phone: "(813) 846-0800",
    lat: 27.898573,
    lng: -82.307341,
    id: 464,
  },
  {
    name: "DOSUMU-JOHNSON, THOMAS",
    address: "169 S Alvarado Street , #101",
    city: "LOS ANGELES",
    state: "CA",
    zip: 90057,
    phone: "(562) 733-9789",
    lat: 34.0651,
    lng: -118.27159,
    id: 465,
  },
  {
    name: "Dotson, Robert",
    address: "1022 M 1022 Main Street, Suite J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.010895,
    lng: -82.773995,
    id: 466,
  },
  {
    name: "DOTSON, SUZANNA",
    address: "10041 Pines Blvd., Suite A",
    city: "Pembroke Pines PEMBROKE PINES",
    state: "FL",
    zip: 33024,
    phone: "(954) 328-3768",
    lat: 26.008348,
    lng: -80.278752,
    id: 467,
  },
  {
    name: "DOUEK, MAURICE",
    address: "2489 DIPLOMAT PARKWAY EAST",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33909,
    phone: "(239) 652-1800",
    lat: 26.685311,
    lng: -81.920009,
    id: 468,
  },
  {
    name: "DOWDY, MATTHEW",
    address: "11264 Boyette Road",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33569,
    phone: "(813) 672-2014",
    lat: 27.854613,
    lng: -82.315718,
    id: 469,
  },
  {
    name: "DRAESEL, JEFFREY",
    address: "1108 KANE CONCOURSE, SUITE 300",
    city: "BAY HARBOR ISLANDS",
    state: "FL",
    zip: 33154,
    phone: "(305) 864-1373",
    lat: 25.88661,
    lng: -80.13146,
    id: 470,
  },
  {
    name: "DRAGUN, JOANNE",
    address: "7751 BAYMEADOWS RD E , suite 101",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 645-5045",
    lat: 30.23119,
    lng: -81.51003,
    id: 471,
  },
  {
    name: "DRAKE, NICHOLAS",
    address: "1717 NORTH E STREET , TOWER 3, SUITE 231",
    city: "PENSACOLA",
    state: "FL",
    zip: 32501,
    phone: "(850) 469-7975",
    lat: 30.430275,
    lng: -87.23,
    id: 472,
  },
  {
    name: "DRANGINIS, PATRICIA",
    address: "3110 LAFAYETTE LANDING DR.",
    city: "DE LEON SPRINGS",
    state: "FL",
    zip: 32130,
    phone: "(386) 290-3351",
    lat: 29.163493,
    lng: -81.26121,
    id: 473,
  },
  {
    name: "DRATLER, STANLEY",
    address: "24430 SANDHILL BLVD , UNIT 303",
    city: "PUNTA GORDA",
    state: "FL",
    zip: 33983,
    phone: "(941) 244-4414",
    lat: 27.021853,
    lng: -82.044115,
    id: 474,
  },
  {
    name: "DREXLER, DAVID",
    address: "12773 W. FOREST HILL BLVD , SUITE #1203",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 758-2271",
    lat: 26.67165,
    lng: -80.24738,
    id: 475,
  },
  {
    name: "DROBA, ARTHUR",
    address: "5664 Bee Ridge Road , Unit 101",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 377-6674",
    lat: 27.29871,
    lng: -82.45707,
    id: 476,
  },
  {
    name: "DRUCKER, RICHARD",
    address: "3716 NE 208th Terrace",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 491-4501",
    lat: 25.969216,
    lng: -80.127451,
    id: 477,
  },
  {
    name: "Drummond, Alrick",
    address: "13043 SUMMERFIELD SQUARE DRIVE",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33578,
    phone: "(813) 677-2222",
    lat: 27.795115,
    lng: -82.336595,
    id: 478,
  },
  {
    name: "Drury, James",
    address: "1062 Lake Sebring Drive",
    city: "SEBRING",
    state: "FL",
    zip: 33870,
    phone: "(863) 658-0116",
    lat: 27.519688,
    lng: -81.483651,
    id: 479,
  },
  {
    name: "DUARTE, JUAN",
    address: "5040 NW 7th St , suite 630",
    city: "MIAMI",
    state: "FL",
    zip: 33157,
    phone: "(305) 793-6343",
    lat: 25.777769,
    lng: -80.2768,
    id: 480,
  },
  {
    name: "DUBE, DEBRA",
    address: "901 W WARREN AVENUE , SUITE 1001",
    city: "LONGWOOD",
    state: "FL",
    zip: 32750,
    phone: "(407) 834-0860",
    lat: 28.69911,
    lng: -81.35891,
    id: 481,
  },
  {
    name: "DUDLEY, ROBERT",
    address: "2645 N FEDERAL HWY , SUITE 100",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33483,
    phone: "(561) 740-2004",
    lat: 26.48956,
    lng: -80.063583,
    id: 482,
  },
  {
    name: "DUFRENY, ALPHONSE",
    address: "13140 WEST DIXIE HIGHWAY",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33161,
    phone: "(305) 981-1570",
    lat: 25.896566,
    lng: -80.181794,
    id: 483,
  },
  {
    name: "DUFRESNE, CHANTELLE",
    address: "10861 SE Arielle Ter",
    city: "TEQUESTA",
    state: "FL",
    zip: 33469,
    phone: "(561) 316-7287",
    lat: 26.971437,
    lng: -80.108186,
    id: 484,
  },
  {
    name: "DUIC, PETER",
    address: "10730 SR 54 , SUITE 104",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34655,
    phone: "(727) 372-3888",
    lat: 28.191876,
    lng: -82.636374,
    id: 485,
  },
  {
    name: "DUNN, ROBERT",
    address: "203 S. GLORIA STREET",
    city: "CLEWISTON",
    state: "FL",
    zip: 33440,
    phone: "(863) 983-1123",
    lat: 26.753186,
    lng: -80.94227,
    id: 486,
  },
  {
    name: "DURAN, ROBERTO",
    address: "601 UNIVERSITY BLVD , SUITE 205",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 624-0702",
    lat: 26.89136,
    lng: -80.10496,
    id: 487,
  },
  {
    name: "DURY, PATRICIA",
    address: "8646 Gladiolus Drive , Suite 404",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 202-0606",
    lat: 26.521679,
    lng: -81.897135,
    id: 488,
  },
  {
    name: "ECHAGUE COLMAN, RAMON",
    address: "410 11th St",
    city: "LAKE WALES",
    state: "FL",
    zip: 33853,
    phone: "(863) 679-6811",
    lat: 27.896594,
    lng: -81.565012,
    id: 489,
  },
  {
    name: "ECKERT, ROBERT",
    address: "9901 SURFSIDE AVE",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32257,
    phone: "(888) 908-0143",
    lat: 30.135708,
    lng: -81.541385,
    id: 490,
  },
  {
    name: "ECKLIND, JERROLD",
    address: "300 S MAIN ST , Suite 2",
    city: "WILDWOOD",
    state: "FL",
    zip: 34785,
    phone: "(352) 643-6699",
    lat: 28.860474,
    lng: -82.041277,
    id: 491,
  },
  {
    name: "EDWARDS, DENISE",
    address: "2 TAMPA GENERAL CIRCLE",
    city: "TAMPA",
    state: "FL",
    zip: 33606,
    phone: "(813) 259-8752",
    lat: 27.937082,
    lng: -82.459182,
    id: 492,
  },
  {
    name: "EDWARDS, WILSON",
    address: "1607 LISENBY AVE., SUITE D",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 250-3360",
    lat: 30.177755,
    lng: -85.684154,
    id: 493,
  },
  {
    name: "EDWARDS-CRAWFORD, KAREN",
    address: "1215 Dunn Avenue , Suite 120",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32218,
    phone: "(904) 757-1998",
    lat: 30.431856,
    lng: -81.665816,
    id: 494,
  },
  {
    name: "Edwin, Micah",
    address: "1515 N University Dr",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33071,
    phone: "(786) 214-2001",
    lat: 26.250021,
    lng: -80.251907,
    id: 495,
  },
  {
    name: "EFTEKHARI, NASSER",
    address: "6301 SW 112 STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33156,
    phone: "(305) 206-4726",
    lat: 25.667461,
    lng: -80.296965,
    id: 496,
  },
  {
    name: "EGAS, JUAN",
    address: "17807 Hunting Bow Cir",
    city: "LUTZ",
    state: "FL",
    zip: 33558,
    phone: "(203) 823-6527",
    lat: 28.19296,
    lng: -82.519385,
    id: 497,
  },
  {
    name: "EISENBERG, IRIS",
    address: "4051 Atlantic Blvd",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(904) 751-9491",
    lat: 30.307396,
    lng: -81.622903,
    id: 498,
  },
  {
    name: "EISMAN, EUGENE",
    address: "17971 BISCAYNE BOULEVARD , SUITE 208",
    city: "AVENTURA",
    state: "FL",
    zip: 33160,
    phone: "(305) 932-7814",
    lat: 25.941895,
    lng: -80.147712,
    id: 499,
  },
  {
    name: "EL-GENDY, ALAA",
    address: "2625 LEE BLVD. , SUITE 100",
    city: "LEHIGH",
    state: "FL",
    zip: 33971,
    phone: "(239) 369-3333",
    lat: 26.616474,
    lng: -81.667244,
    id: 500,
  },
  {
    name: "EL-HAJJAR, DANY",
    address: "820 PRUDENTIAL DR , SUITE 304",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(904) 202-3860",
    lat: 30.316273,
    lng: -81.664131,
    id: 501,
  },
  {
    name: "EL-SALAWY, SHERIF",
    address: "486 Town Plaza Ave , Suite 420",
    city: "PONTE VEDRA",
    state: "FL",
    zip: 32081,
    phone: "(352) 514-5211",
    lat: 30.108673,
    lng: -81.421453,
    id: 502,
  },
  {
    name: "ELGOZY, JACOBO",
    address: "4302 ALTON ROAD , SUITE 105",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 674-1887",
    lat: 25.815955,
    lng: -80.137696,
    id: 503,
  },
  {
    name: "ELISEE, SABINE",
    address: "8896 ROYAL PALM BLVD",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 372-0179",
    lat: 26.258312,
    lng: -80.244468,
    id: 504,
  },
  {
    name: "ELISEO, LAURA",
    address: "4725 North Federal Highway",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 492-5753",
    lat: 26.187074,
    lng: -80.119447,
    id: 505,
  },
  {
    name: "ELKINS, ROBERT",
    address: "100 WHETSTONE PLACE , #309",
    city: "ST. AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(904) 615-1181",
    lat: 29.866246,
    lng: -81.319239,
    id: 506,
  },
  {
    name: "ELLENBY, JAY",
    address: "1699 SW 27th Ave.",
    city: "MIAMI",
    state: "FL",
    zip: 33145,
    phone: "(305) 933-6033",
    lat: 25.756026,
    lng: -80.23832,
    id: 507,
  },
  {
    name: "ELLIOTT, PAUL",
    address: "1889 SE Port Saint Lucie Blvd",
    city: "PORT ST. LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 224-2221",
    lat: 27.312492,
    lng: -80.313322,
    id: 508,
  },
  {
    name: "ELLIS, GILLIAN",
    address: "1900 DON WICKHAM DRIVE",
    city: "CLERMONT",
    state: "FL",
    zip: 34711,
    phone: "(352) 536-8840",
    lat: 28.552896,
    lng: -81.722825,
    id: 509,
  },
  {
    name: "ELLIS, NATALIE",
    address: "900 Central Ave. , #302",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33712,
    phone: "(727) 203-4207",
    lat: 27.770964,
    lng: -82.646953,
    id: 510,
  },
  {
    name: "ELLIS, NEIL",
    address: "3450 E. Fletcher Avenue , Suite 350",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(813) 739-7495",
    lat: 28.069731,
    lng: -82.420412,
    id: 511,
  },
  {
    name: "ELLISON, MARGARETT",
    address: "2626 Care Drive , Suite 101",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(888) 496-6621",
    lat: 30.477164,
    lng: -84.232218,
    id: 512,
  },
  {
    name: "ELSBREE, SCOTT",
    address: "2601 CATTLEMEN ROAD , SUITE 304",
    city: "SARASOTA",
    state: "FL",
    zip: 34232,
    phone: "(941) 342-1333",
    lat: 27.31012,
    lng: -82.45126,
    id: 513,
  },
  {
    name: "ELVIR, PATRICIA",
    address: "319 W. TOWN PLACE, SUITE 1",
    city: "ST AUGUSTINE",
    state: "FL",
    zip: 32092,
    phone: "(904) 940-1577",
    lat: 29.983624,
    lng: -81.469599,
    id: 514,
  },
  {
    name: "EMANDI, VENKATA",
    address: "14535 Cortez Blvd",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 596-3622",
    lat: 28.534831,
    lng: -82.484611,
    id: 515,
  },
  {
    name: "ENAMORADO, YANKO",
    address: "4302 ALTON ROAD, SUITE 820",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 674-2876",
    lat: 25.815955,
    lng: -80.137696,
    id: 516,
  },
  {
    name: "EPSTEIN, SANFORD",
    address: "5692 Buckeye Lane",
    city: "THE VILLAGES",
    state: "FL",
    zip: 32163,
    phone: "(850) 261-3636",
    lat: 28.794933,
    lng: -82.031506,
    id: 517,
  },
  {
    name: "ERB, DONALD",
    address: "6050 CATTLERIDGE BLVD , SUITE 201",
    city: "SARASOTA",
    state: "FL",
    zip: 34232,
    phone: "(941) 365-0655",
    lat: 27.304667,
    lng: -82.4496,
    id: 518,
  },
  {
    name: "ERIKSEN, DEBRA",
    address: "15380 N. Florida Ave",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(813) 898-8980",
    lat: 28.091322,
    lng: -82.4606,
    id: 519,
  },
  {
    name: "ESCALANTE, JOSE",
    address: "777 EAST 25TH STREET , SUITE#214",
    city: "HIALEAH",
    state: "FL",
    zip: 33013,
    phone: "(305) 836-1997",
    lat: 25.845455,
    lng: -80.267154,
    id: 520,
  },
  {
    name: "ESCOBAR, JUAN",
    address: "7664 SOUTH US HIGHWAY ONE",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 879-0699",
    lat: 27.325589,
    lng: -80.319681,
    id: 521,
  },
  {
    name: "Esmail-Rawji, Adam",
    address: "150 Muir Rd",
    city: "MARTINEZ",
    state: "CA",
    zip: 94553,
    phone: "(386) 589-4125",
    lat: 37.991974,
    lng: -122.114891,
    id: 522,
  },
  {
    name: "Estel, Jean",
    address: "10025 East Clolonial Drive",
    city: "ORLANDO",
    state: "FL",
    zip: 32817,
    phone: "(407) 382-4218",
    lat: 28.589077,
    lng: -81.227652,
    id: 523,
  },
  {
    name: "ESTES, MELISA",
    address: "2055 MILITARY TRAIL, SUITE 200",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 694-7776",
    lat: 26.91544,
    lng: -80.10225,
    id: 524,
  },
  {
    name: "ETTEDGUI, DANIEL",
    address: "7284 WEST PALMETTO PARK ROAD , 105S",
    city: "BOCA RATON",
    state: "FL",
    zip: 33433,
    phone: "(561) 912-9580",
    lat: 26.350157,
    lng: -80.159763,
    id: 525,
  },
  {
    name: "EUGENE, JEAN-MARIE",
    address: "1362 SW BAYSHORE BLVD",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34984,
    phone: "(772) 873-5213",
    lat: 27.298937,
    lng: -80.368661,
    id: 526,
  },
  {
    name: "EURIBE, CESAR",
    address: "2419 SE 22nd Place",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(352) 572-9760",
    lat: 29.166612,
    lng: -82.1039,
    id: 527,
  },
  {
    name: "EVANS, BOYD",
    address: "12276 SAN JOSE BLVD , Suite 617",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32223,
    phone: "(904) 262-9075",
    lat: 30.147081,
    lng: -81.635804,
    id: 528,
  },
  {
    name: "EVANS, LILLIAN",
    address: "127 LAUREL RD",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 260-3323",
    lat: 26.035451,
    lng: -80.200098,
    id: 529,
  },
  {
    name: "EVERDING, KRISTINE",
    address: "2002 Pennwood Dr",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 243-6356",
    lat: 28.078321,
    lng: -80.619615,
    id: 530,
  },
  {
    name: "EVERETT, TIMOTHY",
    address: "3375 PINE RIDGE RD , suite 204",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 325-6499",
    lat: 26.212275,
    lng: -81.74955,
    id: 531,
  },
  {
    name: "EVERS, HANS",
    address: "5034 7 5034 73rd AVE N",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(727) 685-1404",
    lat: 28.230998,
    lng: -82.179147,
    id: 532,
  },
  {
    name: "EXELBERT, ERIC",
    address: "1117 EAST HALLANDALE BEACH BLVD",
    city: "HALLANDALE",
    state: "FL",
    zip: 33009,
    phone: "(954) 986-6345",
    lat: 25.986399,
    lng: -80.136566,
    id: 533,
  },
  {
    name: "FAJARDO, NATASHA",
    address: "5995 SW 71 Street , Suite 403A",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 894-7400",
    lat: 25.705482,
    lng: -80.291601,
    id: 534,
  },
  {
    name: "FAKIH, FAISAL",
    address: "515 Wekiva Commons Circle",
    city: "APOPKA",
    state: "FL",
    zip: 32712,
    phone: "(407) 464-9516",
    lat: 28.687517,
    lng: -81.505827,
    id: 535,
  },
  {
    name: "FALCON, ARMANDO",
    address: "10961 SW 186 ST",
    city: "MIAMI",
    state: "FL",
    zip: 33157,
    phone: "(305) 252-2228",
    lat: 25.596616,
    lng: -80.369583,
    id: 536,
  },
  {
    name: "FALKOWSKI, STEPHEN",
    address: "410 N Main St , Stes 1 & 2",
    city: "CHIEFLAND",
    state: "FL",
    zip: 32626,
    phone: "(352) 493-7274",
    lat: 29.482125,
    lng: -82.859669,
    id: 537,
  },
  {
    name: "FALLOWS, CHRISTOPHER",
    address: "70 N. LECANTO HWY",
    city: "LECANTO",
    state: "FL",
    zip: 34461,
    phone: "(352) 527-4444",
    lat: 28.863964,
    lng: -82.48926,
    id: 538,
  },
  {
    name: "FARBOWITZ, STEVEN",
    address: "1800 WEST HIBISCUS BLVD , SUITE 101",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 726-1600",
    lat: 28.086335,
    lng: -80.653369,
    id: 539,
  },
  {
    name: "FAREMOUTH, JAMES",
    address: "12250 Tamiami Trail E., suite 202",
    city: "NAPLES",
    state: "FL",
    zip: 34113,
    phone: "(239) 692-8934",
    lat: 26.068132,
    lng: -81.708323,
    id: 540,
  },
  {
    name: "FARMER, DANNY",
    address: "570 MEMORIAL CIR",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 676-0427",
    lat: 29.283647,
    lng: -81.081083,
    id: 541,
  },
  {
    name: "FAROOQUE, MOHAMMAD",
    address: "1106 10175 Fortune Parkway",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 379-5928",
    lat: 30.185228,
    lng: -81.549136,
    id: 542,
  },
  {
    name: "FATTOUCH, PATRICK",
    address: "52 WEST UNDERWOOD STREET",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 581-9180",
    lat: 28.526737,
    lng: -81.378044,
    id: 543,
  },
  {
    name: "FAVIS, WEENA",
    address: "2226 West Main St",
    city: "LEESBURG",
    state: "FL",
    zip: 34748,
    phone: "(352) 250-8415",
    lat: 28.813282,
    lng: -81.898282,
    id: 544,
  },
  {
    name: "FAWAZ, FAWZI",
    address: "962 HWY 331 S.",
    city: "DE FUNIAK SPRINGS",
    state: "FL",
    zip: 32435,
    phone: "(850) 951-8800",
    lat: 30.708184,
    lng: -86.12312,
    id: 545,
  },
  {
    name: "FAZA, BRANDON",
    address: "4725 N FEDERAL HWY",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 771-8000",
    lat: 26.187074,
    lng: -80.119447,
    id: 546,
  },
  {
    name: "FEDOR, ROBERT",
    address: "10500 Ulmerton Road , Suite 360",
    city: "LARGO",
    state: "FL",
    zip: 33771,
    phone: "(727) 517-1500",
    lat: 27.892083,
    lng: -82.783448,
    id: 547,
  },
  {
    name: "FELDMAN, ALAN",
    address: "10333 SEMINOLE BLVD , SUITE 3",
    city: "LARGO",
    state: "FL",
    zip: 33778,
    phone: "(727) 392-0199",
    lat: 27.866556,
    lng: -82.785481,
    id: 548,
  },
  {
    name: "FELICIANO, CARLOS",
    address: "201 Hilda st , Unit 33",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(813) 508-5353",
    lat: 28.317334,
    lng: -81.405251,
    id: 549,
  },
  {
    name: "Fenzl, Mark",
    address: "6131 US Highway 19",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34652,
    phone: "(727) 842-6900",
    lat: 28.248251,
    lng: -82.7287,
    id: 550,
  },
  {
    name: "FERNANDEZ, ANTONIO",
    address: "2 MAIN ENDO HALL",
    city: "WINTER HAVEN",
    state: "FL",
    zip: 33881,
    phone: "(863) 294-8400",
    lat: 28.022701,
    lng: -81.534362,
    id: 551,
  },
  {
    name: "FERNANDEZ, HERIBERTO",
    address: "7500 N.W. 25th Street , Suite 104",
    city: "MIAMI",
    state: "FL",
    zip: 33122,
    phone: "(305) 456-3233",
    lat: 25.794978,
    lng: -80.320002,
    id: 552,
  },
  {
    name: "FERNANDEZ, INTI",
    address: "901-A SW 87 AVE",
    city: "MIAMI",
    state: "FL",
    zip: 33174,
    phone: "(305) 512-0080",
    lat: 25.76108,
    lng: -80.335403,
    id: 553,
  },
  {
    name: "FERNANDEZ, JUAN",
    address: "5040 NW 7TH STREET , SUITE 370",
    city: "MIAMI",
    state: "FL",
    zip: 33126,
    phone: "(305) 648-1087",
    lat: 25.777769,
    lng: -80.2768,
    id: 554,
  },
  {
    name: "FERNANDEZ, MARTA",
    address: "Buildi 7900 NW 33 ST , #101",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33024,
    phone: "(305) 246-1265",
    lat: 26.029582,
    lng: -80.248916,
    id: 555,
  },
  {
    name: "FERNANDEZ, SANDER",
    address: "1320 N Main Street Suite B",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34744,
    phone: "(407) 785-1962",
    lat: 28.304016,
    lng: -81.403934,
    id: 556,
  },
  {
    name: "FERNANDEZ, VANIA",
    address: "3702 WASHINGTON STREET , SUITE 101",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 272-2225",
    lat: 26.00221,
    lng: -80.180826,
    id: 557,
  },
  {
    name: "FERNANDEZ, WIFREDO",
    address: "7201 NORTH UNIVERSITY DR , suite 100",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 721-2200",
    lat: 26.212656,
    lng: -80.254544,
    id: 558,
  },
  {
    name: "FERNANDEZ SABATER, ALBERTO",
    address: "6610 NORTH UNIVERSITY DRIVE , SUITE 210",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 724-9640",
    lat: 26.206048,
    lng: -80.251697,
    id: 559,
  },
  {
    name: "FERNANDEZ-BLAY, ROBERTO",
    address: "4765 SW 148th Ave , 1st Floor, Suite 404",
    city: "DAVIE",
    state: "FL",
    zip: 33331,
    phone: "(954) 614-7762",
    lat: 26.06125,
    lng: -80.347976,
    id: 560,
  },
  {
    name: "FERREIRA, MICHELLE",
    address: "5350 W. Hillsboro Blvd. , SUITE 108",
    city: "COCONUT CREEK",
    state: "FL",
    zip: 33073,
    phone: "(561) 962-1508",
    lat: 26.316832,
    lng: -80.196182,
    id: 561,
  },
  {
    name: "FERRIN, FRANK",
    address: "2020 PONCE DE LEON BLVD, SUITE 105",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 640-5602",
    lat: 25.753945,
    lng: -80.259398,
    id: 562,
  },
  {
    name: "FERRO, JACLYN",
    address: "3661 S Miami Ave , Suite 501",
    city: "COCONUT GROVE",
    state: "FL",
    zip: 33133,
    phone: "(305) 333-8872",
    lat: 25.740156,
    lng: -80.21405,
    id: 563,
  },
  {
    name: "Fichter, Leonard",
    address: "22207 Fox Glenn Trace",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32413,
    phone: "(304) 545-7444",
    lat: 30.3103,
    lng: -85.905314,
    id: 564,
  },
  {
    name: "FIELDS, NADAV",
    address: "2400 PATTERSON STREET , SUITE 216",
    city: "NASHVILLE",
    state: "TN",
    zip: 37203,
    phone: "(615) 342-6830",
    lat: 36.154094,
    lng: -86.809406,
    id: 565,
  },
  {
    name: "Figueredo, Ronald",
    address: "812 West MLK Jr Blvd , Suite 202",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(813) 448-6820",
    lat: 27.981229,
    lng: -82.470877,
    id: 566,
  },
  {
    name: "FIGUEREDO REYES, KAREN",
    address: "9299 SW 152 St, Suite 206",
    city: "MIAMI",
    state: "FL",
    zip: 33157,
    phone: "(786) 808-6190",
    lat: 25.629578,
    lng: -80.343524,
    id: 567,
  },
  {
    name: "FIGUEROA, EDUARDO",
    address: "3785 AIRPORT PULLING ROAD, SUITE A",
    city: "NAPLES",
    state: "FL",
    zip: 34105,
    phone: "(239) 435-1610",
    lat: 26.192896,
    lng: -81.768402,
    id: 568,
  },
  {
    name: "FILIBERTO, FRANK",
    address: "400 EAST STRAWBRIDGE AVE",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 676-3101",
    lat: 28.079754,
    lng: -80.614432,
    id: 569,
  },
  {
    name: "FINA, LERA",
    address: "96279 Brady Point Rd, Suite C",
    city: "FERNANDINA BEACH",
    state: "FL",
    zip: 32034,
    phone: "(904) 572-1884",
    lat: 30.616297,
    lng: -81.505822,
    id: 570,
  },
  {
    name: "FINAN, EUGENE",
    address: "1656 Medical Blvd , ste 302",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(239) 597-7001",
    lat: 26.274807,
    lng: -81.786789,
    id: 571,
  },
  {
    name: "FINE, IRA",
    address: "10075 Jog Road , Suite 202",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 375-8800",
    lat: 26.526482,
    lng: -80.147447,
    id: 572,
  },
  {
    name: "FINE, STEVEN",
    address: "325 SW 28th Street",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33315,
    phone: "(954) 357-4864",
    lat: 26.089439,
    lng: -80.14566,
    id: 573,
  },
  {
    name: "fink, david",
    address: "1800 N Federal Hwy , Suite 104",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33063,
    phone: "(954) 782-0010",
    lat: 26.255012,
    lng: -80.099644,
    id: 574,
  },
  {
    name: "FINK, RANDY",
    address: "8700 N. KENDALL DRIVE , SUITE 208",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 515-5425",
    lat: 25.687581,
    lng: -80.33423,
    id: 575,
  },
  {
    name: "FISCHER, ALAN",
    address: "9980 Central Park Blvd. N. , #320",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 488-2900",
    lat: 26.35455,
    lng: -80.202329,
    id: 576,
  },
  {
    name: "FISHMAN, CRAIG",
    address: "640 S LAKE STREET",
    city: "LEESBURG",
    state: "FL",
    zip: 34748,
    phone: "(352) 360-2301",
    lat: 28.805279,
    lng: -81.866923,
    id: 577,
  },
  {
    name: "FITZER, PETER",
    address: "115 OCEAN KEY WAY",
    city: "JUPITER",
    state: "FL",
    zip: 33477,
    phone: "(561) 747-8770",
    lat: 26.915629,
    lng: -80.064781,
    id: 578,
  },
  {
    name: "FLEISCHMAN, JAMES",
    address: "3280 Old Boynton Road",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33436,
    phone: "(561) 733-3010",
    lat: 26.531475,
    lng: -80.097042,
    id: 579,
  },
  {
    name: "FLETCHER, BRANDON",
    address: "771 Ciara Creek Cove",
    city: "LONGWOOD",
    state: "FL",
    zip: 32750,
    phone: "(407) 543-1270",
    lat: 28.731295,
    lng: -81.333165,
    id: 580,
  },
  {
    name: "FLIEDNER, DANE",
    address: "2340 DAIRY ROAD , SUITE 104",
    city: "MELBOURNE",
    state: "FL",
    zip: 32904,
    phone: "(321) 259-7111",
    lat: 28.07374,
    lng: -80.638714,
    id: 581,
  },
  {
    name: "Florek, Michael",
    address: "133 133 NE 2nd ave , Apt 2204",
    city: "MIAMI",
    state: "FL",
    zip: 33132,
    phone: "(973) 876-8718",
    lat: 25.77591,
    lng: -80.190078,
    id: 582,
  },
  {
    name: "FLORES LANDA, YANETSI",
    address: "981 East Commercial Blvd , Suite 206",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33334,
    phone: "(908) 377-9607",
    lat: 26.188972,
    lng: -80.133932,
    id: 583,
  },
  {
    name: "FLOYD, JULIE ANN",
    address: "2784 N ROOSEVELT BLVD",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(305) 292-4970",
    lat: 24.564609,
    lng: -81.774212,
    id: 584,
  },
  {
    name: "FLUGSRUD-BRECKENRIDGE, MARCIA",
    address: "1111 EAST END BLVD , SOUTH MARION AVENUE",
    city: "WILKES BARRE",
    state: "PA",
    zip: 18711,
    phone: "(570) 824-3521",
    lat: 41.248105,
    lng: -75.835976,
    id: 585,
  },
  {
    name: "FLYNN, GREGORY",
    address: "2808 W. DR MARTIN LUTHER KING JR. BLVD.",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 872-9200",
    lat: 27.980993,
    lng: -82.489262,
    id: 586,
  },
  {
    name: "FOLTZ, JERRY",
    address: "4796 Hodges Blvd, Unit 101",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32224,
    phone: "(904) 449-7246",
    lat: 30.257725,
    lng: -81.467231,
    id: 587,
  },
  {
    name: "FORMAN, MITCHELL",
    address: "2352 Bruce B Downs Blvd , Suite 101",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33544,
    phone: "(813) 929-3600",
    lat: 28.190307,
    lng: -82.352438,
    id: 588,
  },
  {
    name: "FORMOSO, FERDINAND",
    address: "11555 CENTRAL PARKWAY , SUITE 304",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32224,
    phone: "(904) 265-7755",
    lat: 30.281088,
    lng: -81.520612,
    id: 589,
  },
  {
    name: "FORTIER, DANIEL",
    address: "1552 PALM BEACH LAKES BLVD",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33401,
    phone: "(561) 659-7411",
    lat: 26.723943,
    lng: -80.07861,
    id: 590,
  },
  {
    name: "FORTNER, FLORENDA",
    address: "5535 GRAND BLVD , SUITE C",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34652,
    phone: "(727) 841-0700",
    lat: 28.239844,
    lng: -82.720597,
    id: 591,
  },
  {
    name: "FOUST, PAULA",
    address: "900 N Swallowtail Dr, Ste 102",
    city: "PORT ORANGE",
    state: "FL",
    zip: 32129,
    phone: "(386) 492-6969",
    lat: 29.126941,
    lng: -81.013081,
    id: 592,
  },
  {
    name: "FOX, KEVIN",
    address: "10860 SW 88TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 595-1300",
    lat: 25.686495,
    lng: -80.370909,
    id: 593,
  },
  {
    name: "FOX, SUSAN",
    address: "8501 SW 124TH AVE, SUITE 211",
    city: "MIAMI",
    state: "FL",
    zip: 33183,
    phone: "(305) 595-6488",
    lat: 25.68921,
    lng: -80.39111,
    id: 594,
  },
  {
    name: "FRAME, DANIEL",
    address: "10125 Big Bend Road",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33578,
    phone: "(813) 605-3200",
    lat: 27.791038,
    lng: -82.3451,
    id: 595,
  },
  {
    name: "FRANK, JAMES",
    address: "12257 NW 57 Street",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33076,
    phone: "(561) 399-1826",
    lat: 26.301372,
    lng: -80.290166,
    id: 596,
  },
  {
    name: "FRASER, OWEN",
    address: "1805 W COLONIAL DR , SUITE A",
    city: "ORLANDO",
    state: "FL",
    zip: 32804,
    phone: "(407) 578-9142",
    lat: 28.553241,
    lng: -81.403914,
    id: 597,
  },
  {
    name: "FREHER, MARK",
    address: "5258 linton blvd suite 106",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 303-3491",
    lat: 26.4392,
    lng: -80.12533,
    id: 598,
  },
  {
    name: "Freij, Richard",
    address: "9770 S. Military Trail B4 , #255",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33436,
    phone: "(561) 631-2638",
    lat: 26.530877,
    lng: -80.120762,
    id: 599,
  },
  {
    name: "FREYMAN, TARAH",
    address: "1000 N Olive Ave",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33401,
    phone: "(561) 557-1767",
    lat: 26.721661,
    lng: -80.051345,
    id: 600,
  },
  {
    name: "FRIEDMAN, JARROD",
    address: "5458 TOWN CENTER DRIVE , SUITE 103",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 923-9599",
    lat: 26.362774,
    lng: -80.128559,
    id: 601,
  },
  {
    name: "FRIEDMAN, JEFFREY",
    address: "401 VENTURE DRIVE , SUITE A",
    city: "SOUTH DAYTONA",
    state: "FL",
    zip: 32119,
    phone: "(386) 761-8888",
    lat: 29.153214,
    lng: -80.992996,
    id: 602,
  },
  {
    name: "FRIEDMAN, JOEL",
    address: "11190 HEALTH PARK BOULEVARD",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(239) 624-5000",
    lat: 26.274687,
    lng: -81.788679,
    id: 603,
  },
  {
    name: "FRIEDMAN, ROBERT",
    address: "5600 PGA BLVD , STE 200",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33418,
    phone: "(561) 842-7246",
    lat: 26.83871,
    lng: -80.123368,
    id: 604,
  },
  {
    name: "FRISCH, ANNA",
    address: "12957 PALMS WEST DRIVE, SUITE 204",
    city: "LOXAHATCHEE",
    state: "FL",
    zip: 33470,
    phone: "(561) 303-2800",
    lat: 26.684296,
    lng: -80.249484,
    id: 605,
  },
  {
    name: "FUCHS, SCOTT",
    address: "4513 EXECUTIVE DRIVE , SUITE 101",
    city: "NAPLES",
    state: "FL",
    zip: 34119,
    phone: "(239) 591-2803",
    lat: 26.274273,
    lng: -81.732296,
    id: 606,
  },
  {
    name: "FULK, BILLY",
    address: "16731 MCGREGOR BLVD. , STE. 105",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 437-2121",
    lat: 26.499755,
    lng: -81.967166,
    id: 607,
  },
  {
    name: "FULLER, AARON",
    address: "800 5th Ave South , Suite 201",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(904) 885-2754",
    lat: 26.141378,
    lng: -81.795827,
    id: 608,
  },
  {
    name: "Fuller, Joseph",
    address: "1370 Gasparilla Drive",
    city: "FORT MYERS",
    state: "FL",
    zip: 33901,
    phone: "(239) 910-7445",
    lat: 26.611631,
    lng: -81.885897,
    id: 609,
  },
  {
    name: "FUNEZ, KEVIN",
    address: "52 WEST UNDERWOOD STREET",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 581-9180",
    lat: 28.526737,
    lng: -81.378044,
    id: 610,
  },
  {
    name: "FURMAN, NEIL",
    address: "16991 NE 20th Ave",
    city: "NORTH MIAMI BEACH",
    state: "FL",
    zip: 33162,
    phone: "(305) 651-6891",
    lat: 25.932187,
    lng: -80.159942,
    id: 611,
  },
  {
    name: "FURMANSKI, DAVID",
    address: "11705 Boyette Road , Suite #290",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33569,
    phone: "(813) 289-4554",
    lat: 27.853013,
    lng: -82.303169,
    id: 612,
  },
  {
    name: "GAINES, RICHARD",
    address: "3785 N. Federal Hwy , suite 150",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 931-2430",
    lat: 26.384137,
    lng: -80.076976,
    id: 613,
  },
  {
    name: "GALANTE, EDGARDO",
    address: "801 MEADOWS RD, #110",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 347-6262",
    lat: 26.359828,
    lng: -80.101721,
    id: 614,
  },
  {
    name: "GALLEGO, MANUEL",
    address: "3460 DEPEW AVE",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 764-6300",
    lat: 26.975977,
    lng: -82.086346,
    id: 615,
  },
  {
    name: "GALLO, RENEE",
    address: "2700 RIVERSIDE AVENUE, #2",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32205,
    phone: "(904) 264-8801",
    lat: 30.26052,
    lng: -81.631422,
    id: 616,
  },
  {
    name: "GAMBINO, PAUL",
    address: "721 COLORADO AVENUE",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 888-2545",
    lat: 27.193657,
    lng: -80.252848,
    id: 617,
  },
  {
    name: "GAME, MARIA",
    address: "2500 E. Commercial Blvd , Suite D",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 909-5708",
    lat: 26.189281,
    lng: -80.11122,
    id: 618,
  },
  {
    name: "GAMEZ, JASON",
    address: "110 Longwood Avenue",
    city: "ROCKLEDGE",
    state: "FL",
    zip: 32955,
    phone: "(321) 636-2211",
    lat: 28.335229,
    lng: -80.723198,
    id: 619,
  },
  {
    name: "GARCIA, ALI",
    address: "1611 NW 12 TH AVENUE",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(305) 585-3627",
    lat: 25.791124,
    lng: -80.212074,
    id: 620,
  },
  {
    name: "GARCIA, BERNARD",
    address: "4800 NORTH FEDERAL HWY , SUITE 200",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 771-2111",
    lat: 26.186582,
    lng: -80.11628,
    id: 621,
  },
  {
    name: "GARCIA, JOSE",
    address: "3417 TAMIAMI TRAIL, SUITE G",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 391-5522",
    lat: 26.979748,
    lng: -82.09701,
    id: 622,
  },
  {
    name: "GARCIA, JOVEN",
    address: "6080 BABCOCK STREET",
    city: "PALM BAY",
    state: "FL",
    zip: 32909,
    phone: "(321) 409-3073",
    lat: 27.997294,
    lng: -80.6229,
    id: 623,
  },
  {
    name: "GARCIA, ROLANDO",
    address: "13131 KINGS LAKE DR, SUITE 101",
    city: "GIBSONTON",
    state: "FL",
    zip: 33534,
    phone: "(813) 672-6092",
    lat: 27.794224,
    lng: -82.373674,
    id: 624,
  },
  {
    name: "GARCIA-LEYVA, GISELA",
    address: "211 211 Fourth Avenue North",
    city: "ST PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 823-8366",
    lat: 27.776657,
    lng: -82.635962,
    id: 625,
  },
  {
    name: "GARCIA-RIVERA, CARLOS",
    address: "9240 SW 72ND STREET , SUITE 118",
    city: "MIAMI",
    state: "FL",
    zip: 33173,
    phone: "(305) 229-9899",
    lat: 25.701785,
    lng: -80.342995,
    id: 626,
  },
  {
    name: "GARCIARENA, PEDRO",
    address: "6101 PINE RIDGE , UNIT 0431",
    city: "NAPLES",
    state: "FL",
    zip: 34119,
    phone: "(239) 348-4236",
    lat: 26.213827,
    lng: -81.732945,
    id: 627,
  },
  {
    name: "Gardella, William",
    address: "9981 S. Healthpark Drive, Suite 159",
    city: "FT. MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 343-6529",
    lat: 26.507377,
    lng: -81.913104,
    id: 628,
  },
  {
    name: "GARDERE, SERGE-HENRI",
    address: "1201 NE 26th Street , suite 109",
    city: "WILTON MANORS",
    state: "FL",
    zip: 33305,
    phone: "(954) 381-7334",
    lat: 26.159764,
    lng: -80.130298,
    id: 629,
  },
  {
    name: "GARG, PURUSHOTTAM",
    address: "5553 HWY 90",
    city: "PACE",
    state: "FL",
    zip: 32571,
    phone: "(850) 995-8811",
    lat: 30.602363,
    lng: -87.091392,
    id: 630,
  },
  {
    name: "GARIB-SANKAR, ANITA",
    address: "3319 STATE ROAD 7, STE:113",
    city: "WELLINGTON",
    state: "FL",
    zip: 33449,
    phone: "(561) 422-1003",
    lat: 26.629369,
    lng: -80.205314,
    id: 631,
  },
  {
    name: "GARRAMONE, CHARLES",
    address: "4725 SW 148TH AVE , SUITE 202",
    city: "DAVIE",
    state: "FL",
    zip: 33330,
    phone: "(954) 752-7842",
    lat: 26.06135,
    lng: -80.347414,
    id: 632,
  },
  {
    name: "GARRETT, RODGER",
    address: "3810 Hwy 90",
    city: "PACE",
    state: "FL",
    zip: 32571,
    phone: "(850) 484-4080",
    lat: 30.598556,
    lng: -87.165055,
    id: 633,
  },
  {
    name: "GARRIDO, LAZARO",
    address: "14055 WEST DIXIE HWY",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33161,
    phone: "(786) 387-2597",
    lat: 25.904962,
    lng: -80.173207,
    id: 634,
  },
  {
    name: "GAUDIEL, REMO",
    address: "1990 SOUTH TAMIAMI TRAIL",
    city: "VENICE",
    state: "FL",
    zip: 34293,
    phone: "(941) 492-1774",
    lat: 27.058817,
    lng: -82.414606,
    id: 635,
  },
  {
    name: "GAYLES, RICHARD",
    address: "9145 NARCOOSSEE ROAD , SUITE A200",
    city: "ORLANDO",
    state: "FL",
    zip: 32827,
    phone: "(407) 412-5030",
    lat: 28.435261,
    lng: -81.256767,
    id: 636,
  },
  {
    name: "GEARY, NATALIE",
    address: "7800 Red Road",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33143,
    phone: "(786) 375-1515",
    lat: 25.698455,
    lng: -80.285642,
    id: 637,
  },
  {
    name: "GEBHARDT, SCOTT",
    address: "2264 D 2264 Dog Leg Ct",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34604,
    phone: "(352) 616-9892",
    lat: 28.476942,
    lng: -82.434791,
    id: 638,
  },
  {
    name: "GELBARD, STEVEN",
    address: "7351 Wiles Rd , suite 105",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33067,
    phone: "(954) 383-2000",
    lat: 26.287528,
    lng: -80.226238,
    id: 639,
  },
  {
    name: "GENCO, MARK",
    address: "13813 Metro Parkway",
    city: "FT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(877) 856-3774",
    lat: 26.541911,
    lng: -81.852116,
    id: 640,
  },
  {
    name: "GEORGE-SAINTILUS, ERICA",
    address: "1118 Hospital Rd",
    city: "FORT WALTON BEACH",
    state: "FL",
    zip: 32547,
    phone: "(850) 862-3415",
    lat: 30.457597,
    lng: -86.634366,
    id: 641,
  },
  {
    name: "GERENSTEIN, RICARDO",
    address: "20770 W DIXIE HWY",
    city: "MIAMI",
    state: "FL",
    zip: 33180,
    phone: "(305) 709-6686",
    lat: 25.967747,
    lng: -80.147831,
    id: 642,
  },
  {
    name: "GERLEY, PETER",
    address: "1050 East Brandon Blvd",
    city: "TAMPA",
    state: "FL",
    zip: 33511,
    phone: "(813) 661-6841",
    lat: 27.940072,
    lng: -82.268007,
    id: 643,
  },
  {
    name: "GERMAIN, JACQUELINE",
    address: "2831 Ringling Blvd, Suite 116D",
    city: "SARASOTA",
    state: "FL",
    zip: 34237,
    phone: "(888) 908-0143",
    lat: 27.336467,
    lng: -82.513381,
    id: 644,
  },
  {
    name: "GHATA, GHASSAN",
    address: "13460 BEACH BLVD",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32224,
    phone: "(904) 854-1700",
    lat: 30.287191,
    lng: -81.463715,
    id: 645,
  },
  {
    name: "GHEN, MITCHELL",
    address: "1515 S. FEDERAL HIGHWAY , SUITE 215",
    city: "BOCA RATON",
    state: "FL",
    zip: 33432,
    phone: "(561) 674-0555",
    lat: 26.364657,
    lng: -80.080909,
    id: 646,
  },
  {
    name: "Giang, William",
    address: "105 Oa 105 Oakdene Road",
    city: "POOLER",
    state: "GA",
    zip: 31322,
    phone: "(717) 614-5262",
    lat: 32.11548,
    lng: -81.24706,
    id: 647,
  },
  {
    name: "GIBSON, GREGORY",
    address: "201 HEALTH PARK BLVD #215 , STE 5008",
    city: "SAINT AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(904) 824-3777",
    lat: 29.862164,
    lng: -81.318097,
    id: 648,
  },
  {
    name: "GILDERMAN, LARRY",
    address: "1009 NORTH SOUTH LAKE DRIVE",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33019,
    phone: "(954) 966-7470",
    lat: 26.009793,
    lng: -80.125422,
    id: 649,
  },
  {
    name: "GILROY, PATRICIA",
    address: "2299 9TH AVE NORTH , SUITE 3-B",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 321-3344",
    lat: 27.781545,
    lng: -82.664596,
    id: 650,
  },
  {
    name: "GIOVANELLI PORRATA, CLAUDIA",
    address: "3319 STATE ROAD 7 , SUITE 212",
    city: "WELLINGTON",
    state: "FL",
    zip: 33449,
    phone: "(561) 318-3727",
    lat: 26.629369,
    lng: -80.205314,
    id: 651,
  },
  {
    name: "GIRARD, JOHN",
    address: "2499 GLADES ROAD , SUITE 301",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 750-9900",
    lat: 26.36882,
    lng: -80.1362,
    id: 652,
  },
  {
    name: "GITTENS, CARL",
    address: "611 SW FEDERAL HWY , SUITE E",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 215-5905",
    lat: 27.194767,
    lng: -80.256037,
    id: 653,
  },
  {
    name: "GITTMAN, ALLAN",
    address: "3333 NORTH FEDERAL HIGHWAY",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33064,
    phone: "(904) 941-8866",
    lat: 26.27251,
    lng: -80.097934,
    id: 654,
  },
  {
    name: "Giuffrida, Anthony",
    address: "3000 Bayview Dr",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33306,
    phone: "(954) 280-7383",
    lat: 26.166122,
    lng: -80.108749,
    id: 655,
  },
  {
    name: "GIULIANO, VINCENZO",
    address: "5732 CANTON COVE",
    city: "WINTER SPRINGS",
    state: "FL",
    zip: 32708,
    phone: "(407) 699-7787",
    lat: 28.648719,
    lng: -81.263989,
    id: 656,
  },
  {
    name: "GIULIANTI, DONALD",
    address: "3501 N OCEAN DRIVE, 6G",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33019,
    phone: "(954) 925-1239",
    lat: 26.032855,
    lng: -80.114771,
    id: 657,
  },
  {
    name: "GIVEN, MICHAEL",
    address: "2010 LEWIS TURNER BLVD",
    city: "FORT WALTON BEACH",
    state: "FL",
    zip: 32547,
    phone: "(850) 863-3000",
    lat: 30.470024,
    lng: -86.608851,
    id: 658,
  },
  {
    name: "GLASER, CHARLES",
    address: "12711 N.W. 19TH MANOR",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33071,
    phone: "(954) 562-0148",
    lat: 26.255702,
    lng: -80.296321,
    id: 659,
  },
  {
    name: "GLASER, HERMANDA",
    address: "7365 MERCHANT CT , SUITE 1",
    city: "LAKEWOOD RANCH",
    state: "FL",
    zip: 34240,
    phone: "(941) 362-2744",
    lat: 27.382753,
    lng: -82.420967,
    id: 660,
  },
  {
    name: "GLASSMAN, PAUL",
    address: "16991 NE 20TH AVENUE",
    city: "NORTH MIAMI BEACH",
    state: "FL",
    zip: 33162,
    phone: "(305) 940-9300",
    lat: 25.932187,
    lng: -80.159942,
    id: 661,
  },
  {
    name: "GLAUSER, JOSHUA",
    address: "800 MEADOWS RD",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 955-7100",
    lat: 26.358492,
    lng: -80.103499,
    id: 662,
  },
  {
    name: "GLIDER, ROSS",
    address: "1323 W. Busch Blvd. , Suite A",
    city: "TAMPA",
    state: "FL",
    zip: 33612,
    phone: "(888) 908-0143",
    lat: 28.033731,
    lng: -82.472904,
    id: 663,
  },
  {
    name: "Gliosca, Danielle",
    address: "1599 66th St N",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33710,
    phone: "(727) 317-3388",
    lat: 27.786085,
    lng: -82.728085,
    id: 664,
  },
  {
    name: "GLOVER, LESLEY",
    address: "12983 SOUTHERN BLVD, SUITE 102",
    city: "LOXAHATCHEE",
    state: "FL",
    zip: 33470,
    phone: "(561) 721-1953",
    lat: 26.682587,
    lng: -80.250852,
    id: 665,
  },
  {
    name: "GLOWER, JAMES",
    address: "7035 LANCASTER COURT",
    city: "BRADENTON",
    state: "FL",
    zip: 34201,
    phone: "(847) 309-3295",
    lat: 27.415089,
    lng: -82.468694,
    id: 666,
  },
  {
    name: "GOETHE, ROBERT",
    address: "9030 W Ft Island Trail, Suite 11B",
    city: "CRYSTAL RIVER",
    state: "FL",
    zip: 34429,
    phone: "(352) 601-4200",
    lat: 28.880222,
    lng: -82.584502,
    id: 667,
  },
  {
    name: "GOLDBERG, ANDREW",
    address: "8880 ROYAL PALM BLVD. , SUITE #103",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 975-8233",
    lat: 26.258621,
    lng: -80.244338,
    id: 668,
  },
  {
    name: "GOLDBERG, ERIC",
    address: "137 Crystal Beach Drive , Suite 137-C",
    city: "DESTIN",
    state: "FL",
    zip: 32451,
    phone: "(850) 226-7100",
    lat: 30.388044,
    lng: -86.42134,
    id: 669,
  },
  {
    name: "Goldberg, Kandis",
    address: "1886 S 14th Street",
    city: "FERNANDINA BEACH",
    state: "FL",
    zip: 32034,
    phone: "(904) 335-1813",
    lat: 30.644744,
    lng: -81.454646,
    id: 670,
  },
  {
    name: "GOLDBERG, TODD",
    address: "603 N FLAMINGO RD , SUITE 361",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33028,
    phone: "(954) 432-7900",
    lat: 26.01223,
    lng: -80.31188,
    id: 671,
  },
  {
    name: "GOLDEN, MARLA",
    address: "12627 San Jose Boulevard , ST 902 E",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32223,
    phone: "(904) 260-1070",
    lat: 30.13866,
    lng: -81.63243,
    id: 672,
  },
  {
    name: "GOLOFF, JACK",
    address: "189 N State, Road 7",
    city: "PLANTATION",
    state: "FL",
    zip: 33317,
    phone: "(954) 316-2442",
    lat: 26.122604,
    lng: -80.202079,
    id: 673,
  },
  {
    name: "GONSALVES SIKORA, ALITA",
    address: "1255 37th Steet , Suite B",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 228-6882",
    lat: 27.659665,
    lng: -80.397583,
    id: 674,
  },
  {
    name: "GONZALEZ, BLANCA",
    address: "1435 W 49 PLACE , SUITE 701",
    city: "HIALEAH",
    state: "FL",
    zip: 33012,
    phone: "(305) 987-5720",
    lat: 25.868004,
    lng: -80.311342,
    id: 675,
  },
  {
    name: "GONZALEZ, CHRISTIAN",
    address: "21000 NE 28TH AVE , SUITE # 104",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 974-5533",
    lat: 25.96952,
    lng: -80.146259,
    id: 676,
  },
  {
    name: "GONZALEZ, JAIME",
    address: "265 WEST STATE, ROAD 50",
    city: "CLERMONT",
    state: "FL",
    zip: 34711,
    phone: "(352) 394-5535",
    lat: 28.550997,
    lng: -81.758886,
    id: 677,
  },
  {
    name: "GONZALEZ, JONATHAN",
    address: "400 Health Park Blvd",
    city: "ST AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(786) 271-6791",
    lat: 29.863368,
    lng: -81.317166,
    id: 678,
  },
  {
    name: "GONZALEZ, RICARDO",
    address: "200 3rd Ave W, STE 210",
    city: "BRADENTON",
    state: "FL",
    zip: 34205,
    phone: "(941) 792-0340",
    lat: 27.497402,
    lng: -82.564972,
    id: 679,
  },
  {
    name: "Gonzalez Betancourt, Dennis",
    address: "3301 3301 NE 1st ave, apt 2906",
    city: "Miami MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(787) 509-1333",
    lat: 25.807534,
    lng: -80.192942,
    id: 680,
  },
  {
    name: "GONZALEZ-ABREU, FRANCISCO",
    address: "432 SW 8th Ave",
    city: "MIAMI",
    state: "FL",
    zip: 33130,
    phone: "(305) 547-1444",
    lat: 25.769565,
    lng: -80.207654,
    id: 681,
  },
  {
    name: "GORDON, BARRY",
    address: "425 Commercial Court, Suite C",
    city: "VENICE",
    state: "FL",
    zip: 34292,
    phone: "(941) 586-2426",
    lat: 27.10992,
    lng: -82.385927,
    id: 682,
  },
  {
    name: "GOULDING, RICHARD",
    address: "18 Northshore Dr.",
    city: "PALM COAST",
    state: "FL",
    zip: 32137,
    phone: "(321) 266-6895",
    lat: 29.606796,
    lng: -81.190944,
    id: 683,
  },
  {
    name: "GRABER, MYLISSA",
    address: "1425 SOUTH CONGRESS AVE",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33445,
    phone: "(561) 330-9363",
    lat: 26.442082,
    lng: -80.091936,
    id: 684,
  },
  {
    name: "Green, Susan",
    address: "7932 Sand Lake Dr, Suite# 206",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(352) 222-5340",
    lat: 28.448733,
    lng: -81.49113,
    id: 685,
  },
  {
    name: "GREENE, MICHAEL",
    address: "1821 Blanding Blvd",
    city: "MIDDLEBURG",
    state: "FL",
    zip: 32068,
    phone: "(904) 406-3160",
    lat: 30.102847,
    lng: -81.830082,
    id: 686,
  },
  {
    name: "GREENE, TUCKER",
    address: "3524 tamiami trail , suite 105G",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 375-6775",
    lat: 26.978838,
    lng: -82.093172,
    id: 687,
  },
  {
    name: "GREENSTEIN, YAKOV",
    address: "1065 NE 125 STREET, #206",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33161,
    phone: "(646) 841-4184",
    lat: 25.891307,
    lng: -80.177008,
    id: 688,
  },
  {
    name: "GRENDYS, EDWARD",
    address: "8931 COLONIAL CENTER DR., #400",
    city: "FORT MYERS",
    state: "FL",
    zip: 33905,
    phone: "(239) 334-6626",
    lat: 26.614848,
    lng: -81.808774,
    id: 689,
  },
  {
    name: "GRIDER, DAVID",
    address: "29980 OVERSEAS HWY",
    city: "BIG PINE KEY",
    state: "FL",
    zip: 33043,
    phone: "(305) 872-3321",
    lat: 24.669237,
    lng: -81.363569,
    id: 690,
  },
  {
    name: "GRIFFIN, RYAN",
    address: "238 CANAL BLVD, SUITE 2",
    city: "PONTE VEDRA",
    state: "FL",
    zip: 32082,
    phone: "(904) 395-3041",
    lat: 30.177506,
    lng: -81.392451,
    id: 691,
  },
  {
    name: "GRIFFITH, DANIEL",
    address: "2701 W Busch Blvd , Suite 144",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(786) 493-4684",
    lat: 28.035272,
    lng: -82.487559,
    id: 692,
  },
  {
    name: "GRINSHTEYN, SIMON",
    address: "1649 Tamiami Trail , Suite 1",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33948,
    phone: "(941) 235-2372",
    lat: 27.007773,
    lng: -82.137775,
    id: 693,
  },
  {
    name: "GROSSGOLD, ANDREAS",
    address: "609 Lakeview Rd",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 330-3844",
    lat: 27.947874,
    lng: -82.798169,
    id: 694,
  },
  {
    name: "GRUCCI, FELIX",
    address: "101 Hospital Road, Access Center",
    city: "PATCHOGUE",
    state: "NY",
    zip: 11772,
    phone: "(631) 687-4357",
    lat: 40.780071,
    lng: -72.976581,
    id: 695,
  },
  {
    name: "GUADAGNA, DEAN",
    address: "2057 N University Dr.",
    city: "SUNRISE",
    state: "FL",
    zip: 33322,
    phone: "(954) 742-0330",
    lat: 26.151163,
    lng: -80.257219,
    id: 696,
  },
  {
    name: "GUDICELLO, FRANK",
    address: "9 SPRAY ROAD",
    city: "KEY LARGO",
    state: "FL",
    zip: 33037,
    phone: "(305) 394-8208",
    lat: 25.316133,
    lng: -80.287655,
    id: 697,
  },
  {
    name: "GUERRA DEL CASTILLO, ROBERTO",
    address: "4308 Alton Road Suite 860",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 604-2888",
    lat: 25.815364,
    lng: -80.13773,
    id: 698,
  },
  {
    name: "GUERRE, EUGENE",
    address: "7011 NIGHTWALKER ROAD",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 597-7700",
    lat: 28.528618,
    lng: -82.552795,
    id: 699,
  },
  {
    name: "GUIDRY, ANDREW",
    address: "19 BALD EAGLE DRIVE , SUITE B",
    city: "MARCO ISLAND",
    state: "FL",
    zip: 34145,
    phone: "(239) 394-4111",
    lat: 25.937555,
    lng: -81.71416,
    id: 700,
  },
  {
    name: "GULATI, ANISHA",
    address: "5995 5995 SE Community Dr.",
    city: "STUART",
    state: "FL",
    zip: 34997,
    phone: "(772) 403-4000",
    lat: 27.131417,
    lng: -80.221327,
    id: 701,
  },
  {
    name: "Gulati, Vishal",
    address: "1501 W 9 Mile Road",
    city: "PENSACOLA",
    state: "FL",
    zip: 32534,
    phone: "(321) 710-5390",
    lat: 30.532107,
    lng: -87.232909,
    id: 702,
  },
  {
    name: "GUNTHER, ROBERT",
    address: "151 NORTHAMPTON H",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33417,
    phone: "(914) 325-4471",
    lat: 26.716778,
    lng: -80.134792,
    id: 703,
  },
  {
    name: "GUPTA, VIRIND",
    address: "4541 BEE RIDGE ROAD",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 371-9355",
    lat: 27.299217,
    lng: -82.477035,
    id: 704,
  },
  {
    name: "GURTMAN, FRED",
    address: "10680 Bardes Court",
    city: "LARGO",
    state: "FL",
    zip: 33777,
    phone: "(727) 804-5247",
    lat: 27.869113,
    lng: -82.757688,
    id: 705,
  },
  {
    name: "GUSHWA, RICHARD",
    address: "3751 SW 86th ST",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32608,
    phone: "(352) 222-6089",
    lat: 29.620007,
    lng: -82.433291,
    id: 706,
  },
  {
    name: "GUTHRIE, JENNIFER",
    address: "333 Southern Blvd , #404",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33405,
    phone: "(561) 214-3323",
    lat: 26.675956,
    lng: -80.053646,
    id: 707,
  },
  {
    name: "GUTIERREZ, ENRIQUE",
    address: "907A NORTH CENTRAL AVE.",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 846-2050",
    lat: 28.300016,
    lng: -81.4073,
    id: 708,
  },
  {
    name: "GUTIERREZ, PETE",
    address: "2015 NORTHWEST 1ST AVENUE",
    city: "MIAMI",
    state: "FL",
    zip: 33127,
    phone: "(305) 572-2027",
    lat: 25.795817,
    lng: -80.196467,
    id: 709,
  },
  {
    name: "GUTIERREZ, VICTORIANO",
    address: "1700 Baker Ave E",
    city: "HAINES CITY",
    state: "FL",
    zip: 33844,
    phone: "(863) 519-7910",
    lat: 28.122254,
    lng: -81.617084,
    id: 710,
  },
  {
    name: "GUTIERREZ DEL ARROYO COLON, MARISEL",
    address: "199 SE AVE K",
    city: "WINTER HAVEN",
    state: "FL",
    zip: 33880,
    phone: "(863) 299-6700",
    lat: 28.011558,
    lng: -81.725151,
    id: 711,
  },
  {
    name: "GUTIERREZ-GALATAS, JOSE",
    address: "6001 VINELAND RD, #101",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(407) 352-0573",
    lat: 28.483421,
    lng: -81.463997,
    id: 712,
  },
  {
    name: "GUZIK, CHRISTOPHER",
    address: "1135 LAKE AVENUE",
    city: "CLERMONT",
    state: "FL",
    zip: 34711,
    phone: "(352) 394-4035",
    lat: 28.550796,
    lng: -81.764749,
    id: 713,
  },
  {
    name: "HADLEY, HOLLY",
    address: "13901 U.S. HIGHWAY 1 , #4 #5",
    city: "JUNO BEACH",
    state: "FL",
    zip: 33408,
    phone: "(561) 491-4666",
    lat: 26.88026,
    lng: -80.05611,
    id: 714,
  },
  {
    name: "HALIM, TARIQ",
    address: "7357 International Place , Suite 107",
    city: "LAKEWOOD RANCH",
    state: "FL",
    zip: 34240,
    phone: "(941) 500-9292",
    lat: 27.380628,
    lng: -82.420296,
    id: 715,
  },
  {
    name: "HALL, ANTHONY",
    address: "7541 WEST OAKLAND PARK BOULEVARD",
    city: "LAUDERHILL",
    state: "FL",
    zip: 33319,
    phone: "(954) 459-4600",
    lat: 26.167272,
    lng: -80.249576,
    id: 716,
  },
  {
    name: "Hall, Jason",
    address: "20900 Biscayne Blvd.",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 682-7000",
    lat: 25.970045,
    lng: -80.145338,
    id: 717,
  },
  {
    name: "HALL, MATTHEW",
    address: "8900 N. KENDALL DR",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 596-2000",
    lat: 25.68445,
    lng: -80.338947,
    id: 718,
  },
  {
    name: "HALPERIN, ANDREW",
    address: "1478 Riverplace Blvd , Unit 1008",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(904) 357-0218",
    lat: 30.317825,
    lng: -81.654791,
    id: 719,
  },
  {
    name: "Hamadiya, Shaker",
    address: "40 sw 13 street, , Ste 402",
    city: "MIAMI",
    state: "FL",
    zip: 33130,
    phone: "(919) 259-2627",
    lat: 25.76109,
    lng: -80.194236,
    id: 720,
  },
  {
    name: "HAMMAD, MUSTAFA",
    address: "1931 MARTIN LUTHER KING JR BLVD",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 215-7093",
    lat: 30.184231,
    lng: -85.650535,
    id: 721,
  },
  {
    name: "HAN, HOKE",
    address: "6030 Hollywood Blvd , #100",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33024,
    phone: "(954) 322-7200",
    lat: 26.009433,
    lng: -80.209208,
    id: 722,
  },
  {
    name: "HANES, MICHAEL",
    address: "10475 CENTURION PARKWAY NORTH , SUITE 201",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 223-3321",
    lat: 30.248555,
    lng: -81.542518,
    id: 723,
  },
  {
    name: "HANFLINK, NATHAN",
    address: "601 Jennings Ave",
    city: "EUSTIS",
    state: "FL",
    zip: 32726,
    phone: "(352) 357-0668",
    lat: 28.835029,
    lng: -81.692421,
    id: 724,
  },
  {
    name: "Hanley, Daniel",
    address: "10700 Stringfellow Road , Unit 50",
    city: "BOKEELIA",
    state: "FL",
    zip: 33922,
    phone: "(833) 742-6276",
    lat: 26.615968,
    lng: -82.118314,
    id: 725,
  },
  {
    name: "HANNUM, ROBERT",
    address: "2577 County Rd , #448",
    city: "LAKE PANASOFFKEE",
    state: "FL",
    zip: 33538,
    phone: "(844) 442-0362",
    lat: 28.794004,
    lng: -82.126537,
    id: 726,
  },
  {
    name: "Hanson, Svetlana",
    address: "4476 Legendary Dr , bld 100",
    city: "DESTIN",
    state: "FL",
    zip: 32541,
    phone: "(850) 424-7320",
    lat: 30.389168,
    lng: -86.414628,
    id: 727,
  },
  {
    name: "HANUS, MICHAEL",
    address: "820 Goodlette Rd",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 434-0166",
    lat: 26.15979,
    lng: -81.788009,
    id: 728,
  },
  {
    name: "HAO, YIHONG",
    address: "2900 N MILITARY TRAIL , STE 101",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 998-0309",
    lat: 26.37789,
    lng: -80.12097,
    id: 729,
  },
  {
    name: "HARDING, VICTOR",
    address: "6200 METROWEST BLVD, STE 106",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 345-1551",
    lat: 28.514411,
    lng: -81.466721,
    id: 730,
  },
  {
    name: "HARDY, CARL",
    address: "100 Whetstone Pl. , Suite 205",
    city: "ST AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(904) 615-1181",
    lat: 29.866246,
    lng: -81.319239,
    id: 731,
  },
  {
    name: "HARING, NANCY",
    address: "121 Quincy Circle",
    city: "SANTA ROSA BEACH",
    state: "FL",
    zip: 32459,
    phone: "(850) 231-6085",
    lat: 30.321058,
    lng: -86.137386,
    id: 732,
  },
  {
    name: "HARMAN, LEONARD",
    address: "50 NE 26TH AVE , SUITE 203",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33062,
    phone: "(954) 900-3154",
    lat: 26.232673,
    lng: -80.097588,
    id: 733,
  },
  {
    name: "HARMON, DANIEL",
    address: "3466 Pine Ridge Road , Suite A",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 261-2663",
    lat: 26.211455,
    lng: -81.747596,
    id: 734,
  },
  {
    name: "HARPER, MARK",
    address: "3665 BEE RIDGE ROAD, SUITE 100",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 923-4108",
    lat: 27.29955,
    lng: -82.494561,
    id: 735,
  },
  {
    name: "HARPER-NIMOCK, LYNN",
    address: "2868 SWEETHOLLY DRIVE",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32223,
    phone: "(904) 403-4565",
    lat: 30.15209,
    lng: -81.634209,
    id: 736,
  },
  {
    name: "HARRELL, JON",
    address: "10167 W. Sunrise Blvd",
    city: "SUNRISE",
    state: "FL",
    zip: 33322,
    phone: "(954) 526-0066",
    lat: 26.146839,
    lng: -80.285335,
    id: 737,
  },
  {
    name: "Harris, Justin",
    address: "3900 Clark Rd , Suite H-1",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 926-1600",
    lat: 27.26928,
    lng: -82.491261,
    id: 738,
  },
  {
    name: "HARRIS, SYLVIA",
    address: "4412 north davis highway",
    city: "PENSACOLA",
    state: "FL",
    zip: 32503,
    phone: "(517) 442-5000",
    lat: 30.461469,
    lng: -87.223784,
    id: 739,
  },
  {
    name: "HARVEY, FRED",
    address: "3982 BEE RIDGE RD, SUITE J",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 929-9355",
    lat: 27.29878,
    lng: -82.48957,
    id: 740,
  },
  {
    name: "HASAN, SYED",
    address: "2120 M MARINER BLVD",
    city: "SPRING HILL",
    state: "FL",
    zip: 34609,
    phone: "(352) 666-1703",
    lat: 28.463426,
    lng: -82.540457,
    id: 741,
  },
  {
    name: "HASHIM, MARK",
    address: "7412 COMMUNITY COURT",
    city: "HUDSON",
    state: "FL",
    zip: 34667,
    phone: "(727) 861-1000",
    lat: 28.358441,
    lng: -82.691742,
    id: 742,
  },
  {
    name: "HASS, WILLIAM",
    address: "801 East Cervantes , Suite C",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 777-3334",
    lat: 30.422455,
    lng: -87.207878,
    id: 743,
  },
  {
    name: "HASSAN, KAZI",
    address: "1330 Coral Way , STE 200",
    city: "MIAMI",
    state: "FL",
    zip: 33145,
    phone: "(727) 421-6568",
    lat: 25.748303,
    lng: -80.282985,
    id: 744,
  },
  {
    name: "HASSAN, MAJED",
    address: "2255 DUNN AVE",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32218,
    phone: "(904) 861-1900",
    lat: 30.438933,
    lng: -81.682911,
    id: 745,
  },
  {
    name: "HASSAN, SHEHNAZ",
    address: "6401 N FEDERAL HWY",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 776-8500",
    lat: 26.206443,
    lng: -80.110866,
    id: 746,
  },
  {
    name: "HASSANZADEH, KAMBIZ",
    address: "881 OCEAN DRIVE , APT # 2",
    city: "KEY BISCAYNE",
    state: "FL",
    zip: 33149,
    phone: "(305) 610-6823",
    lat: 25.685741,
    lng: -80.157265,
    id: 747,
  },
  {
    name: "HAWAYEK CARRERA, JOSE",
    address: "7031 SW 62nd Ave",
    city: "MIAMI",
    state: "FL",
    zip: 33134,
    phone: "(305) 284-7500",
    lat: 25.705584,
    lng: -80.293115,
    id: 748,
  },
  {
    name: "HAYES, CHERYLLE",
    address: "6420 NEWBERRY ROAD",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32605,
    phone: "(352) 333-5840",
    lat: 29.663065,
    lng: -82.410154,
    id: 749,
  },
  {
    name: "HAYM, JEROME",
    address: "8480 State Road 84",
    city: "DAVIE",
    state: "FL",
    zip: 33424,
    phone: "(954) 577-0177",
    lat: 26.101142,
    lng: -80.262201,
    id: 750,
  },
  {
    name: "HAYNES, ERIC",
    address: "5413 GEORGE STREET",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34652,
    phone: "(727) 846-7618",
    lat: 28.237866,
    lng: -82.719893,
    id: 751,
  },
  {
    name: "HAYNES, SHERYL",
    address: "24945 US HWY 19 N",
    city: "CLEARWATER",
    state: "FL",
    zip: 34684,
    phone: "(727) 726-1460",
    lat: 27.999045,
    lng: -82.728339,
    id: 752,
  },
  {
    name: "HECHTMAN, JASON",
    address: "10740 palm river road , Suite 360",
    city: "TAMPA",
    state: "FL",
    zip: 33619,
    phone: "(813) 844-7585",
    lat: 27.930061,
    lng: -82.336094,
    id: 753,
  },
  {
    name: "HENDERSON, RAYMOND",
    address: "1411 N FLAGLER DR , SUITE 7300",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33401,
    phone: "(561) 804-9898",
    lat: 26.72599,
    lng: -80.05149,
    id: 754,
  },
  {
    name: "HENRIQUEZ, OMAR",
    address: "1525 South Tamiami Trail , Suit 601",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(813) 495-8011",
    lat: 27.078614,
    lng: -82.427935,
    id: 755,
  },
  {
    name: "HENSON, KENNETH",
    address: "1250 S. TAMIAMI TRAIL , Suite 201",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 929-1039",
    lat: 27.3224,
    lng: -82.529708,
    id: 756,
  },
  {
    name: "HERMAN, MATTHEW",
    address: "3166 Shakespeare Rd.",
    city: "BETHLEHEM",
    state: "PA",
    zip: 18017,
    phone: "(305) 987-5449",
    lat: 40.659878,
    lng: -75.343029,
    id: 757,
  },
  {
    name: "HERMANS, HOWARD",
    address: "4051 UPPER CREEK DR. , SUITE 108",
    city: "SUN CITY CENTER",
    state: "FL",
    zip: 33573,
    phone: "(813) 634-9264",
    lat: 27.712672,
    lng: -82.367636,
    id: 758,
  },
  {
    name: "HERNANDEZ, HUMBERTO",
    address: "900 WEST 49TH STREET , SUITE 448",
    city: "HIALEAH",
    state: "FL",
    zip: 33012,
    phone: "(305) 885-3111",
    lat: 25.866244,
    lng: -80.300838,
    id: 759,
  },
  {
    name: "HERNANDEZ, IVETTE",
    address: "1277 N Semoran Blvd, Ste 104",
    city: "ORLANDO",
    state: "FL",
    zip: 32807,
    phone: "(407) 745-4581",
    lat: 28.563256,
    lng: -81.310441,
    id: 760,
  },
  {
    name: "HERNANDEZ, JOSEFINA",
    address: "8480 S.W. 8TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33144,
    phone: "(305) 265-0910",
    lat: 25.7607,
    lng: -80.333088,
    id: 761,
  },
  {
    name: "HERNANDEZ, OSCAR",
    address: "14221 SW 120TH ST, Ste 129",
    city: "MIAMI",
    state: "FL",
    zip: 33186,
    phone: "(305) 279-1515",
    lat: 25.656989,
    lng: -80.426351,
    id: 762,
  },
  {
    name: "HERON, PATRICK",
    address: "9290 SOUTHWEST 72ND STREET, STE 101",
    city: "MIAMI",
    state: "FL",
    zip: 33173,
    phone: "(305) 412-9825",
    lat: 25.701751,
    lng: -80.343807,
    id: 763,
  },
  {
    name: "HERRERA BARTER, RODOLFO",
    address: "Unit 2 2929 North University Drive",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 757-1909",
    lat: 26.27377,
    lng: -80.260118,
    id: 764,
  },
  {
    name: "HERSHFIELD, BARTON",
    address: "4419 Del Prado Blvd S. , Suite 4",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33904,
    phone: "(239) 789-3353",
    lat: 26.56948,
    lng: -81.943462,
    id: 765,
  },
  {
    name: "HERSON, DAVID",
    address: "21756 STATE ROAD 54 , SUITE 102A",
    city: "LUTZ",
    state: "FL",
    zip: 33549,
    phone: "(813) 443-5817",
    lat: 28.186496,
    lng: -82.456432,
    id: 766,
  },
  {
    name: "HERZOG, LESLIE",
    address: "8251 West Broward Blvd , Suite 102",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 983-9191",
    lat: 26.122511,
    lng: -80.260189,
    id: 767,
  },
  {
    name: "HESSEN, HOSSAM",
    address: "3625 UNIVERSITY BLVD S. , # 405",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 702-6111",
    lat: 30.28791,
    lng: -81.601031,
    id: 768,
  },
  {
    name: "HESSLER, DALLAS",
    address: "4201 WESTGATE AVENUE , SUITE A-1",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33409,
    phone: "(404) 273-0916",
    lat: 26.703448,
    lng: -80.106583,
    id: 769,
  },
  {
    name: "HEURICH, EVA-MARIA",
    address: "2300 Maitland Center Parkway",
    city: "MAITLAND",
    state: "FL",
    zip: 32751,
    phone: "(407) 644-1165",
    lat: 28.633801,
    lng: -81.392295,
    id: 770,
  },
  {
    name: "HEVERT, DAVID",
    address: "3848 FAU BLVD , SUITE 210",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 394-3088",
    lat: 26.383926,
    lng: -80.097111,
    id: 771,
  },
  {
    name: "HEVEY, MATTHEW",
    address: "244 Mohawk Road",
    city: "CLERMONT",
    state: "FL",
    zip: 34715,
    phone: "(352) 223-0472",
    lat: 28.565761,
    lng: -81.732185,
    id: 772,
  },
  {
    name: "HEWITT, TODD",
    address: "3334 CAPITAL MEDICAL BLVD. , #400",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 877-8174",
    lat: 30.477013,
    lng: -84.224162,
    id: 773,
  },
  {
    name: "HIGGINS, CALVIN",
    address: "2295 N UNIVERSITY DRIVE",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33024,
    phone: "(954) 932-0132",
    lat: 26.02876,
    lng: -80.24876,
    id: 774,
  },
  {
    name: "HIGGINS, CYNTHIA",
    address: "4800 Rowan Road",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34653,
    phone: "(727) 483-5912",
    lat: 28.228812,
    lng: -82.690292,
    id: 775,
  },
  {
    name: "HIGHT, PERRY",
    address: "1598 South County Hwy 393 , unit 105",
    city: "SANTA ROSA BEACH",
    state: "FL",
    zip: 32459,
    phone: "(850) 502-7987",
    lat: 30.351549,
    lng: -86.23037,
    id: 776,
  },
  {
    name: "HILL, JAMES",
    address: "1800 SE TIFFANY AVE",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-4000",
    lat: 27.288469,
    lng: -80.294454,
    id: 777,
  },
  {
    name: "Hillman, Alexys",
    address: "14 W Jordan Street , Suite C",
    city: "PENSACOLA",
    state: "FL",
    zip: 32501,
    phone: "(850) 368-7528",
    lat: 30.433884,
    lng: -87.221239,
    id: 778,
  },
  {
    name: "HINZ, MICHAEL",
    address: "1108 Winding Pines Circle , Unit 105",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33909,
    phone: "(312) 590-5167",
    lat: 26.670085,
    lng: -81.92008,
    id: 779,
  },
  {
    name: "HIRSCH, BRETT",
    address: "1250 S 1250 S. Tamiami Trail , Ste 404",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 365-2122",
    lat: 27.193651,
    lng: -81.948685,
    id: 780,
  },
  {
    name: "HITZ, KENNETH",
    address: "115 Professional Dr , SUITES 101",
    city: "PONTE VEDRA BEACH",
    state: "FL",
    zip: 32082,
    phone: "(904) 999-9927",
    lat: 30.250519,
    lng: -81.389501,
    id: 781,
  },
  {
    name: "HODGE, ARTHUR",
    address: "2195 RINGLING BLVD.",
    city: "SARASOTA",
    state: "FL",
    zip: 34237,
    phone: "(941) 362-0304",
    lat: 27.335916,
    lng: -82.526363,
    id: 782,
  },
  {
    name: "HOFFMEISTER, TIMOTHY",
    address: "2206 Jo An Drive , Suite 4",
    city: "SARASOTA",
    state: "FL",
    zip: 34231,
    phone: "(941) 217-6828",
    lat: 27.2631,
    lng: -82.520502,
    id: 783,
  },
  {
    name: "HORNER, TAMI",
    address: "9095 Belcher Road",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33782,
    phone: "(727) 548-0001",
    lat: 27.855211,
    lng: -82.736274,
    id: 784,
  },
  {
    name: "HORTON, GERALD",
    address: "1345 Clay St",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 369-8994",
    lat: 28.585168,
    lng: -81.373338,
    id: 785,
  },
  {
    name: "HOSSAIN, IMTIAZ",
    address: "101 N CLEARWATER-LARGO RD., #2",
    city: "LARGO",
    state: "FL",
    zip: 33770,
    phone: "(727) 588-0366",
    lat: 27.917936,
    lng: -82.79677,
    id: 786,
  },
  {
    name: "HOTCHNER, KIRBY",
    address: "1330 SW 22 ST , SUITE 205",
    city: "MIAMI",
    state: "FL",
    zip: 33145,
    phone: "(305) 856-8185",
    lat: 25.750738,
    lng: -80.217002,
    id: 787,
  },
  {
    name: "HOUSS, CAROLYN",
    address: "3345 BURNS RD , STE 101",
    city: "PALM BCH GDNS",
    state: "FL",
    zip: 33410,
    phone: "(561) 622-2022",
    lat: 26.831511,
    lng: -80.084749,
    id: 788,
  },
  {
    name: "HUANG, CHARLES",
    address: "6700 S. FLORIDA AVE , STE 3",
    city: "ORLANDO",
    state: "FL",
    zip: 33813,
    phone: "(407) 940-4750",
    lat: 27.9433,
    lng: -81.9721,
    id: 789,
  },
  {
    name: "HUDSON, JANICE",
    address: "4570 Gulf Breeze Pkwy",
    city: "GULF BREEZE",
    state: "FL",
    zip: 32563,
    phone: "(850) 226-6803",
    lat: 30.395429,
    lng: -87.032668,
    id: 790,
  },
  {
    name: "HUFFMAN, KEVIN",
    address: "2805 Casanova Ct.",
    city: "NEW SMYRNA BEACH",
    state: "FL",
    zip: 32168,
    phone: "(386) 402-4250",
    lat: 29.029158,
    lng: -81.01467,
    id: 791,
  },
  {
    name: "HUGHES, KEVIN",
    address: "6313 Balboa Lane",
    city: "APOLLO BEACH",
    state: "FL",
    zip: 33572,
    phone: "(813) 391-4891",
    lat: 27.774364,
    lng: -82.420656,
    id: 792,
  },
  {
    name: "HUGHES, LENARD",
    address: "15471 TEMPLE BLVD",
    city: "LOXAHATCHEE",
    state: "FL",
    zip: 33470,
    phone: "(561) 792-5096",
    lat: 26.796019,
    lng: -80.285285,
    id: 793,
  },
  {
    name: "HUME, DANIEL",
    address: "6400 Edgelake Drive",
    city: "SARASOTA",
    state: "FL",
    zip: 34240,
    phone: "(941) 928-6909",
    lat: 27.301476,
    lng: -82.44334,
    id: 794,
  },
  {
    name: "HUNT, CHRISTOPHER",
    address: "11195 JOG ROAD, SUITE 3",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 733-9690",
    lat: 26.50855,
    lng: -80.14748,
    id: 795,
  },
  {
    name: "HUNTON, REBECCA",
    address: "420 5th AVENUE",
    city: "INDIALANTIC",
    state: "FL",
    zip: 32903,
    phone: "(321) 254-6803",
    lat: 28.089686,
    lng: -80.57333,
    id: 796,
  },
  {
    name: "Hurtado, Michael",
    address: "14125 NW 80th Avenue , #305",
    city: "MIAMI LAKES",
    state: "FL",
    zip: 33016,
    phone: "(786) 246-2697",
    lat: 25.902409,
    lng: -80.330137,
    id: 797,
  },
  {
    name: "HURTADO INFANTE, CARLOS",
    address: "4160 west 16 ave , suite 503",
    city: "HIALEAH",
    state: "FL",
    zip: 33012,
    phone: "(305) 364-5029",
    lat: 25.859376,
    lng: -80.314662,
    id: 798,
  },
  {
    name: "HUSSAIN, JAWED",
    address: "4131 University Blvd South , Bldg 12",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 274-8813",
    lat: 30.281743,
    lng: -81.6027,
    id: 799,
  },
  {
    name: "HUSSAIN, SYED",
    address: "7685 103RD STREET , SUITE 1",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32210,
    phone: "(904) 771-1116",
    lat: 30.249163,
    lng: -81.774857,
    id: 800,
  },
  {
    name: "HUSSAMY, OMAR",
    address: "1260 37th Street, Suite 102",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 213-9800",
    lat: 27.661011,
    lng: -80.398015,
    id: 801,
  },
  {
    name: "HUSSEIN, MALEK",
    address: "1451 el Camino real",
    city: "LADY LAKE",
    state: "FL",
    zip: 32159,
    phone: "(352) 751-8140",
    lat: 28.950492,
    lng: -81.958475,
    id: 802,
  },
  {
    name: "HUSSEY, FRANCIS",
    address: "130 TAMIAMI TRAIL NORTH , SUITE # 250",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 263-1641",
    lat: 26.145564,
    lng: -81.795473,
    id: 803,
  },
  {
    name: "Hyman, Alan",
    address: "110 Dumbarton , Apt C",
    city: "BALTIMORE",
    state: "MD",
    zip: 21212,
    phone: "(813) 259-0670",
    lat: 39.379595,
    lng: -76.620049,
    id: 804,
  },
  {
    name: "HYPPOLITE, JACOB",
    address: "21298 OLEAN BLVD",
    city: "PORT-CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 629-1181",
    lat: 26.988577,
    lng: -82.097973,
    id: 805,
  },
  {
    name: "IANNOTTI, NICHOLAS",
    address: "1871 S.E. TIFFANY AVENUE , SUITE 100",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-5666",
    lat: 27.291802,
    lng: -80.294805,
    id: 806,
  },
  {
    name: "IGLEBURGER, JAMES",
    address: "7552 Navarre pkwy , SUITE 54",
    city: "NAVARRE",
    state: "FL",
    zip: 32566,
    phone: "(850) 684-3884",
    lat: 30.403831,
    lng: -86.906404,
    id: 807,
  },
  {
    name: "IGLESIAS, CARLOS",
    address: "8530 SW 8TH ST",
    city: "MIAMI",
    state: "FL",
    zip: 33144,
    phone: "(305) 455-6804",
    lat: 25.76037,
    lng: -80.334426,
    id: 808,
  },
  {
    name: "IRELAND, BRIAN",
    address: "1380 S. PATRICK DRIVE",
    city: "SATELLITE BEACH",
    state: "FL",
    zip: 32937,
    phone: "(321) 773-2659",
    lat: 28.173361,
    lng: -80.606392,
    id: 809,
  },
  {
    name: "IRIZARRY-ORTIZ, MARICELIS",
    address: "7765 S CR 231",
    city: "LAKE BUTLER",
    state: "FL",
    zip: 32054,
    phone: "(386) 496-7147",
    lat: 29.98773,
    lng: -82.355572,
    id: 810,
  },
  {
    name: "IRIZARRY-ROMAN, MOISES",
    address: "2250 NE 53RD ST , SUITE 230",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33132,
    phone: "(305) 396-9095",
    lat: 26.19196,
    lng: -80.116255,
    id: 811,
  },
  {
    name: "IRWIN, FRANKLIN",
    address: "11555 CENTRAL PARKWAY , SUITE 304",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32224,
    phone: "(904) 265-7755",
    lat: 30.281088,
    lng: -81.520612,
    id: 812,
  },
  {
    name: "iSALGUEZ, ELIZABETH",
    address: "3224 North Roosevelt Boulevard",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(954) 494-6529",
    lat: 24.569391,
    lng: -81.766471,
    id: 813,
  },
  {
    name: "ISENALUMHE, ANTHONY",
    address: "13141 SPRING HILL DRIVE",
    city: "SPRING HILL",
    state: "FL",
    zip: 34609,
    phone: "(352) 515-0025",
    lat: 28.468366,
    lng: -82.509307,
    id: 814,
  },
  {
    name: "ISRAEL-CVIK, JELIN",
    address: "13831 METROPOLIS AVE.",
    city: "FORT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(239) 466-8947",
    lat: 26.541292,
    lng: -81.853232,
    id: 815,
  },
  {
    name: "ISSA, OMAR",
    address: "1202 2797 1st St , #1202",
    city: "FORT MYERS",
    state: "FL",
    zip: 33916,
    phone: "(561) 350-8824",
    lat: 26.651509,
    lng: -81.85701,
    id: 816,
  },
  {
    name: "ISSAC, MICHELLE",
    address: "8900 N. Kendall DR",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 596-2000",
    lat: 25.68445,
    lng: -80.338947,
    id: 817,
  },
  {
    name: "IZEIYAMU, OSAYANDE",
    address: "187 NW MADISON STREET",
    city: "LAKE CITY",
    state: "FL",
    zip: 32055,
    phone: "(386) 867-5457",
    lat: 30.190873,
    lng: -82.638588,
    id: 818,
  },
  {
    name: "JACINTHE, EDWIDGE",
    address: "1347 S Andrews Ave",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33316,
    phone: "(917) 805-4510",
    lat: 26.104871,
    lng: -80.143301,
    id: 819,
  },
  {
    name: "JACKSON, BURKE",
    address: "2018 E Silver Springs Blvd",
    city: "OCALA",
    state: "FL",
    zip: 34470,
    phone: "(352) 512-9301",
    lat: 29.186596,
    lng: -82.111138,
    id: 820,
  },
  {
    name: "JACKSON, JOHN",
    address: "111 NW 183rd STREET , SUITE 306",
    city: "MIAMI",
    state: "FL",
    zip: 33169,
    phone: "(786) 863-5714",
    lat: 25.943406,
    lng: -80.203739,
    id: 821,
  },
  {
    name: "JACKSON, ROOSEVELT",
    address: "133 NORTH STATE , ROAD-7",
    city: "PLANTATION",
    state: "FL",
    zip: 33317,
    phone: "(954) 587-7075",
    lat: 26.122144,
    lng: -80.202068,
    id: 822,
  },
  {
    name: "JACOBOWITZ, ADAM",
    address: "975 West 49th Street",
    city: "HIALEAH",
    state: "FL",
    zip: 33012,
    phone: "(305) 819-6300",
    lat: 25.867045,
    lng: -80.302761,
    id: 823,
  },
  {
    name: "JACOBS, PATRICIA",
    address: "701 NW 13th Street",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 955-5966",
    lat: 26.362532,
    lng: -80.102197,
    id: 824,
  },
  {
    name: "JAFFE, SCOTT",
    address: "10915 BONITA BEACH RD , #1122",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(239) 498-5760",
    lat: 26.330941,
    lng: -81.771334,
    id: 825,
  },
  {
    name: "JAGMOHAN, GEM",
    address: "86 BAHIA TRACE CIRCLE",
    city: "OCALA",
    state: "FL",
    zip: 34472,
    phone: "(347) 432-8073",
    lat: 29.135727,
    lng: -82.009274,
    id: 826,
  },
  {
    name: "JAIN, VIJAY",
    address: "399 Palm Coast Parkway , Suite 4",
    city: "PALM COAST",
    state: "FL",
    zip: 32137,
    phone: "(386) 627-0501",
    lat: 29.551407,
    lng: -81.24616,
    id: 827,
  },
  {
    name: "JALBERT, EUGENE",
    address: "1717 North E Street, Suite 425",
    city: "PENSACOLA",
    state: "FL",
    zip: 32501,
    phone: "(850) 437-8640",
    lat: 30.430275,
    lng: -87.23,
    id: 828,
  },
  {
    name: "JANES, DONALD",
    address: "2445 SW 75TH ST , SUITE 110",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32608,
    phone: "(352) 872-5111",
    lat: 29.62991,
    lng: -82.422315,
    id: 829,
  },
  {
    name: "JANHO, RAJAE",
    address: "3970 Rogers Bridge RD",
    city: "DULUTH",
    state: "GA",
    zip: 30097,
    phone: "(678) 824-8600",
    lat: 34.02099,
    lng: -84.13675,
    id: 830,
  },
  {
    name: "JARA, FERNANDO",
    address: "9/29/01",
    city: "TAMPA",
    state: "FL",
    zip: 33606,
    phone: "(845) 721-9458",
    lat: 27.917452,
    lng: -82.452279,
    id: 831,
  },
  {
    name: "JARRETT, WENTWORTH",
    address: "12955 SW 132ND STREET , BUILDING 3B, SUITE # 104",
    city: "MIAMI",
    state: "FL",
    zip: 33186,
    phone: "(305) 520-5750",
    lat: 25.64528,
    lng: -80.40362,
    id: 832,
  },
  {
    name: "JARVIS, VINCENT",
    address: "3161 South Ocean Drive, #1408",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(917) 690-3811",
    lat: 25.976166,
    lng: -80.120696,
    id: 833,
  },
  {
    name: "Jassey, Lewis",
    address: "10658 Greenbriar Villa Drive",
    city: "LAKE WORTH",
    state: "FL",
    zip: 33449,
    phone: "(516) 236-5599",
    lat: 26.60988,
    lng: -80.217274,
    id: 834,
  },
  {
    name: "JASSIR, CARLOS",
    address: "8150 VIA BELLA NOTTE",
    city: "ORLANDO",
    state: "FL",
    zip: 32836,
    phone: "(407) 242-4098",
    lat: 28.444339,
    lng: -81.493491,
    id: 835,
  },
  {
    name: "JAVED, MOHAMMAD",
    address: "6447 LAKE WORTH ROAD",
    city: "GREENACRES",
    state: "FL",
    zip: 33463,
    phone: "(561) 762-2349",
    lat: 26.619553,
    lng: -80.145781,
    id: 836,
  },
  {
    name: "JAVIER, KRISTINA",
    address: "9743 Selten Way",
    city: "ORLANDO",
    state: "FL",
    zip: 32827,
    phone: "(407) 736-8733",
    lat: 28.364683,
    lng: -81.248036,
    id: 837,
  },
  {
    name: "JAWAHIR, MARK",
    address: "1759-1763 US HWY 27 S",
    city: "SEBRING",
    state: "FL",
    zip: 33870,
    phone: "(863) 402-0244",
    lat: 27.470723,
    lng: -81.461135,
    id: 838,
  },
  {
    name: "JAYSON, MAURY",
    address: "601 NORTH FLAMINGO ROAD , SUITE 308",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33028,
    phone: "(954) 392-7770",
    lat: 26.01221,
    lng: -80.31188,
    id: 839,
  },
  {
    name: "JEANNOT, FRANCISCO",
    address: "300 NW 70TH AVENUE , SUITE 107",
    city: "PLANTATION",
    state: "FL",
    zip: 33317,
    phone: "(954) 255-9930",
    lat: 26.124888,
    lng: -80.240213,
    id: 840,
  },
  {
    name: "JENNINGS, JAMES",
    address: "11921 SOUTH DIXIE HWY, Set. 201",
    city: "MIAMI",
    state: "FL",
    zip: 33156,
    phone: "(786) 868-0503",
    lat: 25.659775,
    lng: -80.325827,
    id: 841,
  },
  {
    name: "JETTON, TIMOTHY",
    address: "1423 US Highway 1 , Ste. 109",
    city: "FORT PIERCE",
    state: "FL",
    zip: 34950,
    phone: "(772) 466-6855",
    lat: 27.43351,
    lng: -80.326504,
    id: 842,
  },
  {
    name: "JIBAWI, ABDEL KARIM",
    address: "5350 SPRING HILL DR",
    city: "SPRING HILL",
    state: "FL",
    zip: 34606,
    phone: "(787) 621-3700",
    lat: 28.457547,
    lng: -82.625036,
    id: 843,
  },
  {
    name: "JIMENEZ, ENRIQUE",
    address: "2000 N BAYSHORE DR , UNIT 906",
    city: "MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(570) 394-9944",
    lat: 25.795671,
    lng: -80.188053,
    id: 844,
  },
  {
    name: "JOHN, JONES",
    address: "7351 W. Oakland Park Blvd. , SUITE 105",
    city: "LAUDERHILL",
    state: "FL",
    zip: 33319,
    phone: "(954) 908-5992",
    lat: 26.166897,
    lng: -80.24662,
    id: 845,
  },
  {
    name: "JOHN, THOMAS",
    address: "9961 E EAST COUNTY HIGHWAY 30A",
    city: "NASHVILLE",
    state: "FL",
    zip: 32461,
    phone: "(850) 231-9286",
    lat: 30.283286,
    lng: -86.024021,
    id: 846,
  },
  {
    name: "JOHNSON, ERIKA",
    address: "12250 TAMIAMI TRAIL EAST , SUITE 201",
    city: "NAPLES",
    state: "FL",
    zip: 34113,
    phone: "(239) 595-9939",
    lat: 26.068132,
    lng: -81.708323,
    id: 847,
  },
  {
    name: "JOHNSON, GORDON",
    address: "11455 PARADISE COVE LANE",
    city: "WELLINGTON",
    state: "FL",
    zip: 33449,
    phone: "(561) 543-5562",
    lat: 26.613819,
    lng: -80.228344,
    id: 848,
  },
  {
    name: "JOHNSON, JESSICA",
    address: "1106 White St",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(305) 204-7178",
    lat: 24.554873,
    lng: -81.790467,
    id: 849,
  },
  {
    name: "JOHNSON, RICHARD",
    address: "1400 East Bay Drive",
    city: "LARGO",
    state: "FL",
    zip: 33771,
    phone: "(727) 433-2553",
    lat: 27.916936,
    lng: -82.772548,
    id: 850,
  },
  {
    name: "JOHNSON, STEPHANIE",
    address: "Suite 870 Mack Bayou Road",
    city: "SANTA ROSA BEACH",
    state: "FL",
    zip: 32459,
    phone: "(850) 213-1215",
    lat: 30.39603,
    lng: -86.22883,
    id: 851,
  },
  {
    name: "JOLLY, MARK",
    address: "206 2ND STREET EAST",
    city: "BRADENTON",
    state: "FL",
    zip: 34208,
    phone: "(941) 745-7564",
    lat: 27.497518,
    lng: -82.562083,
    id: 852,
  },
  {
    name: "JORGE, ALLAN",
    address: "6705 RED ROAD , #522",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33143,
    phone: "(786) 517-8650",
    lat: 25.706322,
    lng: -80.284671,
    id: 853,
  },
  {
    name: "JORGE, JAIME",
    address: "897 TOWNE CENTER DRIVE, SUITE 101",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34759,
    phone: "(407) 267-8823",
    lat: 28.145544,
    lng: -81.450499,
    id: 854,
  },
  {
    name: "JOSEPH, ROBERT",
    address: "2250 HARRISON AVENUE",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 784-2477",
    lat: 30.189323,
    lng: -85.658873,
    id: 855,
  },
  {
    name: "JOYNER, JAMES",
    address: "10058 Baymeadows Road",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 636-5400",
    lat: 30.217796,
    lng: -81.55041,
    id: 856,
  },
  {
    name: "JUDSON, DONNA",
    address: "2400 SOUTH HIGHWAY 29",
    city: "CANTONMENT",
    state: "FL",
    zip: 32533,
    phone: "(850) 476-0559",
    lat: 30.568232,
    lng: -87.289196,
    id: 857,
  },
  {
    name: "JURADO, MARIA",
    address: "9980 CENTRAL PARK BLVD. NORTH , SUITE 214",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 483-8990",
    lat: 26.35455,
    lng: -80.202329,
    id: 858,
  },
  {
    name: "JUSINO, EDUARDO",
    address: "1397 Medical Park Blvd , Suite 340",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 568-6463",
    lat: 26.655803,
    lng: -80.206575,
    id: 859,
  },
  {
    name: "Kahane, Ian",
    address: "3303 Manatee Ave W",
    city: "Bradenton BRADENTON",
    state: "FL",
    zip: 34205,
    phone: "(631) 889-1442",
    lat: 27.495461,
    lng: -82.594479,
    id: 860,
  },
  {
    name: "KAIRAB, HIMANSHU",
    address: "3306 SW 26TH AVE , BLDG 200",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(352) 861-8381",
    lat: 29.154341,
    lng: -82.167912,
    id: 861,
  },
  {
    name: "KALAKATA, VIJAYASEKHARA",
    address: "690 NE 3RD AVE, STE 104",
    city: "CRYSTAL RIVER",
    state: "FL",
    zip: 34428,
    phone: "(352) 364-0045",
    lat: 28.900577,
    lng: -82.589266,
    id: 862,
  },
  {
    name: "KALAN, PRAKASH",
    address: "7512 DrPhillip Blvd , SUITE 50-344",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(407) 543-6306",
    lat: 28.45409,
    lng: -81.48843,
    id: 863,
  },
  {
    name: "KALAN, SHIVAM",
    address: "300 Pinellas St",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 462-7000",
    lat: 27.952803,
    lng: -82.803272,
    id: 864,
  },
  {
    name: "KALIN, DAVID",
    address: "1931 WEST MARTIN LUTHER KING BLVD , SUITE A",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 443-4742",
    lat: 27.981538,
    lng: -82.47967,
    id: 865,
  },
  {
    name: "KALIRAO, PARAMJIT",
    address: "5441 N UNIVERSITY DRIVE , SUITE 101",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33067,
    phone: "(954) 739-2221",
    lat: 26.297458,
    lng: -80.247438,
    id: 866,
  },
  {
    name: "KALLEN, PHILLIP",
    address: "5130 LINTON BLVD , STE F-1",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 824-0038",
    lat: 26.439168,
    lng: -80.123854,
    id: 867,
  },
  {
    name: "KALNOKY, ACHILLES",
    address: "6981 Curtiss Ave. , Suite 4",
    city: "SARASOTA",
    state: "FL",
    zip: 34231,
    phone: "(941) 921-0986",
    lat: 27.255099,
    lng: -82.498885,
    id: 868,
  },
  {
    name: "KAMAJIAN, GEORGE",
    address: "10500 ULMERTON , suite 360",
    city: "LARGO",
    state: "FL",
    zip: 33771,
    phone: "(727) 517-1500",
    lat: 27.892083,
    lng: -82.783448,
    id: 869,
  },
  {
    name: "Kaminski, Joseph",
    address: "517 RIVIERA ST. , SUITE C",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(941) 244-0178",
    lat: 27.091552,
    lng: -82.446621,
    id: 870,
  },
  {
    name: "KAPLAN, BENJAMIN",
    address: "21 W. COLUMBIA ST. , SUITE 201",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(321) 841-6600",
    lat: 28.528028,
    lng: -81.380143,
    id: 871,
  },
  {
    name: "KAPLAN, HAROLD",
    address: "200 AVE DES PARQUES N.",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(941) 488-5608",
    lat: 27.10111,
    lng: -82.45161,
    id: 872,
  },
  {
    name: "KAPROW, MARC",
    address: "S Pine Island Rd , Suite 900",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(407) 907-4295",
    lat: 26.108404,
    lng: -80.261967,
    id: 873,
  },
  {
    name: "KARTZINEL, JERROLD",
    address: "125 TERRA MANGO LOOP , SUITE B",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(949) 398-7654",
    lat: 28.543972,
    lng: -81.503661,
    id: 874,
  },
  {
    name: "KARUMANCHI, PRUDHVI RAJAN",
    address: "3001 W. Dr. Martin Luther King Jr. , blvd, 3 East",
    city: "TAMPA",
    state: "FL",
    zip: 33611,
    phone: "(813) 870-4933",
    lat: 27.982181,
    lng: -82.491805,
    id: 875,
  },
  {
    name: "KASHLAN, GHIATH",
    address: "17921 HUNTING BOW CIRCLE , suite 101",
    city: "LUTZ",
    state: "FL",
    zip: 33558,
    phone: "(813) 792-1900",
    lat: 28.194342,
    lng: -82.518667,
    id: 876,
  },

  {
    name: "KATANICK, CINDY",
    address: "350 NW 84TH AVE , SUITE 109",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 424-4321",
    lat: 26.125434,
    lng: -80.260786,
    id: 878,
  },
  {
    name: "KATANICK, SHELDON",
    address: "1704 SW 29TH ST",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(352) 622-5528",
    lat: 29.158632,
    lng: -82.153779,
    id: 879,
  },
  {
    name: "KATT, LAWRENCE",
    address: "3039 GUILDFORD C",
    city: "BOCA RATON",
    state: "FL",
    zip: 33434,
    phone: "(561) 212-6779",
    lat: 26.388392,
    lng: -80.17502,
    id: 880,
  },
  {
    name: "KAUFMAN, SETH",
    address: "410 LIONEL WAY , SUITE 201",
    city: "DAVENPORT",
    state: "FL",
    zip: 33837,
    phone: "(863) 547-8900",
    lat: 28.178883,
    lng: -81.641676,
    id: 881,
  },
  {
    name: "KAUFMANN, JOHN",
    address: "22049 Palm Grass Drive",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 908-3579",
    lat: 26.346483,
    lng: -80.247369,
    id: 882,
  },
  {
    name: "KAUZLARICH, JOHN",
    address: "9110 Cypress Trail",
    city: "LARGO",
    state: "FL",
    zip: 33777,
    phone: "(727) 460-1300",
    lat: 27.865486,
    lng: -82.765293,
    id: 883,
  },
  {
    name: "KAWLICHE, BORIS",
    address: "407 NORTH PARSONS AVENUE , SUITE 104",
    city: "BRANDON",
    state: "FL",
    zip: 33510,
    phone: "(813) 681-5880",
    lat: 27.94415,
    lng: -82.28581,
    id: 884,
  },
  {
    name: "KAYE, ALEXANDER",
    address: "4750 SW 91 DR STE A",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32608,
    phone: "(352) 367-9602",
    lat: 29.609729,
    lng: -82.438452,
    id: 885,
  },
  {
    name: "KEENE, JAGGERS",
    address: "1301 2ND AVENUE SW",
    city: "LARGO",
    state: "FL",
    zip: 33770,
    phone: "(727) 584-7706",
    lat: 27.914474,
    lng: -82.802238,
    id: 886,
  },
  {
    name: "KEIFER, GREGORY",
    address: "553 INDIES ROAD",
    city: "RAMROD KEY",
    state: "FL",
    zip: 33042,
    phone: "(305) 849-0463",
    lat: 24.654103,
    lng: -81.407086,
    id: 887,
  },
  {
    name: "KELLOGG, SPENCER",
    address: "8950 N KENDALL DR, STE 303",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 595-4070",
    lat: 25.684916,
    lng: -80.341237,
    id: 888,
  },
  {
    name: "KELMENSON, EDWARD",
    address: "1350 East Venice Avenue",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(207) 478-5944",
    lat: 27.100424,
    lng: -82.415196,
    id: 889,
  },
  {
    name: "KENDRICK, MARK",
    address: "790 DUNLAWTON AVENUE , SUITE D",
    city: "PORT ORANGE",
    state: "FL",
    zip: 32127,
    phone: "(386) 304-9556",
    lat: 29.132774,
    lng: -81.000072,
    id: 890,
  },
  {
    name: "KENT, ROBERT",
    address: "850 W Plymouth Ave",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(386) 507-5269",
    lat: 29.041592,
    lng: -81.31954,
    id: 891,
  },
  {
    name: "KEON, ANGELA",
    address: "1107 WEST MARION AVE , SUITE 116",
    city: "PUNTA GORDA",
    state: "FL",
    zip: 33950,
    phone: "(941) 637-7000",
    lat: 26.926745,
    lng: -82.061161,
    id: 892,
  },
  {
    name: "KERR, DANIEL",
    address: "15211 CORTEZ BLVD",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 345-4565",
    lat: 28.5349,
    lng: -82.476453,
    id: 893,
  },
  {
    name: "KESLER, ROMAN",
    address: "8333 NORTH DAVIS HIGHWAY",
    city: "PENSACOLA",
    state: "FL",
    zip: 32514,
    phone: "(850) 474-8353",
    lat: 30.514082,
    lng: -87.219581,
    id: 894,
  },
  {
    name: "KESSEL, JULIE",
    address: "851 35TH AVENUE NORTH",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33704,
    phone: "(813) 351-0518",
    lat: 27.803987,
    lng: -82.645976,
    id: 895,
  },
  {
    name: "KETTANI, HIND",
    address: "3000 Medical Park Drive, Suite 510",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(646) 267-3310",
    lat: 28.071557,
    lng: -82.425215,
    id: 896,
  },
  {
    name: "KHALAF, MOHAMED ABDEL",
    address: "6245 66th Street N",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(727) 873-3891",
    lat: 27.829268,
    lng: -82.727496,
    id: 897,
  },
  {
    name: "KHAMVONGSA, PETER",
    address: "8950 NORTH KENDALL DRIVE, #302",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 595-4070",
    lat: 25.684916,
    lng: -80.341237,
    id: 898,
  },
  {
    name: "Khan, Abraham",
    address: "2901 Busch Lake Blvd",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(727) 534-0057",
    lat: 28.035301,
    lng: -82.490171,
    id: 899,
  },
  {
    name: "KHAN, IRFAN",
    address: "9250 GLADES RD, SUITE 110 , SUITE 110",
    city: "BOCA RATON",
    state: "FL",
    zip: 33434,
    phone: "(561) 470-1110",
    lat: 26.367404,
    lng: -80.190102,
    id: 900,
  },
  {
    name: "KHAN, MOHAMMAD",
    address: "YUSRA 806 N ROSE AVENUE",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 798-6021",
    lat: 28.30639,
    lng: -81.425522,
    id: 901,
  },
  {
    name: "KHANI, HOWARD",
    address: "2140 NE 26TH ST",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33305,
    phone: "(954) 396-3274",
    lat: 26.159562,
    lng: -80.118728,
    id: 902,
  },
  {
    name: "KHANNA, ASHOK",
    address: "15493 Stoneybrook West Pkwy110 , Suite 110",
    city: "WINTER GARDEN",
    state: "FL",
    zip: 34787,
    phone: "(407) 299-7791",
    lat: 28.518502,
    lng: -81.618728,
    id: 903,
  },
  {
    name: "KHANNA, PARVEEN",
    address: "10250 Normandy Boulevard, Suite 703",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32221,
    phone: "(904) 495-7200",
    lat: 30.275611,
    lng: -81.829107,
    id: 904,
  },
  {
    name: "KHATIB, ZIAD",
    address: "3100 SW 62 AVENUE , #121",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(305) 662-8360",
    lat: 25.74122,
    lng: -80.295442,
    id: 905,
  },
  {
    name: "KHELL, JOSEPH",
    address: "1100 NW 95TH ST",
    city: "MIAMI",
    state: "FL",
    zip: 33150,
    phone: "(305) 835-6191",
    lat: 25.860407,
    lng: -80.214749,
    id: 906,
  },
  {
    name: "Khousakoun, Somphanh",
    address: "300 Pinellas St",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 462-7000",
    lat: 27.952803,
    lng: -82.803272,
    id: 907,
  },
  {
    name: "KILEY, LINDA",
    address: "3375 Burns Rd , SUITE 204",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 701-2841",
    lat: 26.83083,
    lng: -80.08551,
    id: 908,
  },
  {
    name: "KILGORE, JOHN",
    address: "122 122 S. MOON AVE",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 502-6430",
    lat: 27.936785,
    lng: -82.288843,
    id: 909,
  },
  {
    name: "KIM, LLOYD",
    address: "17270 SE 109th Terrace Road",
    city: "SUMMERFIELD",
    state: "FL",
    zip: 34491,
    phone: "(352) 633-7222",
    lat: 28.970958,
    lng: -81.975356,
    id: 910,
  },
  {
    name: "Kim, Tae",
    address: "1354 B Unit D",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33304,
    phone: "(650) 644-7146",
    lat: 26.140453,
    lng: -80.121179,
    id: 911,
  },
  {
    name: "Kindya, Michael",
    address: "2307 West Broward Blvd , Suite 200",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33312,
    phone: "(954) 486-4045",
    lat: 26.122297,
    lng: -80.173647,
    id: 912,
  },
  {
    name: "KING, KELLY",
    address: "166 E Bloomingdale Ave",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(844) 735-3231",
    lat: 27.894203,
    lng: -82.283813,
    id: 913,
  },
  {
    name: "KING, NANCY",
    address: "125 NEWBERN CIRCLE",
    city: "AUBURNDALE",
    state: "FL",
    zip: 33823,
    phone: "(863) 965-1288",
    lat: 28.066415,
    lng: -81.791971,
    id: 914,
  },
  {
    name: "Kirsch, Charles",
    address: "2777 Miccosukee Road",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 906-5000",
    lat: 30.467714,
    lng: -84.233172,
    id: 915,
  },
  {
    name: "KIRSCHBAUM, NEIL",
    address: "1951 SW 172 Avenue , Suite 314",
    city: "MIRAMAR",
    state: "FL",
    zip: 33029,
    phone: "(954) 447-5206",
    lat: 25.99016,
    lng: -80.37594,
    id: 916,
  },
  {
    name: "KLEIN, JUSTIN",
    address: "6101 Pine Ridge Road",
    city: "NAPLES",
    state: "FL",
    zip: 34119,
    phone: "(954) 806-9099",
    lat: 26.213827,
    lng: -81.732945,
    id: 917,
  },
  {
    name: "KLOPUKH, BORIS",
    address: "21150 BISCAYNE BLVD, SUITE 104",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 534-4747",
    lat: 25.97127,
    lng: -80.14449,
    id: 918,
  },
  {
    name: "KNABB, KYLE",
    address: "159 NORTH 3RD ST",
    city: "MACCLENNY",
    state: "FL",
    zip: 32063,
    phone: "(904) 259-7815",
    lat: 30.285139,
    lng: -82.118752,
    id: 919,
  },
  {
    name: "KOFFLER, KAREN",
    address: "1801 NW 9th Ave , Suite 470",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(305) 243-0965",
    lat: 25.79278,
    lng: -80.209632,
    id: 920,
  },
  {
    name: "KOFFLER, RICHARD",
    address: "1005 Kane Concourse, suite 211",
    city: "BAY HARBOR ISLANDS",
    state: "FL",
    zip: 33154,
    phone: "(305) 280-0561",
    lat: 25.886809,
    lng: -80.129291,
    id: 921,
  },
  {
    name: "KOLLURI, GLENN",
    address: "701 West Plymouth Avenue",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(386) 793-7588",
    lat: 29.043391,
    lng: -81.317308,
    id: 922,
  },
  {
    name: "KOLODCHAK, JENNIFER",
    address: "465 Minuteman Cswy , Suite 455",
    city: "COCOA BEACH",
    state: "FL",
    zip: 32931,
    phone: "(866) 389-7601",
    lat: 28.319075,
    lng: -80.61235,
    id: 923,
  },
  {
    name: "KONA, SUGUNA",
    address: "4502 Cortez Road, Suite 205",
    city: "BRADENTON",
    state: "FL",
    zip: 34210,
    phone: "(941) 259-8505",
    lat: 27.463092,
    lng: -82.607633,
    id: 924,
  },
  {
    name: "KORUNDA, ZDENKO",
    address: "4513 EXECUTIVE DRIVE",
    city: "NAPLES",
    state: "FL",
    zip: 34119,
    phone: "(239) 591-2803",
    lat: 26.274273,
    lng: -81.732296,
    id: 925,
  },
  {
    name: "KOSTUR, ALEXANDRA",
    address: "7807 BAYMEADOWS ROAD EAST , Suite 207",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 446-9991",
    lat: 30.23078,
    lng: -81.51002,
    id: 926,
  },
  {
    name: "KOTAK, TANUJA",
    address: "36440 US HIGHWAY 19 N",
    city: "PALM HARBOR",
    state: "FL",
    zip: 34684,
    phone: "(727) 786-0696",
    lat: 28.103614,
    lng: -82.740699,
    id: 927,
  },
  {
    name: "KOTTURAN, PAULSON",
    address: "220 SW NATURA AVENUE",
    city: "DEERFIELD BEACH",
    state: "FL",
    zip: 33441,
    phone: "(954) 360-7000",
    lat: 26.315786,
    lng: -80.112309,
    id: 928,
  },
  {
    name: "KOWALSKI, IAN",
    address: "6816 Griffin Road",
    city: "DAVIE",
    state: "FL",
    zip: 33314,
    phone: "(954) 900-3163",
    lat: 26.064589,
    lng: -80.237838,
    id: 929,
  },
  {
    name: "KRAMER, LENI",
    address: "2605 W SWANN AVENUE , SUITE 600",
    city: "TAMPA",
    state: "FL",
    zip: 33609,
    phone: "(813) 876-7073",
    lat: 27.93773,
    lng: -82.487501,
    id: 930,
  },
  {
    name: "KRAUCAK, NELSON",
    address: "1501 US HIGHWAY 441 NORTH , SUITE 1704",
    city: "THE VILLAGES",
    state: "FL",
    zip: 32159,
    phone: "(352) 750-4333",
    lat: 28.951031,
    lng: -81.955383,
    id: 931,
  },
  {
    name: "KRICHMAR, PERRY",
    address: "1380 S HIATUS ROAD",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33025,
    phone: "(954) 432-1511",
    lat: 25.996323,
    lng: -80.296112,
    id: 932,
  },
  {
    name: "KROLL, BRIAN",
    address: "120 MEDICAL BLVD, SUITE 102",
    city: "SPRING HILL",
    state: "FL",
    zip: 34609,
    phone: "(352) 683-3662",
    lat: 28.435198,
    lng: -82.541071,
    id: 933,
  },
  {
    name: "KROTENBERG, JEFFREY",
    address: "305 WAYMONT CT, #111",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 324-0405",
    lat: 28.754079,
    lng: -81.338732,
    id: 934,
  },
  {
    name: "KRUGMAN, RICHARD",
    address: "100 S. VIRGINIA AVENUE , #403",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(561) 293-6137",
    lat: 28.597045,
    lng: -81.354582,
    id: 935,
  },
  {
    name: "KUEHNER, PAUL",
    address: "4301 SANIBEL CAPTIVA ROAD",
    city: "SANIBEL",
    state: "FL",
    zip: 33957,
    phone: "(239) 472-0700",
    lat: 26.449892,
    lng: -82.129251,
    id: 936,
  },
  {
    name: "KUFEL, MARK",
    address: "8900 North Kendall Drive",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 596-1960",
    lat: 25.68445,
    lng: -80.338947,
    id: 937,
  },
  {
    name: "KUHN, ALLEN",
    address: "9429 Treasure Lane NE",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33702,
    phone: "(727) 385-3180",
    lat: 27.858617,
    lng: -82.633391,
    id: 938,
  },
  {
    name: "KUMAR, RAMESH",
    address: "1115 N. PARROTT AVE.",
    city: "OKEECHOBEE",
    state: "FL",
    zip: 34972,
    phone: "(863) 467-9500",
    lat: 27.254961,
    lng: -80.82991,
    id: 939,
  },
  {
    name: "KUNWAR, SHAAN",
    address: "6050 State Road 70 East , Suite B",
    city: "BRADENTON",
    state: "FL",
    zip: 34203,
    phone: "(941) 727-7771",
    lat: 27.44508,
    lng: -82.483713,
    id: 940,
  },
  {
    name: "KUSNETZ, NORMA",
    address: "2055 GLENWOOD DRIVE",
    city: "WINTER PARK",
    state: "FL",
    zip: 32792,
    phone: "(407) 645-3555",
    lat: 28.599694,
    lng: -81.325837,
    id: 941,
  },
  {
    name: "KUTOB, DAVID",
    address: "1120 Homestead Road North",
    city: "LEHIGH ACRES",
    state: "FL",
    zip: 33936,
    phone: "(239) 303-5020",
    lat: 26.605319,
    lng: -81.647462,
    id: 942,
  },
  {
    name: "KYRITSIS, ATHINA",
    address: "3400 Radio Road , suite 109",
    city: "NAPLES",
    state: "FL",
    zip: 34104,
    phone: "(239) 307-5307",
    lat: 26.152189,
    lng: -81.765916,
    id: 943,
  },
  {
    name: "LABISSIERE, JEAN-CLAUDE",
    address: "555 N. CONGRESS AVENUE , SUITE 206",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33426,
    phone: "(561) 739-9333",
    lat: 26.533313,
    lng: -80.091231,
    id: 944,
  },
  {
    name: "LACAYO, MARTA",
    address: "Suite 3100 SOUTH FEDERAL HIGHWAY",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33483,
    phone: "(561) 257-2511",
    lat: 26.464145,
    lng: -80.064844,
    id: 945,
  },
  {
    name: "LACROSS, CHRISTOPHER",
    address: "1421 E Oakland Park Blvd , #200",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33334,
    phone: "(954) 565-0875",
    lat: 26.167411,
    lng: -80.12855,
    id: 946,
  },
  {
    name: "Lafferty, Bobby",
    address: "417 Walnut Street",
    city: "GREEN COVE SPRINGS",
    state: "FL",
    zip: 32043,
    phone: "(904) 531-5694",
    lat: 29.993205,
    lng: -81.679914,
    id: 947,
  },
  {
    name: "LAKIN, RYAN",
    address: "241 N. SUPERIOR ST , #301",
    city: "TOLEDO",
    state: "OH",
    zip: 43604,
    phone: "(312) 720-9313",
    lat: 41.651611,
    lng: -83.536123,
    id: 948,
  },

  {
    name: "LAMBERT, CHARLES",
    address: "Advent 3100 EAST FLETCHER AVE",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(813) 615-7201",
    lat: 28.077403,
    lng: -82.444209,
    id: 950,
  },
  {
    name: "LAMOUR, JACQUES",
    address: "13619 SE HIGHWAY 70",
    city: "ARCADIA",
    state: "FL",
    zip: 34266,
    phone: "(863) 491-4800",
    lat: 27.204854,
    lng: -81.656307,
    id: 951,
  },
  {
    name: "LAMPARD, WILLIAM",
    address: "1609 SE PORT ST LUCIE BLVD",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 398-0067",
    lat: 27.271479,
    lng: -80.302221,
    id: 952,
  },
  {
    name: "LANCASTER, STEELE",
    address: "1675 riggins rd",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 656-4800",
    lat: 30.45953,
    lng: -84.23699,
    id: 953,
  },
  {
    name: "LANDAU, IRWIN",
    address: "1022 Main Street , Ste J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.018753,
    lng: -82.776729,
    id: 954,
  },
  {
    name: "LANE, KERRY",
    address: "6680 Tiburon Circle",
    city: "BOCA RATON",
    state: "FL",
    zip: 33433,
    phone: "(561) 706-9581",
    lat: 26.343056,
    lng: -80.148673,
    id: 955,
  },
  {
    name: "LANE, SARA",
    address: "7841 Cambridge Manor Place , Suite C",
    city: "FORT MYERS",
    state: "FL",
    zip: 33907,
    phone: "(239) 908-3593",
    lat: 26.559841,
    lng: -81.88307,
    id: 956,
  },
  {
    name: "LANGLEY, JOHN",
    address: "75 Seaside North Court",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(305) 360-1211",
    lat: 24.561512,
    lng: -81.749655,
    id: 957,
  },
  {
    name: "LAPUZ, LAURO",
    address: "975 Highway 90 , SUITE 5",
    city: "CHIPLEY",
    state: "FL",
    zip: 32428,
    phone: "(850) 373-3051",
    lat: 30.780676,
    lng: -85.516157,
    id: 958,
  },
  {
    name: "LARGE, RANDY",
    address: "3801 E Highway 98",
    city: "PORT SAINT JOE",
    state: "FL",
    zip: 32456,
    phone: "(913) 645-1210",
    lat: 29.779002,
    lng: -85.287825,
    id: 959,
  },
  {
    name: "LASKI, HAROLD",
    address: "3604 SOUTHSIDE BLVD",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 641-4411",
    lat: 30.281287,
    lng: -81.56026,
    id: 960,
  },
  {
    name: "LAUREDAN, BERNIER",
    address: "270 NW PEACORK BLVD , SUITE114",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34986,
    phone: "(772) 600-1000",
    lat: 27.333392,
    lng: -80.397427,
    id: 961,
  },
  {
    name: "LAUTIN, EVERETT",
    address: "3740 SOUTH OCEAN BLVD , SUITE 1804",
    city: "HIGHLAND BEACH",
    state: "FL",
    zip: 33487,
    phone: "(212) 535-0229",
    lat: 26.40872,
    lng: -80.064907,
    id: 962,
  },
  {
    name: "LAUX, GARY",
    address: "900 VILLAGE SQUARE CROSSING , SUITE 170",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 627-8500",
    lat: 26.835675,
    lng: -80.073886,
    id: 963,
  },
  {
    name: "LAWS, FRANK",
    address: "1906 BLAKE AVENUE",
    city: "GLENWOOD SPRINGS",
    state: "CO",
    zip: 81601,
    phone: "(970) 384-7290",
    lat: 39.532375,
    lng: -107.321673,
    id: 964,
  },
  {
    name: "LE, FRANCIS",
    address: "2202 STATE AVE , Suite 207",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(704) 299-9658",
    lat: 30.187991,
    lng: -85.665901,
    id: 965,
  },
  {
    name: "Le, Tho",
    address: "3308 NE 34th Street",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(901) 337-2941",
    lat: 26.169708,
    lng: -80.101268,
    id: 966,
  },
  {
    name: "LEAL, DOMINGO",
    address: "7211 SW 78TH PLACE",
    city: "MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(786) 374-4431",
    lat: 25.702512,
    lng: -80.32109,
    id: 967,
  },
  {
    name: "LEBRON SANCHEZ, JUAN",
    address: "101 East Florida Avenue",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(315) 254-9744",
    lat: 28.055723,
    lng: -80.620105,
    id: 968,
  },
  {
    name: "LEE, BRIAN",
    address: "1855 VETERANS PARK DRIVE , SUITE 102",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 348-4008",
    lat: 26.26985,
    lng: -81.782064,
    id: 969,
  },
  {
    name: "LEE, MARAH",
    address: "5333 N DIXIE HWY , SUITE 110",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33334,
    phone: "(954) 772-8554",
    lat: 26.193409,
    lng: -80.132796,
    id: 970,
  },
  {
    name: "LEFEBVRE, GIGI",
    address: "6600 30TH AVENUE NORTH",
    city: "ST PETERSBURG",
    state: "FL",
    zip: 33710,
    phone: "(727) 381-4463",
    lat: 27.798786,
    lng: -82.728847,
    id: 971,
  },
  {
    name: "LEGASPI SAUTER, ADRIAN",
    address: "7150 WEST 20TH AVE., SUITE 615",
    city: "HIALEAH",
    state: "FL",
    zip: 33016,
    phone: "(305) 820-6657",
    lat: 25.88691,
    lng: -80.324995,
    id: 972,
  },
  {
    name: "LEICHMAN, GERALD",
    address: "7038 Brunswick Circle",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33472,
    phone: "(561) 704-8501",
    lat: 26.544615,
    lng: -80.158553,
    id: 973,
  },
  {
    name: "LEIMBACHER, JEFFREY",
    address: "4743 Central Avenue",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(844) 682-8261",
    lat: 27.771087,
    lng: -82.697551,
    id: 974,
  },
  {
    name: "LEMIRE, JAMES",
    address: "9401 S suite 301 , SUITE 301",
    city: "OCALA",
    state: "FL",
    zip: 34481,
    phone: "(325) 291-9459",
    lat: 29.128062,
    lng: -82.297544,
    id: 975,
  },
  {
    name: "LEMOINE, FRITZ",
    address: "2590 Northbrooke Plaza Dr. , Suite #203",
    city: "NAPLES",
    state: "FL",
    zip: 34119,
    phone: "(239) 325-9470",
    lat: 26.274214,
    lng: -81.739292,
    id: 976,
  },
  {
    name: "LERRO, DESIREE",
    address: "13260 SW 97th place",
    city: "DUNNELLON",
    state: "FL",
    zip: 34432,
    phone: "(216) 285-3603",
    lat: 29.080758,
    lng: -82.340197,
    id: 977,
  },
  {
    name: "LESLIE, FRED",
    address: "10875 PARK BLVD. , SUITE A",
    city: "SEMINOLE",
    state: "FL",
    zip: 33772,
    phone: "(727) 851-9910",
    lat: 27.840149,
    lng: -82.789963,
    id: 978,
  },
  {
    name: "LESSER, MICHAEL",
    address: "1705 BERGLUND LANE., SUITE 101",
    city: "VIERA",
    state: "FL",
    zip: 32940,
    phone: "(321) 722-2860",
    lat: 28.264463,
    lng: -80.71546,
    id: 979,
  },
  {
    name: "LEVIN, ESTHER",
    address: "475 BILTMORE WAY, Suite #401",
    city: "MIAMI",
    state: "FL",
    zip: 33134,
    phone: "(305) 476-5001",
    lat: 25.748659,
    lng: -80.26435,
    id: 980,
  },
  {
    name: "LEVINE, ADAM",
    address: "1201 South Myrtle Avenue",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 812-6140",
    lat: 27.953311,
    lng: -82.795546,
    id: 981,
  },
  {
    name: "LEVINE, BRETT",
    address: "1519 MLK Jr. Street N.",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33704,
    phone: "(727) 314-6472",
    lat: 27.786725,
    lng: -82.646421,
    id: 982,
  },
  {
    name: "LEVINE, HAL",
    address: "200 WEST OAK STREET",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 932-0883",
    lat: 28.3005,
    lng: -81.405706,
    id: 983,
  },
  {
    name: "LEVITT, DAVID",
    address: "16810 US Hwy 441, Suite 502",
    city: "SUMMERFIELD",
    state: "FL",
    zip: 34491,
    phone: "(352) 787-0640",
    lat: 28.976739,
    lng: -81.982893,
    id: 984,
  },
  {
    name: "LEVY, CARLOS",
    address: "2004 N. Flamingo Rd",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33028,
    phone: "(954) 450-8500",
    lat: 26.02636,
    lng: -80.31259,
    id: 985,
  },
  {
    name: "LEWIS, BENNETT",
    address: "2247 Palm Beach Lakes Blvd , Suite 109",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33409,
    phone: "(561) 471-2844",
    lat: 26.710628,
    lng: -80.097275,
    id: 986,
  },
  {
    name: "LEWIS, MICHAEL",
    address: "12679 Seminole Blvd.",
    city: "LARGO",
    state: "FL",
    zip: 33778,
    phone: "(727) 306-4673",
    lat: 27.88843,
    lng: -82.78698,
    id: 987,
  },
  {
    name: "LEWIS-LEVINE, NEIL",
    address: "719 SEMINOLE AVENUE",
    city: "ORLANDO",
    state: "FL",
    zip: 32804,
    phone: "(407) 990-3300",
    lat: 28.553604,
    lng: -81.389751,
    id: 988,
  },
  {
    name: "LIBREROS-CUPIDO, JAIRO",
    address: "36338 US 19 NORTH",
    city: "PALM HARBOR",
    state: "FL",
    zip: 34684,
    phone: "(727) 773-8886",
    lat: 28.102946,
    lng: -82.740937,
    id: 989,
  },
  {
    name: "LICHTINGER, DAVID",
    address: "29320 US HIGHWAY 27",
    city: "LEESBURG",
    state: "FL",
    zip: 34748,
    phone: "(352) 360-0400",
    lat: 28.774789,
    lng: -81.885975,
    id: 990,
  },
  {
    name: "LIEBER, CHARLES",
    address: "6610 N UNIVERSITY DR , STE 120",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 720-6166",
    lat: 26.206048,
    lng: -80.251697,
    id: 991,
  },
  {
    name: "LIEBERMAN, DONALD",
    address: "2645 Executive Park Dr. , STE 127",
    city: "WESTON",
    state: "FL",
    zip: 33331,
    phone: "(561) 504-0025",
    lat: 26.085909,
    lng: -80.374264,
    id: 992,
  },
  {
    name: "LIEBERMAN, MARK",
    address: "6245 NW 104 WAY",
    city: "PARKLAND",
    state: "FL",
    zip: 33076,
    phone: "(954) 817-6048",
    lat: 26.307921,
    lng: -80.266295,
    id: 993,
  },
  {
    name: "LIN, JACK",
    address: "1800 2nd St., Ste 810",
    city: "SARASOTA",
    state: "FL",
    zip: 34236,
    phone: "(941) 483-7740",
    lat: 27.337808,
    lng: -82.533774,
    id: 994,
  },
  {
    name: "Liogier-Weyback, Luis Enrique",
    address: "2984 Hamblin Way",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 406-0685",
    lat: 26.636998,
    lng: -80.191531,
    id: 995,
  },
  {
    name: "LIPINSKI, IRENE",
    address: "2119 W. Brandon Blvd , Suite M",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(727) 523-3700",
    lat: 27.937031,
    lng: -82.320244,
    id: 996,
  },
  {
    name: "LIPMAN, RICHARD",
    address: "7241 S.W. 63RD AVE. , SUITE 202",
    city: "MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 670-3259",
    lat: 25.703592,
    lng: -80.295838,
    id: 997,
  },
  {
    name: "LIPNACK, ERIC",
    address: "8480 West State Road 84",
    city: "DAVIE",
    state: "FL",
    zip: 33324,
    phone: "(954) 577-0177",
    lat: 26.101407,
    lng: -80.261297,
    id: 998,
  },
  {
    name: "LIPORACE, DAVID",
    address: "580 VILLAGE BLVD , SUITE 210",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33409,
    phone: "(561) 688-5030",
    lat: 26.714597,
    lng: -80.097864,
    id: 999,
  },
  {
    name: "LIPSON, BRAD",
    address: "3196 NORTH FEDERAL HIGHWAY",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 338-3999",
    lat: 26.379262,
    lng: -80.075973,
    id: 1000,
  },
  {
    name: "LITTLE, LEW",
    address: "2103 SOUTH MCCALL RD",
    city: "ENGLEWOOD",
    state: "FL",
    zip: 34224,
    phone: "(941) 441-9007",
    lat: 26.935253,
    lng: -82.328503,
    id: 1001,
  },
  {
    name: "LOCH, ELI",
    address: "463386 S.R. 200 , Unit A",
    city: "YULEE",
    state: "FL",
    zip: 32097,
    phone: "(904) 468-3080",
    lat: 30.629261,
    lng: -81.582611,
    id: 1002,
  },
  {
    name: "LOCICERO, KARON",
    address: "2605 W SWANN AVE, Suite 600",
    city: "TAMPA",
    state: "FL",
    zip: 33609,
    phone: "(813) 876-7073",
    lat: 27.93773,
    lng: -82.487501,
    id: 1003,
  },
  {
    name: "LOGAN, BRADLEY",
    address: "22299 State Road 54 , Ste 105",
    city: "LUTZ",
    state: "FL",
    zip: 33549,
    phone: "(813) 333-1516",
    lat: 28.186485,
    lng: -82.448142,
    id: 1004,
  },
  {
    name: "LOGAN, SEAN",
    address: "9090 Park Royal Drive",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(419) 222-6622",
    lat: 26.511721,
    lng: -81.910608,
    id: 1005,
  },
  {
    name: "LOGUIDICE, MICHAEL",
    address: "23110 STATE ROAD 54 , #328",
    city: "LUTZ",
    state: "FL",
    zip: 33549,
    phone: "(727) 808-7123",
    lat: 28.185248,
    lng: -82.434638,
    id: 1006,
  },
  {
    name: "LOMAS, JERILEE",
    address: "1220 BUSINESS WAY , SUITE 2",
    city: "LEHIGH ACRES",
    state: "FL",
    zip: 33936,
    phone: "(239) 303-2600",
    lat: 26.605418,
    lng: -81.651556,
    id: 1007,
  },
  {
    name: "LOPERA, JOHN",
    address: "1501 CORPORATE DRIVE",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33426,
    phone: "(561) 369-4255",
    lat: 26.513653,
    lng: -80.077657,
    id: 1008,
  },
  {
    name: "LOPEZ, JOSE",
    address: "6101 WEBB ROAD , SUITE 209",
    city: "TAMPA",
    state: "FL",
    zip: 33615,
    phone: "(813) 890-8000",
    lat: 28.003672,
    lng: -82.574584,
    id: 1009,
  },
  {
    name: "LOPEZ, JOSEPH",
    address: "1401 R , # 1111",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(305) 915-0020",
    lat: 30.290839,
    lng: -81.631465,
    id: 1010,
  },
  {
    name: "LOPEZ, MANUEL",
    address: "2270 christy lane",
    city: "OLDSMAR",
    state: "FL",
    zip: 34677,
    phone: "(305) 661-6637",
    lat: 28.084152,
    lng: -82.691724,
    id: 1011,
  },
  {
    name: "LOPEZ, RAYMOND",
    address: "1801 NE 123rd Street , Suite 314",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33181,
    phone: "(305) 433-1767",
    lat: 25.89004,
    lng: -80.161928,
    id: 1012,
  },
  {
    name: "LOPEZ-BRIGNONI, EVELYN",
    address: "299 ALHAMBRA CIRCLE, STE 218",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 670-1411",
    lat: 25.75238,
    lng: -80.26047,
    id: 1013,
  },
  {
    name: "LOPEZ-IVERN, FERNANDO",
    address: "9980 CENTRAL PARK BLVD N , SUITE 116A",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 448-1880",
    lat: 26.35455,
    lng: -80.202329,
    id: 1014,
  },
  {
    name: "LOPEZ-ROMERO, JOSE",
    address: "412 Citico St",
    city: "KNOXVILLE",
    state: "TN",
    zip: 39721,
    phone: "(865) 522-0661",
    lat: 35.963316,
    lng: -83.940877,
    id: 1015,
  },
  {
    name: "LORENTE, MIGUEL",
    address: "2107 DAIRY ROAD",
    city: "MELBOURNE",
    state: "FL",
    zip: 32904,
    phone: "(321) 676-7860",
    lat: 28.076227,
    lng: -80.637568,
    id: 1016,
  },
  {
    name: "LOSS, MICHAEL",
    address: "398 Camino Gardens blvd, #102",
    city: "BOCA RATON",
    state: "FL",
    zip: 33432,
    phone: "(561) 392-3341",
    lat: 26.340374,
    lng: -80.093208,
    id: 1017,
  },
  {
    name: "LOVINGER, ROBERT",
    address: "300 Prosperity Farms Road",
    city: "NORTH PALM BEACH",
    state: "FL",
    zip: 33408,
    phone: "(561) 355-5536",
    lat: 26.80809,
    lng: -80.07374,
    id: 1018,
  },
  {
    name: "LOWRY, LISA",
    address: "155 BERMUDA GREENS AVE",
    city: "PONTE VEDRA",
    state: "FL",
    zip: 32081,
    phone: "(843) 338-1548",
    lat: 30.080874,
    lng: -81.413053,
    id: 1019,
  },
  {
    name: "LOZANO, HECTOR",
    address: "689 East Altamonte Drive",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(407) 894-4474",
    lat: 28.664298,
    lng: -81.367425,
    id: 1020,
  },
  {
    name: "LUBIN, BARBARA",
    address: "756 HARRISON AVE",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32401,
    phone: "(850) 785-8246",
    lat: 30.162094,
    lng: -85.659099,
    id: 1021,
  },
  {
    name: "LUCEY, TIMOTHY",
    address: "1865 LIME STREET , SUITE 103",
    city: "FERNANDINA BEACH",
    state: "FL",
    zip: 32034,
    phone: "(904) 277-3311",
    lat: 30.654567,
    lng: -81.448303,
    id: 1022,
  },
  {
    name: "LUCIEN, DANIELLE",
    address: "175 To 175 Toney Penna Drive , suite 201",
    city: "JUPITER",
    state: "FL",
    zip: 33432,
    phone: "(561) 581-6026",
    lat: 26.343902,
    lng: -80.082735,
    id: 1023,
  },
  {
    name: "LUKASIEWICZ, CYNTHIA",
    address: "6120 WINKLER ROAD , SUITE E",
    city: "FORT MYERS",
    state: "FL",
    zip: 33919,
    phone: "(239) 481-2400",
    lat: 26.554515,
    lng: -81.899263,
    id: 1024,
  },
  {
    name: "LYDON, LYNN",
    address: "3662 SW 30th Ave",
    city: "PALM CITY",
    state: "FL",
    zip: 34996,
    phone: "(772) 485-3767",
    lat: 27.160708,
    lng: -80.294668,
    id: 1025,
  },
  {
    name: "LYN-LASSITER, SAFIYA",
    address: "4611 South University Dr , Suite #405",
    city: "DAVIE",
    state: "FL",
    zip: 33328,
    phone: "(305) 487-0902",
    lat: 26.063835,
    lng: -80.253945,
    id: 1026,
  },
  {
    name: "LYNCH, WINDRIK",
    address: "2824-1 Mahan Dr",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 558-1260",
    lat: 30.459742,
    lng: -84.230435,
    id: 1027,
  },
  {
    name: "LYTER, DAVID",
    address: "4302 N. Habana Ave., Suite #200",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 518-0881",
    lat: 27.980317,
    lng: -82.489331,
    id: 1028,
  },
  {
    name: "MAAG, MARTIN",
    address: "5860 Ranch Lake Blvd , Suite 106",
    city: "LAKEWOOD RANCH",
    state: "FL",
    zip: 34202,
    phone: "(941) 739-9001",
    lat: 27.43636,
    lng: -82.455438,
    id: 1029,
  },
  {
    name: "Maarouf, John",
    address: "587 Hartland dr",
    city: "TROY",
    state: "MI",
    zip: 48083,
    phone: "(586) 994-1816",
    lat: 42.565391,
    lng: -83.13609,
    id: 1030,
  },
  {
    name: "MACDOWELL, BRIAN",
    address: "6530 park blvd",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(844) 482-6263",
    lat: 27.838738,
    lng: -82.726862,
    id: 1031,
  },
  {
    name: "MACHADO, HUMBERTO",
    address: "747 PONCE DE LEON BLVD , SUITE#503",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 529-9901",
    lat: 25.76518,
    lng: -80.25825,
    id: 1032,
  },
  {
    name: "MACKAY, JOHN",
    address: "1216 North Monroe Street , Suite 3b",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32303,
    phone: "(850) 877-8171",
    lat: 30.455332,
    lng: -84.281169,
    id: 1033,
  },
  {
    name: "MACKIE, CHRISTINE",
    address: "4304 Del Prado Blvd S",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33904,
    phone: "(239) 540-0800",
    lat: 26.571757,
    lng: -81.807832,
    id: 1034,
  },
  {
    name: "MacMahon, Seth",
    address: "670 Glades Road, Suite 200",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(786) 218-2841",
    lat: 26.364356,
    lng: -80.100641,
    id: 1035,
  },
  {
    name: "MACMILLAN, MICHAEL",
    address: "625 6th Ave S, Suite 455",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 440-5410",
    lat: 27.76425,
    lng: -82.642057,
    id: 1036,
  },
  {
    name: "MAHANEY, EUGENE",
    address: "7964 Summerlin Lakes Drive",
    city: "FORT MYERS",
    state: "FL",
    zip: 33907,
    phone: "(239) 333-1177",
    lat: 26.544798,
    lng: -81.886373,
    id: 1037,
  },
  {
    name: "MAHL, CHARLES",
    address: "2333 PONCE DE LEON BLVD , SUITE 302",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 332-7234",
    lat: 25.750641,
    lng: -80.258669,
    id: 1038,
  },
  {
    name: "MAINOR, HENRY",
    address: "10000 Bay Pines Blvd",
    city: "BAY PINES",
    state: "FL",
    zip: 33744,
    phone: "(727) 398-6661",
    lat: 27.80875,
    lng: -82.775428,
    id: 1039,
  },
  {
    name: "MAIQUEZ, ADONIS",
    address: "1011 Sunnybrook Rd , Suite 901",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(305) 204-7816",
    lat: 25.78631,
    lng: -80.211361,
    id: 1040,
  },
  {
    name: "MAIT, JARRED",
    address: "435 21st Street , Suite 103",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33139,
    phone: "(305) 699-6963",
    lat: 25.797437,
    lng: -80.131307,
    id: 1041,
  },
  {
    name: "MAITLAND, CHARLES",
    address: "1401 CENTERVILLE ROAD, SUITE 510",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 878-3592",
    lat: 30.46037,
    lng: -84.261467,
    id: 1042,
  },
  {
    name: "MAKAR, WASFI",
    address: "415 S. WICKHAM RD",
    city: "WEST MELBOURNE",
    state: "FL",
    zip: 32904,
    phone: "(407) 728-5970",
    lat: 28.100674,
    lng: -80.672038,
    id: 1043,
  },
  {
    name: "MAKKENA, RAMARAO",
    address: "3389 W VINE STREET , SUITE 304",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 932-2799",
    lat: 28.306652,
    lng: -81.436997,
    id: 1044,
  },
  {
    name: "MALANKAR, AMOL",
    address: "7741 NW 7TH ST , APT 214",
    city: "MIAMI",
    state: "FL",
    zip: 33126,
    phone: "(347) 702-2848",
    lat: 25.777475,
    lng: -80.322814,
    id: 1045,
  },
  {
    name: "MALDONADO BURDETTE, EDWIN",
    address: "1049 South St Rd 7 , SUITE 202",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 578-4582",
    lat: 26.662129,
    lng: -80.205235,
    id: 1046,
  },
  {
    name: "MALDONADO-GARCIA, CAROLYN",
    address: "4020 West Hillsboro Blvd",
    city: "DEERFIELD BEACH",
    state: "FL",
    zip: 33442,
    phone: "(855) 226-6633",
    lat: 26.31676,
    lng: -80.159065,
    id: 1047,
  },
  {
    name: "MALHOTRA, POONAM",
    address: "3376 Mariner Blvd",
    city: "SPRING HILL",
    state: "FL",
    zip: 34609,
    phone: "(352) 684-3300",
    lat: 28.484227,
    lng: -82.542853,
    id: 1048,
  },
  {
    name: "MALIK, ALEEM",
    address: "219 SW STANLEY COURT",
    city: "LAKE CITY",
    state: "FL",
    zip: 32034,
    phone: "(386) 292-5413",
    lat: 30.15009,
    lng: -82.691979,
    id: 1049,
  },
  {
    name: "MALIK, FARHAN",
    address: "501 6TH AVE S",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(201) 344-0629",
    lat: 27.764641,
    lng: -82.641003,
    id: 1050,
  },
  {
    name: "MALLONEE, RONALD",
    address: "822 Lake Baldwin Lane",
    city: "ORLANDO",
    state: "FL",
    zip: 32803,
    phone: "(407) 720-1843",
    lat: 28.55581,
    lng: -81.327427,
    id: 1051,
  },
  {
    name: "MALONEY, TIMI",
    address: "7822 9th Ave. S",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33707,
    phone: "(813) 817-4525",
    lat: 27.759135,
    lng: -82.747016,
    id: 1052,
  },
  {
    name: "Manashirov, Ruslan",
    address: "5901 SW 74th Street",
    city: "MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 666-8691",
    lat: 25.702644,
    lng: -80.289806,
    id: 1053,
  },
  {
    name: "MANDELKORN, ROBERT",
    address: "1510 ROYAL PALM SQUARE BLVD., #106",
    city: "FT MYERS",
    state: "FL",
    zip: 33919,
    phone: "(239) 275-3000",
    lat: 26.593801,
    lng: -81.885274,
    id: 1054,
  },
  {
    name: "MANDELL, KENNETH",
    address: "15300 JOG ROAD , SUITE 202",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33446,
    phone: "(561) 455-5601",
    lat: 26.449159,
    lng: -80.146917,
    id: 1055,
  },
  {
    name: "MANEES, CHRISTOPHER",
    address: "280 Dundas Drive",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32218,
    phone: "(512) 537-5982",
    lat: 30.427596,
    lng: -81.648382,
    id: 1056,
  },
  {
    name: "MANELLA, SUSAN",
    address: "500 N HIATUS ROAD , SUITE 201",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33026,
    phone: "(954) 381-8989",
    lat: 26.01219,
    lng: -80.29648,
    id: 1057,
  },
  {
    name: "MANGINANI, SRIDEVI",
    address: "17240 Cortez Blvd",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34601,
    phone: "(815) 757-1200",
    lat: 28.538577,
    lng: -82.44314,
    id: 1058,
  },
  {
    name: "MANN, BRUCE",
    address: "5841 S Congress Avenue",
    city: "ATLANTIS",
    state: "FL",
    zip: 33462,
    phone: "(305) 974-5533",
    lat: 26.589436,
    lng: -80.094698,
    id: 1059,
  },
  {
    name: "MANN, JUSTIN",
    address: "10475 Centurion Pkwy N, Ste 201",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 223-3321",
    lat: 30.248555,
    lng: -81.542518,
    id: 1060,
  },
  {
    name: "MANOHAR, SHONITH",
    address: "8259 BAYBERRY RD",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 737-7246",
    lat: 30.218758,
    lng: -81.581912,
    id: 1061,
  },
  {
    name: "MANOS, GINGER",
    address: "492 N. WILSON STREET",
    city: "CRESTVIEW",
    state: "FL",
    zip: 32536,
    phone: "(850) 398-2548",
    lat: 30.760276,
    lng: -86.572981,
    id: 1062,
  },
  {
    name: "Manresa, Aldo",
    address: "8900 North Kendall Drive",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 496-0532",
    lat: 25.68445,
    lng: -80.338947,
    id: 1063,
  },
  {
    name: "MANSELL, KIMBERLY",
    address: "16750 16750 Persimmon Blvd.",
    city: "LOXAHATCHEE GROVES",
    state: "FL",
    zip: 33470,
    phone: "(561) 791-7200",
    lat: 26.752055,
    lng: -80.306153,
    id: 1064,
  },
  {
    name: "MAQUERA, VICTOR",
    address: "1210 KINGSLEY AVENUE",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(904) 276-1663",
    lat: 30.164987,
    lng: -81.719883,
    id: 1065,
  },
  {
    name: "MARANTE, ALBERTO",
    address: "17105 GULF PINE CIRCLE",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 753-9926",
    lat: 26.674864,
    lng: -80.28723,
    id: 1066,
  },
  {
    name: "Marble, Benjamin",
    address: "859 Winter Street",
    city: "LUCEDALE",
    state: "MS",
    zip: 39452,
    phone: "(601) 508-1478",
    lat: 30.921808,
    lng: -88.5928,
    id: 1067,
  },
  {
    name: "MARCELO, CLAUDIA",
    address: "100 E McNab Rd , Suite B",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33060,
    phone: "(786) 422-1776",
    lat: 26.210755,
    lng: -80.122136,
    id: 1068,
  },
  {
    name: "MARCHETTA, ROSS",
    address: "3636 Yellow Creek Rd",
    city: "AKRON",
    state: "OH",
    zip: 44333,
    phone: "(330) 414-8232",
    lat: 41.156736,
    lng: -81.636155,
    id: 1069,
  },
  {
    name: "MARCOM, RODNEY",
    address: "12276 SAN JOSE BLVD , SUITE 210",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32223,
    phone: "(904) 268-9266",
    lat: 30.147081,
    lng: -81.635804,
    id: 1070,
  },
  {
    name: "MARGOLIN, JERRY",
    address: "7850 Ulmerton Rd, suite 1A",
    city: "LARGO",
    state: "FL",
    zip: 33771,
    phone: "(727) 351-0091",
    lat: 27.893719,
    lng: -82.746915,
    id: 1071,
  },
  {
    name: "MARIC, OLGA",
    address: "2122 NW 62nd Street , Suite 110",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33309,
    phone: "(954) 353-3181",
    lat: 26.201826,
    lng: -80.172232,
    id: 1072,
  },
  {
    name: "MARINO, RALPH",
    address: "6220 S. ORANGE BLOSSOM TRAIL , SUITE 606",
    city: "ORLANDO",
    state: "FL",
    zip: 32809,
    phone: "(386) 492-2914",
    lat: 28.466384,
    lng: -81.397014,
    id: 1073,
  },
  {
    name: "MARISTANY, EDUARDO",
    address: "800 Goodlette-Frank Rd. North , Suite 270",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 649-7400",
    lat: 26.159673,
    lng: -81.786819,
    id: 1074,
  },
  {
    name: "MARKOWITZ, DAVID",
    address: "13971 N. Cleveland Avenue , Suite 17",
    city: "FT. MYERS",
    state: "FL",
    zip: 33903,
    phone: "(888) 908-0143",
    lat: 26.666022,
    lng: -81.890012,
    id: 1075,
  },
  {
    name: "MARKS, MITCHELL",
    address: "3345 BURNS RD , STE 101",
    city: "PALM BCH GDNS",
    state: "FL",
    zip: 33410,
    phone: "(561) 622-2022",
    lat: 26.831511,
    lng: -80.084749,
    id: 1076,
  },
  {
    name: "MARKUS, PETER",
    address: "4144 US Highway 19",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34652,
    phone: "(727) 484-6703",
    lat: 28.219619,
    lng: -82.73531,
    id: 1077,
  },
  {
    name: "MARQUINA, WENDY",
    address: "2701 SW 3 AVE , Suite 101",
    city: "MIAMI",
    state: "FL",
    zip: 33129,
    phone: "(786) 467-2154",
    lat: 25.754388,
    lng: -80.209048,
    id: 1078,
  },
  {
    name: "MARQUIS, DAVID",
    address: "1101 nw 101 st way",
    city: "PLANTATION",
    state: "FL",
    zip: 33322,
    phone: "(954) 258-6715",
    lat: 26.1375,
    lng: -80.286735,
    id: 1079,
  },
  {
    name: "MARRERO, GABRIEL",
    address: "1930 NE 47TH STREET , #300",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 493-5048",
    lat: 26.184896,
    lng: -80.120199,
    id: 1080,
  },
  {
    name: "MARRERO, KAREN",
    address: "1417 N. SEMORAN BLVD. , SUITE 202",
    city: "ORLANDO",
    state: "FL",
    zip: 32807,
    phone: "(407) 601-7748",
    lat: 28.56503,
    lng: -81.310306,
    id: 1081,
  },
  {
    name: "MARS, DONALD",
    address: "9901 San Jose Boulevard",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32257,
    phone: "(888) 908-0143",
    lat: 30.197659,
    lng: -81.620693,
    id: 1082,
  },
  {
    name: "MARSH, MATTHEW",
    address: "14555 CORTEZ BLVD",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 556-4823",
    lat: 28.534495,
    lng: -82.483151,
    id: 1083,
  },
  {
    name: "MARSH, VICKY",
    address: "4101 NW 4th Street, Suite 211",
    city: "PLANTATION",
    state: "FL",
    zip: 33317,
    phone: "(954) 440-0072",
    lat: 26.126958,
    lng: -80.203563,
    id: 1084,
  },
  {
    name: "MARTIN, RALPH",
    address: "3455 Pine Ridge Rd",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 315-9571",
    lat: 26.21176,
    lng: -81.74788,
    id: 1085,
  },
  {
    name: "MARTIN DEL CAMPO, ALONSO",
    address: "1040 Biscayne Blvd 8th floor",
    city: "MIAMI",
    state: "FL",
    zip: 33132,
    phone: "(305) 400-0005",
    lat: 25.784675,
    lng: -80.19018,
    id: 1086,
  },
  {
    name: "MARTINEZ, GLADYS",
    address: "801 MEADOWS RD",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 334-2074",
    lat: 26.359828,
    lng: -80.101721,
    id: 1087,
  },
  {
    name: "MARTINEZ, MICHAEL",
    address: "27160 Bay Landing Dr., Suite 200",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(239) 676-5665",
    lat: 26.346845,
    lng: -81.806528,
    id: 1088,
  },
  {
    name: "MARTINEZ, RICARDO",
    address: "851 5t N. Suite 102",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 315-7801",
    lat: 26.152758,
    lng: -81.797044,
    id: 1089,
  },
  {
    name: "MARTINEZ, ROBERT",
    address: "402 WEST DR. MLK JR. BLVD",
    city: "TAMPA",
    state: "FL",
    zip: 33603,
    phone: "(813) 223-4444",
    lat: 27.981379,
    lng: -82.463694,
    id: 1090,
  },
  {
    name: "MARTINEZ IRIZARRY, AXEL",
    address: "6400 Davis Blvd, Ste 103",
    city: "NAPLES",
    state: "FL",
    zip: 34104,
    phone: "(239) 262-4519",
    lat: 26.13861,
    lng: -81.73611,
    id: 1091,
  },
  {
    name: "MARTINO VILLANUEVA, MARGARITA",
    address: "OAKFIELD DRIVE",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(787) 388-9990",
    lat: 27.93434,
    lng: -82.296083,
    id: 1092,
  },
  {
    name: "MASID, ELBA",
    address: "4513 OLD CANOE CREEK ROAD",
    city: "ST. CLOUD",
    state: "FL",
    zip: 34769,
    phone: "(407) 498-0461",
    lat: 28.249133,
    lng: -81.317012,
    id: 1093,
  },
  {
    name: "MASRI, ADDAM",
    address: "300 S CENTRAL AVE",
    city: "APOPKA",
    state: "FL",
    zip: 32703,
    phone: "(407) 900-2747",
    lat: 28.674093,
    lng: -81.511709,
    id: 1094,
  },
  {
    name: "MASSE, NICOLA",
    address: "10160 Forest Hill Blvd. , Suite 120",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 798-7420",
    lat: 26.65058,
    lng: -80.20525,
    id: 1095,
  },
  {
    name: "MASTAW, GERALD",
    address: "210 S Main Street",
    city: "CRESTVIEW",
    state: "FL",
    zip: 32536,
    phone: "(850) 226-6801",
    lat: 30.756736,
    lng: -86.568302,
    id: 1096,
  },
  {
    name: "MASTERS, CHAD",
    address: "40 SW 10th Street",
    city: "CROSS CITY",
    state: "FL",
    zip: 32628,
    phone: "(904) 316-3906",
    lat: 29.636909,
    lng: -83.142506,
    id: 1097,
  },
  {
    name: "MATEZ, MITCHELL",
    address: "1599 NW 9th Ave , Suite 201",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 953-5490",
    lat: 26.363178,
    lng: -80.105179,
    id: 1098,
  },
  {
    name: "MATHEW, MANOJ",
    address: "4228 53RD AVENUE EAST",
    city: "BRADENTON",
    state: "FL",
    zip: 34203,
    phone: "(941) 251-4010",
    lat: 27.444283,
    lng: -82.507954,
    id: 1099,
  },
  {
    name: "MATRICIA, DANIEL",
    address: "96279 BRADY PT RD",
    city: "FERNANDINA BEACH",
    state: "FL",
    zip: 32034,
    phone: "(904) 321-0088",
    lat: 30.616297,
    lng: -81.505822,
    id: 1100,
  },
  {
    name: "MAURY OCHOA, JOAQUIN",
    address: "8200 8200 SW 117th ave., Suite 416",
    city: "MIAMI",
    state: "FL",
    zip: 33183,
    phone: "(786) 409-7662",
    lat: 25.69191,
    lng: -80.38207,
    id: 1101,
  },
  {
    name: "MAXSON, WAYNE",
    address: "1259 SE MacArthur Blvd",
    city: "STUART",
    state: "FL",
    zip: 34996,
    phone: "(954) 461-5651",
    lat: 27.18953,
    lng: -80.160646,
    id: 1102,
  },
  {
    name: "MAYUGBA, JEMELLE JUNE",
    address: "1901 S. CONGRESS AVE , Suite 420",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33426,
    phone: "(561) 733-6300",
    lat: 26.51136,
    lng: -80.091073,
    id: 1103,
  },
  {
    name: "MAZA, RICHARD",
    address: "3253 MCMULLEN BOOTH RD, STE 200",
    city: "CLEARWATER",
    state: "FL",
    zip: 33761,
    phone: "(727) 725-6170",
    lat: 28.040604,
    lng: -82.708398,
    id: 1104,
  },
  {
    name: "MAZO, JOSSE",
    address: "315 E NASA BLVD",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 733-2711",
    lat: 28.092801,
    lng: -80.616442,
    id: 1105,
  },
  {
    name: "MAZZA, ANGELA",
    address: "30 WINDSORMERE WAY , SUITE 200",
    city: "OVIEDO",
    state: "FL",
    zip: 32765,
    phone: "(407) 542-0661",
    lat: 28.669524,
    lng: -81.215104,
    id: 1106,
  },
  {
    name: "MBAERI, CHINYERE",
    address: "10111 FOREST HILL BLVD , SUITE 320",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 623-0801",
    lat: 26.65087,
    lng: -80.20473,
    id: 1107,
  },
  {
    name: "MCCAIN, JESSICA",
    address: "465 NW Prima Vista Blvd , Ste 204",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34983,
    phone: "(772) 218-7262",
    lat: 27.318416,
    lng: -80.359047,
    id: 1108,
  },
  {
    name: "MCCARTHY, MARISSA",
    address: "13000 BRUCE B. DOWNS BLVD, #117",
    city: "TAMPA",
    state: "FL",
    zip: 33612,
    phone: "(813) 972-2000",
    lat: 28.064564,
    lng: -82.427682,
    id: 1109,
  },
  {
    name: "MCCORMICK, MARK",
    address: "1050 NW 15TH ST, #215-A",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 392-7704",
    lat: 26.363357,
    lng: -80.10805,
    id: 1110,
  },
  {
    name: "MCDONALD, ALISON",
    address: "515 WEKIVA COMMONS CIRCLE",
    city: "APOPKA",
    state: "FL",
    zip: 32712,
    phone: "(407) 464-9516",
    lat: 28.687517,
    lng: -81.505827,
    id: 1111,
  },
  {
    name: "MCGAHARAN, KEVIN",
    address: "10438 Flagstaff Falls Ave.",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33578,
    phone: "(941) 350-7172",
    lat: 27.85189,
    lng: -82.331989,
    id: 1112,
  },
  {
    name: "MCGANN, ROBERT",
    address: "1713 SW HEALTH PARKWAY, #1",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 597-8000",
    lat: 26.271837,
    lng: -81.785445,
    id: 1113,
  },
  {
    name: "MCILWAIN, HARRIS",
    address: "4700 NORTH HABANA AVENUE, Ste 201",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(813) 345-2380",
    lat: 27.985764,
    lng: -82.489289,
    id: 1114,
  },
  {
    name: "MCKENZIE, MICHAEL",
    address: "200 East Hallandale Beach Blvd",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(954) 362-8677",
    lat: 25.985277,
    lng: -80.14651,
    id: 1115,
  },
  {
    name: "McLane, Carole",
    address: "213 e sheridan st , suite 1",
    city: "DANIA BEACH",
    state: "FL",
    zip: 33004,
    phone: "(954) 921-9555",
    lat: 26.033964,
    lng: -80.14031,
    id: 1116,
  },
  {
    name: "MCLOUGHLIN, HUGH",
    address: "2337 SE University Drive",
    city: "DAVIE",
    state: "FL",
    zip: 33324,
    phone: "(954) 423-9234",
    lat: 26.092086,
    lng: -80.25215,
    id: 1117,
  },
  {
    name: "MCSHANE, ROBERT",
    address: "1855 JESS PARRISH CT",
    city: "TITUSVILLE",
    state: "FL",
    zip: 32796,
    phone: "(321) 268-0291",
    lat: 28.626516,
    lng: -80.822909,
    id: 1118,
  },
  {
    name: "MCTAGGART, JOHN",
    address: "2814 West Martin Luther King Blvd",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 898-8009",
    lat: 27.980953,
    lng: -82.490419,
    id: 1119,
  },
  {
    name: "MCTAMMANY, MARK",
    address: "1315 VALENTINE STREET",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 768-7677",
    lat: 28.088527,
    lng: -80.61678,
    id: 1120,
  },
  {
    name: "MEADOWS, WILLIAM",
    address: "5416 Town N Country Blvd",
    city: "TOWN N COUNTRY",
    state: "FL",
    zip: 33615,
    phone: "(813) 549-3800",
    lat: 27.997451,
    lng: -82.569235,
    id: 1121,
  },
  {
    name: "MEDINA, ANITA",
    address: "400 ARTHUR GODFREY ROAD , SUITE 200",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 254-8900",
    lat: 25.813959,
    lng: -80.127598,
    id: 1122,
  },
  {
    name: "MEDINA, NANCY",
    address: "4800 Deerwood Campus Pkwy",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32246,
    phone: "(904) 716-4111",
    lat: 30.25794,
    lng: -81.54439,
    id: 1123,
  },
  {
    name: "MEDINA, SULEYKI",
    address: "8900 N. KENDALL DR",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 596-2000",
    lat: 25.68445,
    lng: -80.338947,
    id: 1124,
  },
  {
    name: "MEDINA SMESTER, JOSE",
    address: "601 Brickell Key Drive , Suite 700",
    city: "MIAMI",
    state: "FL",
    zip: 33131,
    phone: "(786) 477-4292",
    lat: 25.76627,
    lng: -80.185091,
    id: 1125,
  },
  {
    name: "MEDINA VILLANUEVA, NELSON",
    address: "3480 Polynesian Isle Blvd",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34746,
    phone: "(407) 507-2615",
    lat: 28.345101,
    lng: -81.488831,
    id: 1126,
  },
  {
    name: "Meenan, Daniel",
    address: "1086 Franklin St.",
    city: "JOHNSTOWN",
    state: "PA",
    zip: 15905,
    phone: "(814) 534-5876",
    lat: 40.305216,
    lng: -78.919193,
    id: 1127,
  },
  {
    name: "MEETZE, RUSSELL",
    address: "1780 14TH AVENUE",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 567-6460",
    lat: 27.63444,
    lng: -80.399083,
    id: 1128,
  },
  {
    name: "MEHRBERG, ROBERT",
    address: "12700 Creekside Lane, ste 101",
    city: "FORT MYERS",
    state: "FL",
    zip: 33919,
    phone: "(239) 432-0774",
    lat: 26.557324,
    lng: -81.894121,
    id: 1129,
  },
  {
    name: "MEHTA, ASHWIN",
    address: "3341 Johnson street",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(965) 844-9080",
    lat: 26.018532,
    lng: -80.177008,
    id: 1130,
  },
  {
    name: "MEISELMAN, HOWARD",
    address: "4723 West Atlantic Avenue , Suite 11",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33445,
    phone: "(561) 665-6090",
    lat: 26.458833,
    lng: -80.117993,
    id: 1131,
  },
  {
    name: "MEMON, MUHAMMED",
    address: "2852 Tamiami Trail, Unit 5",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 625-9494",
    lat: 26.989125,
    lng: -82.107696,
    id: 1132,
  },
  {
    name: "MENASHEHOFF, SINA",
    address: "660 Glades Road , Suite 460",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 300-1751",
    lat: 26.364217,
    lng: -80.100431,
    id: 1133,
  },
  {
    name: "MENCZELESZ, GABOR",
    address: "3574 US Highway 1 South , Suite 102",
    city: "ST AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(904) 217-7161",
    lat: 29.828139,
    lng: -81.320683,
    id: 1134,
  },
  {
    name: "MENDELOW, GARY",
    address: "2300 N . DIXIE HWY.",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 208-8455",
    lat: 26.371116,
    lng: -80.084043,
    id: 1135,
  },
  {
    name: "MENDEZ, EDUARDO",
    address: "9600 SW 8 ST , SUITE 9",
    city: "MIAMI",
    state: "FL",
    zip: 33174,
    phone: "(786) 953-6415",
    lat: 25.760909,
    lng: -80.351322,
    id: 1136,
  },
  {
    name: "MENDEZ, LUIS",
    address: "6141 Sunset Drive , SUITE 503",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 663-7001",
    lat: 25.704302,
    lng: -80.292822,
    id: 1137,
  },
  {
    name: "MENENDEZ, EUGENIO",
    address: "1600 EAST ATLANTIC BOULEVARD",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33060,
    phone: "(954) 942-2247",
    lat: 26.23142,
    lng: -80.107642,
    id: 1138,
  },
  {
    name: "MENGES, JAMES",
    address: "3229 BEAR RUN BOULEVARD",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32065,
    phone: "(352) 226-6854",
    lat: 30.12339,
    lng: -81.801485,
    id: 1139,
  },
  {
    name: "MERCADO, MICHAEL",
    address: "1002 S DILLARD STREET, SUITE 102",
    city: "WINTER GARDEN",
    state: "FL",
    zip: 34787,
    phone: "(407) 877-3577",
    lat: 28.55254,
    lng: -81.58353,
    id: 1140,
  },
  {
    name: "MERLINO, GARY",
    address: "4308 ALTON ROAD , SUITE 860",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 604-2888",
    lat: 25.815364,
    lng: -80.13773,
    id: 1141,
  },
  {
    name: "Merritt, Lisa",
    address: "625 North Washington Blvd , STE B",
    city: "SARASOTA",
    state: "FL",
    zip: 34236,
    phone: "(941) 225-8198",
    lat: 27.34177,
    lng: -82.531441,
    id: 1142,
  },
  {
    name: "MERSON, MARCIE",
    address: "5130 LINTON BLVD., SUITE C-2",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 499-7020",
    lat: 26.439168,
    lng: -80.123854,
    id: 1143,
  },
  {
    name: "METYK, TETYANA",
    address: "3191 HARBOR BLVD , STE D",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 613-1919",
    lat: 26.982429,
    lng: -82.097529,
    id: 1144,
  },
  {
    name: "MEWAR, SEETAL",
    address: "3450 LANTANA RD.",
    city: "LAKE WORTH",
    state: "FL",
    zip: 33462,
    phone: "(561) 965-1864",
    lat: 26.587685,
    lng: -80.097138,
    id: 1145,
  },
  {
    name: "MIAN, MUNIRA",
    address: "9620 Strike Ln",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(850) 207-0110",
    lat: 26.379554,
    lng: -81.793136,
    id: 1146,
  },
  {
    name: "MICHAEL, PETER",
    address: "8501 Aw 124th Ave , Suite 110",
    city: "MIAMI",
    state: "FL",
    zip: 33183,
    phone: "(305) 799-8466",
    lat: 25.68921,
    lng: -80.39111,
    id: 1147,
  },
  {
    name: "MICHEL, IMMACULA",
    address: "1800 FOREST HILL BLVD , SUITE B-9",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33406,
    phone: "(561) 968-7288",
    lat: 26.65032,
    lng: -80.10778,
    id: 1148,
  },
  {
    name: "MICKEY, JAIMIE",
    address: "10597 US Highway 19 N",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33782,
    phone: "(727) 351-1700",
    lat: 27.868042,
    lng: -82.703474,
    id: 1149,
  },
  {
    name: "MIDDLETON, ROBERT",
    address: "98 Terra Mango Loop , Ste 12",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 381-7360",
    lat: 28.542928,
    lng: -81.504342,
    id: 1150,
  },
  {
    name: "MILEY, JANET",
    address: "863 N Cocoa Blvd",
    city: "COCOA",
    state: "FL",
    zip: 32922,
    phone: "(321) 264-2345",
    lat: 28.369063,
    lng: -80.735416,
    id: 1151,
  },
  {
    name: "MILLARES, AVELINO",
    address: "356 SOUTH INDIANA AVENUE",
    city: "ENGLEWOOD",
    state: "FL",
    zip: 34223,
    phone: "(941) 474-1042",
    lat: 26.955158,
    lng: -82.353441,
    id: 1152,
  },
  {
    name: "MILLER, ADRIENE",
    address: "325 SOUTH DIXIE HIGHWAY , Suite 1",
    city: "LAKE WORTH",
    state: "FL",
    zip: 33460,
    phone: "(561) 568-1272",
    lat: 26.61976,
    lng: -80.052609,
    id: 1153,
  },
  {
    name: "MILLER, JEFFREY",
    address: "3218 W AZEELE ST",
    city: "TAMPA",
    state: "FL",
    zip: 33609,
    phone: "(813) 879-1188",
    lat: 27.940673,
    lng: -82.496885,
    id: 1154,
  },
  {
    name: "MILLION, STEPHANIE",
    address: "229 Loggerhead drive",
    city: "MELBOURNE BEACH",
    state: "FL",
    zip: 32951,
    phone: "(913) 221-9454",
    lat: 27.988686,
    lng: -80.521977,
    id: 1155,
  },
  {
    name: "MILLS, WILLIAM",
    address: "801 MARSHALL FARMS ROAD",
    city: "OCOEE",
    state: "FL",
    zip: 34761,
    phone: "(407) 877-6280",
    lat: 28.556468,
    lng: -81.546243,
    id: 1156,
  },
  {
    name: "MILNE, JAMES",
    address: "5333 NORTH DIXIE HWY , SUITE 204",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33334,
    phone: "(954) 776-7566",
    lat: 26.193409,
    lng: -80.132796,
    id: 1157,
  },
  {
    name: "MILTENBERGER, CHESTER",
    address: "766 N SUN DR , STE 1060",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 322-5923",
    lat: 28.757493,
    lng: -81.352347,
    id: 1158,
  },
  {
    name: "MINSAL-BALLESTER, GERARDO",
    address: "6850 Coral Way , STE 305",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(305) 308-0231",
    lat: 25.747778,
    lng: -80.306479,
    id: 1159,
  },
  {
    name: "MIRABELLI, JORGE",
    address: "570 Memorial Clircle Dr. , Suit 200",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 944-9813",
    lat: 29.283647,
    lng: -81.081083,
    id: 1160,
  },
  {
    name: "MIRANDA, ALTAGRACIA",
    address: "1000 5TH STREET , STE 200",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33139,
    phone: "(305) 305-0310",
    lat: 25.773902,
    lng: -80.138617,
    id: 1161,
  },
  {
    name: "MISHKIN, DAVID",
    address: "1960 NE 47th Street , second floor",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 202-7899",
    lat: 26.184636,
    lng: -80.119599,
    id: 1162,
  },
  {
    name: "MITCHELL, KENNETH",
    address: "910 ROYCE STREET",
    city: "PENSACOLA",
    state: "FL",
    zip: 32503,
    phone: "(850) 444-4997",
    lat: 30.469285,
    lng: -87.21298,
    id: 1163,
  },
  {
    name: "MITCHELL, KIA",
    address: "12086 Fort Caroilne Road , Suite 103",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32225,
    phone: "(904) 747-3800",
    lat: 30.378796,
    lng: -81.509463,
    id: 1164,
  },
  {
    name: "MIXCO, ROBERTO",
    address: "1395 N COURTENAY PRKWY, STE 106",
    city: "MERRITT ISLAND",
    state: "FL",
    zip: 32953,
    phone: "(321) 452-1224",
    lat: 28.382443,
    lng: -80.703241,
    id: 1165,
  },
  {
    name: "MOBIN, ANJUM",
    address: "6601 CENTRAL FLORIDA PARKWAY",
    city: "ORLANDO",
    state: "FL",
    zip: 32821,
    phone: "(407) 370-0111",
    lat: 28.408954,
    lng: -81.469844,
    id: 1166,
  },
  {
    name: "MODEL, DMITRIY",
    address: "1633 RACETRACK ROAD, SUITE 101",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32259,
    phone: "(904) 230-6988",
    lat: 30.122089,
    lng: -81.621434,
    id: 1167,
  },
  {
    name: "Modi, Dixitkumar",
    address: "4350 North Atlantic Avenue",
    city: "COCOA BEACH",
    state: "FL",
    zip: 32931,
    phone: "(334) 327-9530",
    lat: 28.358712,
    lng: -80.607346,
    id: 1168,
  },
  {
    name: "MOENNING, STEPHEN",
    address: "4001 SW 13th St , apt B",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32608,
    phone: "(239) 314-4681",
    lat: 29.616337,
    lng: -82.339586,
    id: 1169,
  },
  {
    name: "MOHAMMED, SHEILA",
    address: "4507 Furling Lane, Suite 213",
    city: "DESTIN",
    state: "FL",
    zip: 32571,
    phone: "(850) 281-8186",
    lat: 30.387442,
    lng: -86.403574,
    id: 1170,
  },
  {
    name: "MOHSENI, NATHAN",
    address: "200 SE Hospital Ave",
    city: "STUART",
    state: "FL",
    zip: 34995,
    phone: "(484) 919-1297",
    lat: 27.199559,
    lng: -80.243015,
    id: 1171,
  },
  {
    name: "MOISE, GUY",
    address: "650 NW 120TH ST",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33168,
    phone: "(305) 688-4178",
    lat: 25.884423,
    lng: -80.209603,
    id: 1172,
  },
  {
    name: "MOISE-JOHNSON, DAPHNEE",
    address: "8500 East SR 70",
    city: "BRADENTON",
    state: "FL",
    zip: 34202,
    phone: "(941) 242-6532",
    lat: 27.43856,
    lng: -82.453275,
    id: 1173,
  },
  {
    name: "MONASTERO, JOSEPH",
    address: "123 se panama city beach parkway",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32413,
    phone: "(888) 908-0143",
    lat: 30.241148,
    lng: -85.913849,
    id: 1174,
  },
  {
    name: "MONDELLO, CHRISTOPHER",
    address: "28149 U.S. HWY 27",
    city: "DUNDEE",
    state: "FL",
    zip: 33838,
    phone: "(863) 242-0808",
    lat: 28.017789,
    lng: -81.631548,
    id: 1175,
  },
  {
    name: "MONROE, KAREN",
    address: "F 401 33rd St N",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 323-1090",
    lat: 27.789403,
    lng: -82.677321,
    id: 1176,
  },
  {
    name: "MONSON, ANTONIO",
    address: "16 Paradise Lane, Suite 102",
    city: "TREASURE ISLAND",
    state: "FL",
    zip: 33707,
    phone: "(727) 388-9774",
    lat: 27.77098,
    lng: -82.75795,
    id: 1177,
  },
  {
    name: "MONTALVAN, JORGE",
    address: "3848 FAU BOULEVARD , SUITE 210",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 394-3088",
    lat: 26.383926,
    lng: -80.097111,
    id: 1178,
  },
  {
    name: "MOORE, BRENDA",
    address: "1003 W COLLEGE BLVD , SUITE #2",
    city: "NICEVILLE",
    state: "FL",
    zip: 32578,
    phone: "(850) 678-0443",
    lat: 30.53421,
    lng: -86.44267,
    id: 1179,
  },
  {
    name: "MOORE, CELINA",
    address: "9970 CENTRAL PARK BLVD , SUITE 203",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 487-1616",
    lat: 26.355815,
    lng: -80.202215,
    id: 1180,
  },
  {
    name: "MOORE, JAMES",
    address: "36. South. 5 th st",
    city: "HAINES CITY",
    state: "FL",
    zip: 33844,
    phone: "(321) 624-4307",
    lat: 28.105424,
    lng: -81.627623,
    id: 1181,
  },
  {
    name: "MOORE, JEFFREY",
    address: "4915 Ehrlich Rd",
    city: "TAMPA",
    state: "FL",
    zip: 33624,
    phone: "(813) 591-4570",
    lat: 28.085034,
    lng: -82.530829,
    id: 1182,
  },
  {
    name: "Moore, Joshua",
    address: "4651 Sheridan St , Suite 350",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 276-8559",
    lat: 26.033186,
    lng: -80.19163,
    id: 1183,
  },
  {
    name: "MOORE, MARK",
    address: "1849 Capital Medical Ct",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 222-2222",
    lat: 30.475677,
    lng: -84.228045,
    id: 1184,
  },
  {
    name: "MOOSA, MOHAMMED",
    address: "2869 WILSHIRE DRIVE, SUITE 205",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 295-0500",
    lat: 28.512396,
    lng: -81.463088,
    id: 1185,
  },
  {
    name: "MOPE, MELODIE",
    address: "15820 Shaddock Drive, Suite 130",
    city: "WINTER GARDEN",
    state: "FL",
    zip: 34787,
    phone: "(407) 558-8211",
    lat: 28.460197,
    lng: -81.631911,
    id: 1186,
  },
  {
    name: "MORAES, BRIAN",
    address: "9325 GLADES RD. , SUITE 107",
    city: "BOCA RATON",
    state: "FL",
    zip: 33434,
    phone: "(561) 477-3285",
    lat: 26.368004,
    lng: -80.191502,
    id: 1187,
  },
  {
    name: "MORALES, ALICE",
    address: "35209 Highway 19 North",
    city: "PALM HARBOR",
    state: "FL",
    zip: 34684,
    phone: "(727) 734-5276",
    lat: 28.092534,
    lng: -82.738019,
    id: 1188,
  },
  {
    name: "MORALES COLON, MARICELIS",
    address: "904 W Magnolia St",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 593-2816",
    lat: 28.301598,
    lng: -81.412777,
    id: 1189,
  },
  {
    name: "MORELLI, JOSEPH",
    address: "1501 COPA D ORO",
    city: "MARATHON",
    state: "FL",
    zip: 33050,
    phone: "(216) 278-2631",
    lat: 24.703258,
    lng: -81.086803,
    id: 1190,
  },
  {
    name: "MORENO, DAVID",
    address: "12142 CORTEZ BLVD.",
    city: "BROOKSVILLE",
    state: "FL",
    zip: 34613,
    phone: "(352) 596-9095",
    lat: 28.532648,
    lng: -82.526033,
    id: 1191,
  },
  {
    name: "MORENO RIVERO, KEILER",
    address: "2940 mallory circle , suite 202",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34747,
    phone: "(407) 269-8554",
    lat: 28.334615,
    lng: -81.528186,
    id: 1192,
  },
  {
    name: "MORGAN, ANNE",
    address: "600 Sandtree Dr, , Suite 108",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33403,
    phone: "(561) 631-7994",
    lat: 26.80215,
    lng: -80.09743,
    id: 1193,
  },
  {
    name: "MORGAN, JEFFREY",
    address: "6245 N. Federal Hwy 406 , STE 406",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(844) 536-2663",
    lat: 26.205021,
    lng: -80.108902,
    id: 1194,
  },
  {
    name: "MORGAN, MELISSA",
    address: "600 Cleveland St, Ste 392",
    city: "CLEARWATER",
    state: "FL",
    zip: 33755,
    phone: "(305) 905-2539",
    lat: 27.966026,
    lng: -82.798449,
    id: 1195,
  },
  {
    name: "MORGAN, TRESCIANA",
    address: "1500 North University Drive , SUITE 202",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33071,
    phone: "(561) 609-2365",
    lat: 26.249723,
    lng: -80.254193,
    id: 1196,
  },
  {
    name: "MORRIS, DARELD",
    address: "6800 PORTO FINO CIRCLE",
    city: "FT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(239) 418-0775",
    lat: 26.539585,
    lng: -81.84271,
    id: 1197,
  },
  {
    name: "MORROW, R",
    address: "3841 tamiami trail, suite b",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 888-3232",
    lat: 26.972529,
    lng: -82.08696,
    id: 1198,
  },
  {
    name: "MORTON, PATRICK",
    address: "800 SE 4th Ave , Ste 505",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(305) 932-9111",
    lat: 25.976863,
    lng: -80.144324,
    id: 1199,
  },
  {
    name: "MOSKOWITZ, KIMBERLY",
    address: "651 GRAND PANAMA BLVD , SUITE 102",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32407,
    phone: "(850) 233-0264",
    lat: 30.19078,
    lng: -85.823271,
    id: 1200,
  },
  {
    name: "MOSTOVYCH, MARK",
    address: "1824 KING STREET , SUITE 200",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32204,
    phone: "(904) 384-3343",
    lat: 30.306957,
    lng: -81.691434,
    id: 1201,
  },
  {
    name: "MOULAVI, SASSON",
    address: "3196 NORTH FEDERAL HIGHWAY",
    city: "BOCA RATON",
    state: "FL",
    zip: 33432,
    phone: "(561) 338-3999",
    lat: 26.379262,
    lng: -80.075973,
    id: 1202,
  },
  {
    name: "MOULTON, MARLENE",
    address: "13421 PARKER COMMONS BLVD, STE 101",
    city: "FT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(239) 985-2600",
    lat: 26.549919,
    lng: -81.821605,
    id: 1203,
  },
  {
    name: "MUELLER, JEFFREY",
    address: "1201 LOUISIANA AVE., STE E",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 644-2990",
    lat: 28.609262,
    lng: -81.362745,
    id: 1204,
  },
  {
    name: "MULHERN, PETER",
    address: "4113 S. Water Tower Place",
    city: "MOUNT VERNON",
    state: "IL",
    zip: 62864,
    phone: "(618) 244-9038",
    lat: 38.305003,
    lng: -88.941846,
    id: 1205,
  },
  {
    name: "Munoz, Jose",
    address: "21355 E Dixie Hwy, #109",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 931-9316",
    lat: 25.972875,
    lng: -80.146789,
    id: 1206,
  },
  {
    name: "Murphy, Derek",
    address: "1240 N University Dr",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33071,
    phone: "(954) 970-9355",
    lat: 26.141619,
    lng: -80.256378,
    id: 1207,
  },
  {
    name: "MURPHY, FRANK",
    address: "99B S. Alcaniz st.",
    city: "PENSACOLA",
    state: "FL",
    zip: 32502,
    phone: "(850) 437-0035",
    lat: 30.412466,
    lng: -87.210312,
    id: 1208,
  },
  {
    name: "MURPHY, JOHN",
    address: "921 SE Ocean Blvd , Suite #1",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 888-1000",
    lat: 27.197932,
    lng: -80.237433,
    id: 1209,
  },
  {
    name: "MURRAY, MICHAEL",
    address: "1550 pasadena ave",
    city: "GULFPORT",
    state: "FL",
    zip: 33707,
    phone: "(727) 575-7757",
    lat: 27.752072,
    lng: -82.738274,
    id: 1210,
  },
  {
    name: "MUSOFF, ROY",
    address: "14530 SOUTH MILITARY TRAIL , suite A1-A5",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 381-0260",
    lat: 26.461015,
    lng: -80.122049,
    id: 1211,
  },
  {
    name: "NABIZADEH, SHAHRIAR",
    address: "3599 UNIVERSITY BLVD SOUTH , SUITE 507",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 565-1001",
    lat: 30.28907,
    lng: -81.60227,
    id: 1212,
  },
  {
    name: "NAGALAPADI, VENKATESH",
    address: "985 STATE ROAD 436",
    city: "CASSELBERRY",
    state: "FL",
    zip: 32707,
    phone: "(407) 831-5252",
    lat: 28.64578,
    lng: -81.32262,
    id: 1213,
  },
  {
    name: "NAGLER, JOEL",
    address: "698 sw port st lucie blvd , SUITE 106",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34953,
    phone: "(772) 353-5222",
    lat: 27.26829,
    lng: -80.367763,
    id: 1214,
  },
  {
    name: "NAIR, SANTOSH",
    address: "805 North Spring Garden Ave",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(386) 774-1223",
    lat: 29.040827,
    lng: -81.324129,
    id: 1215,
  },
  {
    name: "NANKO, RAYMOND",
    address: "919 WEST JACKSON STREET",
    city: "MUNCIE",
    state: "IN",
    zip: 47305,
    phone: "(768) 288-3276",
    lat: 40.193122,
    lng: -85.395588,
    id: 1216,
  },
  {
    name: "narendran, vijay",
    address: "1630 S Congress Ave, Ste 200",
    city: "PALM SPRINGS",
    state: "FL",
    zip: 33461,
    phone: "(561) 253-3980",
    lat: 26.63646,
    lng: -80.08827,
    id: 1217,
  },
  {
    name: "NARVAEZ, ADOLFO",
    address: "5591 CORTEZ ROAD WEST",
    city: "BRADENTON",
    state: "FL",
    zip: 34210,
    phone: "(941) 795-4206",
    lat: 27.462218,
    lng: -82.617974,
    id: 1218,
  },
  {
    name: "NASSIF, JOHN",
    address: "2350 VANDERBILT BEACH ROAD 101 , SUITE 101",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 494-4441",
    lat: 26.242479,
    lng: -81.771721,
    id: 1219,
  },
  {
    name: "NASTA, ANIL",
    address: "520 SE 5th Ave, Apt 3302",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33301,
    phone: "(954) 218-2112",
    lat: 26.115791,
    lng: -80.137847,
    id: 1220,
  },
  {
    name: "NAVARRO, FELIX",
    address: "1403 MEDICAL PLAZA DR., #206",
    city: "SANFORD",
    state: "FL",
    zip: 32771,
    phone: "(407) 322-9530",
    lat: 28.812619,
    lng: -81.28395,
    id: 1221,
  },
  {
    name: "NAVARRO, JASON",
    address: "7800 SHERIDAN STREET",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33024,
    phone: "(954) 663-6271",
    lat: 26.029256,
    lng: -80.246278,
    id: 1222,
  },
  {
    name: "Navarro, Ricardo",
    address: "1137 Druid Circle",
    city: "LAKE WALES",
    state: "FL",
    zip: 33853,
    phone: "(863) 949-6541",
    lat: 27.8972,
    lng: -81.562693,
    id: 1223,
  },
  {
    name: "NAZIR, KHURRAM",
    address: "2202 State Ave, St 201",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 785-0029",
    lat: 30.187461,
    lng: -85.666156,
    id: 1224,
  },
  {
    name: "NEUMAN, CAROLE",
    address: "200 Settlers Row N",
    city: "PONTE VEDRA BEACH",
    state: "FL",
    zip: 32082,
    phone: "(904) 626-5966",
    lat: 30.168652,
    lng: -81.37547,
    id: 1225,
  },
  {
    name: "NEWMAN, BENJAMIN",
    address: "480 ALLISON AVE",
    city: "LONGWOOD",
    state: "FL",
    zip: 32750,
    phone: "(207) 664-8444",
    lat: 28.686266,
    lng: -81.369863,
    id: 1226,
  },
  {
    name: "Newstead, Suzanne",
    address: "2481 SAN PIETRO CIRCLE",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(516) 314-8992",
    lat: 26.849828,
    lng: -80.0728,
    id: 1227,
  },
  {
    name: "NEWTON, TEREL",
    address: "10175 FORTUNE PARKWAY , UNIT 803",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(727) 219-0772",
    lat: 30.185464,
    lng: -81.54758,
    id: 1228,
  },
  {
    name: "NGUYEN, HANSON",
    address: "312 Gerry CT",
    city: "SAINT CLOUD",
    state: "FL",
    zip: 34771,
    phone: "(407) 749-8877",
    lat: 28.292472,
    lng: -81.204262,
    id: 1229,
  },
  {
    name: "NGUYEN, VU",
    address: "7727 LAKE UNDERHILL",
    city: "ORLANDO",
    state: "FL",
    zip: 32822,
    phone: "(407) 303-6413",
    lat: 28.539836,
    lng: -81.281512,
    id: 1230,
  },
  {
    name: "NICKOLAKIS, IRENE",
    address: "1501 SOUTH PINELLAS AVENUE, SUITE K",
    city: "TARPON SPRINGS",
    state: "FL",
    zip: 34689,
    phone: "(727) 938-0714",
    lat: 28.130771,
    lng: -82.758385,
    id: 1231,
  },
  {
    name: "NIELSEN, RICHARD",
    address: "6550 N. Wickham Rd",
    city: "MELBOURNE",
    state: "FL",
    zip: 32940,
    phone: "(321) 600-4457",
    lat: 28.218181,
    lng: -80.675531,
    id: 1232,
  },
  {
    name: "NIMBARGI, STEPHEN",
    address: "321 MAITLAND AVENUE , STE 1000",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(407) 331-6236",
    lat: 28.657742,
    lng: -81.365559,
    id: 1233,
  },
  {
    name: "Nissel, Chaim",
    address: "7481 west oakland park blvd , suite 100",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33319,
    phone: "(786) 390-6406",
    lat: 26.166942,
    lng: -80.247501,
    id: 1234,
  },
  {
    name: "NOBLE, ANJALI",
    address: "2499 GLADES ROAD , #103",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 392-3788",
    lat: 26.36882,
    lng: -80.1362,
    id: 1235,
  },
  {
    name: "NOBO, RAFAEL",
    address: "222 WEST MAIN STREET , SUITE B",
    city: "BARTOW",
    state: "FL",
    zip: 33830,
    phone: "(863) 533-8944",
    lat: 27.896556,
    lng: -81.844556,
    id: 1236,
  },
  {
    name: "NOE, SASHA",
    address: "6150 NORTH US HIGHWAY 41",
    city: "APOLLO BEACH",
    state: "FL",
    zip: 33572,
    phone: "(813) 641-0007",
    lat: 27.767057,
    lng: -82.395064,
    id: 1237,
  },
  {
    name: "NORMAN, ROBERT",
    address: "8002 GUNN HWY",
    city: "TAMPA",
    state: "FL",
    zip: 33626,
    phone: "(813) 880-7546",
    lat: 28.079206,
    lng: -82.57837,
    id: 1238,
  },
  {
    name: "NORRIS, STEVEN",
    address: "3978 STATE RD 64 E",
    city: "BRADENTON",
    state: "FL",
    zip: 34208,
    phone: "(941) 761-9797",
    lat: 27.4978,
    lng: -82.512726,
    id: 1239,
  },
  {
    name: "NOTMAN, SAMANTHA",
    address: "8614 East State Road 70 , Suite 200",
    city: "BRADENTON",
    state: "FL",
    zip: 34202,
    phone: "(941) 727-1243",
    lat: 27.437306,
    lng: -82.453166,
    id: 1240,
  },
  {
    name: "Novince, Ryan",
    address: "2770 Capital Medical Blvd , Suite 200",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(850) 878-8235",
    lat: 30.476422,
    lng: -84.231068,
    id: 1241,
  },
  {
    name: "NOVIS, NORMAN",
    address: "801 HIGHWAY 466 , SUITE B101",
    city: "LADY LAKE",
    state: "FL",
    zip: 32159,
    phone: "(352) 633-7659",
    lat: 28.916898,
    lng: -81.939028,
    id: 1242,
  },
  {
    name: "NOVITZKY, DIMITRI",
    address: "2123 W Dr.Martin Luther King Jr Blv , #103",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 675-4849",
    lat: 27.981536,
    lng: -82.481484,
    id: 1243,
  },
  {
    name: "NURIEL, GABRIEL",
    address: "7620 Lake Underhill Rd",
    city: "ORLANDO",
    state: "FL",
    zip: 32822,
    phone: "(321) 235-0692",
    lat: 28.538825,
    lng: -81.283646,
    id: 1244,
  },
  {
    name: "NUSSBAUM, CHRIS",
    address: "510 VONDERBURG DRIVE, SUITE 213",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 689-2961",
    lat: 27.930681,
    lng: -82.288993,
    id: 1245,
  },
  {
    name: "NUTINSKY, CHARLES",
    address: "1022 1022 Main St , Suite J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.018753,
    lng: -82.776729,
    id: 1246,
  },
  {
    name: "O'BRIEN, MAUREEN",
    address: "96279 BRADYPOINT RD",
    city: "FERNADINA BEACH",
    state: "FL",
    zip: 32034,
    phone: "(904) 321-0088",
    lat: 30.609692,
    lng: -81.49172,
    id: 1247,
  },
  {
    name: "O'DONNELL, CASEY",
    address: "1434 Jam Lane",
    city: "ODESSA",
    state: "FL",
    zip: 33556,
    phone: "(888) 385-4555",
    lat: 28.17956,
    lng: -82.552729,
    id: 1248,
  },
  {
    name: "O'DONNELL, KIRK",
    address: "4008 W. Neptune St.",
    city: "TAMPA",
    state: "FL",
    zip: 33629,
    phone: "(314) 452-3780",
    lat: 27.930041,
    lng: -82.511211,
    id: 1249,
  },
  {
    name: "Obid, Samer",
    address: "4300 Alton Road",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 674-2273",
    lat: 25.815456,
    lng: -80.138213,
    id: 1250,
  },
  {
    name: "ODELL, ROBERT",
    address: "4002 NORTH OCEAN BOULEVARD",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 563-6784",
    lat: 26.175813,
    lng: -80.098878,
    id: 1251,
  },
  {
    name: "ODOCHA, INNOCENT",
    address: "605 NE 1st Street",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32601,
    phone: "(352) 371-3212",
    lat: 29.657106,
    lng: -82.32353,
    id: 1252,
  },
  {
    name: "OFFENBERG, HOWARD",
    address: "40 S. W 12TH STREET , SUITE C101",
    city: "OCALA",
    state: "FL",
    zip: 34474,
    phone: "(386) 677-5220",
    lat: 29.176748,
    lng: -82.137669,
    id: 1253,
  },
  {
    name: "OFNER, MICHELE",
    address: "1155 35TH LANE, STE 100",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 569-2330",
    lat: 27.65615,
    lng: -80.396512,
    id: 1254,
  },
  {
    name: "OKIE, ALLEN",
    address: "2345 PARK STREET",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32204,
    phone: "(904) 465-3899",
    lat: 30.312638,
    lng: -81.687891,
    id: 1255,
  },
  {
    name: "OKOLO, PETER",
    address: "10101 FOREST HILL BLVD",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 798-8543",
    lat: 26.651804,
    lng: -80.204957,
    id: 1256,
  },
  {
    name: "OKOYE, GODWIN",
    address: "131 NORTH MOON AVENUE , SUITES 3 and 4",
    city: "BRANDON",
    state: "FL",
    zip: 33510,
    phone: "(813) 315-9898",
    lat: 27.939345,
    lng: -82.287982,
    id: 1257,
  },
  {
    name: "OLENEK, CHRISTOPHER",
    address: "927 37TH PLACE",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 770-6225",
    lat: 27.660793,
    lng: -80.391146,
    id: 1258,
  },
  {
    name: "OLIVEIRA, LIDIA",
    address: "917 Rinehart Road , suite 2001",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 248-9003",
    lat: 28.783867,
    lng: -81.346404,
    id: 1259,
  },
  {
    name: "OLIVER, CORLISS",
    address: "1456 E MICHIGAN STREET",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 420-7691",
    lat: 28.51276,
    lng: -81.361328,
    id: 1260,
  },
  {
    name: "OLIVEROS, PEDRO",
    address: "341 N MAITLAND AVE, STE 200",
    city: "MAITLAND",
    state: "FL",
    zip: 32751,
    phone: "(407) 265-2100",
    lat: 28.630363,
    lng: -81.366692,
    id: 1261,
  },
  {
    name: "Olivieri, Brandon",
    address: "4300 Alton Road",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 674-2071",
    lat: 25.815456,
    lng: -80.138213,
    id: 1262,
  },
  {
    name: "Olszewska, Paulina",
    address: "6800 Gulfport BLVD S , Suite 201-115",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33707,
    phone: "(888) 908-0143",
    lat: 27.758512,
    lng: -82.73461,
    id: 1263,
  },
  {
    name: "OLUSEGUN, TINUADE",
    address: "140 S. Beach St , SUITE 402",
    city: "DAYTONA BEACH",
    state: "FL",
    zip: 32114,
    phone: "(855) 657-6800",
    lat: 29.211063,
    lng: -81.019188,
    id: 1264,
  },
  {
    name: "ONYEMA, JUDEPATRICKS",
    address: "955 Town Center Drive, # 200",
    city: "ORANGE CITY",
    state: "FL",
    zip: 32763,
    phone: "(386) 218-4016",
    lat: 28.908128,
    lng: -81.288131,
    id: 1265,
  },
  {
    name: "OON, SU-MIN",
    address: "6500 NEWBERRY ROAD",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32605,
    phone: "(352) 333-4035",
    lat: 29.66084,
    lng: -82.411772,
    id: 1266,
  },
  {
    name: "OPHORI, OGHENEVWOGAGA",
    address: "1615 Miami Rd",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33316,
    phone: "(305) 619-4248",
    lat: 26.101709,
    lng: -80.13442,
    id: 1267,
  },
  {
    name: "Oppenheimer, Joshua",
    address: "4645 4645 North Bay Road",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(347) 306-4295",
    lat: 25.822479,
    lng: -80.136383,
    id: 1268,
  },
  {
    name: "OQUENDO ROMAN, GUALBERTO",
    address: "720 ALMOND ST",
    city: "CLERMONT",
    state: "FL",
    zip: 34711,
    phone: "(352) 404-6959",
    lat: 28.549459,
    lng: -81.745253,
    id: 1269,
  },
  {
    name: "ORBEZO, ANED",
    address: "2020 CARE 4 ALL, 2020 FLAMINGO DRIVE",
    city: "BARTOW",
    state: "FL",
    zip: 33830,
    phone: "(863) 514-3624",
    lat: 27.918445,
    lng: -81.790794,
    id: 1270,
  },
  {
    name: "ORNA, RENATA",
    address: "6735 Conroy Road , Unit 422",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 745-4009",
    lat: 28.49419,
    lng: -81.474385,
    id: 1271,
  },
  {
    name: "ORTIZ MIRANDA, MELISSA",
    address: "431 West Oak Street",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 845-0330",
    lat: 28.301516,
    lng: -81.408502,
    id: 1272,
  },
  {
    name: "OWENS, DANA",
    address: "2501 N. Orange Ave , Suite 513",
    city: "ORLANDO",
    state: "FL",
    zip: 32804,
    phone: "(407) 303-0410",
    lat: 28.57489,
    lng: -81.37198,
    id: 1273,
  },
  {
    name: "PABLOS, ORESTES",
    address: "8356 SW 40 ST",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(305) 662-2815",
    lat: 25.731781,
    lng: -80.330888,
    id: 1274,
  },
  {
    name: "PACOLD, VIVIEN",
    address: "68100 RAMON RD, SUITE C5",
    city: "CATHEDRAL CITY",
    state: "CA",
    zip: 92234,
    phone: "(760) 321-6068",
    lat: 33.816827,
    lng: -116.474257,
    id: 1275,
  },
  {
    name: "PADRO, MARIELA",
    address: "2550 S Douglas Rd, , Suite 301",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 456-1014",
    lat: 25.748467,
    lng: -80.254707,
    id: 1276,
  },
  {
    name: "PADRON, JOHN",
    address: "1865 NE 163ST",
    city: "NORTH MIAMI BEACH",
    state: "FL",
    zip: 33162,
    phone: "(305) 948-9958",
    lat: 25.926032,
    lng: -80.162765,
    id: 1277,
  },
  {
    name: "PALANCA, EDUARDO",
    address: "2719 STATE ROAD 580",
    city: "CLEARWATER",
    state: "FL",
    zip: 33761,
    phone: "(727) 724-8777",
    lat: 28.020082,
    lng: -82.712014,
    id: 1278,
  },
  {
    name: "PALESTRANT, KENNETH",
    address: "1680 SE Lyngate Ave , Suite # 204",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 222-5411",
    lat: 27.285852,
    lng: -80.299072,
    id: 1279,
  },
  {
    name: "PALMA, ALEXANDER",
    address: "1613 N.HARRISON PARKWAY , SUITE 200",
    city: "SUNRISE",
    state: "FL",
    zip: 33323,
    phone: "(954) 240-2572",
    lat: 26.143986,
    lng: -80.331946,
    id: 1280,
  },
  {
    name: "PALOSKY, ERIC",
    address: "4511 SUN N LAKE BLVD , SUITE 108",
    city: "SEBRING",
    state: "FL",
    zip: 33872,
    phone: "(863) 385-1777",
    lat: 27.53537,
    lng: -81.51063,
    id: 1281,
  },
  {
    name: "Pandya , Kaylesh",
    address: "261 261 north university Drive , Suite 500-52",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 507-7505",
    lat: 26.123416,
    lng: -80.257124,
    id: 1282,
  },
  {
    name: "PANGANIBAN, RUDOLFO",
    address: "5413 GEORGE STREET , Suite 1",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34652,
    phone: "(727) 846-7618",
    lat: 28.237866,
    lng: -82.719893,
    id: 1283,
  },
  {
    name: "PANJIKARAN, ROSAMMA",
    address: "350 MARY STREET , STE G",
    city: "PUNTA GORDA",
    state: "FL",
    zip: 33950,
    phone: "(941) 505-0604",
    lat: 26.938011,
    lng: -82.041513,
    id: 1284,
  },
  {
    name: "PAONE, DOUGLAS",
    address: "671 GOODLETTE ROAD NORTH, SUITE 200",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 263-2808",
    lat: 26.156023,
    lng: -81.791562,
    id: 1285,
  },
  {
    name: "PAPPACHEN, STEPHEN",
    address: "1241 Vista Hills Drive",
    city: "LAKELAND",
    state: "FL",
    zip: 33813,
    phone: "(863) 255-6092",
    lat: 27.931714,
    lng: -81.942184,
    id: 1286,
  },
  {
    name: "PARADIS, KRISTYNA",
    address: "50 Spr Spring Vista Drive",
    city: "DEBARY",
    state: "FL",
    zip: 32713,
    phone: "(386) 279-3087",
    lat: 28.865268,
    lng: -81.32951,
    id: 1287,
  },
  {
    name: "PAREKH, MANISH",
    address: "615 E ALEXANDER STREET",
    city: "PLANT CITY",
    state: "FL",
    zip: 33563,
    phone: "(813) 719-2500",
    lat: 27.988327,
    lng: -82.11835,
    id: 1288,
  },
  {
    name: "Pares, Jose",
    address: "3655 Innovation Drive",
    city: "LAKELAND",
    state: "FL",
    zip: 33812,
    phone: "(863) 619-5999",
    lat: 27.979203,
    lng: -81.890543,
    id: 1289,
  },
  {
    name: "PARIKH, NAVAL",
    address: "1 West Sample Rd , Suite 201",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33064,
    phone: "(954) 782-3170",
    lat: 26.276232,
    lng: -80.122198,
    id: 1290,
  },
  {
    name: "PARILO, KARINA",
    address: "2615 Windguard Circle , Suite 101",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33544,
    phone: "(813) 536-0050",
    lat: 28.194455,
    lng: -82.355053,
    id: 1291,
  },
  {
    name: "PARIS, MARK",
    address: "100 EAST LINTON BLVD , SUITE 503A",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33483,
    phone: "(305) 318-7585",
    lat: 26.439276,
    lng: -80.073056,
    id: 1292,
  },
  {
    name: "PARMAR, RAVIINDER",
    address: "6798 Crosswinds Dr. N , E-102",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33710,
    phone: "(727) 490-2727",
    lat: 27.789725,
    lng: -82.733101,
    id: 1293,
  },
  {
    name: "Parsaei, nazanin",
    address: "20600 Ventura Blv., #1310",
    city: "WOODLAND HILLS",
    state: "CA",
    zip: 91364,
    phone: "(305) 942-7698",
    lat: 34.167148,
    lng: -118.581959,
    id: 1294,
  },
  {
    name: "PARVUS, DIRK",
    address: "787 37TH STREET , SUITE 130",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 564-7887",
    lat: 27.66025,
    lng: -80.388213,
    id: 1295,
  },
  {
    name: "PARZYNSKI, PAMELA",
    address: "8551 w sunrise blvd , #206",
    city: "PLANTATION",
    state: "FL",
    zip: 33322,
    phone: "(754) 529-4372",
    lat: 26.148443,
    lng: -80.264552,
    id: 1296,
  },
  {
    name: "PATEL, ANAND",
    address: "2233 Lee Road , SUITE 101",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 916-0041",
    lat: 28.606115,
    lng: -81.378255,
    id: 1297,
  },
  {
    name: "PATEL, DEEPAK",
    address: "119 US 27 N",
    city: "SEBRING",
    state: "FL",
    zip: 33870,
    phone: "(863) 382-0009",
    lat: 27.295614,
    lng: -81.357669,
    id: 1298,
  },
  {
    name: "PATEL, GIRISH",
    address: "2500 BOBCAT VILLAGE CENTER ROAD , SUITE E",
    city: "NORTH PORT",
    state: "FL",
    zip: 34288,
    phone: "(941) 429-4744",
    lat: 27.0551,
    lng: -82.15836,
    id: 1299,
  },
  {
    name: "PATEL, GNYANDEV",
    address: "3300 E. SOUTH STREET , STE 206",
    city: "LAKEWOOD",
    state: "CA",
    zip: 90805,
    phone: "(562) 232-2380",
    lat: 33.85957,
    lng: -118.153874,
    id: 1300,
  },
  {
    name: "PATEL, HEJAL",
    address: "4274 WEST MAIN ST",
    city: "DOTHAN",
    state: "AL",
    zip: 36305,
    phone: "(334) 793-2312",
    lat: 31.184608,
    lng: -85.468144,
    id: 1301,
  },
  {
    name: "PATEL, KAMAL",
    address: "300 2700 Healing Way, #300",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33543,
    phone: "(813) 333-1186",
    lat: 28.196466,
    lng: -82.351733,
    id: 1302,
  },
  {
    name: "PATEL, NICK",
    address: "5616 TUSCOLA BOULEVARD",
    city: "NORTH PORT",
    state: "FL",
    zip: 34287,
    phone: "(941) 240-6273",
    lat: 27.040938,
    lng: -82.22603,
    id: 1303,
  },
  {
    name: "Patel, Nikash",
    address: "2338 Immokalee Rd 186",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(863) 838-7825",
    lat: 26.27226,
    lng: -81.77474,
    id: 1304,
  },
  {
    name: "PATEL, NIRAL",
    address: "11600 LAKESIDE VILLAGE LANE",
    city: "WINDERMERE",
    state: "FL",
    zip: 34786,
    phone: "(407) 876-2273",
    lat: 28.448784,
    lng: -81.562653,
    id: 1305,
  },
  {
    name: "PATEL, PRITESH",
    address: "155 Crystal Beach Drive , Suite 200",
    city: "DESTIN",
    state: "FL",
    zip: 32541,
    phone: "(850) 460-2350",
    lat: 30.388327,
    lng: -86.421322,
    id: 1306,
  },
  {
    name: "Patel, Ravi",
    address: "1620 N. Shore DR NE",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33704,
    phone: "(727) 412-2701",
    lat: 27.78797,
    lng: -82.623397,
    id: 1307,
  },
  {
    name: "PATEL, RIMAL",
    address: "1796 Highway 441 North",
    city: "OKEECHOBEE",
    state: "FL",
    zip: 34972,
    phone: "(863) 763-2151",
    lat: 27.259828,
    lng: -80.828549,
    id: 1308,
  },
  {
    name: "PATEL, SAMEER",
    address: "2341 BARTOLO DRIVE",
    city: "LAND O LAKES",
    state: "FL",
    zip: 34639,
    phone: "(718) 864-0765",
    lat: 28.190667,
    lng: -82.419875,
    id: 1309,
  },
  {
    name: "PATIDAR, BHAVIN",
    address: "2920 baycourt ave",
    city: "TAMPA",
    state: "FL",
    zip: 33611,
    phone: "(312) 576-2537",
    lat: 27.891345,
    lng: -82.505665,
    id: 1310,
  },
  {
    name: "PAUL, SEAN",
    address: "3205 Southgate Circle , Suite #2",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 405-3020",
    lat: 27.30479,
    lng: -82.513618,
    id: 1311,
  },
  {
    name: "PAULIAN, GABRIEL",
    address: "4343 W Newberry Road , Suite 14",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32607,
    phone: "(352) 373-4321",
    lat: 29.654419,
    lng: -82.388887,
    id: 1312,
  },
  {
    name: "PEARLBERG, KENNETH",
    address: "3535 S 1406 , APT. 1406",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33019,
    phone: "(305) 263-9050",
    lat: 26.027459,
    lng: -80.120219,
    id: 1313,
  },
  {
    name: "PEARSON, EDWARD",
    address: "967 Old Dixie Highway , Suite 2",
    city: "JUPITER",
    state: "FL",
    zip: 33477,
    phone: "(888) 561-4325",
    lat: 26.944186,
    lng: -80.09254,
    id: 1314,
  },
  {
    name: "PELAEZ, JORGE",
    address: "1004 SCENIC HIGHWAY",
    city: "PENSACOLA",
    state: "FL",
    zip: 32503,
    phone: "(850) 436-4444",
    lat: 30.430759,
    lng: -87.175646,
    id: 1315,
  },
  {
    name: "PELAYO, ENRIQUE",
    address: "8780 SW 8TH ST , SUITE 22",
    city: "MIAMI",
    state: "FL",
    zip: 33174,
    phone: "(305) 554-6198",
    lat: 25.76177,
    lng: -80.33739,
    id: 1316,
  },
  {
    name: "PELHAM, STEPHEN",
    address: "3909 EAST BAY DRIVE , SUITE 100",
    city: "BRADENTON BCH",
    state: "FL",
    zip: 34217,
    phone: "(941) 778-1007",
    lat: 27.496146,
    lng: -82.706917,
    id: 1317,
  },
  {
    name: "PENA, ALEJANDRO",
    address: "3000 HUNTER S CREEK BLVD",
    city: "ORLANDO",
    state: "FL",
    zip: 32837,
    phone: "(407) 857-2502",
    lat: 28.352648,
    lng: -81.418529,
    id: 1318,
  },
  {
    name: "PENA JIMENEZ, AWILDA",
    address: "7101 McNab Road , Suite 101",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(954) 722-5600",
    lat: 26.208818,
    lng: -80.246251,
    id: 1319,
  },
  {
    name: "PENATE, FELIX",
    address: "9600 sw 8th , Suite 50",
    city: "MIAMI",
    state: "FL",
    zip: 33174,
    phone: "(786) 536-9009",
    lat: 25.760909,
    lng: -80.351322,
    id: 1320,
  },
  {
    name: "PENDRAK, ROBERT",
    address: "6500 Crill Avenue",
    city: "PALATKA",
    state: "FL",
    zip: 32177,
    phone: "(386) 326-0575",
    lat: 29.6414,
    lng: -81.689136,
    id: 1321,
  },
  {
    name: "PERELMAN, JASON",
    address: "8890 W. OAKLAND PARK BLVD., #304",
    city: "SUNRISE",
    state: "FL",
    zip: 33351,
    phone: "(954) 748-4771",
    lat: 26.168136,
    lng: -80.269919,
    id: 1322,
  },
  {
    name: "PEREZ, CARLOS",
    address: "400 Ar , Suite 200",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 254-8900",
    lat: 25.817701,
    lng: -80.131406,
    id: 1323,
  },
  {
    name: "PEREZ, HUMBERTO",
    address: "4170 Town Center Blvd",
    city: "ORLANDO",
    state: "FL",
    zip: 32837,
    phone: "(407) 857-2817",
    lat: 28.361943,
    lng: -81.428573,
    id: 1324,
  },
  {
    name: "PEREZ, RENE",
    address: "145 MADEIRA AVE , SUITE 205",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(786) 373-7975",
    lat: 25.75617,
    lng: -80.25793,
    id: 1325,
  },
  {
    name: "Perez, Stephen",
    address: "6517 Taft Street , Suite 101",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33024,
    phone: "(954) 983-9191",
    lat: 26.025295,
    lng: -80.218846,
    id: 1326,
  },
  {
    name: "PEREZ, VIVIANA",
    address: "4410 WEST 16 AVENUE , SUITE NO 26",
    city: "HIALEAH",
    state: "FL",
    zip: 33012,
    phone: "(305) 822-8883",
    lat: 25.861726,
    lng: -80.315604,
    id: 1327,
  },
  {
    name: "PEREZ-GARCIA, MARIA",
    address: "2001 COUNTY RD. 210. , SUITE 101",
    city: "ST. JOHNS",
    state: "FL",
    zip: 32259,
    phone: "(904) 450-8402",
    lat: 30.061258,
    lng: -81.505699,
    id: 1328,
  },
  {
    name: "PEREZ-PUELLES, GENIBERT",
    address: "9555 SW 162 AVE",
    city: "MIAMI",
    state: "FL",
    zip: 33196,
    phone: "(786) 467-5159",
    lat: 25.678081,
    lng: -80.456285,
    id: 1329,
  },
  {
    name: "PEREZ-RODRIGUEZ, SOCRATES",
    address: "870 111th Ave. North, SUITE 3",
    city: "NAPLES",
    state: "FL",
    zip: 34108,
    phone: "(561) 315-1940",
    lat: 26.271932,
    lng: -81.803417,
    id: 1330,
  },
  {
    name: "PERKINS, HASHANI",
    address: "10250 SE 167th Place Road , ste. 5-1",
    city: "SUMMERFIELD",
    state: "FL",
    zip: 34491,
    phone: "(352) 307-9925",
    lat: 28.978699,
    lng: -81.983026,
    id: 1331,
  },
  {
    name: "PERL, CHARLES",
    address: "1980 S E Ocean Blvd",
    city: "STUART",
    state: "FL",
    zip: 34996,
    phone: "(772) 678-7043",
    lat: 27.228964,
    lng: -80.184275,
    id: 1332,
  },
  {
    name: "PERRY, MICHAEL",
    address: "17115 Journeys End Dr",
    city: "ODESSA",
    state: "FL",
    zip: 33556,
    phone: "(813) 920-8765",
    lat: 28.117174,
    lng: -82.576529,
    id: 1333,
  },
  {
    name: "PESCATORE, FRED",
    address: "369 LEXINGTON AVENUE , 8TH FLOOR",
    city: "NEW YORK",
    state: "NY",
    zip: 10017,
    phone: "(212) 779-2944",
    lat: 40.750386,
    lng: -73.976643,
    id: 1334,
  },
  {
    name: "PESKIN, BARRY",
    address: "10831 Redlake Isle",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33473,
    phone: "(216) 973-2844",
    lat: 26.513979,
    lng: -80.194648,
    id: 1335,
  },
  {
    name: "PESSAH, ARYEH",
    address: "800 MEADOWS RD",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 543-5555",
    lat: 26.358492,
    lng: -80.103499,
    id: 1336,
  },
  {
    name: "Petrovic, Marija",
    address: "105 East 6th Street",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32206,
    phone: "(415) 316-9217",
    lat: 30.34312,
    lng: -81.652444,
    id: 1337,
  },
  {
    name: "PEZZOTTI-SMITH, REYNALDO",
    address: "115 LAKE DAVENPORT BLVD",
    city: "DAVENPORT",
    state: "FL",
    zip: 33897,
    phone: "(787) 420-4054",
    lat: 28.333075,
    lng: -81.670559,
    id: 1338,
  },
  {
    name: "PFAHLER, KENNETH",
    address: "601 Medical Drive",
    city: "ENGLEWOOD",
    state: "FL",
    zip: 34223,
    phone: "(941) 475-5621",
    lat: 26.94938,
    lng: -82.328506,
    id: 1339,
  },
  {
    name: "PHAM, BAO",
    address: "6816 SOUTHPOINT PARKWAY , SUITE 302",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 527-3135",
    lat: 30.258469,
    lng: -81.588358,
    id: 1340,
  },
  {
    name: "PHELPS, ANID",
    address: "2020 FLAMINGO DRIVE",
    city: "BARTOW",
    state: "FL",
    zip: 33830,
    phone: "(863) 604-0047",
    lat: 27.89832,
    lng: -81.824553,
    id: 1341,
  },
  {
    name: "PHILLIPS, BRANDI",
    address: "9095 BELCHER RD",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33782,
    phone: "(727) 548-0001",
    lat: 27.855211,
    lng: -82.736274,
    id: 1342,
  },
  {
    name: "PHILLIPS, J",
    address: "12700 BISCAYNE BLVD , SUITE 307",
    city: "MIAMI",
    state: "FL",
    zip: 33181,
    phone: "(305) 893-2333",
    lat: 25.893849,
    lng: -80.162727,
    id: 1343,
  },
  {
    name: "PHILLIPS, LANE",
    address: "920 INTERNATIONAL PARKWAY , SUITE 1056",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 915-5300",
    lat: 28.77888,
    lng: -81.358299,
    id: 1344,
  },
  {
    name: "PICCHIELLO, ANTHONY",
    address: "1515 Herbert St. Suite 209",
    city: "PORT ORANGE",
    state: "FL",
    zip: 32129,
    phone: "(386) 366-9119",
    lat: 29.130889,
    lng: -81.023707,
    id: 1345,
  },
  {
    name: "PICHARDO, NELSON",
    address: "13123E Emerald Coast Pkwy",
    city: "INLET BEACH",
    state: "FL",
    zip: 32461,
    phone: "(850) 460-5543",
    lat: 30.27733,
    lng: -86.006,
    id: 1346,
  },
  {
    name: "PICHON, ANDRE",
    address: "52 WEST UNDERWOOD STREET",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 581-9180",
    lat: 28.526737,
    lng: -81.378044,
    id: 1347,
  },
  {
    name: "PICKHOLTZ, DOV",
    address: "5341 WEST ATLANTIC AVE , SUITE 301",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 450-9933",
    lat: 26.457665,
    lng: -80.127088,
    id: 1348,
  },
  {
    name: "PIERONE, GERALD",
    address: "3715 7TH TERRACE",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 770-2664",
    lat: 27.660622,
    lng: -80.387507,
    id: 1349,
  },
  {
    name: "PILA, KALMAN",
    address: "4234 Winding Willow Dr",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(813) 390-3688",
    lat: 28.059676,
    lng: -82.516532,
    id: 1350,
  },
  {
    name: "PINKSTON, JAMES",
    address: "1240 N University Dr",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33071,
    phone: "(855) 933-3463",
    lat: 26.141619,
    lng: -80.256378,
    id: 1351,
  },
  {
    name: "PINO, ANTONIO",
    address: "14000 NW 41st St",
    city: "MIAMI",
    state: "FL",
    zip: 33178,
    phone: "(844) 442-0362",
    lat: 25.804329,
    lng: -80.427546,
    id: 1352,
  },
  {
    name: "PISCITELLI, ANN",
    address: "329 NOKOMIS AVENUE SOUTH , SUITE F",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(941) 483-3377",
    lat: 27.094842,
    lng: -82.445279,
    id: 1353,
  },
  {
    name: "PLATT, GEORGE",
    address: "705 FERRIS STREET",
    city: "GREEN COVE SPRINGS",
    state: "FL",
    zip: 32043,
    phone: "(904) 284-4510",
    lat: 29.990711,
    lng: -81.682344,
    id: 1354,
  },
  {
    name: "PLAXE, JANICE",
    address: "3848 FAU BOULEVARD , SUITE 210",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 394-3088",
    lat: 26.383926,
    lng: -80.097111,
    id: 1355,
  },
  {
    name: "Pohlman, Dane",
    address: "8130 Royal Palm Blvd , Suite 104",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(754) 206-1877",
    lat: 26.257099,
    lng: -80.234827,
    id: 1356,
  },
  {
    name: "POLHEMUS, JONATHAN",
    address: "200 SE HOSPITAL AVE.",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 287-5200",
    lat: 27.199559,
    lng: -80.243015,
    id: 1357,
  },
  {
    name: "POLLAN, ALINA",
    address: "4921 71st Ave N",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(727) 548-1111",
    lat: 27.836741,
    lng: -82.700704,
    id: 1358,
  },
  {
    name: "POLSKY, MARK",
    address: "110 HILLCREST TERRACE",
    city: "STUART",
    state: "FL",
    zip: 34996,
    phone: "(772) 486-5202",
    lat: 27.197381,
    lng: -80.199465,
    id: 1359,
  },
  {
    name: "POOVENDRAN, GAYAN",
    address: "404 Wa 404 Washington Ave , Suite 120",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33139,
    phone: "(305) 479-2973",
    lat: 25.790654,
    lng: -80.130046,
    id: 1360,
  },
  {
    name: "PORTAL, ORLANDO",
    address: "13150 VAIL RIDGE DRIVE",
    city: "RIVERVIEW",
    state: "FL",
    zip: 33579,
    phone: "(813) 374-8883",
    lat: 27.793365,
    lng: -82.329095,
    id: 1361,
  },
  {
    name: "PORTER, KATHY",
    address: "251 North Bayou St",
    city: "MOBILE",
    state: "AL",
    zip: 36603,
    phone: "(251) 690-8158",
    lat: 30.692797,
    lng: -88.055194,
    id: 1362,
  },
  {
    name: "POTO, ANTONIO",
    address: "266 NW PEACOCK BLVD , SUITE 205",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34986,
    phone: "(772) 446-4883",
    lat: 27.316188,
    lng: -80.40689,
    id: 1363,
  },
  {
    name: "POTTER, WILLIE",
    address: "647 Orange Ave",
    city: "DAYTONA BEACH",
    state: "FL",
    zip: 32114,
    phone: "(386) 252-5501",
    lat: 29.203685,
    lng: -81.028108,
    id: 1364,
  },
  {
    name: "POWELL, BRIAN",
    address: "6150 Metrowest Blvd , Suite 306",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 630-7246",
    lat: 28.514107,
    lng: -81.466129,
    id: 1365,
  },
  {
    name: "POWELL, MICHELLE",
    address: "7/9/96",
    city: "MIAMI",
    state: "FL",
    zip: 33169,
    phone: "(305) 948-4701",
    lat: 25.939982,
    lng: -80.21484,
    id: 1366,
  },
  {
    name: "PRAGER, RICHARD",
    address: "8950 SW 57 AVENUE",
    city: "PINECREST",
    state: "FL",
    zip: 33156,
    phone: "(305) 322-4116",
    lat: 25.687879,
    lng: -80.285408,
    id: 1367,
  },
  {
    name: "PRESCOTT, THERESA",
    address: "333 17th St , Suite F",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 213-8881",
    lat: 27.631517,
    lng: -80.380508,
    id: 1368,
  },
  {
    name: "PRESTON, LAURA",
    address: "2181 Orange Avenue E",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32311,
    phone: "(850) 513-7059",
    lat: 30.409993,
    lng: -84.248041,
    id: 1369,
  },
  {
    name: "PREUDHOMME, JULES",
    address: "1228 SE SE8TH TERRACE",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33990,
    phone: "(239) 945-1105",
    lat: 26.636782,
    lng: -81.949069,
    id: 1370,
  },
  {
    name: "PRIBIL, STEFAN",
    address: "1311 West Webster Ave",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 357-0635",
    lat: 28.604505,
    lng: -81.366656,
    id: 1371,
  },
  {
    name: "PRIETO, MARIA",
    address: "109 MARGARET ST",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 654-2544",
    lat: 27.936671,
    lng: -82.281943,
    id: 1372,
  },
  {
    name: "PRIETO-HERNANDEZ, JOSE",
    address: "7600 West 20 th Avenue, SUITE 112",
    city: "HIALEAH",
    state: "FL",
    zip: 33016,
    phone: "(305) 557-0000",
    lat: 25.89152,
    lng: -80.32449,
    id: 1373,
  },
  {
    name: "Prin, William",
    address: "7000 West Camino Real",
    city: "BOCA RATON",
    state: "FL",
    zip: 33433,
    phone: "(717) 870-1279",
    lat: 26.343939,
    lng: -80.155612,
    id: 1374,
  },
  {
    name: "PROPIS, MELVIN",
    address: "12575 ORANGE DRIVE , SUITE 301",
    city: "DAVIE",
    state: "FL",
    zip: 33330,
    phone: "(954) 587-7600",
    lat: 26.06584,
    lng: -80.317693,
    id: 1375,
  },
  {
    name: "Provost, Tyler",
    address: "4600 N Ocean Blvd (A1A) , Suite 101",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33435,
    phone: "(561) 330-4300",
    lat: 26.507102,
    lng: -80.053965,
    id: 1376,
  },
  {
    name: "PRZLOMSKI, ANDREW",
    address: "2018 SE 29th Street",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33904,
    phone: "(715) 904-2600",
    lat: 26.597943,
    lng: -81.930348,
    id: 1377,
  },
  {
    name: "Puchir, Marc",
    address: "4222 midnight blue tun",
    city: "BRADENTON",
    state: "FL",
    zip: 34211,
    phone: "(888) 908-0143",
    lat: 27.462256,
    lng: -82.411709,
    id: 1378,
  },
  {
    name: "PUERTO, JUAN",
    address: "555 N. 15TH STREET , SUITE A",
    city: "IMMOKALEE",
    state: "FL",
    zip: 34142,
    phone: "(239) 657-2779",
    lat: 26.42734,
    lng: -81.434298,
    id: 1379,
  },
  {
    name: "PUGH, DARRELL",
    address: "1950 Laurel Manor , Bldg 210",
    city: "THE VILLAGES",
    state: "FL",
    zip: 32162,
    phone: "(352) 350-8800",
    lat: 28.924814,
    lng: -82.001656,
    id: 1380,
  },
  {
    name: "PULIDO, ALEX",
    address: "700 ZEAGLER AVENUE , SUITE 1",
    city: "PALATKA",
    state: "FL",
    zip: 32177,
    phone: "(386) 329-9036",
    lat: 29.641395,
    lng: -81.694552,
    id: 1381,
  },
  {
    name: "PULLER, CARRIE ELLEN",
    address: "7451 WILES RD. , Suite 106",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33067,
    phone: "(954) 345-1117",
    lat: 26.287528,
    lng: -80.226238,
    id: 1382,
  },
  {
    name: "QUENTZEL, PAUL",
    address: "709 SE 25 AVE",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33062,
    phone: "(954) 942-7385",
    lat: 26.2242,
    lng: -80.099034,
    id: 1383,
  },
  {
    name: "QUINN, LINDA",
    address: "410 JACKSONVILLE DRIVE",
    city: "JACKSONVILLE BEACH",
    state: "FL",
    zip: 32250,
    phone: "(904) 595-5980",
    lat: 30.259625,
    lng: -81.388578,
    id: 1384,
  },
  {
    name: "QUINONEZ, GERARDO",
    address: "8190 JOG RD , #100",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33472,
    phone: "(561) 369-2144",
    lat: 26.554526,
    lng: -80.149801,
    id: 1385,
  },
  {
    name: "Quinonez, Jonathan",
    address: "10150 Highland Manor Dr, #200",
    city: "TAMPA",
    state: "FL",
    zip: 33610,
    phone: "(855) 420-9170",
    lat: 27.983989,
    lng: -82.332667,
    id: 1386,
  },
  {
    name: "QURESHI, IMTIAZ",
    address: "11183 SOUTH ORANGE BLOSSOM TRAIL , SUITE A",
    city: "ORLANDO",
    state: "FL",
    zip: 32837,
    phone: "(407) 859-8686",
    lat: 28.403929,
    lng: -81.404174,
    id: 1387,
  },
  {
    name: "RADICE, PETER",
    address: "6804 Cecelia Dr",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34653,
    phone: "(855) 232-0644",
    lat: 28.231381,
    lng: -82.702225,
    id: 1388,
  },
  {
    name: "RADU, MIOARA",
    address: "255 FORTENBERRY ROAD , STE A-6",
    city: "MERRITT ISLAND",
    state: "FL",
    zip: 32952,
    phone: "(321) 783-6115",
    lat: 28.354024,
    lng: -80.694891,
    id: 1389,
  },
  {
    name: "RAELE, CHARLES",
    address: "1901 South Congress Ave , Suite 300",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33426,
    phone: "(561) 330-4557",
    lat: 26.51136,
    lng: -80.091073,
    id: 1390,
  },
  {
    name: "RAGSDALE, VERNICE",
    address: "1663 Georgia Street, Ste 400",
    city: "PALM BAY",
    state: "FL",
    zip: 32907,
    phone: "(321) 802-9080",
    lat: 27.999172,
    lng: -80.62353,
    id: 1391,
  },
  {
    name: "RAHMAN, FARHANA",
    address: "13067 N TELECOM PKWY",
    city: "TEMPLE TERRACE",
    state: "FL",
    zip: 33637,
    phone: "(813) 779-6303",
    lat: 28.064606,
    lng: -82.370625,
    id: 1392,
  },
  {
    name: "RAINS, CAROLINE",
    address: "Helpin 509 NE 1st St.",
    city: "GAINESVI",
    state: "FL",
    zip: 32601,
    phone: "(352) 519-5542",
    lat: 29.648993,
    lng: -82.324515,
    id: 1393,
  },
  {
    name: "RAJ, VIJAY",
    address: "2160 Secret Cove",
    city: "ODESSA",
    state: "FL",
    zip: 33556,
    phone: "(571) 344-8084",
    lat: 28.190798,
    lng: -82.563454,
    id: 1394,
  },
  {
    name: "RAJAMANICKAM, SURESH",
    address: "1395 STATE ROAD 7 , SUITE 350",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(512) 966-7571",
    lat: 26.655786,
    lng: -80.204259,
    id: 1395,
  },
  {
    name: "RAJASHEKAR, HARSHA",
    address: "2955 Brownwood Blvd",
    city: "THE VILLAGES",
    state: "FL",
    zip: 32163,
    phone: "(352) 674-8700",
    lat: 28.846062,
    lng: -82.030783,
    id: 1396,
  },
  {
    name: "RAJU, BHASKAR",
    address: "2345 Sand Lake Rd, Suite 200",
    city: "ORLANDO",
    state: "FL",
    zip: 32809,
    phone: "(407) 851-5121",
    lat: 28.450578,
    lng: -81.413225,
    id: 1397,
  },
  {
    name: "RAJU, DANTULURI",
    address: "2840 S.E. 3 COURT",
    city: "OCALA",
    state: "FL",
    zip: 34471,
    phone: "(352) 622-1777",
    lat: 29.160248,
    lng: -82.133588,
    id: 1398,
  },
  {
    name: "RAMANI, PRIYA",
    address: "15416 NORTH FLORIDA AVENUE",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(317) 840-0074",
    lat: 28.092291,
    lng: -82.460881,
    id: 1399,
  },
  {
    name: "RAMIREZ, ANGELICA",
    address: "7301A W Palmetto Park Rd , SUITE 301A",
    city: "BOCA RATON",
    state: "FL",
    zip: 33433,
    phone: "(561) 392-6226",
    lat: 26.350459,
    lng: -80.159412,
    id: 1400,
  },
  {
    name: "RAMIREZ-SEIFERT, SONIA",
    address: "258 East Altamonte Dr , SUITE 1001",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(321) 280-3949",
    lat: 28.661603,
    lng: -81.383605,
    id: 1401,
  },
  {
    name: "RAMKISHUN, LOAKHNAUTH",
    address: "6150 METROWEST BOULEVARD , SUITE 202",
    city: "ORLANDO",
    state: "FL",
    zip: 32835,
    phone: "(407) 291-2620",
    lat: 28.514107,
    lng: -81.466129,
    id: 1402,
  },
  {
    name: "RAMOS, JUAN",
    address: "1050 SE Monterey Road , Suite 204",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 288-2400",
    lat: 27.176785,
    lng: -80.238244,
    id: 1403,
  },
  {
    name: "RAMSAY, ANDREA",
    address: "105 8320 W. Sunrise Blvd, ste 105",
    city: "PLANTATION",
    state: "FL",
    zip: 33322,
    phone: "(954) 797-3887",
    lat: 26.147489,
    lng: -80.262421,
    id: 1404,
  },
  {
    name: "RAMSDEN, TIMOTHY",
    address: "13569 Panama City Beach Pkwy",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32407,
    phone: "(850) 238-8563",
    lat: 30.206866,
    lng: -85.844058,
    id: 1405,
  },
  {
    name: "Ramsook, Ryan",
    address: "547 N. Monroe Street",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32301,
    phone: "(850) 633-4877",
    lat: 30.447878,
    lng: -84.280467,
    id: 1406,
  },
  {
    name: "RATHBUN, KATHLEEN",
    address: "4889 LAKE WORTH ROAD , #109",
    city: "GREENACRES",
    state: "FL",
    zip: 33463,
    phone: "(561) 649-7532",
    lat: 26.61885,
    lng: -80.11993,
    id: 1407,
  },
  {
    name: "RAVID, JOSEPH",
    address: "21942 Edgewater Drive",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 505-2100",
    lat: 26.964745,
    lng: -82.082096,
    id: 1408,
  },
  {
    name: "RAVINDRA, RAJANI",
    address: "1740 US 90 WEST, SUITE 102",
    city: "LAKE CITY",
    state: "FL",
    zip: 32055,
    phone: "(386) 755-3000",
    lat: 30.180317,
    lng: -82.660801,
    id: 1409,
  },
  {
    name: "RAWAL, RAMAGAVRI",
    address: "250 s S. WICKHAM RD.",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 752-5210",
    lat: 28.102697,
    lng: -80.673085,
    id: 1410,
  },
  {
    name: "RAYMAKER, BRIAN",
    address: "105 Mariner Health Way , SUITE 203",
    city: "ST. AUGUSTINE",
    state: "FL",
    zip: 32086,
    phone: "(904) 342-2989",
    lat: 29.810768,
    lng: -81.323454,
    id: 1411,
  },
  {
    name: "REDDY, RAM",
    address: "7824 LAKE UNDERHILL RD, Ste D",
    city: "ORLANDO",
    state: "FL",
    zip: 32822,
    phone: "(407) 384-3000",
    lat: 28.538702,
    lng: -81.279404,
    id: 1412,
  },
  {
    name: "REDDY, SATHAVARAM",
    address: "10475 CENTURION PARKWAY NORTH , SUITE #201",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 223-3321",
    lat: 30.248555,
    lng: -81.542518,
    id: 1413,
  },
  {
    name: "REDRICK, SCOTT",
    address: "SE 7TH AVE",
    city: "CRYSTAL RIVER",
    state: "FL",
    zip: 34429,
    phone: "(352) 564-8245",
    lat: 28.891629,
    lng: -82.58277,
    id: 1414,
  },
  {
    name: "REHMAN, ARKAM",
    address: "2570 Atlantic Blvd",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(904) 651-8206",
    lat: 30.305524,
    lng: -81.63771,
    id: 1415,
  },
  {
    name: "REHMAN, JAMIL",
    address: "620 Eichenfeld Drive",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 653-3737",
    lat: 27.930719,
    lng: -82.290694,
    id: 1416,
  },
  {
    name: "REICH, NORBERT",
    address: "153 Bella Strano",
    city: "PORT ST. LUCIE",
    state: "FL",
    zip: 34084,
    phone: "(850) 488-0595",
    lat: 27.214644,
    lng: -80.331622,
    id: 1417,
  },
  {
    name: "REINERTSEN GARLAND, SHARON",
    address: "1600 PRUDENTIAL DRIVE , SUITE 400",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(904) 396-0000",
    lat: 30.316291,
    lng: -81.65369,
    id: 1418,
  },
  {
    name: "REINOSO, JOSE",
    address: "4315 highland park blvd",
    city: "LAKELAND",
    state: "FL",
    zip: 33813,
    phone: "(863) 816-5884",
    lat: 27.987169,
    lng: -81.954687,
    id: 1419,
  },
  {
    name: "REISMAN, DAVID",
    address: "4211 Metro Parkway, 2nd floor",
    city: "FORT MYERS",
    state: "FL",
    zip: 33916,
    phone: "(239) 343-8505",
    lat: 26.601248,
    lng: -81.849569,
    id: 1420,
  },
  {
    name: "REMARK-ZARATE, ROBIN",
    address: "3704 W 23rd street , Suite C",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 213-1215",
    lat: 30.190254,
    lng: -85.709175,
    id: 1421,
  },
  {
    name: "RENO, LETICIA",
    address: "MCDONALD ARMY COMMUNITY CLINIC",
    city: "NEWPORT NEWS",
    state: "VA",
    zip: 23604,
    phone: "(912) 435-5819",
    lat: 37.157438,
    lng: -76.584469,
    id: 1422,
  },
  {
    name: "Resnick, Stephen",
    address: "368 NE Franklins St.",
    city: "LAKE CITY",
    state: "FL",
    zip: 32055,
    phone: "(386) 292-8000",
    lat: 30.193475,
    lng: -82.632982,
    id: 1423,
  },
  {
    name: "RESNICK, STEVEN",
    address: "1111 KANE CONCOURSE , SUITE 504",
    city: "BAY HARBOR ISLAND",
    state: "FL",
    zip: 33154,
    phone: "(305) 865-1995",
    lat: 25.886757,
    lng: -80.131448,
    id: 1424,
  },
  {
    name: "RESTREPO, JOSE",
    address: "8950 North Kendall Drive , Suite 410-W",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 527-9172",
    lat: 25.684916,
    lng: -80.341237,
    id: 1425,
  },
  {
    name: "REYES, RENE",
    address: "3700 WASHINGTON STREET , SUITE # 404",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 983-3233",
    lat: 26.002912,
    lng: -80.180709,
    id: 1426,
  },
  {
    name: "REYES, RHEINCHARD",
    address: "4659 WEST FLAGLER STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33134,
    phone: "(305) 445-3372",
    lat: 25.771744,
    lng: -80.271248,
    id: 1427,
  },
  {
    name: "REYNOLDS, DWIGHT",
    address: "1809 S.E. Port Saint Luice Blv",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 398-0006",
    lat: 27.271509,
    lng: -80.297177,
    id: 1428,
  },
  {
    name: "RHODEN, DONNA",
    address: "5458 TOWN CENTER , SUITE 102",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 750-9596",
    lat: 26.362774,
    lng: -80.128559,
    id: 1429,
  },
  {
    name: "RIASCOS, MARITZA",
    address: "315 E NASA BLVD",
    city: "MELBOURNE",
    state: "FL",
    zip: 32901,
    phone: "(321) 733-2711",
    lat: 28.092801,
    lng: -80.616442,
    id: 1430,
  },
  {
    name: "RICE, CHRISTINE",
    address: "2815 S Seacrest Blvd",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33435,
    phone: "(561) 588-4844",
    lat: 26.505194,
    lng: -80.070251,
    id: 1431,
  },
  {
    name: "RICHARDSON-PRICE, MARLA",
    address: "201 E Dixie Avenue , Suite 148",
    city: "LEESBURG",
    state: "FL",
    zip: 34748,
    phone: "(352) 326-4066",
    lat: 28.80666,
    lng: -81.87376,
    id: 1432,
  },
  {
    name: "RICKETTS, DENISE",
    address: "1801 SE HILLMOOR DRIVE , STE B109",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(786) 390-2290",
    lat: 27.285382,
    lng: -80.293981,
    id: 1433,
  },
  {
    name: "RIKER, SYLVAN",
    address: "33 6th Street S , Suite 120",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 523-3700",
    lat: 27.770592,
    lng: -82.641988,
    id: 1434,
  },
  {
    name: "RINDE, JOHN",
    address: "25400 US Highway 19 N #259",
    city: "CLEARWATER",
    state: "FL",
    zip: 33763,
    phone: "(727) 523-3700",
    lat: 28.003788,
    lng: -82.730299,
    id: 1435,
  },
  {
    name: "RINEY, THOMAS",
    address: "103 south Navy boulevard",
    city: "PENSACOLA",
    state: "FL",
    zip: 32507,
    phone: "(850) 291-0874",
    lat: 30.386044,
    lng: -87.278491,
    id: 1436,
  },
  {
    name: "RIVAS, AGUSTIN",
    address: "6500 W 4th ave , Suite 13",
    city: "HIALEAH",
    state: "FL",
    zip: 33012,
    phone: "(786) 621-9777",
    lat: 25.881497,
    lng: -80.291864,
    id: 1437,
  },
  {
    name: "RIVERA, ALBERTO",
    address: "201 We 201 West Canton Ave, , Suite 275",
    city: "WINTER PARK",
    state: "FL",
    zip: 32789,
    phone: "(407) 664-3509",
    lat: 28.602327,
    lng: -81.35874,
    id: 1438,
  },
  {
    name: "RIVERA, MIGUEL",
    address: "6151 Lake Osprey Dr. Suite 300",
    city: "SARASOTA",
    state: "FL",
    zip: 34240,
    phone: "(941) 921-9576",
    lat: 27.38467,
    lng: -82.445821,
    id: 1439,
  },
  {
    name: "RIVERA, SALOMON",
    address: "255 fieldston terrace apt 6 k",
    city: "BRONX",
    state: "NY",
    zip: 10471,
    phone: "(646) 251-3754",
    lat: 40.895819,
    lng: -73.898452,
    id: 1440,
  },
  {
    name: "RIVERA MENDEZ, JOSE",
    address: "2001 W 68th St. , UNIT 601",
    city: "HIALEAH",
    state: "FL",
    zip: 33016,
    phone: "(305) 823-5000",
    lat: 25.886299,
    lng: -80.326966,
    id: 1441,
  },
  {
    name: "RIVERA-HIDALGO, ZORAIDA",
    address: "2647 HOLLYWOOD BOULEVARD",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33020,
    phone: "(954) 927-7575",
    lat: 26.011451,
    lng: -80.160869,
    id: 1442,
  },
  {
    name: "ROBBINS, HOWARD",
    address: "528 so 528 2b",
    city: "STUART",
    state: "FL",
    zip: 34994,
    phone: "(772) 419-3800",
    lat: 27.189748,
    lng: -80.254768,
    id: 1443,
  },
  {
    name: "ROBERTS, CAROL",
    address: "800 N. GOODLETTE ROAD , SUITE 270",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 649-7400",
    lat: 26.159426,
    lng: -81.786686,
    id: 1444,
  },
  {
    name: "ROBERTS, EVANS",
    address: "1978 Industrial Blvd",
    city: "HOUMA",
    state: "LA",
    zip: 70363,
    phone: "(504) 278-3411",
    lat: 29.569945,
    lng: -90.688366,
    id: 1445,
  },
  {
    name: "ROBERTS, JAMES",
    address: "609 Century Drive",
    city: "LARGO",
    state: "FL",
    zip: 33771,
    phone: "(888) 908-0143",
    lat: 27.910292,
    lng: -82.7834,
    id: 1446,
  },
  {
    name: "ROBERTS, JOYCE",
    address: "2488 E Irlo Bronson Memorial H , Ste 204",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34744,
    phone: "(407) 200-9258",
    lat: 28.297638,
    lng: -81.32402,
    id: 1447,
  },
  {
    name: "ROBINSON, DEBRA",
    address: "721 US Highway One , #114",
    city: "NORTH PALM BEACH",
    state: "FL",
    zip: 33408,
    phone: "(561) 444-7759",
    lat: 26.817993,
    lng: -80.058069,
    id: 1448,
  },
  {
    name: "ROBINSON, PATRICK",
    address: "12630 Curley St, Suite 104",
    city: "SAN ANTONIO",
    state: "FL",
    zip: 33576,
    phone: "(386) 237-7150",
    lat: 28.341688,
    lng: -82.273942,
    id: 1449,
  },
  {
    name: "ROCHELIN, FLEURGIN",
    address: "600 1611 NW 12th Avenue , Central 600 A",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(305) 585-6856",
    lat: 25.791124,
    lng: -80.212074,
    id: 1450,
  },
  {
    name: "ROCK, DAVID",
    address: "8931 COLONIAL CENTER DRIVE , SUITE 301",
    city: "FORT MYERS",
    state: "FL",
    zip: 33905,
    phone: "(239) 277-0479",
    lat: 26.614848,
    lng: -81.808774,
    id: 1451,
  },
  {
    name: "RODDY, RICHARD",
    address: "17906 Lake Azure Way",
    city: "BOCA RATON",
    state: "FL",
    zip: 33496,
    phone: "(561) 271-4175",
    lat: 26.409528,
    lng: -80.191864,
    id: 1452,
  },
  {
    name: "RODRIGUEZ, HECTOR",
    address: "2400 NW 54 Street , SUITE 402",
    city: "MIAMI",
    state: "FL",
    zip: 33142,
    phone: "(305) 633-9090",
    lat: 25.823744,
    lng: -80.237137,
    id: 1453,
  },
  {
    name: "RODRIGUEZ, JOSE",
    address: "5975 SUNSET DR. , Suite 100",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 669-2833",
    lat: 25.7048,
    lng: -80.29067,
    id: 1454,
  },
  {
    name: "RODRIGUEZ, MAGALY",
    address: "11140 SW 88th Street",
    city: "KENDALL",
    state: "FL",
    zip: 33176,
    phone: "(305) 279-1872",
    lat: 25.68581,
    lng: -80.375916,
    id: 1455,
  },
  {
    name: "RODRIGUEZ, MARA",
    address: "3850 SW 87TH AVE #205",
    city: "MIAMI",
    state: "FL",
    zip: 33165,
    phone: "(786) 452-9095",
    lat: 25.734065,
    lng: -80.336101,
    id: 1456,
  },
  {
    name: "RODRIGUEZ, PAUL",
    address: "1821 NE 25TH STREET , SUITE 100",
    city: "LIGHTHOUSE POINT",
    state: "FL",
    zip: 33064,
    phone: "(954) 941-0484",
    lat: 26.262974,
    lng: -80.098269,
    id: 1457,
  },
  {
    name: "RODRIGUEZ, PEDRO",
    address: "258 NE 27TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(305) 573-9898",
    lat: 25.802674,
    lng: -80.189763,
    id: 1458,
  },
  {
    name: "RODRIGUEZ ALVAREZ, HUMBERTO",
    address: "2nd Fl 2332 Galiano St",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 209-1197",
    lat: 25.769974,
    lng: -80.266507,
    id: 1459,
  },
  {
    name: "RODRIGUEZ DE LA TORRE, JESUS",
    address: "7801 SW 24ST SUITE 115",
    city: "MIAMI",
    state: "FL",
    zip: 33155,
    phone: "(305) 534-9009",
    lat: 25.747865,
    lng: -80.323118,
    id: 1460,
  },
  {
    name: "RODRIGUEZ HERNANDEZ, RALPH",
    address: "1726 MEDICAL BLVD , SUITE 201",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(239) 596-8804",
    lat: 26.272781,
    lng: -81.784673,
    id: 1461,
  },
  {
    name: "RODRIGUEZ VILORIO, FELIX",
    address: "8230 SW 144th",
    city: "PALMETTO BAY",
    state: "FL",
    zip: 33158,
    phone: "(917) 628-9083",
    lat: 25.636323,
    lng: -80.326316,
    id: 1462,
  },
  {
    name: "ROJAS, JOSE",
    address: "1855 JESS PARRISH CT",
    city: "TITUSVILLE",
    state: "FL",
    zip: 32796,
    phone: "(321) 268-0291",
    lat: 28.626516,
    lng: -80.822909,
    id: 1463,
  },
  {
    name: "ROLLAND, LILIVETTE",
    address: "2123 west Dr Martin Luther King JR",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 873-1725",
    lat: 27.981536,
    lng: -82.481484,
    id: 1464,
  },
  {
    name: "ROMAN-DIAZ, ANGEL",
    address: "2101 BRICKELL AVE. , 3105",
    city: "MIAMI",
    state: "FL",
    zip: 33129,
    phone: "(787) 397-1218",
    lat: 25.752635,
    lng: -80.199059,
    id: 1465,
  },
  {
    name: "ROMANIUK, GREGORY",
    address: "24299 whip o will lane",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(239) 961-6214",
    lat: 26.385594,
    lng: -81.777338,
    id: 1466,
  },
  {
    name: "ROMERO, CARLOS",
    address: "1022 MAIN STREET , SUITE J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.018753,
    lng: -82.776729,
    id: 1467,
  },
  {
    name: "ROMERO, FRANCES",
    address: "24830 Burnt Pine Drive Suite 3",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34134,
    phone: "(239) 237-5688",
    lat: 26.378314,
    lng: -81.809233,
    id: 1468,
  },
  {
    name: "ROPHIE, RALPH",
    address: "1013 LOTUS PATH",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 449-9595",
    lat: 27.954204,
    lng: -82.791956,
    id: 1469,
  },
  {
    name: "ROSEN, SETH",
    address: "1871 S.E. TIFFANY AVENUE , SUITE #100",
    city: "PORT ST LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-5666",
    lat: 27.291802,
    lng: -80.294805,
    id: 1470,
  },
  {
    name: "ROSENBLATT, MELANIE",
    address: "2900 N Military Tr #241",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(954) 941-5556",
    lat: 26.37789,
    lng: -80.12097,
    id: 1471,
  },
  {
    name: "ROSENFIELD, LOUIS",
    address: "2300 LOVELAND BOULEVARD",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33980,
    phone: "(941) 629-4500",
    lat: 26.993323,
    lng: -82.05743,
    id: 1472,
  },
  {
    name: "ROSEWATER, STANLEY",
    address: "25400 US HWY 19 N , #259",
    city: "CLEARWATER",
    state: "FL",
    zip: 33763,
    phone: "(727) 523-3700",
    lat: 28.003788,
    lng: -82.730299,
    id: 1473,
  },
  {
    name: "ROSIN, JOSEPH",
    address: "9917 SAGO POINT DR",
    city: "LARGO",
    state: "FL",
    zip: 33777,
    phone: "(727) 314-3456",
    lat: 27.863705,
    lng: -82.742902,
    id: 1474,
  },
  {
    name: "ROSS, HARRIS",
    address: "328 W GODFREY AVENUE",
    city: "PHILADELPHIA",
    state: "PA",
    zip: 19120,
    phone: "(215) 276-1122",
    lat: 40.045173,
    lng: -75.126547,
    id: 1475,
  },
  {
    name: "ROSS, STEPHEN",
    address: "2706 SE SANTA BARBARA PLACE",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33904,
    phone: "(239) 471-0721",
    lat: 26.601889,
    lng: -81.973521,
    id: 1476,
  },
  {
    name: "Ross, Stuart",
    address: "9066 Jena Road",
    city: "SPRING HILL",
    state: "FL",
    zip: 34608,
    phone: "(615) 456-9696",
    lat: 28.468067,
    lng: -82.573282,
    id: 1477,
  },
  {
    name: "ROSSELLI, MATTEO",
    address: "1210 S DIXIE HWY",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 747-2234",
    lat: 26.923006,
    lng: -80.095988,
    id: 1478,
  },
  {
    name: "ROTHMAN, JACK",
    address: "Suite 24761 U.S. Highway 19 North",
    city: "CLEARWATER",
    state: "FL",
    zip: 33759,
    phone: "(888) 908-0143",
    lat: 27.975284,
    lng: -82.701482,
    id: 1479,
  },
  {
    name: "ROTT, REINHARD",
    address: "6747 Gall Blvd",
    city: "ZEPHYRHILLS",
    state: "FL",
    zip: 33542,
    phone: "(813) 501-4777",
    lat: 28.256163,
    lng: -82.189405,
    id: 1480,
  },
  {
    name: "Roufaiel, David",
    address: "210 Jupiter Lakes Blvd , Building 4000, Suite 206",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 944-5534",
    lat: 26.9211,
    lng: -80.097,
    id: 1481,
  },
  {
    name: "ROY, LISBETH",
    address: "2595 NW Boca Raton Blvd. , Suite 200",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 444-7751",
    lat: 26.374279,
    lng: -80.086972,
    id: 1482,
  },
  {
    name: "RUBIN, STUART",
    address: "10151 ENTERPRISE CENTER BLVD. # 107",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 738-2000",
    lat: 26.525909,
    lng: -80.167654,
    id: 1483,
  },
  {
    name: "RUBINO, MARK",
    address: "201 8TH ST. SOUTH STE 309 , SUITE 202",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 261-6876",
    lat: 26.14533,
    lng: -81.796355,
    id: 1484,
  },
  {
    name: "RUELLE, IVETTE",
    address: "Suit B 7824 Lake Underhill Rd",
    city: "ORLANDO",
    state: "FL",
    zip: 32822,
    phone: "(407) 281-0470",
    lat: 28.494385,
    lng: -81.29024,
    id: 1485,
  },
  {
    name: "RUSILKO, MATTHEW",
    address: "1800 PURDY AVE",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33139,
    phone: "(786) 334-3664",
    lat: 25.793616,
    lng: -80.144547,
    id: 1486,
  },
  {
    name: "RUTERBUSCH, JEFFREY",
    address: "316 PARKRIDGE AVE",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32065,
    phone: "(904) 589-0750",
    lat: 30.134909,
    lng: -81.775431,
    id: 1487,
  },
  {
    name: "RUTH, ROBERT",
    address: "Kolbe 8734 Ortega Park Dr.",
    city: "NAVARRE",
    state: "FL",
    zip: 32566,
    phone: "(850) 904-2277",
    lat: 30.427685,
    lng: -86.927118,
    id: 1488,
  },
  {
    name: "RUTHERFORD, ANDREW",
    address: "16404 SWAN VIEW CIRCLE",
    city: "ODESSA",
    state: "FL",
    zip: 33556,
    phone: "(813) 753-7340",
    lat: 28.184375,
    lng: -82.546131,
    id: 1489,
  },
  {
    name: "Ruz-Pau, Carlos",
    address: "700 W Oak Street",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(786) 366-2760",
    lat: 28.299707,
    lng: -81.409296,
    id: 1490,
  },
  {
    name: "RYAN, LORRAINE",
    address: "208 Booth Road Suite A",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 444-6464",
    lat: 29.253794,
    lng: -81.121184,
    id: 1491,
  },
  {
    name: "RYAN, VALERIE",
    address: "314 HOSPITAL AVENUE",
    city: "STUART",
    state: "FL",
    zip: 34996,
    phone: "(772) 223-5913",
    lat: 27.197955,
    lng: -80.243305,
    id: 1492,
  },
  {
    name: "RYBACK, RALPH",
    address: "1415 Panther Lane, Ste. 248",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 591-6704",
    lat: 26.214681,
    lng: -81.790158,
    id: 1493,
  },
  {
    name: "RYSCHON, TIMOTHY",
    address: "5151 North 9th Ave",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 416-1600",
    lat: 30.476737,
    lng: -87.211226,
    id: 1494,
  },
  {
    name: "SAAVEDRA, DIEGO",
    address: "9045 SW 87TH COURT",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(305) 412-5946",
    lat: 25.686076,
    lng: -80.335118,
    id: 1495,
  },
  {
    name: "SABA, RASHID",
    address: "1485 37th STREET , SUITE 102",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 567-4336",
    lat: 27.659739,
    lng: -80.399502,
    id: 1496,
  },
  {
    name: "SACHER, MARK",
    address: "3320 SW 33RD ROAD , SUITE 200",
    city: "OCALA",
    state: "FL",
    zip: 34474,
    phone: "(352) 512-0970",
    lat: 29.152714,
    lng: -82.17673,
    id: 1497,
  },
  {
    name: "Sachs, Rachel",
    address: "31 Sunnen Dr , Apt 249",
    city: "SAINT LOUIS",
    state: "MO",
    zip: 63143,
    phone: "(417) 622-7924",
    lat: 38.607723,
    lng: -90.331288,
    id: 1498,
  },
  {
    name: "SADHWANI, HARISH",
    address: "8701 U 8701 U S HWY 1",
    city: "SEBASTIAN",
    state: "FL",
    zip: 32958,
    phone: "(772) 581-2373",
    lat: 27.799123,
    lng: -80.483098,
    id: 1499,
  },
  {
    name: "SAFF, GARY",
    address: "5353 NORTH FEDERAL HIGHWAY , SUITE 301",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 772-7552",
    lat: 26.192036,
    lng: -80.114289,
    id: 1500,
  },
  {
    name: "SAGER, STEVEN",
    address: "9671 Gladiolus Drive , Suite 111",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 768-7071",
    lat: 26.519541,
    lng: -81.915114,
    id: 1501,
  },
  {
    name: "SAINT LOUIS, OBED",
    address: "235 Citrus tower blvd suite 104",
    city: "CLERMONT",
    state: "FL",
    zip: 34711,
    phone: "(352) 404-8160",
    lat: 28.564871,
    lng: -81.741712,
    id: 1502,
  },
  {
    name: "SAINT-ELIE, DANIEL",
    address: "240 Mohawk Rd",
    city: "CLERMONT",
    state: "FL",
    zip: 34715,
    phone: "(407) 708-3540",
    lat: 28.566029,
    lng: -81.732015,
    id: 1503,
  },
  {
    name: "SAINT-FLEUR, PAUL",
    address: "1114 S Florida Ave",
    city: "LAKELAND",
    state: "FL",
    zip: 33803,
    phone: "(863) 500-1773",
    lat: 28.028118,
    lng: -81.956933,
    id: 1504,
  },
  {
    name: "SALAMA, ALGHIDAK",
    address: "1951 N 1951 NW 7 Ave , SUITE 220",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(305) 243-7003",
    lat: 25.78718,
    lng: -80.204773,
    id: 1505,
  },
  {
    name: "Salcedo, Stephen",
    address: "1022 Main St. , Suite J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.018753,
    lng: -82.776729,
    id: 1506,
  },
  {
    name: "Salei, Inesa",
    address: "4855 W Hillsboro Blvd",
    city: "COCONUT CREEK",
    state: "FL",
    zip: 33073,
    phone: "(954) 418-1683",
    lat: 26.318599,
    lng: -80.191048,
    id: 1507,
  },
  {
    name: "SALHAB, SUZANNE",
    address: "11808 North 56TH Street",
    city: "TEMPLE TERRACE",
    state: "FL",
    zip: 33617,
    phone: "(813) 769-9866",
    lat: 28.056553,
    lng: -82.394426,
    id: 1508,
  },
  {
    name: "SALVATO, MICHAEL",
    address: "413 N. ALEXANDER STREET",
    city: "PLANT CITY",
    state: "FL",
    zip: 33563,
    phone: "(813) 719-3525",
    lat: 28.01821,
    lng: -82.138339,
    id: 1509,
  },
  {
    name: "SALZBERG, JAN",
    address: "4470 SPANISH TRAIL, APT. B31",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 497-2097",
    lat: 30.469437,
    lng: -87.172939,
    id: 1510,
  },
  {
    name: "SAMALOT, JOSE",
    address: "2119 W. Brandon Blvd , Suite M",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(727) 523-3700",
    lat: 27.937031,
    lng: -82.320244,
    id: 1511,
  },
  {
    name: "SAMANO, GREGORY",
    address: "499 East Central parkway , suite 100",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(407) 543-3461",
    lat: 28.670083,
    lng: -81.37474,
    id: 1512,
  },
  {
    name: "SANCHEZ, ALICIA",
    address: "701 5th Ave STE 2300",
    city: "SEATTLE",
    state: "WA",
    zip: 98104,
    phone: "(310) 918-1942",
    lat: 47.604529,
    lng: -122.330613,
    id: 1513,
  },
  {
    name: "SANCHEZ, CARLOS",
    address: "9600 N.E. SECOND AVENUE",
    city: "MIAMI SHORES",
    state: "FL",
    zip: 33138,
    phone: "(786) 731-4238",
    lat: 25.863824,
    lng: -80.193755,
    id: 1514,
  },
  {
    name: "Sanchez, Daniel",
    address: "480 NE 31 ST",
    city: "MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(305) 915-2645",
    lat: 25.806498,
    lng: -80.18752,
    id: 1515,
  },
  {
    name: "SANCHEZ, NATALIE",
    address: "255 University Dr",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 362-2686",
    lat: 25.74376,
    lng: -80.260098,
    id: 1516,
  },
  {
    name: "SANCHEZ, PEDRO",
    address: "1035 south state road 7 , ste 120",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 432-4971",
    lat: 26.662145,
    lng: -80.203948,
    id: 1517,
  },
  {
    name: "SANCHEZ, RAMON",
    address: "4600 N. HABANA AVENUE SUITE 32",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(813) 423-6515",
    lat: 27.984209,
    lng: -82.492876,
    id: 1518,
  },
  {
    name: "SANCHEZ, VIRGILIO",
    address: "3099 SW 8TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33135,
    phone: "(305) 644-3100",
    lat: 25.765279,
    lng: -80.244595,
    id: 1519,
  },
  {
    name: "SANCHEZ-DELACRUZ, GUILLERMO",
    address: "1928 MORGANS MILL CIRCLE",
    city: "ORLANDO",
    state: "FL",
    zip: 32825,
    phone: "(407) 459-6216",
    lat: 28.523995,
    lng: -81.274361,
    id: 1520,
  },
  {
    name: "SANDADI, SAMITH",
    address: "8931 COLONIAL CENTER DRIVE , SUITE 400",
    city: "FT. MYERS",
    state: "FL",
    zip: 33905,
    phone: "(239) 334-6626",
    lat: 26.614848,
    lng: -81.808774,
    id: 1521,
  },
  {
    name: "SANGHRAJKA, NEHA",
    address: "942 LAKE BALDWIN LANE",
    city: "ORLANDO",
    state: "FL",
    zip: 32814,
    phone: "(321) 285-6363",
    lat: 28.558433,
    lng: -81.327541,
    id: 1522,
  },
  {
    name: "SANTEIRO, JOSE",
    address: "4384 purdy lane",
    city: "PALM SPRINGS",
    state: "FL",
    zip: 33406,
    phone: "(866) 419-3899",
    lat: 26.643954,
    lng: -80.110746,
    id: 1523,
  },
  {
    name: "SANTI, CELESTINO",
    address: "1300 E Burleigh Blvd",
    city: "TAVARES",
    state: "FL",
    zip: 32778,
    phone: "(352) 742-1500",
    lat: 28.821219,
    lng: -81.714541,
    id: 1524,
  },
  {
    name: "SANTIAGO, RAMON",
    address: "13250 56TH STREET , SUITE 101",
    city: "TAMPA",
    state: "FL",
    zip: 33617,
    phone: "(813) 988-1984",
    lat: 28.067344,
    lng: -82.39425,
    id: 1525,
  },
  {
    name: "SANTIAGO-GONZALEZ, RAFAEL",
    address: "2407 8819 river crossing blvd",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34655,
    phone: "(813) 907-3300",
    lat: 28.239134,
    lng: -82.669504,
    id: 1526,
  },
  {
    name: "SANTOS, GUILLERMO",
    address: "230 PARK STREET",
    city: "MIAMI SPRINGS",
    state: "FL",
    zip: 33166,
    phone: "(305) 888-2607",
    lat: 25.82164,
    lng: -80.284563,
    id: 1527,
  },
  {
    name: "SANTOS, RAMON",
    address: "2200 RINGLING BLVD.",
    city: "SARASOTA",
    state: "FL",
    zip: 34237,
    phone: "(941) 861-2900",
    lat: 27.334965,
    lng: -82.525926,
    id: 1528,
  },
  {
    name: "SARASUA, MARTHA",
    address: "6706 N. 9TH AVENUE SUITE A5",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 332-7774",
    lat: 30.493089,
    lng: -87.18993,
    id: 1529,
  },
  {
    name: "SATLOFF, SARA",
    address: "2776 CLEVELAND AVENUE",
    city: "FORT MYERS",
    state: "FL",
    zip: 33901,
    phone: "(239) 691-9564",
    lat: 26.628494,
    lng: -81.874224,
    id: 1530,
  },
  {
    name: "SAYLES, TIFFANY",
    address: "9715 W. Broward Blvd. , # 245",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 361-4780",
    lat: 26.122092,
    lng: -80.278144,
    id: 1531,
  },
  {
    name: "SAYLOR, JASON",
    address: "2300 E COUNTY ROAD 540A",
    city: "LAKELAND",
    state: "FL",
    zip: 33813,
    phone: "(863) 607-3333",
    lat: 27.953956,
    lng: -81.920788,
    id: 1532,
  },
  {
    name: "SAYRE, ROBERT",
    address: "99 SOUTH ALCANIZ ST. , SUITE B",
    city: "PENSACOLA",
    state: "FL",
    zip: 32502,
    phone: "(850) 437-0035",
    lat: 30.412466,
    lng: -87.210312,
    id: 1533,
  },
  {
    name: "Schaff, Austin",
    address: "201 14 201 14th st SW",
    city: "LARGO",
    state: "FL",
    zip: 33770,
    phone: "(727) 588-5200",
    lat: 27.914168,
    lng: -82.803312,
    id: 1534,
  },
  {
    name: "SCHEIDERER, DAVID",
    address: "3392 MAGIC OAK LANE",
    city: "SARASOTA",
    state: "FL",
    zip: 34232,
    phone: "(540) 540-2946",
    lat: 27.338982,
    lng: -82.473971,
    id: 1535,
  },
  {
    name: "SCHELLER, VANDHANA",
    address: "4302 Alton Rd",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(786) 475-4970",
    lat: 25.815955,
    lng: -80.137696,
    id: 1536,
  },
  {
    name: "SCHIFFMAN, LAWRENCE",
    address: "3650 NW 82ND AVE , SUITE 306",
    city: "DORAL",
    state: "FL",
    zip: 33166,
    phone: "(305) 735-9474",
    lat: 25.808316,
    lng: -80.32933,
    id: 1537,
  },
  {
    name: "SCHIMPFF, SCOTT",
    address: "1564 Kingsley Avenue",
    city: "ORANGE PARK",
    state: "FL",
    zip: 32073,
    phone: "(804) 627-2923",
    lat: 30.164661,
    lng: -81.729092,
    id: 1538,
  },
  {
    name: "SCHISANI, HOLLY",
    address: "13240 Tamiami Trial N , #204",
    city: "NAPLES",
    state: "FL",
    zip: 34110,
    phone: "(239) 592-7767",
    lat: 26.2916,
    lng: -81.80184,
    id: 1539,
  },
  {
    name: "SCHLAPPER, BRENT",
    address: "121 WEST PLYMOUTH AVENUE , SUITE 121",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(386) 748-4661",
    lat: 29.043156,
    lng: -81.305012,
    id: 1540,
  },
  {
    name: "SCHNEIDER, JEAN-RAPHAEL",
    address: "3190 N McMullen Booth Rd - Ste 202",
    city: "CLEARWATER",
    state: "FL",
    zip: 33761,
    phone: "(813) 855-2900",
    lat: 28.03738,
    lng: -82.70916,
    id: 1541,
  },
  {
    name: "SCHNEIDER, ROBERT",
    address: "1601 CLINT MOORE ROAD , SUITE 115",
    city: "BOCA RATON",
    state: "FL",
    zip: 33487,
    phone: "(561) 997-8484",
    lat: 26.40899,
    lng: -80.116413,
    id: 1542,
  },
  {
    name: "SCHNELL, MARTIN",
    address: "9400 San Jose Blvd.",
    city: "HOWEY IN THE HILLS",
    state: "FL",
    zip: 34737,
    phone: "(863) 202-0076",
    lat: 28.725175,
    lng: -81.796953,
    id: 1543,
  },
  {
    name: "SCHNUR, STEVEN",
    address: "1700 79 Street Cswy Suite 120 , STE. 300",
    city: "NORTH BAY VILLAGE",
    state: "FL",
    zip: 33141,
    phone: "(305) 726-2177",
    lat: 25.84872,
    lng: -80.15065,
    id: 1544,
  },
  {
    name: "SCHOCHET, ELIE",
    address: "21097 NE 27 COURT , SUITE 490",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(954) 573-1499",
    lat: 25.970363,
    lng: -80.146201,
    id: 1545,
  },
  {
    name: "SCHOLLE, JANET",
    address: "1920 PALM BEACH LAKES BLVD #102",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33409,
    phone: "(561) 683-3371",
    lat: 26.71691,
    lng: -80.09117,
    id: 1546,
  },
  {
    name: "SCHOW, DENISE",
    address: "1022 Main Street , Suite J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.018753,
    lng: -82.776729,
    id: 1547,
  },
  {
    name: "SCHOW, DOUGLAS",
    address: "1022 Main Street , SUITE J",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(888) 908-0143",
    lat: 28.018753,
    lng: -82.776729,
    id: 1548,
  },
  {
    name: "SCHULMAN, ROBERT",
    address: "3293 Fruitville Rd , Suite 105",
    city: "SARASOTASARASOTA",
    state: "FL",
    zip: 34237,
    phone: "(941) 600-9055",
    lat: 27.338218,
    lng: -82.503357,
    id: 1549,
  },
  {
    name: "SCHULTZ, CARL",
    address: "13785 BALD CYPRESS CIRCLE",
    city: "FORT MYERS",
    state: "FL",
    zip: 33907,
    phone: "(239) 910-8080",
    lat: 26.541182,
    lng: -81.87871,
    id: 1550,
  },
  {
    name: "SCHULTZ, JASON",
    address: "7932 W. Sand Lake Road , Suite 205",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(855) 420-9170",
    lat: 28.448733,
    lng: -81.49113,
    id: 1551,
  },
  {
    name: "SCHWARTZ, JEFFREY",
    address: "1718 Mockingbird Lane",
    city: "LAKELAND",
    state: "FL",
    zip: 33801,
    phone: "(863) 272-1550",
    lat: 28.027611,
    lng: -81.929559,
    id: 1552,
  },
  {
    name: "SCHWARTZ, JEFFREY",
    address: "148 13TH STREET S.W.",
    city: "LARGO",
    state: "FL",
    zip: 33770,
    phone: "(727) 581-8706",
    lat: 27.916138,
    lng: -82.801373,
    id: 1553,
  },
  {
    name: "SCHWARTZ, PETER",
    address: "10115 Forest Hill blvd , STE 200",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 966-7717",
    lat: 26.654692,
    lng: -80.204267,
    id: 1554,
  },
  {
    name: "SCHWARTZ, STEPHEN",
    address: "3880 TAMIAMI TRAIL NORTH",
    city: "NAPLES",
    state: "FL",
    zip: 34103,
    phone: "(239) 659-3937",
    lat: 26.192874,
    lng: -81.799515,
    id: 1555,
  },
  {
    name: "SCHWARZ, HAROLD",
    address: "311 ELEVENTH STREET",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33401,
    phone: "(561) 659-7212",
    lat: 26.715844,
    lng: -80.070249,
    id: 1556,
  },
  {
    name: "SCIARRINO, JOHN",
    address: "5601 N Ste115",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33334,
    phone: "(954) 771-4271",
    lat: 26.184511,
    lng: -80.134366,
    id: 1557,
  },
  {
    name: "SCOTT, CLARENCE",
    address: "1061 MEDICAL CENTER DRIVE,#101",
    city: "ORANGE CITY",
    state: "FL",
    zip: 32763,
    phone: "(386) 917-5547",
    lat: 28.915595,
    lng: -81.284625,
    id: 1558,
  },
  {
    name: "SCOTT, DOUGLAS",
    address: "2814 W Doctor M.L.K. Jr Blvd",
    city: "TAMPA",
    state: "FL",
    zip: 33607,
    phone: "(813) 898-8009",
    lat: 27.980953,
    lng: -82.490419,
    id: 1559,
  },
  {
    name: "SCOTT, RANDALL",
    address: "3189 US HWY 17",
    city: "GREEN COVE SPRINGS",
    state: "FL",
    zip: 32043,
    phone: "(904) 621-0247",
    lat: 30.028553,
    lng: -81.707209,
    id: 1560,
  },
  {
    name: "SCOTT, SHEKYLA",
    address: "5008 SR 64 E",
    city: "BRADENTON",
    state: "FL",
    zip: 34208,
    phone: "(941) 720-6701",
    lat: 27.428132,
    lng: -82.611591,
    id: 1561,
  },
  {
    name: "Scuteri, Bryan",
    address: "5045 Fruitville Rd Ste 123",
    city: "SARASOTA",
    state: "FL",
    zip: 34232,
    phone: "(774) 223-7332",
    lat: 27.337871,
    lng: -82.468671,
    id: 1562,
  },
  {
    name: "SEARS, ALFRED",
    address: "11905 SOUTHERN BLVD",
    city: "ROYAL PALM BEACH",
    state: "FL",
    zip: 33411,
    phone: "(561) 784-7852",
    lat: 26.682997,
    lng: -80.233408,
    id: 1563,
  },
  {
    name: "SEBESTO, JASON",
    address: "446 3rd St , Suite 1",
    city: "NEPTUNE BEACH",
    state: "FL",
    zip: 32266,
    phone: "(904) 325-9004",
    lat: 30.321055,
    lng: -81.398375,
    id: 1564,
  },
  {
    name: "SEIDELMAN, RICHARD",
    address: "2623 S. SEACREST BLVD. #214",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33435,
    phone: "(561) 731-2269",
    lat: 26.505223,
    lng: -80.069122,
    id: 1565,
  },
  {
    name: "SEIFERT, WALTER",
    address: "258 EAST ALTAMONTE DR. , SUITE 1001",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(321) 280-3949",
    lat: 28.661603,
    lng: -81.383605,
    id: 1566,
  },
  {
    name: "SELSKY, CLIFFORD",
    address: "2830 Casa Aloma Way",
    city: "WINTER PARK",
    state: "FL",
    zip: 32792,
    phone: "(407) 335-4760",
    lat: 28.613455,
    lng: -81.310841,
    id: 1567,
  },
  {
    name: "SEMEL, LEWIS",
    address: "801 MEADOWS ROAD SUITE 103",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 395-7494",
    lat: 26.359828,
    lng: -80.101721,
    id: 1568,
  },
  {
    name: "SENDE, FERNANDO",
    address: "350 NW 84th Avenue , Suite 200",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 424-4321",
    lat: 26.125434,
    lng: -80.260786,
    id: 1569,
  },
  {
    name: "SENGER, CHRISTOPHER",
    address: "1203 E SUNRISE BLVD",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33304,
    phone: "(954) 947-7548",
    lat: 26.137937,
    lng: -80.131305,
    id: 1570,
  },
  {
    name: "SERPA, JOHN",
    address: "1800 WEST 68TH STREET , #114",
    city: "HIALEAH",
    state: "FL",
    zip: 33014,
    phone: "(305) 698-9077",
    lat: 25.883252,
    lng: -80.320547,
    id: 1571,
  },
  {
    name: "SERRA, HIGINIO",
    address: "5106 Neptune Circle",
    city: "OXFORD",
    state: "FL",
    zip: 34484,
    phone: "(352) 753-2826",
    lat: 28.9032,
    lng: -82.019116,
    id: 1572,
  },
  {
    name: "SERT, PERI",
    address: "12443 San Jose Blvd , Ste 204",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32223,
    phone: "(855) 657-6800",
    lat: 30.142783,
    lng: -81.630518,
    id: 1573,
  },
  {
    name: "Sevel, Ryan",
    address: "1815 Griffin Rd.",
    city: "DANIA BEACH",
    state: "FL",
    zip: 33004,
    phone: "(954) 624-7900",
    lat: 26.062282,
    lng: -80.160602,
    id: 1574,
  },
  {
    name: "SEVERSON, KAREN",
    address: "12765 Forest Hill Blvd , Suite 1309",
    city: "WELLINGTON",
    state: "FL",
    zip: 33414,
    phone: "(561) 288-6979",
    lat: 26.67147,
    lng: -80.2473,
    id: 1575,
  },
  {
    name: "Shaaban, Muhammad",
    address: "2306 North Highway 77",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 250-0021",
    lat: 30.18988,
    lng: -85.649538,
    id: 1576,
  },
  {
    name: "SHAH, CHINTAN",
    address: "2215 NEBRASKA AVENUE , SUITE 2B",
    city: "FORT PIERCE",
    state: "FL",
    zip: 34950,
    phone: "(772) 302-3767",
    lat: 27.432588,
    lng: -80.344789,
    id: 1577,
  },
  {
    name: "SHAH, NISHAN",
    address: "2810 Manatee Avenue East",
    city: "BRADENTON",
    state: "FL",
    zip: 34208,
    phone: "(941) 226-0206",
    lat: 27.495609,
    lng: -82.528502,
    id: 1578,
  },
  {
    name: "SHAH, SAURIN",
    address: "13731 METROPOLIS AVE",
    city: "FORT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(239) 333-2742",
    lat: 26.543228,
    lng: -81.853143,
    id: 1579,
  },
  {
    name: "SHAH, SYED",
    address: "1315 S. Howard Ave",
    city: "TAMPA",
    state: "FL",
    zip: 33606,
    phone: "(813) 350-9090",
    lat: 27.929199,
    lng: -82.483506,
    id: 1580,
  },
  {
    name: "SHAHLA, ZIAD",
    address: "8800 TERRENE COURT , SUITE 102",
    city: "BONITA SPRINGS",
    state: "FL",
    zip: 34135,
    phone: "(941) 498-7090",
    lat: 26.353539,
    lng: -81.807081,
    id: 1581,
  },
  {
    name: "SHAPIRO, DAVID",
    address: "561 561 SOUTH DUNCAN AVENUE",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 724-4279",
    lat: 27.959484,
    lng: -82.766889,
    id: 1582,
  },
  {
    name: "SHAREEF, HUMAYUN",
    address: "2401 FRIST BLVD , SUITE 1",
    city: "FORT PIERCE",
    state: "FL",
    zip: 34950,
    phone: "(772) 465-6979",
    lat: 27.43133,
    lng: -80.348787,
    id: 1583,
  },
  {
    name: "SHARMA, PRIXIT",
    address: "1005 WEST CIRCLE ST.",
    city: "AVON PARK",
    state: "FL",
    zip: 33825,
    phone: "(863) 453-5500",
    lat: 27.599854,
    lng: -81.512847,
    id: 1584,
  },
  {
    name: "SHARMA, SHEKHAR",
    address: "3347 SOUTH STATE ROAD 7, SUITE 200",
    city: "WELLINGTON",
    state: "FL",
    zip: 33449,
    phone: "(561) 795-9087",
    lat: 26.628506,
    lng: -80.206299,
    id: 1585,
  },
  {
    name: "SHAWN, LESLIE",
    address: "500 NORTH HIATUS ROAD , SUITE 203",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33026,
    phone: "(954) 442-3434",
    lat: 26.01219,
    lng: -80.29648,
    id: 1586,
  },
  {
    name: "SHEEHAN, ANTHONY",
    address: "5000 W OAKLAND PARK BLVD",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33313,
    phone: "(954) 730-2895",
    lat: 26.162806,
    lng: -80.218495,
    id: 1587,
  },
  {
    name: "SHELL, STEPHANIE",
    address: "21300 Gertrude Avenue , SUITE 1",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 743-3311",
    lat: 26.992609,
    lng: -82.097691,
    id: 1588,
  },
  {
    name: "SHELL, STEVEN",
    address: "21300 GERTRUDE AVENUE , SUITE 1",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 743-3311",
    lat: 26.992609,
    lng: -82.097691,
    id: 1589,
  },
  {
    name: "SHER, JERRY",
    address: "2260 NE 123RD ST",
    city: "NORTH MIAMI",
    state: "FL",
    zip: 33181,
    phone: "(305) 674-5956",
    lat: 25.889398,
    lng: -80.152928,
    id: 1590,
  },
  {
    name: "SHERMAN, ROBERT",
    address: "3455 Pine Ridge Road",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 597-5700",
    lat: 26.21176,
    lng: -81.74788,
    id: 1591,
  },
  {
    name: "SHERMAN, SCOTT",
    address: "5948 TURKEY LAKE ROAD",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(407) 288-8080",
    lat: 28.477484,
    lng: -81.475854,
    id: 1592,
  },
  {
    name: "SHETH, BAIJU",
    address: "10831 BARBADOSE ISLE DR",
    city: "TAMPA",
    state: "FL",
    zip: 33647,
    phone: "(636) 212-0667",
    lat: 28.127834,
    lng: -82.350338,
    id: 1593,
  },
  {
    name: "SHIELDS, JAMES",
    address: "1865 Lime Street , Suite 101",
    city: "FERNANDINA BEACH",
    state: "FL",
    zip: 32034,
    phone: "(904) 321-2422",
    lat: 30.654567,
    lng: -81.448303,
    id: 1594,
  },
  {
    name: "Shivraman, Suchitra",
    address: "701 Ma 701 Manatee Ave West, Suite 10",
    city: "BRADENTON",
    state: "FL",
    zip: 34205,
    phone: "(503) 888-7110",
    lat: 27.484084,
    lng: -82.581821,
    id: 1595,
  },
  {
    name: "SHOKAT, MAXIMILIAN",
    address: "619 SW Baya Dr , Suite 102",
    city: "LAKE CITY",
    state: "FL",
    zip: 32025,
    phone: "(229) 226-2234",
    lat: 30.187324,
    lng: -82.644013,
    id: 1596,
  },
  {
    name: "SHOUKAS, JAMES",
    address: "934 WILLISTON PARK POINT , SUITE 1028",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 333-2518",
    lat: 28.77251,
    lng: -81.349679,
    id: 1597,
  },
  {
    name: "SHREEVE, THOMAS",
    address: "425 Commercial Court , Suite C",
    city: "VENICE",
    state: "FL",
    zip: 34292,
    phone: "(941) 444-2748",
    lat: 27.10992,
    lng: -82.385927,
    id: 1598,
  },
  {
    name: "SHTENDER, GRIGORY",
    address: "6477 BAY PARKWAY",
    city: "BROOKLYN",
    state: "NY",
    zip: 11204,
    phone: "(718) 234-6767",
    lat: 40.613882,
    lng: -73.98106,
    id: 1599,
  },
  {
    name: "SHULTZ, DEBORAH",
    address: "201 FLAMINGO DRIVE",
    city: "APOLLO BEACH",
    state: "FL",
    zip: 33572,
    phone: "(813) 645-4687",
    lat: 27.765641,
    lng: -82.395905,
    id: 1600,
  },
  {
    name: "SHYDOHUB, ANTHONY",
    address: "40230 HWY 27 , SUITE 160",
    city: "DAVENPORT",
    state: "FL",
    zip: 33837,
    phone: "(407) 354-1091",
    lat: 28.185393,
    lng: -81.640851,
    id: 1601,
  },
  {
    name: "SIANO, JOSEPH",
    address: "487 E. TENNESSEE ST. , SUITE 2",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32301,
    phone: "(850) 321-8756",
    lat: 30.444452,
    lng: -84.277373,
    id: 1602,
  },
  {
    name: "SIBLEY, MARK",
    address: "10770 North 46th Street",
    city: "TAMPA",
    state: "FL",
    zip: 33617,
    phone: "(727) 639-2020",
    lat: 28.046714,
    lng: -82.41005,
    id: 1603,
  },
  {
    name: "SIDDIKI, AWAIS",
    address: "535 Pine Island Rd M",
    city: "NORTH FT MYERS",
    state: "FL",
    zip: 33903,
    phone: "(239) 656-7700",
    lat: 26.679736,
    lng: -81.896824,
    id: 1604,
  },
  {
    name: "SIEGEL, MARC",
    address: "840 goldenbeach blvd",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(540) 808-8746",
    lat: 27.085683,
    lng: -82.455074,
    id: 1605,
  },
  {
    name: "SIEGEL, ROBERT",
    address: "345 MIRACLE STRIP PKWY S W",
    city: "FORT WALTON BEACH",
    state: "FL",
    zip: 32548,
    phone: "(850) 244-3211",
    lat: 30.407916,
    lng: -86.636888,
    id: 1606,
  },
  {
    name: "SIEGRIST, JOY",
    address: "230 Linger Ln",
    city: "SUN CITY CENTER",
    state: "FL",
    zip: 33573,
    phone: "(813) 330-0607",
    lat: 27.723746,
    lng: -82.336366,
    id: 1607,
  },
  {
    name: "SILEBI, VANESSA",
    address: "3661 SOUTH MIAMI AVENUE , SUITE 108",
    city: "MIAMI",
    state: "FL",
    zip: 33133,
    phone: "(305) 858-3900",
    lat: 25.740156,
    lng: -80.21405,
    id: 1608,
  },
  {
    name: "SILVA, BRIDGET",
    address: "10220 W SAMPLE ROAD",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 340-1123",
    lat: 26.272034,
    lng: -80.26261,
    id: 1609,
  },
  {
    name: "SILVA, RICHARD",
    address: "1100 South Federal Hwy , STE 100",
    city: "DEERFIELD BEACH",
    state: "FL",
    zip: 33441,
    phone: "(954) 943-9670",
    lat: 26.303481,
    lng: -80.092493,
    id: 1610,
  },
  {
    name: "SILVA, VICTOR",
    address: "3550 West Waters Ave. , Suite 260",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(813) 443-1364",
    lat: 28.024924,
    lng: -82.499821,
    id: 1611,
  },
  {
    name: "SILVERMAN, JOEL",
    address: "7301 W. PALMETTO PARK ROAD , SUITE 101-A",
    city: "BOCA RATON",
    state: "FL",
    zip: 33433,
    phone: "(954) 818-9729",
    lat: 26.350459,
    lng: -80.159412,
    id: 1612,
  },
  {
    name: "SILVERS, DAVID",
    address: "3401 PGA BLVD. , #440",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 799-2831",
    lat: 26.84516,
    lng: -80.08866,
    id: 1613,
  },
  {
    name: "SILVESTRI, FRED",
    address: "1004 S. Old Dixie Hwy , SUITE 303",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 741-5549",
    lat: 26.925461,
    lng: -80.094415,
    id: 1614,
  },
  {
    name: "SINGER, ALAN",
    address: "17511 N. DALE MABRY",
    city: "LUTZ",
    state: "FL",
    zip: 33548,
    phone: "(813) 962-6700",
    lat: 28.123494,
    lng: -82.501437,
    id: 1615,
  },
  {
    name: "SINGER, ALLEN",
    address: "7800 SW 57 Avenue , Suite 200",
    city: "MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 279-9313",
    lat: 25.699032,
    lng: -80.28536,
    id: 1616,
  },
  {
    name: "SINGER, MELISSA",
    address: "12957 PALMS WEST DRIVE , # 103",
    city: "LOXAHATCHEE",
    state: "FL",
    zip: 33470,
    phone: "(561) 798-9119",
    lat: 26.684296,
    lng: -80.249484,
    id: 1617,
  },
  {
    name: "SINGER, MICHAEL",
    address: "9157 HUEBNER ROAD",
    city: "SAN ANTONIO",
    state: "TX",
    zip: 78240,
    phone: "(210) 697-2020",
    lat: 29.528582,
    lng: -98.596101,
    id: 1618,
  },
  {
    name: "SINGH, SUDEEP",
    address: "6200 SW 73rd St",
    city: "MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(786) 662-4000",
    lat: 25.702663,
    lng: -80.293744,
    id: 1619,
  },
  {
    name: "SINGH, SUNANDA",
    address: "14153 YOSEMITE DRIVE, SUITE 101",
    city: "HUDSON",
    state: "FL",
    zip: 34667,
    phone: "(727) 222-0806",
    lat: 28.363409,
    lng: -82.687218,
    id: 1620,
  },
  {
    name: "Skelly, Sarah",
    address: "907 Mar Walt Dr",
    city: "FORT WALTON BEACH",
    state: "FL",
    zip: 32547,
    phone: "(850) 580-7890",
    lat: 30.450218,
    lng: -86.63173,
    id: 1621,
  },
  {
    name: "SLOAN, DEAN",
    address: "3313 W Hillsboro Blvd , Suite 200",
    city: "DEERFIELD BEACH",
    state: "FL",
    zip: 33442,
    phone: "(954) 420-0886",
    lat: 26.318756,
    lng: -80.148124,
    id: 1622,
  },
  {
    name: "SLOTNICK, DAVID",
    address: "1601 CLINT MOORE ROAD , SUITE 115",
    city: "BOCA RATON",
    state: "FL",
    zip: 33487,
    phone: "(561) 997-8484",
    lat: 26.40899,
    lng: -80.116413,
    id: 1623,
  },
  {
    name: "Sluch, Ilya",
    address: "1592 South SR 15A",
    city: "DELAND",
    state: "FL",
    zip: 32720,
    phone: "(386) 734-2931",
    lat: 28.997723,
    lng: -81.308946,
    id: 1624,
  },
  {
    name: "SMALLOW, STEVAN",
    address: "16056 Brier creek drive",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33446,
    phone: "(754) 400-8983",
    lat: 26.437813,
    lng: -80.179309,
    id: 1625,
  },
  {
    name: "SMIRNOFF, ALEXANDER",
    address: "729 CR 466",
    city: "LADY LAKE",
    state: "FL",
    zip: 32159,
    phone: "(352) 633-2164",
    lat: 28.916524,
    lng: -81.938107,
    id: 1626,
  },
  {
    name: "SMITH, CHARLES",
    address: "4341 SW 114th Terrace , Apt# 8310",
    city: "MIRAMAR",
    state: "FL",
    zip: 33025,
    phone: "(813) 802-6352",
    lat: 25.98821,
    lng: -80.281374,
    id: 1627,
  },
  {
    name: "SMITH, CHRISTOPHER",
    address: "616 Altamonte Drive , Suite 204",
    city: "ALTAMONTE SPRINGS",
    state: "FL",
    zip: 32701,
    phone: "(407) 960-4007",
    lat: 28.663417,
    lng: -81.368401,
    id: 1628,
  },
  {
    name: "SMITH, DANIEL",
    address: "21298 OLEAN BLVD",
    city: "PORT CHARLOTTE",
    state: "FL",
    zip: 33952,
    phone: "(941) 235-5800",
    lat: 26.988577,
    lng: -82.097973,
    id: 1629,
  },
  {
    name: "SMITH, DOUGLAS",
    address: "603 N FLAMINGO ROAD , SUITE 361",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33028,
    phone: "(954) 432-7900",
    lat: 26.01223,
    lng: -80.31188,
    id: 1630,
  },
  {
    name: "SMITH, JEFFREY",
    address: "320 N CLYDE MORRIS BLVD",
    city: "DAYTONA BEACH",
    state: "FL",
    zip: 32114,
    phone: "(386) 238-3293",
    lat: 29.203089,
    lng: -81.056969,
    id: 1631,
  },
  {
    name: "SMITH, MICHELLE",
    address: "3700 CREIGHTON RD , SUITE 4",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 473-1441",
    lat: 30.499775,
    lng: -87.174531,
    id: 1632,
  },
  {
    name: "Smith, Milton",
    address: "951 North Washington Ave.",
    city: "TITUSVILLE",
    state: "FL",
    zip: 32796,
    phone: "(321) 268-6111",
    lat: 28.62805,
    lng: -80.82426,
    id: 1633,
  },
  {
    name: "SMITH, RICHARD",
    address: "6301 MEMORIAL HWY , SUITE 303",
    city: "TAMPA",
    state: "FL",
    zip: 33615,
    phone: "(813) 284-7022",
    lat: 27.991232,
    lng: -82.575504,
    id: 1634,
  },
  {
    name: "SMITH-MARTINEZ, CHERYLL",
    address: "13800 Veterans Way",
    city: "ORLANDO",
    state: "FL",
    zip: 32827,
    phone: "(407) 631-1000",
    lat: 28.366196,
    lng: -81.276056,
    id: 1635,
  },
  {
    name: "SNOW, KAREN",
    address: "1921 EAST NINE MILE ROAD",
    city: "PENSACOLA",
    state: "FL",
    zip: 32514,
    phone: "(850) 696-4000",
    lat: 30.531626,
    lng: -87.218906,
    id: 1636,
  },
  {
    name: "SOFAT, ATUL",
    address: "3501 Johnson St",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 265-6959",
    lat: 26.019794,
    lng: -80.177876,
    id: 1637,
  },
  {
    name: "SOFF, MATTHEW",
    address: "8329 W Sunrise Blvd",
    city: "PLANTATION",
    state: "FL",
    zip: 33322,
    phone: "(954) 627-1617",
    lat: 26.148496,
    lng: -80.261419,
    id: 1638,
  },
  {
    name: "SOLEYMANI, SAMAN",
    address: "1633 RACETRACK RD Suite 101",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32259,
    phone: "(904) 230-6988",
    lat: 30.122089,
    lng: -81.621434,
    id: 1639,
  },
  {
    name: "SOMMERS, ANDREA",
    address: "2300 N 2300 N Commerce Pkwy Ste 313",
    city: "WESTON",
    state: "FL",
    zip: 33326,
    phone: "(954) 217-2707",
    lat: 26.114337,
    lng: -80.37067,
    id: 1640,
  },
  {
    name: "SONN, GREGORY",
    address: "15620 mcgregor blvd suite 100",
    city: "FT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 689-6819",
    lat: 26.514936,
    lng: -81.949055,
    id: 1641,
  },
  {
    name: "SONTHEIMER, DANIEL",
    address: "9400 University Parkway , suite 200",
    city: "PENSACOLA",
    state: "FL",
    zip: 32514,
    phone: "(850) 437-8614",
    lat: 30.534005,
    lng: -87.218854,
    id: 1642,
  },
  {
    name: "SORIANO, VICTOR",
    address: "1264 US HWY 1 , STE 105",
    city: "ROCKLEDGE",
    state: "FL",
    zip: 32955,
    phone: "(321) 877-0007",
    lat: 28.339938,
    lng: -80.727303,
    id: 1643,
  },
  {
    name: "SOSA, JOHN",
    address: "3601 Madaca Ln., Unit 3601",
    city: "TAMPA",
    state: "FL",
    zip: 33618,
    phone: "(813) 992-7478",
    lat: 28.083813,
    lng: -82.501451,
    id: 1644,
  },
  {
    name: "SOURIAL, SOURIAL",
    address: "9422 Arlington Expressway",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32225,
    phone: "(904) 559-1844",
    lat: 30.320765,
    lng: -81.553899,
    id: 1645,
  },
  {
    name: "SPECTOR, STEVE",
    address: "8198 JOG ROAD, SUITE 102",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33472,
    phone: "(561) 777-8946",
    lat: 26.554373,
    lng: -80.149861,
    id: 1646,
  },
  {
    name: "SPEIZMAN, DAVID",
    address: "2015 OCEAN DR , STE 8",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33426,
    phone: "(561) 966-7717",
    lat: 26.524216,
    lng: -80.087981,
    id: 1647,
  },
  {
    name: "SPENCER, ROGER",
    address: "1515 Herbert Street",
    city: "PORT ORANGE",
    state: "FL",
    zip: 32129,
    phone: "(386) 366-9119",
    lat: 29.130889,
    lng: -81.023707,
    id: 1648,
  },
  {
    name: "SPIRAZZA, CARL",
    address: "10151 ENTERPRISE CENTER BLVD , SUITE 106",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 734-7400",
    lat: 26.525909,
    lng: -80.167654,
    id: 1649,
  },
  {
    name: "SPLAVER, ADAM",
    address: "3700 WASHINGTON ST. - SUITE 400",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 980-0361",
    lat: 26.002912,
    lng: -80.180709,
    id: 1650,
  },
  {
    name: "SPRINGS, JULIA",
    address: "6530 Park Blvd",
    city: "PINELLAS PARK",
    state: "FL",
    zip: 33781,
    phone: "(844) 482-6263",
    lat: 27.838738,
    lng: -82.726862,
    id: 1651,
  },
  {
    name: "SRIVASTAVA, SUNIT",
    address: "3600 OAK MANOR LN #46",
    city: "LARGO",
    state: "FL",
    zip: 33774,
    phone: "(727) 489-3305",
    lat: 27.895369,
    lng: -82.826264,
    id: 1652,
  },
  {
    name: "ST. CLAIR, DOUGLAS",
    address: "325 5TH AVENEUE, Suite 204",
    city: "INDIALANTIC",
    state: "FL",
    zip: 32903,
    phone: "(321) 821-4889",
    lat: 28.0902,
    lng: -80.57151,
    id: 1653,
  },
  {
    name: "STACHTIARIS, ACHILLES",
    address: "5070 Ocean Drive Plastic Surgery , Suite A",
    city: "VERO BEACH",
    state: "FL",
    zip: 32963,
    phone: "(772) 213-8255",
    lat: 27.646014,
    lng: -80.354054,
    id: 1654,
  },
  {
    name: "Stall, Robert",
    address: "Bay Pi 10000 Bay Pines Boulevard",
    city: "BAY PINES",
    state: "FL",
    zip: 33744,
    phone: "(727) 398-6661",
    lat: 30.419323,
    lng: -85.871608,
    id: 1655,
  },
  {
    name: "STARIKOV, ALBERT",
    address: "1920 E Hallandale Beach , Ste 901",
    city: "HALLANDALE BEACH",
    state: "FL",
    zip: 33009,
    phone: "(954) 204-0054",
    lat: 25.985572,
    lng: -80.128388,
    id: 1656,
  },
  {
    name: "STARKE, MICHELLE",
    address: "135 SAN LORENZO AVENUE , SUITE 550",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33146,
    phone: "(305) 665-8188",
    lat: 25.73176,
    lng: -80.257078,
    id: 1657,
  },
  {
    name: "STATUM, KASEY",
    address: "2645 S. Orange Ave.",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(321) 430-2060",
    lat: 28.513259,
    lng: -81.376086,
    id: 1658,
  },
  {
    name: "STEELE, JAMES",
    address: "3275 Darwin SQ",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34953,
    phone: "(772) 742-9060",
    lat: 27.249708,
    lng: -80.375701,
    id: 1659,
  },
  {
    name: "STEIN, JOEL",
    address: "4109 N FEDERAL HIGHWAY",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 563-2707",
    lat: 26.178772,
    lng: -80.119993,
    id: 1660,
  },
  {
    name: "STEINBERG, JEFF",
    address: "130 S University Dr , STE C",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 900-6699",
    lat: 26.119007,
    lng: -80.250556,
    id: 1661,
  },
  {
    name: "STEINER, DAVID",
    address: "3700 WASHINGTON STREET , SUITE 500A",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 989-4700",
    lat: 26.002912,
    lng: -80.180709,
    id: 1662,
  },
  {
    name: "STENZLER, STEPHEN",
    address: "390 North Orange Ave. , Suite 2300",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(407) 627-0314",
    lat: 28.54695,
    lng: -81.379317,
    id: 1663,
  },
  {
    name: "STEPHENS, PATRICIA",
    address: "7825 BAYMEADOWS WAY , 300",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 390-4600",
    lat: 30.229261,
    lng: -81.581386,
    id: 1664,
  },
  {
    name: "STEVENS, RICK",
    address: "5149 N. Ninth Ave. , Suite 246",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(850) 416-1080",
    lat: 30.475149,
    lng: -87.213107,
    id: 1665,
  },
  {
    name: "STEVENSON, JOHN",
    address: "4500 NEWBERRY RD",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32607,
    phone: "(352) 336-6000",
    lat: 29.657155,
    lng: -82.392222,
    id: 1666,
  },
  {
    name: "STEWART, BRENT",
    address: "194 NW 137TH DRIVE , SUITE 100",
    city: "NEWBERRY",
    state: "FL",
    zip: 32669,
    phone: "(352) 508-8668",
    lat: 29.655288,
    lng: -82.48895,
    id: 1667,
  },
  {
    name: "STOICI, ROXANA",
    address: "400 15TH STREET N",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33705,
    phone: "(727) 498-6554",
    lat: 27.77663,
    lng: -82.654133,
    id: 1668,
  },
  {
    name: "STOKES, STEVEN",
    address: "2100 state ave.",
    city: "PANAMA CITY",
    state: "FL",
    zip: 32405,
    phone: "(850) 890-7766",
    lat: 30.186224,
    lng: -85.666228,
    id: 1669,
  },
  {
    name: "STRONG, DAVID",
    address: "800 MEADOWS RD.",
    city: "BOCA RATON",
    state: "FL",
    zip: 33486,
    phone: "(561) 955-4425",
    lat: 26.358492,
    lng: -80.103499,
    id: 1670,
  },
  {
    name: "STROUP, EDWIN",
    address: "1627 US 1, Suite 211",
    city: "SEBASTIAN",
    state: "FL",
    zip: 32958,
    phone: "(772) 202-7976",
    lat: 27.824112,
    lng: -80.475294,
    id: 1671,
  },
  {
    name: "SUBRAMANIAN, VALLI",
    address: "800 SOUTH FORT HARRISON AVE",
    city: "CLEARWATER",
    state: "FL",
    zip: 33756,
    phone: "(727) 447-8805",
    lat: 27.956995,
    lng: -82.800182,
    id: 1672,
  },
  {
    name: "SUDDERTH, DAVID",
    address: "3210 CLEVELAND AVENUE , SUITE 100",
    city: "FT MYERS",
    state: "FL",
    zip: 33901,
    phone: "(239) 275-6690",
    lat: 26.618385,
    lng: -81.872709,
    id: 1673,
  },
  {
    name: "SULLIVAN, THOMAS",
    address: "1591 Saint James Cir.",
    city: "THE VILLAGES",
    state: "FL",
    zip: 32162,
    phone: "(315) 729-1357",
    lat: 28.953945,
    lng: -82.006056,
    id: 1674,
  },
  {
    name: "SWAIN, SHYAM",
    address: "7105 MARINER BLVD",
    city: "SPRING HILL",
    state: "FL",
    zip: 34609,
    phone: "(352) 596-1339",
    lat: 28.530937,
    lng: -82.512112,
    id: 1675,
  },
  {
    name: "SWANSON, PAUL",
    address: "1871 S.E. TIFFANY AVENUE , SUITE 100",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-5666",
    lat: 27.291802,
    lng: -80.294805,
    id: 1676,
  },
  {
    name: "SYED, ASMA",
    address: "5808 OLD PASCO RD",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33544,
    phone: "(813) 907-0033",
    lat: 28.241317,
    lng: -82.363935,
    id: 1677,
  },
  {
    name: "SYVERSON, THOMAS",
    address: "155 SE US Hwy 19",
    city: "CRYSTAL RIVER",
    state: "FL",
    zip: 34429,
    phone: "(352) 464-4334",
    lat: 28.893908,
    lng: -82.583631,
    id: 1678,
  },
  {
    name: "TADROS, WAGDY",
    address: "4469 NORTH STATE ROAD 7",
    city: "LAUDERDALE LAKES",
    state: "FL",
    zip: 33319,
    phone: "(954) 486-1966",
    lat: 26.180452,
    lng: -80.203614,
    id: 1679,
  },
  {
    name: "TAHIRAJ, GENTI",
    address: "6000 49th St N",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33709,
    phone: "(727) 521-4411",
    lat: 27.825632,
    lng: -82.702305,
    id: 1680,
  },
  {
    name: "TALAKKOTTUR, CHARLES",
    address: "5106 NORTH ARMENIA AVENUE , SUITE 1",
    city: "TAMPA",
    state: "FL",
    zip: 33603,
    phone: "(813) 874-1852",
    lat: 27.992884,
    lng: -82.485151,
    id: 1681,
  },
  {
    name: "TALATI, AMAR",
    address: "2901 1st Avenue North , Suite 301",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 379-4365",
    lat: 27.772197,
    lng: -82.672871,
    id: 1682,
  },
  {
    name: "TALEBI, TONY",
    address: "151 NW 11TH STREET, SUITE 304E",
    city: "HOMESTEAD",
    state: "FL",
    zip: 33030,
    phone: "(786) 504-3084",
    lat: 25.481385,
    lng: -80.480884,
    id: 1683,
  },
  {
    name: "TAMAR, KYRON",
    address: "2320 Wintermere Point Dr.",
    city: "WINTER GARDEN",
    state: "FL",
    zip: 34787,
    phone: "(813) 732-3801",
    lat: 28.507802,
    lng: -81.589809,
    id: 1684,
  },
  {
    name: "TAMBINI, PAMELA",
    address: "7305 N. Military Trail",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 701-6967",
    lat: 26.786075,
    lng: -80.111766,
    id: 1685,
  },
  {
    name: "TAMEA, CONRAD",
    address: "4505 North Armenia",
    city: "TAMPA",
    state: "FL",
    zip: 33603,
    phone: "(727) 667-6783",
    lat: 27.983979,
    lng: -82.484287,
    id: 1686,
  },
  {
    name: "TANDATNICK, CARL",
    address: "1865 Veterans Park Dr. Ste 101",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 254-7778",
    lat: 26.269409,
    lng: -81.782052,
    id: 1687,
  },
  {
    name: "TANVETYANON, TAWEE",
    address: "12902 MAGNOLIA DR",
    city: "TAMPA",
    state: "FL",
    zip: 33612,
    phone: "(813) 745-3050",
    lat: 28.059655,
    lng: -82.419115,
    id: 1688,
  },
  {
    name: "TARUGU, VIKRAM",
    address: "201 SW 16th St",
    city: "OKEECHOBEE",
    state: "FL",
    zip: 34974,
    phone: "(863) 824-3447",
    lat: 27.228236,
    lng: -80.83163,
    id: 1689,
  },
  {
    name: "TAUBE, RYAN",
    address: "4725 N FEDERAL HWY",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 492-5753",
    lat: 26.187074,
    lng: -80.119447,
    id: 1690,
  },
  {
    name: "TAUBMAN, RANDALL",
    address: "5870 N. Hiatus Road , Ste 200",
    city: "TAMARAC",
    state: "FL",
    zip: 33321,
    phone: "(850) 488-0595",
    lat: 26.19679,
    lng: -80.2879,
    id: 1691,
  },
  {
    name: "TAYLOR, ALEXANDRA",
    address: "900 Linton Blvd Ste 205",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33444,
    phone: "(561) 272-5409",
    lat: 26.439535,
    lng: -80.082091,
    id: 1692,
  },
  {
    name: "Taylor, Christin",
    address: "646 Virginia Street , 7th floor",
    city: "DUNEDIN",
    state: "FL",
    zip: 34698,
    phone: "(727) 736-2730",
    lat: 28.013422,
    lng: -82.782603,
    id: 1693,
  },
  {
    name: "TAYLOR, JANET",
    address: "630 South Orange, Suite 103",
    city: "SARASOTA",
    state: "FL",
    zip: 34236,
    phone: "(941) 807-6404",
    lat: 27.330487,
    lng: -82.538861,
    id: 1694,
  },
  {
    name: "TAYLOR, MARC",
    address: "8950 9TH STREET NORTH , SUITE 102",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33702,
    phone: "(727) 498-8898",
    lat: 27.853643,
    lng: -82.647113,
    id: 1695,
  },
  {
    name: "TAYLOR, THOMAS",
    address: "463832 SR 200",
    city: "YULEE",
    state: "FL",
    zip: 32097,
    phone: "(904) 225-2621",
    lat: 30.625619,
    lng: -81.548041,
    id: 1696,
  },
  {
    name: "TEDTAOTAO, MARIA",
    address: "1303 MOSLEY DRIVE",
    city: "LYNN HAVEN",
    state: "FL",
    zip: 32444,
    phone: "(850) 271-5996",
    lat: 30.212941,
    lng: -85.629097,
    id: 1697,
  },
  {
    name: "TEEKELL-TAYLOR, LEAH",
    address: "8950 9th St N , Suite 102",
    city: "ST PETERSBURG",
    state: "FL",
    zip: 33702,
    phone: "(727) 498-8898",
    lat: 27.853643,
    lng: -82.647113,
    id: 1698,
  },
  {
    name: "TELLERIA, MIGUEL",
    address: "9066 9066 sw 73 ct , Apt # 2404",
    city: "PINECREST",
    state: "FL",
    zip: 33156,
    phone: "(305) 205-2306",
    lat: 25.686586,
    lng: -80.314595,
    id: 1699,
  },
  {
    name: "TEMER, ANYA",
    address: "4897 S Jog Rd Suite A",
    city: "LAKE WORTH",
    state: "FL",
    zip: 33467,
    phone: "(561) 323-4994",
    lat: 26.606224,
    lng: -80.147447,
    id: 1700,
  },
  {
    name: "TEMPLE, DONALD",
    address: "3450 East Lake Road Suite 303",
    city: "PALM HARBOR",
    state: "FL",
    zip: 34685,
    phone: "(727) 683-6160",
    lat: 28.06117,
    lng: -82.707496,
    id: 1701,
  },
  {
    name: "TENDLER, ARON",
    address: "11903 SOUTHERN BLVD , SUITE 104",
    city: "ROYAL PALM BEACH",
    state: "FL",
    zip: 33411,
    phone: "(561) 333-8884",
    lat: 26.6815,
    lng: -80.23389,
    id: 1702,
  },
  {
    name: "TEPPER, EVAN",
    address: "3100 East Fletcher Avenue",
    city: "TAMPA",
    state: "FL",
    zip: 33613,
    phone: "(813) 396-6459",
    lat: 28.071278,
    lng: -82.423966,
    id: 1703,
  },
  {
    name: "THACKER, CLIFFORD",
    address: "9818 Old Hyde Park Place",
    city: "BRADENTON",
    state: "FL",
    zip: 34202,
    phone: "(813) 787-8388",
    lat: 27.40333,
    lng: -82.44085,
    id: 1704,
  },
  {
    name: "THOMAS, DELVENA",
    address: "2699 Stirling Rd , Ste C407",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33312,
    phone: "(305) 981-1700",
    lat: 26.048248,
    lng: -80.175948,
    id: 1705,
  },
  {
    name: "THOMAS, DONALD",
    address: "2840 ALTON DRIVE",
    city: "ST PETERSBURG BEACH",
    state: "FL",
    zip: 33706,
    phone: "(727) 224-5991",
    lat: 27.704722,
    lng: -82.732368,
    id: 1706,
  },
  {
    name: "THOMAS, JOSEPH",
    address: "4114 Sunbeam Road Bldg 100 Ste 102",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32257,
    phone: "(904) 262-9444",
    lat: 30.204196,
    lng: -81.609226,
    id: 1707,
  },
  {
    name: "THOMAS, MARI",
    address: "6499 38th Ave. N. , Suite C1",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 440-7786",
    lat: 27.806684,
    lng: -82.725893,
    id: 1708,
  },
  {
    name: "THOMAS, MATTHEW",
    address: "581 Scrubjay Lane",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 324-7349",
    lat: 26.907507,
    lng: -80.127844,
    id: 1709,
  },
  {
    name: "Thomas, Zinia",
    address: "2 Tall Timbers",
    city: "ST. LOUIS",
    state: "MO",
    zip: 63124,
    phone: "(203) 927-6377",
    lat: 38.630509,
    lng: -90.384739,
    id: 1710,
  },
  {
    name: "THOMPSON, MICHELLE",
    address: "3255 NW 94th Ave, #3255",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33075,
    phone: "(954) 210-7729",
    lat: 26.270358,
    lng: -80.252063,
    id: 1711,
  },
  {
    name: "THORNLEY, VIRGINIA",
    address: "4014 Sawyer Road",
    city: "SARASOTA",
    state: "FL",
    zip: 34233,
    phone: "(941) 363-1370",
    lat: 27.29705,
    lng: -82.490039,
    id: 1712,
  },
  {
    name: "THROWER, ANGELO",
    address: "3351 N UNIVERSITY DRIVE",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33024,
    phone: "(305) 757-9797",
    lat: 26.040048,
    lng: -80.247572,
    id: 1713,
  },
  {
    name: "TILAK, MILIND",
    address: "430 COLLEGE DR, Suite 104-106",
    city: "MIDDLEBURG",
    state: "FL",
    zip: 32068,
    phone: "(904) 298-1994",
    lat: 30.108656,
    lng: -81.777084,
    id: 1714,
  },
  {
    name: "TILLMAN, DAVID",
    address: "4401 EAST COLONIAL DRIVE , SUITE 102",
    city: "ORLANDO",
    state: "FL",
    zip: 32803,
    phone: "(407) 895-7246",
    lat: 28.553959,
    lng: -81.330811,
    id: 1715,
  },
  {
    name: "TIRMAL, VIRAJ",
    address: "911 EAST OAKLAND PARK BOULEVARD",
    city: "OAKLAND PARK",
    state: "FL",
    zip: 33334,
    phone: "(954) 800-0931",
    lat: 26.167032,
    lng: -80.135269,
    id: 1716,
  },
  {
    name: "TOBER, ROBERT",
    address: "6400 DAVIS BLVD. , SUITE 104",
    city: "NAPLES",
    state: "FL",
    zip: 34104,
    phone: "(239) 403-2600",
    lat: 26.13861,
    lng: -81.73611,
    id: 1717,
  },
  {
    name: "TOCHTENHAGEN, CHLOE",
    address: "294 Paseo Reyes Drive",
    city: "ST AUGUSTINE",
    state: "FL",
    zip: 32095,
    phone: "(904) 513-0113",
    lat: 30.015473,
    lng: -81.392704,
    id: 1718,
  },
  {
    name: "TOLIA, BHARAT",
    address: "381 PALM COAST PARKWAY , SUITE 4",
    city: "PALM COAST",
    state: "FL",
    zip: 32137,
    phone: "(386) 445-2300",
    lat: 29.551439,
    lng: -81.246702,
    id: 1719,
  },
  {
    name: "TOLLI, THOMAS",
    address: "1001 37TH ST N #C",
    city: "ST. PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(727) 328-1001",
    lat: 27.781859,
    lng: -82.68352,
    id: 1720,
  },
  {
    name: "Tomaszczyk, Michael",
    address: "3871 South Luce Road",
    city: "MUSKEGON",
    state: "MI",
    zip: 49412,
    phone: "(231) 955-0790",
    lat: 43.483941,
    lng: -85.922469,
    id: 1721,
  },
  {
    name: "TOMCHIK, ROBERT",
    address: "3161 Dykes Rd",
    city: "MIRAMAR",
    state: "FL",
    zip: 33027,
    phone: "(954) 450-3550",
    lat: 25.9802,
    lng: -80.359672,
    id: 1722,
  },
  {
    name: "TON, CUONG",
    address: "8348 Little Rd PMB 138",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34654,
    phone: "(407) 925-7211",
    lat: 28.278844,
    lng: -82.674684,
    id: 1723,
  },
  {
    name: "TOOTLE, KAREN",
    address: "6115 Village Oaks Drive",
    city: "PENSACOLA",
    state: "FL",
    zip: 32504,
    phone: "(276) 492-6904",
    lat: 30.48886,
    lng: -87.227188,
    id: 1724,
  },
  {
    name: "TORRES, BENITO",
    address: "631 Midflorida Dr",
    city: "LAKELAND",
    state: "FL",
    zip: 33813,
    phone: "(863) 583-4445",
    lat: 27.95753,
    lng: -81.965982,
    id: 1725,
  },
  {
    name: "TORRES, JAIME",
    address: "6279 N. Lecanto Hwy",
    city: "BEVERLY HILLS",
    state: "FL",
    zip: 34465,
    phone: "(352) 522-0094",
    lat: 28.955222,
    lng: -82.439682,
    id: 1726,
  },
  {
    name: "TORRES, JOSEPH",
    address: "309 WEST BASS STREET",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(321) 231-3509",
    lat: 28.299198,
    lng: -81.407257,
    id: 1727,
  },
  {
    name: "TORRES, WALDEMAR",
    address: "5540 Saratoga Blvd , Suite 200",
    city: "CORPUS CHRISTI",
    state: "TX",
    zip: 78413,
    phone: "(361) 993-8510",
    lat: 27.686234,
    lng: -97.384178,
    id: 1728,
  },
  {
    name: "TORRES-PEREZ, WILLIAM",
    address: "11434 NORTH 53RD STREET",
    city: "TEMPLE TERRACE",
    state: "FL",
    zip: 33617,
    phone: "(813) 248-2111",
    lat: 28.052014,
    lng: -82.396036,
    id: 1729,
  },
  {
    name: "TORRES-URRUTIA, JUAN",
    address: "3530 Fruitville Road",
    city: "SARASOTA",
    state: "FL",
    zip: 34237,
    phone: "(941) 552-8808",
    lat: 27.336356,
    lng: -82.498567,
    id: 1730,
  },
  {
    name: "TOTFALUSI, VICTOR",
    address: "3157 N UNIVERSITY DR SUITE 107",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33024,
    phone: "(954) 990-0595",
    lat: 26.037232,
    lng: -80.248034,
    id: 1731,
  },
  {
    name: "TOTH, GEORGE",
    address: "3520 West Waters Ave",
    city: "TAMPA",
    state: "FL",
    zip: 33614,
    phone: "(813) 932-0079",
    lat: 28.025074,
    lng: -82.499014,
    id: 1732,
  },
  {
    name: "TRABIN, JOSHUA",
    address: "2501 NORTH ORANGE AVE , SUITE 235",
    city: "ORLANDO",
    state: "FL",
    zip: 32804,
    phone: "(407) 303-7270",
    lat: 28.57489,
    lng: -81.37198,
    id: 1733,
  },
  {
    name: "TRABUCCO, ARNALDO",
    address: "8990 North Davis Highway, ste 1",
    city: "PENSACOLA",
    state: "FL",
    zip: 32514,
    phone: "(850) 368-6980",
    lat: 30.526497,
    lng: -87.209651,
    id: 1734,
  },
  {
    name: "TRAN, BRADLEY",
    address: "4085 UNIVERSITY BLVD S Suite 3",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 448-4180",
    lat: 30.283026,
    lng: -81.601867,
    id: 1735,
  },
  {
    name: "TRAN, PHU",
    address: "2951 MAPLE AVENUE",
    city: "ZANESVILLE",
    state: "OH",
    zip: 43701,
    phone: "(740) 454-5271",
    lat: 39.975479,
    lng: -82.014625,
    id: 1736,
  },
  {
    name: "TRAPANI, VICENTE",
    address: "1055 N DIXIE FREEWAY , SUITE 1",
    city: "NEW SMYRNA BCH",
    state: "FL",
    zip: 32168,
    phone: "(386) 423-0505",
    lat: 29.041231,
    lng: -80.933533,
    id: 1737,
  },
  {
    name: "TRAVER, MICHAEL",
    address: "1000 Mar Walt Dr",
    city: "FORT WALTON BEACH",
    state: "FL",
    zip: 32547,
    phone: "(850) 862-1111",
    lat: 30.454478,
    lng: -86.633621,
    id: 1738,
  },
  {
    name: "TRAVNICEK, PETRA",
    address: "1250 S TAMIAMI TRAIL , STE 301",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 365-7771",
    lat: 27.3224,
    lng: -82.529708,
    id: 1739,
  },
  {
    name: "TREATMAN, SCOTT",
    address: "415 Holly Road",
    city: "VERO BEACH",
    state: "FL",
    zip: 32963,
    phone: "(315) 247-6994",
    lat: 27.661083,
    lng: -80.36591,
    id: 1740,
  },
  {
    name: "TRIGUEIRO, CRAIG",
    address: "7411 Vista Way #208",
    city: "BRADENTON",
    state: "FL",
    zip: 34202,
    phone: "(941) 224-8743",
    lat: 27.40336,
    lng: -82.449227,
    id: 1741,
  },
  {
    name: "TRINCHETTO, THOMAS",
    address: "6780 ARECA BLVD",
    city: "SARASOTA",
    state: "FL",
    zip: 34241,
    phone: "(941) 321-1011",
    lat: 27.242189,
    lng: -82.442171,
    id: 1742,
  },
  {
    name: "Tronshaw-Gettings, Napatia",
    address: "168 N Powerline Rd",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33065,
    phone: "(708) 478-7080",
    lat: 26.23312,
    lng: -80.15643,
    id: 1743,
  },
  {
    name: "TRUE, JEFFREY",
    address: "538 Canal Street , SUITE 307",
    city: "NEW SMYRNA BEACH",
    state: "FL",
    zip: 32168,
    phone: "(386) 589-6542",
    lat: 29.02324,
    lng: -80.92593,
    id: 1744,
  },
  {
    name: "TSENG, JACK",
    address: "800 WEST PLATT STREET , SUITE 1",
    city: "ELEVATED HEALTHCARE TAMPA",
    state: "FL",
    zip: 33606,
    phone: "(813) 540-3378",
    lat: 27.941548,
    lng: -82.467939,
    id: 1745,
  },
  {
    name: "TUEL, TIMOTHY",
    address: "1717 North E Street Suite 222",
    city: "PENSACOLA",
    state: "FL",
    zip: 32501,
    phone: "(850) 437-8637",
    lat: 30.430275,
    lng: -87.23,
    id: 1746,
  },
  {
    name: "Tuitt, Jerome",
    address: "1101 Lexington Ave",
    city: "SAVANNAH",
    state: "GA",
    zip: 31404,
    phone: "(912) 350-7171",
    lat: 32.033416,
    lng: -81.091009,
    id: 1747,
  },
  {
    name: "TUMMINIA, LOUIS",
    address: "5130 LINTON BOULEVARD , SUITE H-1",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 498-8891",
    lat: 26.439168,
    lng: -80.123854,
    id: 1748,
  },
  {
    name: "TURNER, IVORIQUE",
    address: "3002 N. Mrytle Ave , Suite 1",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32209,
    phone: "(904) 355-5855",
    lat: 30.356201,
    lng: -81.676177,
    id: 1749,
  },
  {
    name: "ULLMANN, M",
    address: "12111 Panama City Beach",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32407,
    phone: "(850) 588-1066",
    lat: 30.204241,
    lng: -85.826513,
    id: 1750,
  },
  {
    name: "UMSTEAD, THOMAS",
    address: "8215 State Road 54",
    city: "TRINITY",
    state: "FL",
    zip: 34655,
    phone: "(727) 376-2229",
    lat: 28.207776,
    lng: -82.677944,
    id: 1751,
  },
  {
    name: "UPHUES, MICHAEL",
    address: "724 Grand Avenue",
    city: "BILLINGS",
    state: "MT",
    zip: 59101,
    phone: "(406) 696-0409",
    lat: 45.783728,
    lng: -108.534267,
    id: 1752,
  },
  {
    name: "Uribazo Odio, Beatriz",
    address: "7444 Palm River Rd.",
    city: "TAMPA",
    state: "FL",
    zip: 33619,
    phone: "(813) 418-6599",
    lat: 27.942276,
    lng: -82.372362,
    id: 1753,
  },
  {
    name: "URICH, NORMAN",
    address: "1762 N FORT HARRISON AVE",
    city: "1762 NORTH FT. HARRISON AVE. CLEARWATER",
    state: "FL",
    zip: 33755,
    phone: "(727) 447-2267",
    lat: 27.985188,
    lng: -82.79621,
    id: 1754,
  },
  {
    name: "UTTLEY, THOMAS",
    address: "1012 Goodlette Road N. #100",
    city: "NAPLES",
    state: "FL",
    zip: 34102,
    phone: "(239) 330-7976",
    lat: 26.160502,
    lng: -81.790929,
    id: 1755,
  },
  {
    name: "VALDES, MARIA DEL CARMEN",
    address: "9778 SW 24TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33165,
    phone: "(305) 220-3600",
    lat: 25.745691,
    lng: -80.352228,
    id: 1756,
  },
  {
    name: "VALLADARES, JOSE",
    address: "2660 SW 3 ST",
    city: "MIAMI",
    state: "FL",
    zip: 33135,
    phone: "(786) 281-1005",
    lat: 25.769293,
    lng: -80.238649,
    id: 1757,
  },
  {
    name: "VALLRUGO, KRISTINE",
    address: "2480 FRUITVILLE ROAD Suite 11",
    city: "SARASOTA",
    state: "FL",
    zip: 34237,
    phone: "(941) 504-2275",
    lat: 27.337156,
    lng: -82.52057,
    id: 1758,
  },
  {
    name: "VAN BENSCHOTEN, CHRISTOPHER",
    address: "Suite 10730 Gandy Blvd N",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33702,
    phone: "(855) 949-2266",
    lat: 27.863201,
    lng: -82.631509,
    id: 1759,
  },
  {
    name: "VAN DIEPEN, PATRICIA",
    address: "279 SOUTH YONGE STREET",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 673-2133",
    lat: 29.276033,
    lng: -81.058046,
    id: 1760,
  },
  {
    name: "VANCE, NANCY",
    address: "3060 TAMIAMI TRAIL NORTH , SUITE 201",
    city: "NAPLES",
    state: "FL",
    zip: 34103,
    phone: "(239) 261-0074",
    lat: 26.182691,
    lng: -81.79854,
    id: 1761,
  },
  {
    name: "VANDER WOUDE, MATTHEW",
    address: "8201 WEST BROWARD BLVD.",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 473-6600",
    lat: 26.123303,
    lng: -80.259855,
    id: 1762,
  },
  {
    name: "vanderveer, andrew",
    address: "211 Pineneedle Dr.",
    city: "BRADENTON",
    state: "FL",
    zip: 34210,
    phone: "(919) 360-0822",
    lat: 27.451958,
    lng: -82.597628,
    id: 1763,
  },
  {
    name: "VANGELDER, JAMES",
    address: "1150 N 35TH AVE , STE 620",
    city: "HOLLYWOOD",
    state: "FL",
    zip: 33021,
    phone: "(954) 962-0338",
    lat: 26.020476,
    lng: -80.178827,
    id: 1764,
  },
  {
    name: "VANNIER, THERESA",
    address: "551 Healthcare Drive",
    city: "DAYTONA BEACH",
    state: "FL",
    zip: 32114,
    phone: "(386) 323-7500",
    lat: 28.904171,
    lng: -81.287565,
    id: 1765,
  },
  {
    name: "VARNEY, ELLA",
    address: "7716 Club Ln",
    city: "SARASOTA",
    state: "FL",
    zip: 34238,
    phone: "(941) 350-1808",
    lat: 27.242382,
    lng: -82.478809,
    id: 1766,
  },
  {
    name: "VASQUEZ, VICTOR",
    address: "551 Morningside Dr.",
    city: "MIAMI SPRINGS",
    state: "FL",
    zip: 33166,
    phone: "(718) 791-4957",
    lat: 25.817442,
    lng: -80.280556,
    id: 1767,
  },
  {
    name: "VASS, SUZANNA",
    address: "1525 S. Tamiami Trail Suite 603f",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(941) 275-2968",
    lat: 27.078614,
    lng: -82.427935,
    id: 1768,
  },
  {
    name: "VAYSBERG, MIKHAIL",
    address: "422 422 S. Alafaya Trail , suite # 26",
    city: "ORLANDO",
    state: "FL",
    zip: 32828,
    phone: "(407) 966-4941",
    lat: 28.539955,
    lng: -81.20734,
    id: 1769,
  },
  {
    name: "VAZQUEZ, SUZEL",
    address: "6705 SW 57TH AVE SUITE 408",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33143,
    phone: "(786) 953-6629",
    lat: 25.706359,
    lng: -80.284519,
    id: 1770,
  },
  {
    name: "VEGA, DIMARA",
    address: "119 OAKFIELD DR",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 681-5551",
    lat: 27.933626,
    lng: -82.287141,
    id: 1771,
  },
  {
    name: "VEL, JEAN-JACQUE",
    address: "9981 S Healthpark Dr , suite 21330",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 343-2052",
    lat: 26.507377,
    lng: -81.913104,
    id: 1772,
  },
  {
    name: "VELEZ, FRANK",
    address: "17 COQUINA RIDGE WAY",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(386) 589-1114",
    lat: 29.263886,
    lng: -81.141836,
    id: 1773,
  },
  {
    name: "VELEZ RUIZ, NAYMEE'",
    address: "1120 NW 14TH STREET",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(404) 778-3444",
    lat: 25.787382,
    lng: -80.212899,
    id: 1774,
  },
  {
    name: "VENDRYES, CHRISTOPHER",
    address: "9526 NEW 2nd ave Suite #101",
    city: "MIAMI SHORES",
    state: "FL",
    zip: 33138,
    phone: "(305) 576-5200",
    lat: 25.862892,
    lng: -80.193715,
    id: 1775,
  },
  {
    name: "VERA, ALBERTO",
    address: "1713 S.W. Health Parkway , SUITE # 2",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 692-8719",
    lat: 26.271837,
    lng: -81.785445,
    id: 1776,
  },
  {
    name: "VERAS, LUIS",
    address: "747 PONCE DE LEON BLVD #606",
    city: "CORAL GABLES",
    state: "FL",
    zip: 33134,
    phone: "(305) 444-7779",
    lat: 25.76518,
    lng: -80.25825,
    id: 1777,
  },
  {
    name: "VERMA, DEEPA",
    address: "3165 N. MCMULLEN BOOTH RD Suite D-2",
    city: "CLEARWATER",
    state: "FL",
    zip: 33761,
    phone: "(727) 754-2936",
    lat: 28.035848,
    lng: -82.708905,
    id: 1778,
  },
  {
    name: "VERNIER, ERIC",
    address: "5129 S. Lakeland Dr. , Unit 2",
    city: "LAKELAND",
    state: "FL",
    zip: 33813,
    phone: "(863) 232-4323",
    lat: 27.972203,
    lng: -81.964317,
    id: 1779,
  },
  {
    name: "VIAMONTES, LOUIS",
    address: "4440 PGA BLVD suite 600",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(772) 486-2538",
    lat: 26.8382,
    lng: -80.10432,
    id: 1780,
  },
  {
    name: "VICENCIO, RONALD",
    address: "4953 Van Dyke Rd",
    city: "LUTZ",
    state: "FL",
    zip: 33558,
    phone: "(813) 544-1465",
    lat: 28.128673,
    lng: -82.522068,
    id: 1781,
  },
  {
    name: "VIEIRA, LEONARDO",
    address: "2044 TRINITY OAKS BLVD. SUITE 220",
    city: "NEW PORT RICHEY",
    state: "FL",
    zip: 34655,
    phone: "(727) 645-6900",
    lat: 28.189071,
    lng: -82.676,
    id: 1782,
  },
  {
    name: "VIGLIONE, DEBORAH",
    address: "103 NIGHTINGALE LANE",
    city: "GULF BREEZE",
    state: "FL",
    zip: 32561,
    phone: "(850) 934-8138",
    lat: 30.36359,
    lng: -87.170497,
    id: 1783,
  },
  {
    name: "VILLANO, KATHRYN",
    address: "217 NW 15th Street",
    city: "MIAMI",
    state: "FL",
    zip: 33136,
    phone: "(786) 514-0422",
    lat: 25.789901,
    lng: -80.199246,
    id: 1784,
  },
  {
    name: "VINCENTY, CLAUDIO",
    address: "10475 CENTURION PARKWAY N , SUITE 201",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32256,
    phone: "(904) 223-3321",
    lat: 30.248555,
    lng: -81.542518,
    id: 1785,
  },
  {
    name: "VINDHYA, RAJESH",
    address: "10051 5TH STREET NORTH , SUITE 200",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33702,
    phone: "(845) 535-1075",
    lat: 27.863411,
    lng: -82.639974,
    id: 1786,
  },
  {
    name: "VINTIMILLA, MARIA",
    address: "1055 N DIXIE FREEWAY , SUITE 1",
    city: "NEW SMYRNA BEACH",
    state: "FL",
    zip: 32168,
    phone: "(386) 416-0238",
    lat: 29.041231,
    lng: -80.933533,
    id: 1787,
  },
  {
    name: "VISSER, JESSICA",
    address: "4500 W midway road",
    city: "FORT PIERCE",
    state: "FL",
    zip: 34981,
    phone: "(772) 475-5369",
    lat: 27.376608,
    lng: -80.372272,
    id: 1788,
  },
  {
    name: "VON BARGEN, WILLIAM",
    address: "2500 Citrus Blvd",
    city: "LEESBURG",
    state: "FL",
    zip: 34748,
    phone: "(407) 450-6991",
    lat: 28.843853,
    lng: -81.895941,
    id: 1789,
  },
  {
    name: "VON TOBEL, TRAVIS",
    address: "4100 Southpoint Dr. E , Suite 1",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 647-5266",
    lat: 30.256447,
    lng: -81.585338,
    id: 1790,
  },
  {
    name: "VRINDAVANAM, NANDAGOPAL",
    address: "6555 CORTEZ RD W",
    city: "BRADENTON",
    state: "FL",
    zip: 34210,
    phone: "(727) 344-6569",
    lat: 27.462145,
    lng: -82.626318,
    id: 1791,
  },
  {
    name: "WAHLESTEDT, LISA",
    address: "10355 SW 92nd Street",
    city: "KENDALL",
    state: "FL",
    zip: 33176,
    phone: "(561) 603-0074",
    lat: 25.683715,
    lng: -80.363387,
    id: 1792,
  },
  {
    name: "WAITE, NORMA",
    address: "6000 TURKEY LAKE RD STE 112",
    city: "ORLANDO",
    state: "FL",
    zip: 32819,
    phone: "(407) 363-9499",
    lat: 28.476873,
    lng: -81.475017,
    id: 1793,
  },
  {
    name: "WALHA, SANTOKH",
    address: "9371 Cypress Lake Dr Suite 16",
    city: "FORT MYERS",
    state: "FL",
    zip: 33919,
    phone: "(239) 454-0500",
    lat: 26.544657,
    lng: -81.910088,
    id: 1794,
  },
  {
    name: "WALKER, CHRISTOPHER",
    address: "801 N. ORANGE AVE STE 710",
    city: "ORLANDO",
    state: "FL",
    zip: 32801,
    phone: "(407) 648-9400",
    lat: 28.555124,
    lng: -81.379066,
    id: 1795,
  },
  {
    name: "Walker, Penny",
    address: "914 Windsor Lane",
    city: "KEY WEST",
    state: "FL",
    zip: 33040,
    phone: "(985) 249-3772",
    lat: 24.553887,
    lng: -81.795871,
    id: 1796,
  },
  {
    name: "WALLACE, ARLIE",
    address: "3300 SOUTH TAMIAMI TRAIL , SUITE 5",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 366-0664",
    lat: 27.30436,
    lng: -82.53024,
    id: 1797,
  },
  {
    name: "WALLIS, B",
    address: "6152 WEST CORPORATE OAKS DRIVE",
    city: "CRYSTAL RIVER",
    state: "FL",
    zip: 34429,
    phone: "(352) 564-3900",
    lat: 28.879335,
    lng: -82.53691,
    id: 1798,
  },
  {
    name: "WALTER, MARK",
    address: "2068 Hawthorne Street , SUITE 202",
    city: "SARASOTA",
    state: "FL",
    zip: 34239,
    phone: "(941) 955-4325",
    lat: 27.31824,
    lng: -82.52837,
    id: 1799,
  },
  {
    name: "WANDLER, KEVIN",
    address: "Suite 100 SE Third Avenue, 633 UMATILLA BLVD",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33394,
    phone: "(754) 300-3120",
    lat: 26.122063,
    lng: -80.138967,
    id: 1800,
  },
  {
    name: "Ward, Chad",
    address: "1300 Miccosukee Road",
    city: "TALLAHASSEE",
    state: "FL",
    zip: 32308,
    phone: "(229) 894-2472",
    lat: 30.456666,
    lng: -84.261692,
    id: 1801,
  },
  {
    name: "WARD, ELAINE",
    address: "4930 S SUNCOAST BLVD",
    city: "HOMOSASSA",
    state: "FL",
    zip: 34446,
    phone: "(352) 628-7747",
    lat: 28.790135,
    lng: -82.568588,
    id: 1802,
  },
  {
    name: "WARD, GREGORY",
    address: "10627 Hillary Court",
    city: "BATON ROUGE",
    state: "LA",
    zip: 70810,
    phone: "(225) 766-1616",
    lat: 30.365398,
    lng: -91.103347,
    id: 1803,
  },
  {
    name: "Ward Jr., William",
    address: "5597 N Dixie Hwy",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33334,
    phone: "(954) 542-4273",
    lat: 26.19534,
    lng: -80.133022,
    id: 1804,
  },
  {
    name: "Warhurst, Michael",
    address: "7031 SW 62nd Ave",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(904) 568-6116",
    lat: 25.705584,
    lng: -80.293115,
    id: 1805,
  },
  {
    name: "WARSHAW, IRA",
    address: "2611 Embassy Drive",
    city: "WEST PALM BEACH",
    state: "FL",
    zip: 33401,
    phone: "(561) 676-1979",
    lat: 26.736981,
    lng: -80.081036,
    id: 1806,
  },
  {
    name: "WATREN, DONALD",
    address: "1500 N DIXIE HWY, STE 102",
    city: "WEST PALM BCH",
    state: "FL",
    zip: 33401,
    phone: "(561) 655-8990",
    lat: 26.726673,
    lng: -80.052884,
    id: 1807,
  },
  {
    name: "WATRY, STEVEN",
    address: "902 N. DIXIE FREEWAY",
    city: "NEW SMYRNA BEACH",
    state: "FL",
    zip: 32168,
    phone: "(386) 663-3003",
    lat: 29.038482,
    lng: -80.933147,
    id: 1808,
  },
  {
    name: "WATT, JASON",
    address: "3601 NW Federal highway",
    city: "JENSEN BEACH",
    state: "FL",
    zip: 34957,
    phone: "(772) 208-3057",
    lat: 27.248608,
    lng: -80.273371,
    id: 1809,
  },
  {
    name: "WEAVER, HOWARD",
    address: "3120 CORRINE DR",
    city: "ORLANDO",
    state: "FL",
    zip: 32803,
    phone: "(407) 395-4707",
    lat: 28.567629,
    lng: -81.344552,
    id: 1810,
  },
  {
    name: "WEINBERGER, JOEL",
    address: "720 OAK COMMONS BLVD",
    city: "KISSIMMEE",
    state: "FL",
    zip: 34741,
    phone: "(407) 933-2522",
    lat: 28.30188,
    lng: -81.411585,
    id: 1811,
  },
  {
    name: "WEINER, MICHELLE",
    address: "21000 NE 28 Ave , SUITE 104",
    city: "AVENTURA",
    state: "FL",
    zip: 33180,
    phone: "(305) 974-5533",
    lat: 25.96952,
    lng: -80.146259,
    id: 1812,
  },
  {
    name: "WEINER, MITCHELL",
    address: "5350 Spring Hill Dr.",
    city: "SPRING HILL",
    state: "FL",
    zip: 34606,
    phone: "(352) 688-8116",
    lat: 28.457547,
    lng: -82.625036,
    id: 1813,
  },
  {
    name: "WEINSTEIN, ALAN",
    address: "111 SE 1st Avenue , Suite 150",
    city: "GAINESVILLE",
    state: "FL",
    zip: 32601,
    phone: "(352) 792-6707",
    lat: 29.650925,
    lng: -82.323334,
    id: 1814,
  },
  {
    name: "WEINSTEIN, LAWRENCE",
    address: "9868 SOUTH STATE ROAD 7 , SUITE 335",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33472,
    phone: "(561) 200-3583",
    lat: 26.530016,
    lng: -80.204474,
    id: 1815,
  },
  {
    name: "WEISMAN, PAUL",
    address: "1050 SW 116th Way",
    city: "FORT LAUDERDALE",
    state: "FL",
    zip: 33325,
    phone: "(944) 881-5551",
    lat: 26.106718,
    lng: -80.303872,
    id: 1816,
  },
  {
    name: "WEISS, CAROLINA",
    address: "1127 SOUTH UNIVERSITY DR.",
    city: "PLANTATION",
    state: "FL",
    zip: 33324,
    phone: "(954) 401-0039",
    lat: 26.106234,
    lng: -80.250237,
    id: 1817,
  },
  {
    name: "WEISS, DELIA",
    address: "500 GULFSTREAM BLVD. , Suite 202",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33483,
    phone: "(561) 243-8783",
    lat: 26.491149,
    lng: -80.064819,
    id: 1818,
  },
  {
    name: "WELLS, FLOYD",
    address: "642 woodbridge drive",
    city: "ORMOND BEACH",
    state: "FL",
    zip: 32174,
    phone: "(941) 779-6639",
    lat: 29.381069,
    lng: -81.161138,
    id: 1819,
  },
  {
    name: "Welter, Vincent",
    address: "114 W Underwood St. , STE 2",
    city: "ORLANDO",
    state: "FL",
    zip: 32806,
    phone: "(407) 751-5005",
    lat: 28.526624,
    lng: -81.380749,
    id: 1820,
  },
  {
    name: "WERTHEIM, MICHAEL",
    address: "1871 S.E. TIFFANY AVENUE , SUITE 100",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-5666",
    lat: 27.291802,
    lng: -80.294805,
    id: 1821,
  },
  {
    name: "WESTON, KHALILAH",
    address: "995 995 16th St N",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33705,
    phone: "(727) 230-3423",
    lat: 27.781789,
    lng: -82.654708,
    id: 1822,
  },
  {
    name: "WESTWOOD, GREG",
    address: "25827 SE 19 HWY",
    city: "OLD TOWN",
    state: "FL",
    zip: 32680,
    phone: "(352) 542-0068",
    lat: 29.602576,
    lng: -82.980728,
    id: 1823,
  },
  {
    name: "WHEATLEY, RUSSELL",
    address: "HSU Cl 1 Harpst St.",
    city: "ARCATA",
    state: "CA",
    zip: 95521,
    phone: "(707) 826-3146",
    lat: 40.855672,
    lng: -124.06672,
    id: 1824,
  },
  {
    name: "WHITE, BRIAN",
    address: "801 NORTH ORANGE AVE. , SUITE 600",
    city: "ORLANDO",
    state: "FL",
    zip: 32801,
    phone: "(407) 841-2100",
    lat: 28.555124,
    lng: -81.379066,
    id: 1825,
  },
  {
    name: "WHITE, DAVID",
    address: "463832 SR 200",
    city: "YULEE",
    state: "FL",
    zip: 32097,
    phone: "(904) 225-2311",
    lat: 30.625619,
    lng: -81.548041,
    id: 1826,
  },
  {
    name: "WHITTIER, SCOTT",
    address: "491 MARINER BLVD",
    city: "SPRING HILL",
    state: "FL",
    zip: 34609,
    phone: "(352) 515-6000",
    lat: 28.442124,
    lng: -82.543521,
    id: 1827,
  },
  {
    name: "WHU, DANNY",
    address: "1 SW 129 Ave. Suite 405",
    city: "PEMBROKE PINES",
    state: "FL",
    zip: 33027,
    phone: "(954) 909-2912",
    lat: 26.00759,
    lng: -80.32036,
    id: 1828,
  },
  {
    name: "WIDMER, DEBORAH",
    address: "7700 West Sunrise BLVD",
    city: "PLANTATION",
    state: "FL",
    zip: 33322,
    phone: "(800) 437-2672",
    lat: 26.147083,
    lng: -80.254615,
    id: 1829,
  },
  {
    name: "WILEY, MICHAEL",
    address: "3901 HENDERSON BLVD.",
    city: "TAMPA",
    state: "FL",
    zip: 33629,
    phone: "(813) 287-8439",
    lat: 27.931906,
    lng: -82.508459,
    id: 1830,
  },
  {
    name: "WILF, LARRY",
    address: "A201 693 PONTE VEDRA BLVD",
    city: "PONTE VEDRA",
    state: "FL",
    zip: 32082,
    phone: "(904) 200-2409",
    lat: 30.227465,
    lng: -81.380082,
    id: 1831,
  },
  {
    name: "WILKHU, HARSHDEEP",
    address: "851 TRAFALGAR COURT , SUITE 200E",
    city: "MAITLAND",
    state: "FL",
    zip: 32751,
    phone: "(321) 422-7156",
    lat: 28.636272,
    lng: -81.39101,
    id: 1832,
  },
  {
    name: "WILKINS, ROBERT",
    address: "613 Oakfield Drive",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 565-3322",
    lat: 27.93387,
    lng: -82.292215,
    id: 1833,
  },
  {
    name: "WILLIAMS, AMY",
    address: "4711 Curry Ford Rd , Suite B",
    city: "ORLANDO",
    state: "FL",
    zip: 32812,
    phone: "(954) 444-9909",
    lat: 28.524666,
    lng: -81.327717,
    id: 1834,
  },
  {
    name: "WILLIAMS, KAREN",
    address: "3075 E COMMERCIAL BLVD",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33308,
    phone: "(954) 491-6200",
    lat: 26.190376,
    lng: -80.104861,
    id: 1835,
  },
  {
    name: "WILLIAMS, LORI",
    address: "23450 Via Coconut Point",
    city: "ESTERO",
    state: "FL",
    zip: 34135,
    phone: "(239) 468-1000",
    lat: 26.395873,
    lng: -81.80729,
    id: 1836,
  },
  {
    name: "WILLIAMS-THIRSTON, MARGARET",
    address: "204B ELLEN LANE",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32408,
    phone: "(850) 588-6850",
    lat: 30.166022,
    lng: -85.755192,
    id: 1837,
  },
  {
    name: "WILLIS, SCOTT",
    address: "4933 NW 81st Ave",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33067,
    phone: "(561) 213-3482",
    lat: 26.291097,
    lng: -80.232924,
    id: 1838,
  },
  {
    name: "WILSON, JOHN",
    address: "6101 PINE RIDGE ROAD",
    city: "NAPLES",
    state: "FL",
    zip: 34119,
    phone: "(239) 348-4222",
    lat: 26.213827,
    lng: -81.732945,
    id: 1839,
  },
  {
    name: "WILSON, LATRESIA",
    address: "1500 SW 1ST AVE",
    city: "OCALA",
    state: "FL",
    zip: 34474,
    phone: "(352) 351-7600",
    lat: 29.174979,
    lng: -82.138906,
    id: 1840,
  },
  {
    name: "WILSON-KING, GENESTER",
    address: "1540 INTERNATIONAL PKWY , SUITE 2000",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 536-5125",
    lat: 28.785012,
    lng: -81.35864,
    id: 1841,
  },
  {
    name: "WISHNOV, BRUCE",
    address: "22065 STATE ROAD 7",
    city: "BOCA RATON",
    state: "FL",
    zip: 33428,
    phone: "(561) 488-4847",
    lat: 26.34817,
    lng: -80.204036,
    id: 1842,
  },
  {
    name: "WOLEK, EMILY",
    address: "15620 McGregor Blvd , Suite 100",
    city: "FORT MYERS",
    state: "FL",
    zip: 33908,
    phone: "(239) 689-6819",
    lat: 26.514936,
    lng: -81.949055,
    id: 1843,
  },
  {
    name: "WOLF, AIZIK",
    address: "6129 SW 70 STREET",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(786) 871-6800",
    lat: 25.706267,
    lng: -80.292676,
    id: 1844,
  },
  {
    name: "WOLF ROSENBERG, AVA",
    address: "2300 NORTH COMMERCE PARKWAY , SUITE 313",
    city: "WESTON",
    state: "FL",
    zip: 33326,
    phone: "(954) 217-2707",
    lat: 26.089768,
    lng: -80.368525,
    id: 1845,
  },
  {
    name: "WOLFF, RICHARD",
    address: "2002 MCMILLAN AVENUE",
    city: "BAY MINETTE",
    state: "AL",
    zip: 36507,
    phone: "(251) 580-8475",
    lat: 30.903213,
    lng: -87.786724,
    id: 1846,
  },
  {
    name: "WOLPMANN, MICHAEL",
    address: "959 E Venice Ave",
    city: "VENICE",
    state: "FL",
    zip: 34285,
    phone: "(941) 485-5700",
    lat: 27.100253,
    lng: -82.426726,
    id: 1847,
  },
  {
    name: "WONG, ANN-MARIE",
    address: "375 NE 54th Street, Suite 7",
    city: "MIAMI",
    state: "FL",
    zip: 33137,
    phone: "(786) 842-7001",
    lat: 25.825733,
    lng: -80.188204,
    id: 1848,
  },
  {
    name: "WONG, SIMON",
    address: "9750 N.W. 33RD STREET , SUITE 210",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 603-5508",
    lat: 26.270674,
    lng: -80.256426,
    id: 1849,
  },
  {
    name: "WOODARD, DANIEL",
    address: "600 Florida Ave Suite 204",
    city: "COCOA",
    state: "FL",
    zip: 32922,
    phone: "(321) 794-3531",
    lat: 28.3533,
    lng: -80.727496,
    id: 1850,
  },
  {
    name: "WOOLERY, WILLIAM",
    address: "11111 Panama City Beach Parkway",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32413,
    phone: "(850) 770-3270",
    lat: 30.194955,
    lng: -85.810848,
    id: 1851,
  },
  {
    name: "WOYOME, STEPHEN",
    address: "9123 N. MILITARY TRAIL STE 102",
    city: "PALM BEACH GARDENS",
    state: "FL",
    zip: 33410,
    phone: "(561) 630-9339",
    lat: 26.81215,
    lng: -80.106741,
    id: 1852,
  },
  {
    name: "WYATT, JOHN",
    address: "821 Cypress Blvd , 508",
    city: "POMPANO BEAC",
    state: "FL",
    zip: 33069,
    phone: "(719) 251-1880",
    lat: 26.212698,
    lng: -80.182606,
    id: 1853,
  },
  {
    name: "YATES, TIMOTHY",
    address: "4300 Alton Road, Suite 2071",
    city: "MIAMI",
    state: "FL",
    zip: 33140,
    phone: "(402) 304-1073",
    lat: 25.815456,
    lng: -80.138213,
    id: 1854,
  },
  {
    name: "YATHIRAJ, SANJAY",
    address: "2902 59th Street West , Suite D",
    city: "PALMA SOLA",
    state: "FL",
    zip: 34209,
    phone: "(941) 877-7007",
    lat: 27.474426,
    lng: -82.621099,
    id: 1855,
  },
  {
    name: "YCAZA, LUIS",
    address: "1839 Central Ave",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33713,
    phone: "(813) 451-8646",
    lat: 27.771573,
    lng: -82.658643,
    id: 1856,
  },
  {
    name: "YCAZA, ROBERT",
    address: "2001 MANATEE AVENUE EAST , Suite 103",
    city: "BRADENTON",
    state: "FL",
    zip: 34208,
    phone: "(941) 803-8395",
    lat: 27.496628,
    lng: -82.539012,
    id: 1857,
  },
  {
    name: "YECKES RODIN, HEATHER",
    address: "1871 S.E. TIFFANY AVE , SUITE 100",
    city: "PORT SAINT LUCIE",
    state: "FL",
    zip: 34952,
    phone: "(772) 335-5666",
    lat: 27.291802,
    lng: -80.294805,
    id: 1858,
  },
  {
    name: "YEE, GARVIN",
    address: "460 N STATE ROAD 7 STE 300",
    city: "ROYAL PALM BEACH",
    state: "FL",
    zip: 33411,
    phone: "(561) 798-6600",
    lat: 26.683565,
    lng: -80.200341,
    id: 1859,
  },
  {
    name: "Yoseph, Benyam",
    address: "3601 SW 160th Ave Ste 250",
    city: "MIRAMAR",
    state: "FL",
    zip: 33027,
    phone: "(305) 866-7123",
    lat: 25.976284,
    lng: -80.358697,
    id: 1860,
  },
  {
    name: "YOUNG, JAMES",
    address: "1210 South International Pkwy , STE 174",
    city: "LAKE MARY",
    state: "FL",
    zip: 32746,
    phone: "(407) 620-8508",
    lat: 28.755624,
    lng: -81.369343,
    id: 1861,
  },
  {
    name: "YOUNG, RAMONA",
    address: "391 6th Avenue West",
    city: "BRADENTON",
    state: "FL",
    zip: 34205,
    phone: "(941) 782-4150",
    lat: 27.493645,
    lng: -82.566555,
    id: 1862,
  },
  {
    name: "YOUSEF, JONATHAN",
    address: "5801 ARGERIAN DRIVE , SUITE 103",
    city: "WESLEY CHAPEL",
    state: "FL",
    zip: 33545,
    phone: "(813) 991-4991",
    lat: 28.241124,
    lng: -82.326955,
    id: 1863,
  },
  {
    name: "YU, KEWEI",
    address: "1865 Veterans Park Dr #101",
    city: "NAPLES",
    state: "FL",
    zip: 34109,
    phone: "(239) 254-7778",
    lat: 26.269409,
    lng: -81.782052,
    id: 1864,
  },
  {
    name: "YUKNA, BERNARD",
    address: "625 Sixth Avenue S , Suite 475",
    city: "SAINT PETERSBURG",
    state: "FL",
    zip: 33701,
    phone: "(727) 209-5470",
    lat: 27.775654,
    lng: -82.64092,
    id: 1865,
  },
  {
    name: "ZABIH, ISMAIL",
    address: "12007 PANAMA CITY BEACH PARKWAY",
    city: "PANAMA CITY BEACH",
    state: "FL",
    zip: 32407,
    phone: "(850) 234-5151",
    lat: 30.203354,
    lng: -85.824781,
    id: 1866,
  },
  {
    name: "ZACCARI, JAMES",
    address: "2020 TOWN CENTER BLVD , STE B",
    city: "BRANDON",
    state: "FL",
    zip: 33511,
    phone: "(813) 315-1500",
    lat: 27.927008,
    lng: -82.317621,
    id: 1867,
  },
  {
    name: "ZACHAR, LENKA",
    address: "#404 3117 Spring Glen Rd",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32207,
    phone: "(888) 908-0143",
    lat: 30.293803,
    lng: -81.609765,
    id: 1868,
  },
  {
    name: "ZAGER, MARK",
    address: "7540 SW 61ST AVE",
    city: "SOUTH MIAMI",
    state: "FL",
    zip: 33143,
    phone: "(305) 666-8691",
    lat: 25.700943,
    lng: -80.292586,
    id: 1869,
  },
  {
    name: "ZAIAC, MARTIN",
    address: "4308 ALTON ROAD SUITE 750",
    city: "MIAMI BEACH",
    state: "FL",
    zip: 33140,
    phone: "(305) 532-4478",
    lat: 25.815364,
    lng: -80.13773,
    id: 1870,
  },
  {
    name: "ZALIS, BRIAN",
    address: "2300 W 84TH ST, STE 502",
    city: "HIALEAH",
    state: "FL",
    zip: 33016,
    phone: "(305) 821-6600",
    lat: 25.89692,
    lng: -80.3298,
    id: 1871,
  },
  {
    name: "ZAMBELLI, PATRICIA",
    address: "1820 commerce ave",
    city: "VERO BEACH",
    state: "FL",
    zip: 32960,
    phone: "(772) 696-2975",
    lat: 27.635093,
    lng: -80.395284,
    id: 1872,
  },
  {
    name: "ZANNIS, JASON",
    address: "1500 N University Dr. Suite 11",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33071,
    phone: "(954) 675-4341",
    lat: 26.249723,
    lng: -80.254193,
    id: 1873,
  },
  {
    name: "Zapata, Sheena",
    address: "8900 N KENDALL DR",
    city: "MIAMI",
    state: "FL",
    zip: 33176,
    phone: "(786) 596-2000",
    lat: 25.68445,
    lng: -80.338947,
    id: 1874,
  },
  {
    name: "ZARRANZ, ROBERT",
    address: "11725 Collier Blvd Suite H, 423 LINDEN LANE",
    city: "NAPLES",
    state: "FL",
    zip: 34116,
    phone: "(239) 300-4205",
    lat: 26.185744,
    lng: -81.68717,
    id: 1875,
  },
  {
    name: "ZAVOYSKI, STEPHEN",
    address: "5130 Linton Blvd , SUITE B2",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33484,
    phone: "(561) 665-7701",
    lat: 26.439168,
    lng: -80.123854,
    id: 1876,
  },
  {
    name: "ZAWADZKI, EDWARD",
    address: "4600 MILITARY TRL STE 103",
    city: "JUPITER",
    state: "FL",
    zip: 33458,
    phone: "(561) 249-7400",
    lat: 26.892246,
    lng: -80.103324,
    id: 1877,
  },
  {
    name: "ZEB, SHAHID",
    address: "4123 UNIVERSITY BLVD SOUTH , SUITE D",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32216,
    phone: "(904) 367-4460",
    lat: 30.281853,
    lng: -81.602702,
    id: 1878,
  },
  {
    name: "ZELIKOVSKY, SHIRLEY",
    address: "2600 West Hall Lane , Box 300",
    city: "MAITLAND",
    state: "FL",
    zip: 32751,
    phone: "(407) 200-2807",
    lat: 28.625476,
    lng: -81.36459,
    id: 1879,
  },
  {
    name: "ZELINKA, MAUREEN",
    address: "4995 SOUTH US 1",
    city: "FT PIERCE",
    state: "FL",
    zip: 34982,
    phone: "(772) 465-3225",
    lat: 27.374503,
    lng: -80.327003,
    id: 1880,
  },
  {
    name: "ZELLER, DONALD",
    address: "190 SE 9TH STREET",
    city: "POMPANO BEACH",
    state: "FL",
    zip: 33060,
    phone: "(954) 776-3909",
    lat: 26.218365,
    lng: -80.121057,
    id: 1881,
  },
  {
    name: "ZEMLIN, RICHARD",
    address: "2280 W. Atlantic Ave.",
    city: "DELRAY BEACH",
    state: "FL",
    zip: 33445,
    phone: "(561) 278-3134",
    lat: 26.458856,
    lng: -80.096,
    id: 1882,
  },
  {
    name: "ZFAZ, MARTIN",
    address: "3515 Del Prado Blvd, South , Suite 101",
    city: "CAPE CORAL",
    state: "FL",
    zip: 33904,
    phone: "(239) 268-3636",
    lat: 26.586705,
    lng: -81.941613,
    id: 1883,
  },
  {
    name: "ZHANG, HAITAO",
    address: "10250 NORMANDY BLVD , SUITE 703",
    city: "JACKSONVILLE",
    state: "FL",
    zip: 32221,
    phone: "(904) 495-7200",
    lat: 30.275611,
    lng: -81.829107,
    id: 1884,
  },
  {
    name: "ZIMBERG, STEPHEN",
    address: "2830 NE 23rd Street",
    city: "FT LAUDERDALE",
    state: "FL",
    zip: 33305,
    phone: "(954) 465-6745",
    lat: 26.154014,
    lng: -80.108228,
    id: 1885,
  },
  {
    name: "ZIPPER, JEFFREY",
    address: "2900 N.MILITARY TRAIL , SUITE 241",
    city: "BOCA RATON",
    state: "FL",
    zip: 33431,
    phone: "(561) 235-7666",
    lat: 26.37789,
    lng: -80.12097,
    id: 1886,
  },
  {
    name: "ZIPPER, KIM",
    address: "1472 N. HARBOR CITY BLVD",
    city: "MELBOURNE",
    state: "FL",
    zip: 32935,
    phone: "(321) 574-5376",
    lat: 28.130756,
    lng: -80.631161,
    id: 1887,
  },
  {
    name: "ziton, laura",
    address: "2929 UNIVERSITY DRIVE , SUITE 108",
    city: "CORAL SPRINGS",
    state: "FL",
    zip: 33065,
    phone: "(954) 340-1992",
    lat: 26.266852,
    lng: -80.24933,
    id: 1888,
  },
  {
    name: "ZOPO, ALEX",
    address: "11195 JOG RD SUITE 3",
    city: "BOYNTON BEACH",
    state: "FL",
    zip: 33437,
    phone: "(561) 733-9690",
    lat: 26.50855,
    lng: -80.14748,
    id: 1889,
  },
  {
    name: "ZUBKIN, ALLAN",
    address: "131 W MAIN ST.",
    city: "TAVARES",
    state: "FL",
    zip: 32778,
    phone: "(407) 405-1055",
    lat: 28.802223,
    lng: -81.728364,
    id: 1890,
  },
  {
    name: "ZUGHBI, CINDY",
    address: "13691 Metro Pkwy , Suite 400",
    city: "FORT MYERS",
    state: "FL",
    zip: 33912,
    phone: "(239) 440-6456",
    lat: 26.5437,
    lng: -81.852064,
    id: 1891,
  },
  {
    name: "ZWOLINSKI, RALPH",
    address: "3369 W. Locanda Cir.",
    city: "NEW SMYRNA BEACH",
    state: "FL",
    zip: 32168,
    phone: "(386) 846-7699",
    lat: 29.047477,
    lng: -81.034136,
    id: 1892,
  },
];
