import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import renderButtons from "../helpers/renderButtons";

const Hero = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const backgroundStyle = {
    backgroundColor: get(props, "primary.background_color"),
    color: get(props, "primary.text_color"),
  };

  return (
    <section className="hero-section padding" style={backgroundStyle}>
      <div className="container">
        <LazyLoadImage
          className="cover-img"
          wrapperClassName="background-img stretch"
          src={
            isMobile
              ? get(props, "primary.image.Mobile.url")
              : get(props, "primary.image.url")
          }
          alt={
            isMobile
              ? get(props, "primary.image.Mobile.alt")
              : get(props, "primary.image.alt")
          }
          effect="opacity"
        />
        <div className="copy-wrapper top">
          {get(props, "primary.logo.url") && (
            <img
              src={get(props, "primary.logo.url")}
              className="logo"
              alt=""
              role="presentation"
            />
          )}
          <PrismicRichText render={get(props, "primary.content")} />
          <div className="button-container mt-30">
            {renderButtons(get(props, "items"))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
